export const REGIONS = [
  "'asir region",
  "a'ana",
  "aargau",
  "abia state",
  "abidjan",
  "abkhazia",
  "abruzzo",
  "absheron rayon",
  "abu dhabi",
  "aceh",
  "achara",
  "acre",
  "ad dakhiliyah",
  "ad dhahirah",
  "adamaoua region",
  "adamawa",
  "adana",
  "addis ababa",
  "aden",
  "adiyaman",
  "adrar",
  "adygeya republic",
  "aerodrom",
  "afar region",
  "afyonkarahisar",
  "agadez",
  "agalega islands",
  "agder",
  "aghdam rayon",
  "aghdash rayon",
  "aghjabadi rayon",
  "agua grande",
  "aguascalientes",
  "ahafo",
  "ahal",
  "aichi",
  "aiga-i-le-tai",
  "aileu",
  "ainaro",
  "aiwo",
  "aizkraukles novads",
  "ajloun",
  "ajman",
  "akita",
  "aksaray",
  "aktyubinskaya oblast'",
  "akwa ibom state",
  "al anbar",
  "al asimah",
  "al aḩmadī",
  "al bahah region",
  "al batinah north governorate",
  "al batinah south",
  "al bayda",
  "al buraimi",
  "al butnan",
  "al farwaniyah",
  "al hudaydah",
  "al jabal al akhdar",
  "al jafarah",
  "al jawf",
  "al jawf region",
  "al jazīrah",
  "al jufrah",
  "al khalil",
  "al khor",
  "al kufrah",
  "al mahrah",
  "al mahwit",
  "al marj",
  "al marqab",
  "al qaḑārif",
  "al quds",
  "al wahat",
  "al wakrah",
  "al wusta governorate",
  "al-hasakah",
  "al-qassim region",
  "al-shahaniya",
  "alabama",
  "alagoas",
  "alaska",
  "alba",
  "alberta",
  "alborz province",
  "aleppo governorate",
  "alexandria",
  "algiers",
  "ali sabieh region",
  "alibori",
  "almaty",
  "almaty oblysy",
  "alo",
  "altai",
  "altai krai",
  "aluksnes novads",
  "alytus",
  "amanat alasimah",
  "amapa",
  "amasya",
  "amazonas",
  "amhara",
  "amman governorate",
  "ammochostos",
  "amnat charoen",
  "amur oblast",
  "an giang",
  "an najaf",
  "an nuqat al khams",
  "anabar",
  "anambra",
  "ancash",
  "andalusia",
  "andaman and nicobar",
  "andhra pradesh",
  "andijan region",
  "andorra la vella",
  "andrijevica",
  "anenii noi",
  "anetan",
  "ang thong",
  "anhui",
  "anibare",
  "ankara",
  "ankaran",
  "annaba",
  "annobon",
  "anse boileau",
  "anse etoile",
  "anse royale",
  "anse-aux-pins",
  "anse-la-raye",
  "anseba region",
  "antalya",
  "antioquia",
  "antofagasta",
  "anzoátegui",
  "aomori",
  "aosta valley",
  "appenzell ausserrhoden",
  "appenzell innerrhoden",
  "apulia",
  "apure",
  "aqaba",
  "aqmola oblysy",
  "ar-raqqah",
  "arachinovo",
  "arad",
  "aragatsotn",
  "aragon",
  "aragua",
  "ararat",
  "ardabil province",
  "ardahan",
  "arequipa",
  "arges",
  "arhangay aymag",
  "arizona",
  "arkansas",
  "arkhangelskaya",
  "armavir",
  "arta region",
  "artemisa",
  "artigas",
  "artvin",
  "arunachal pradesh",
  "arusha",
  "as-suwayda governorate",
  "ascension",
  "ashanti region",
  "ashgabat",
  "assaba",
  "assam",
  "astara",
  "astrakhan oblast",
  "asuncion",
  "aswan",
  "asyut",
  "atacama",
  "atafu",
  "atakora department",
  "atlantique department",
  "atlántico",
  "attapu",
  "attard",
  "attica",
  "atua",
  "atyrau oblysy",
  "au cap",
  "auckland",
  "australian capital territory",
  "autonomous region in muslim mindanao",
  "auvergne-rhone-alpes",
  "avannaata",
  "aveiro",
  "awdal",
  "ayacucho",
  "aydın",
  "ayeyarwady region",
  "aysén",
  "az zawiyah",
  "azad jammu and kashmir",
  "azores",
  "aïn defla",
  "aïn témouchent",
  "ağrı",
  "ağstafa",
  "ağsu",
  "aḑ ḑāli‘",
  "baa atholhu",
  "bacau",
  "badakhshan",
  "baden-württemberg",
  "badghis",
  "bafata",
  "baghdad",
  "baghlan",
  "bagmati province",
  "bago region",
  "bahia",
  "baie lazare",
  "baie sainte anne",
  "baiti",
  "baja california",
  "baja california sur",
  "bakool",
  "baku city",
  "baladiyat ad dawhah",
  "baladiyat ar rayyan",
  "baladiyat ash shamal",
  "baladiyat az za'ayin",
  "baladiyat umm salal",
  "balakǝn",
  "balearic islands",
  "bali",
  "balkan",
  "balkh",
  "balochistan",
  "balqa",
  "balvu novads",
  "balzan",
  "balzers",
  "balıkesir",
  "bamako region",
  "bamingui-bangoran",
  "bamyan",
  "banaadir",
  "bangka–belitung islands",
  "bangkok",
  "bangui",
  "banjul",
  "banska bystrica",
  "banteay meanchey",
  "banten",
  "bar",
  "baranya",
  "barbuda",
  "barda",
  "barh el gazel",
  "bari",
  "barima-waini region",
  "barinas",
  "baringo",
  "barisal division",
  "bartın",
  "bas-congo",
  "bas-sassandra",
  "basarabeasca",
  "basel-city",
  "basel-landschaft",
  "bashkortostan republic",
  "basilicate",
  "basque country",
  "basra",
  "basse-kotto",
  "batha region",
  "batken",
  "batman",
  "batna",
  "battambang",
  "baucau",
  "bauchi",
  "bauskas novads",
  "bavaria",
  "bay",
  "bay islands",
  "bay of plenty",
  "bayan-oelgiy aymag",
  "bayanhongor aymag",
  "bayburt",
  "bayelsa state",
  "beau vallon",
  "beheira",
  "beijing",
  "beja",
  "bekes county",
  "bel air",
  "bel ombre",
  "belait",
  "belgorod oblast",
  "belgrade",
  "belize district",
  "beltinci",
  "bender municipality",
  "benedikt",
  "bengkulu",
  "bengo province",
  "benguela",
  "beni abbes",
  "beni mellal-khenifra",
  "beni suweif",
  "benue state",
  "berane",
  "berea",
  "bern",
  "berovo",
  "berry islands district",
  "bethlehem",
  "beylagan rayon",
  "beyrouth",
  "bicol",
  "bihar",
  "bihor",
  "bijelo polje",
  "bilecik",
  "bilǝsuvar",
  "bingöl",
  "bioko norte",
  "bioko sur",
  "biombo",
  "birkirkara",
  "birzebbuga",
  "biskra",
  "bissau",
  "bistrica ob sotli",
  "bistrita-nasaud",
  "bitlis",
  "bitola",
  "bjelovar-bilogora",
  "black river district",
  "blagoevgrad",
  "blekinge county",
  "blida",
  "bloke",
  "blue nile",
  "boa vista",
  "bobonaro",
  "boe",
  "bogdanci",
  "bogota d.c.",
  "bogovinje",
  "bohinj",
  "boke region",
  "bokeo",
  "bolama",
  "bolikhamsai",
  "bolu",
  "bolívar",
  "bomet district",
  "bomi county",
  "bonaire",
  "bong county",
  "bono",
  "bono east",
  "bor",
  "bordj badji mokhtar",
  "bordj bou arréridj",
  "borgou department",
  "borkou region",
  "bormla",
  "borno state",
  "borough of arima",
  "borovnica",
  "borsod-abaúj-zemplén",
  "botosani",
  "boucle du mouhoun",
  "bouenza",
  "bougainville",
  "bouira",
  "boumerdes",
  "bourgogne-franche-comte",
  "braga",
  "bragança",
  "braila",
  "brakna",
  "brandenburg",
  "branicevo",
  "brasov",
  "bratislava",
  "brava",
  "brazzaville",
  "brda",
  "bremen",
  "brest",
  "brezovica",
  "briceni",
  "british columbia",
  "brittany",
  "brunei-muara district",
  "brussels capital",
  "brvenica",
  "bryansk oblast",
  "brčko",
  "buada",
  "bubanza province",
  "bucharest",
  "budapest",
  "budva",
  "bueng kan",
  "buenos aires",
  "buenos aires f.d.",
  "bujumbura mairie province",
  "bujumbura rural province",
  "bukhara",
  "bulawayo",
  "bulgan",
  "bumthang dzongkhag",
  "bungoma district",
  "burdur",
  "burgas",
  "burgenland",
  "buriram",
  "bursa",
  "bururi province",
  "buryatiya republic",
  "busan",
  "bushehr province",
  "busia district",
  "butha-buthe",
  "buzau",
  "bács-kiskun",
  "béchar",
  "béjaïa",
  "bíe",
  "bīnshangul gumuz",
  "cabinda",
  "cabo delgado province",
  "cacheu region",
  "cagayan valley",
  "cahul",
  "cairo governorate",
  "cajamarca",
  "calabarzon",
  "calabria",
  "calarasi",
  "california",
  "callao",
  "campania",
  "campeche",
  "can tho",
  "canakkale",
  "canaries",
  "canary islands",
  "canelones",
  "canillo",
  "cankova",
  "cankuzo province",
  "cantabria",
  "cantemir",
  "canterbury",
  "capellen",
  "capital region",
  "carabobo",
  "caraga",
  "caras-severin",
  "cargados carajos",
  "carinthia",
  "carriacou and petite martinique",
  "casablanca-settat",
  "cascade",
  "cascades region",
  "castello di acquaviva",
  "castello di borgo maggiore",
  "castello di domagnano",
  "castello di faetano",
  "castello di montegiardino",
  "castello di san marino citta",
  "castelo branco",
  "castille and león",
  "castille-la mancha",
  "castries",
  "catalonia",
  "catamarca",
  "cayo district",
  "ceara",
  "celje",
  "centar",
  "centar zhupa",
  "central",
  "central abaco district",
  "central and western district",
  "central aymag",
  "central bohemia",
  "central darfur",
  "central district",
  "central eleuthera district",
  "central equatoria",
  "central finland",
  "central greece",
  "central java",
  "central jutland",
  "central kalimantan",
  "central luzon",
  "central macedonia",
  "central ostrobothnia",
  "central province",
  "central region",
  "central river",
  "central singapore",
  "central sulawesi",
  "central visayas",
  "centrale",
  "centre",
  "centre-est",
  "centre-nord",
  "centre-ouest",
  "centre-sud",
  "centre-val de loire",
  "centro sur",
  "cerklje na gorenjskem",
  "cerknica",
  "cerkno",
  "cerkvenjak",
  "cerro largo",
  "cesu novads",
  "cetinje",
  "ceuta",
  "chachoengsao",
  "chaco",
  "chagang-do",
  "chaguanas",
  "chaharmahal and bakhtiari province",
  "chai nat",
  "chaiyaphum",
  "champasak",
  "chandigarh",
  "changhua",
  "chanthaburi",
  "chari-baguirmi region",
  "chashka",
  "chatham islands",
  "chechnya",
  "chelyabinsk oblast",
  "cherkasy oblast",
  "chernihiv",
  "chernivtsi oblast",
  "cheshinovo-obleshevo",
  "chhattisgarh",
  "chiang mai",
  "chiang rai",
  "chiapas",
  "chiayi",
  "chiayi county",
  "chiba",
  "chiesanuova",
  "chihuahua",
  "chimbu province",
  "chin state",
  "chirang",
  "chittagong",
  "chișinău municipality",
  "chlef",
  "chobe district",
  "choiseul",
  "chon buri",
  "chongqing",
  "christ church",
  "christ church nichola town",
  "chubut",
  "chukha",
  "chukotka",
  "chumphon",
  "chungcheongnam-do",
  "chuvashia",
  "chuyskaya oblast'",
  "cibitoke province",
  "cidade de maputo",
  "cienfuegos",
  "cimişlia",
  "cirkulane",
  "city of francistown",
  "city of freeport district",
  "city of zagreb",
  "clarendon",
  "clervaux",
  "cluj",
  "coahuila",
  "coimbra",
  "cojedes",
  "colima",
  "collines department",
  "colonia",
  "colorado",
  "commune de saint-pierre",
  "comoe",
  "conakry region",
  "connaught",
  "connecticut",
  "constanta",
  "constantine",
  "copperbelt",
  "coquimbo region",
  "cordillera",
  "cordoba",
  "corozal district",
  "corrientes",
  "corsica",
  "couva-tabaquite-talparo",
  "cova lima",
  "covasna",
  "crete",
  "crimea",
  "criuleni",
  "cross river state",
  "csongrad megye",
  "cuando cobango",
  "cuanza norte province",
  "cundinamarca",
  "cunene province",
  "cusco",
  "cuvette",
  "cuvette-ouest",
  "cuyuni-mazaruni region",
  "da nang",
  "dadra and nagar haveli and daman and diu",
  "daegu",
  "daejeon",
  "dagana",
  "dagestan",
  "dak nong",
  "dakahlia",
  "dakar",
  "dakhla-oued ed-dahab",
  "dakhlet nouadhibou",
  "dalarna county",
  "damascus governorate",
  "dambovita",
  "damietta governorate",
  "danilovgrad",
  "dar es salaam region",
  "daraa governorate",
  "darnah",
  "dasoguz welayaty",
  "daugavpils",
  "davao",
  "daykundi",
  "daşkǝsǝn",
  "debar",
  "debub region",
  "deir al balah",
  "deir ez-zor",
  "delaware",
  "delchevo",
  "delta",
  "delta amacuro",
  "demerara-mahaica region",
  "demir hisar",
  "demir kapija",
  "denguele",
  "denizli",
  "dennery",
  "departamento central",
  "departamento de ahuachapan",
  "departamento de alta verapaz",
  "departamento de alto paraguay",
  "departamento de arauca",
  "departamento de atlantida",
  "departamento de baja verapaz",
  "departamento de boaco",
  "departamento de bolivar",
  "departamento de boqueron",
  "departamento de boyaca",
  "departamento de caaguazu",
  "departamento de caazapa",
  "departamento de cabanas",
  "departamento de caldas",
  "departamento de canindeyu",
  "departamento de carazo",
  "departamento de casanare",
  "departamento de chalatenango",
  "departamento de chimaltenango",
  "departamento de chinandega",
  "departamento de chiquimula",
  "departamento de choluteca",
  "departamento de chontales",
  "departamento de chuquisaca",
  "departamento de cochabamba",
  "departamento de colon",
  "departamento de comayagua",
  "departamento de concepcion",
  "departamento de copan",
  "departamento de cordoba",
  "departamento de cortes",
  "departamento de cuscatlan",
  "departamento de durazno",
  "departamento de el paraiso",
  "departamento de el progreso",
  "departamento de escuintla",
  "departamento de esteli",
  "departamento de flores",
  "departamento de francisco morazan",
  "departamento de gracias a dios",
  "departamento de granada",
  "departamento de guatemala",
  "departamento de huehuetenango",
  "departamento de intibuca",
  "departamento de itapua",
  "departamento de izabal",
  "departamento de jalapa",
  "departamento de jinotega",
  "departamento de jutiapa",
  "departamento de la cordillera",
  "departamento de la guajira",
  "departamento de la libertad",
  "departamento de la paz",
  "departamento de la union",
  "departamento de lempira",
  "departamento de leon",
  "departamento de madriz",
  "departamento de managua",
  "departamento de masaya",
  "departamento de matagalpa",
  "departamento de misiones",
  "departamento de montevideo",
  "departamento de moquegua",
  "departamento de morazan",
  "departamento de narino",
  "departamento de neembucu",
  "departamento de norte de santander",
  "departamento de ocotepeque",
  "departamento de olancho",
  "departamento de pando",
  "departamento de paraguari",
  "departamento de paysandu",
  "departamento de potosi",
  "departamento de presidente hayes",
  "departamento de quetzaltenango",
  "departamento de retalhuleu",
  "departamento de rio negro",
  "departamento de rio san juan",
  "departamento de risaralda",
  "departamento de rivas",
  "departamento de rivera",
  "departamento de rocha",
  "departamento de sacatepequez",
  "departamento de salto",
  "departamento de san jose",
  "departamento de san marcos",
  "departamento de san miguel",
  "departamento de san pedro",
  "departamento de san salvador",
  "departamento de san vicente",
  "departamento de santa ana",
  "departamento de santa barbara",
  "departamento de santa cruz",
  "departamento de santa rosa",
  "departamento de santander",
  "departamento de solola",
  "departamento de sonsonate",
  "departamento de sucre",
  "departamento de tacuarembo",
  "departamento de tarija",
  "departamento de tolima",
  "departamento de totonicapan",
  "departamento de treinta y tres",
  "departamento de usulutan",
  "departamento de valle",
  "departamento de yoro",
  "departamento de zacapa",
  "departamento del alto parana",
  "departamento del amambay",
  "departamento del caqueta",
  "departamento del cauca",
  "departamento del cesar",
  "departamento del choco",
  "departamento del guainia",
  "departamento del guaira",
  "departamento del guaviare",
  "departamento del huila",
  "departamento del magdalena",
  "departamento del meta",
  "departamento del peten",
  "departamento del putumayo",
  "departamento del quiche",
  "departamento del valle del cauca",
  "departamento del vaupes",
  "departamento del vichada",
  "departement de l'artibonite",
  "departement de l'ouest",
  "departement de nippes",
  "dependencias federales",
  "destrnik",
  "devonshire parish",
  "dhaalu atholhu",
  "dhaka division",
  "dhamār",
  "dhi qar",
  "dhofar",
  "diego martin",
  "diekirch",
  "diffa",
  "dikhil",
  "dili",
  "dingli",
  "diourbel",
  "dire dawa",
  "district of columbia",
  "district of setúbal",
  "distrikt brokopondo",
  "distrikt commewijne",
  "distrikt coronie",
  "distrikt marowijne",
  "distrikt nickerie",
  "distrikt para",
  "distrikt paramaribo",
  "distrikt saramacca",
  "distrikt sipaliwini",
  "distrikt wanica",
  "distrito federal",
  "diyarbakir",
  "diyālá",
  "djanet",
  "djelfa",
  "djibloho",
  "djibouti",
  "dnipropetrovsk oblast",
  "dobeles novads",
  "dobje",
  "dobrepolje",
  "dobrich",
  "dobrna",
  "dobrova-polhov gradec",
  "dobrovnik",
  "dodoma",
  "dojran",
  "dol pri ljubljani",
  "dolenjske toplice",
  "dolj",
  "dolneni",
  "donduşeni",
  "donetsk oblast",
  "donga",
  "dornava",
  "dosso region",
  "draa-tafilalet",
  "dravograd",
  "drenthe",
  "drochia",
  "dubai",
  "dubrovacko-neretvanska zupanija",
  "duezce",
  "duhok",
  "duplek",
  "durango",
  "dushanbe",
  "dzavhan aymag",
  "east",
  "east aimak",
  "east azerbaijan province",
  "east berbice-corentyne region",
  "east gobi aymag",
  "east grand bahama district",
  "east java",
  "east kalimantan",
  "east kazakhstan",
  "east macedonia and thrace",
  "east new britain province",
  "east nusa tenggara",
  "east sepik province",
  "eastern",
  "eastern cape",
  "eastern darfur",
  "eastern district",
  "eastern highlands province",
  "eastern province",
  "eastern region",
  "eastern visayas",
  "ebonyi state",
  "echternach",
  "edirne",
  "edo",
  "ehime",
  "ekiti state",
  "el bayadh",
  "el beni",
  "el menia",
  "el mghair",
  "el oued",
  "el tarf",
  "elazığ",
  "embera-wounaan",
  "embu district",
  "emilia-romagna",
  "encamp",
  "enga province",
  "england",
  "english river",
  "ennedi-est",
  "ennedi-ouest",
  "entre rios",
  "enugu state",
  "epirus",
  "equateur",
  "erbil",
  "ermera",
  "erongo",
  "erzincan",
  "erzurum",
  "escaldes-engordany",
  "esch-sur-alzette",
  "eschen",
  "eskişehir",
  "espirito santo",
  "essequibo islands-west demerara region",
  "est",
  "estado trujillo",
  "estuaire",
  "ewa",
  "extremadura",
  "fa'asaleleaga",
  "faafu atholhu",
  "faiyum",
  "fakaofo",
  "falcón",
  "far north region",
  "farah",
  "faranah",
  "faro",
  "fars",
  "faryab",
  "fatick",
  "fct",
  "federal district",
  "federation of b&h",
  "fejér",
  "fergana",
  "fes-meknes",
  "fiorentino",
  "flacq district",
  "flanders",
  "flevoland",
  "floreşti",
  "floriana",
  "florida",
  "formosa",
  "free and hanseatic city of hamburg",
  "fribourg",
  "friesland",
  "friuli venezia giulia",
  "fujairah",
  "fujian",
  "fukui",
  "fukuoka",
  "fukushima-ken",
  "funafuti",
  "făleşti",
  "gaafu alif atoll",
  "gaafu dhaalu atoll",
  "gaborone",
  "gabrovo",
  "gabu",
  "gafsa",
  "gaga'emauga",
  "gagaifomauga",
  "gagauzia",
  "galati",
  "galguduud",
  "galicia",
  "gambela",
  "gamprin",
  "gandaki pradesh",
  "gangwon-do",
  "ganja city",
  "gansu",
  "gao",
  "garissa district",
  "gasa",
  "gash-barka region",
  "gauteng",
  "gaza",
  "gaza province",
  "gaziantep",
  "gbarpolu county",
  "gedo",
  "gegharkunik",
  "geita",
  "gelderland",
  "geneva",
  "georgia",
  "gevgelija",
  "ghajnsielem",
  "ghanzi district",
  "gharbia",
  "ghardaia",
  "ghat",
  "ghazni",
  "gia lai",
  "gifu",
  "gilan province",
  "gilbert islands",
  "gilgit-baltistan",
  "giresun",
  "gisborne",
  "gitega province",
  "giurgiu",
  "giza",
  "glacis",
  "glarus",
  "glodeni",
  "gnyaviyani atoll",
  "goa",
  "goh-djiboua",
  "goias",
  "golestan",
  "gombe state",
  "goranboy",
  "gorenja vas-poljane",
  "gorgol",
  "gorj",
  "gorje",
  "gornja radgona",
  "gornji grad",
  "gornji petrovci",
  "gorno-badakhshan",
  "gorod bishkek",
  "gorontalo",
  "gostivar",
  "gotland county",
  "gouvernorat de beja",
  "gouvernorat de ben arous",
  "gouvernorat de bizerte",
  "gouvernorat de gabes",
  "gouvernorat de jendouba",
  "gouvernorat de kasserine",
  "gouvernorat de kebili",
  "gouvernorat de kef",
  "gouvernorat de l'ariana",
  "gouvernorat de mahdia",
  "gouvernorat de medenine",
  "gouvernorat de monastir",
  "gouvernorat de nabeul",
  "gouvernorat de sfax",
  "gouvernorat de sidi bouzid",
  "gouvernorat de siliana",
  "gouvernorat de sousse",
  "gouvernorat de tozeur",
  "gouvernorat de tunis",
  "gouvernorat de zaghouan",
  "govi-altay aymag",
  "govi-sumber",
  "goychay rayon",
  "goygol rayon",
  "grad",
  "gradsko",
  "grand anse mahe",
  "grand anse praslin",
  "grand bassa county",
  "grand cay district",
  "grand est",
  "grand kru county",
  "grand port district",
  "grandans",
  "grande comore",
  "granma",
  "greater accra region",
  "greater poland",
  "grenadines",
  "grevenmacher",
  "grisons",
  "grodnenskaya",
  "groningen",
  "gros-islet",
  "grosuplje",
  "guadalcanal province",
  "guanajuato",
  "guangdong",
  "guangxi",
  "guarda",
  "guelma",
  "guelmim-oued noun",
  "guemueshane",
  "guerrero",
  "guidimaka",
  "guizhou",
  "gujarat",
  "gulbenes novads",
  "gulf province",
  "guna yala",
  "gunma",
  "guria",
  "gusinje",
  "guárico",
  "guéra",
  "gwangju",
  "gyeonggi-do",
  "gyeongsangbuk-do",
  "gyeongsangnam-do",
  "győr-moson-sopron",
  "gävleborg county",
  "ha'il region",
  "haa",
  "haa alifu atholhu",
  "haa dhaalu atholhu",
  "hacıqabul",
  "hadjer-lamis",
  "haifa",
  "hainan",
  "haiphong",
  "hajdina",
  "hajdú-bihar",
  "hakkâri",
  "hal gharghur",
  "hal ghaxaq",
  "halland county",
  "hama governorate",
  "hamadan province",
  "hamilton city",
  "hanoi",
  "hanover",
  "harare",
  "harari region",
  "harbour island",
  "hardap",
  "harghita",
  "harjumaa",
  "haryana",
  "haskovo",
  "hatay",
  "haut-katanga",
  "haut-lomami",
  "haut-mbomou",
  "haut-ogooué",
  "haut-uele",
  "haute-kotto",
  "hauts-bassins",
  "hauts-de-france",
  "havana",
  "hawaii",
  "hawalli",
  "hawke's bay",
  "haz-zabbar",
  "haz-zebbug",
  "ha‘apai",
  "hebei",
  "heilongjiang",
  "hela",
  "helmand",
  "henan",
  "hentiy aymag",
  "herat",
  "herceg novi",
  "hesse",
  "heves megye",
  "hhohho",
  "hidalgo",
  "hiiraan",
  "hiiumaa",
  "himachal pradesh",
  "hiroshima",
  "hlavni mesto praha",
  "ho chi minh",
  "hodh ech chargi",
  "hodh el gharbi",
  "hodos",
  "hokkaido",
  "homa bay district",
  "homs governorate",
  "homyel’ voblasc’",
  "honiara",
  "horjul",
  "hormozgan",
  "houaphan",
  "hovd",
  "hovsgol aymag",
  "hrastnik",
  "hrpelje-kozina",
  "hsinchu",
  "hsinchu county",
  "hualien",
  "huambo",
  "huancavelica",
  "hubei",
  "hunan",
  "hunedoara",
  "huíla",
  "hyōgo",
  "hînceşti",
  "hậu giang",
  "ialomita",
  "ialoveni",
  "iasi",
  "ibaraki",
  "ibb",
  "ica",
  "ida-virumaa",
  "idaho",
  "idlib governorate",
  "idrija",
  "ig",
  "ijuw",
  "il-birgu",
  "il-fgura",
  "il-fontana",
  "il-gudja",
  "il-gzira",
  "il-hamrun",
  "il-kalkara",
  "il-marsa",
  "il-mellieha",
  "il-mosta",
  "il-munxar",
  "il-qala",
  "il-qrendi",
  "ilam province",
  "iles australes",
  "iles du vent",
  "iles marquises",
  "iles tuamotu-gambier",
  "ilfov",
  "ilinden",
  "ilirska bistrica",
  "illinois",
  "illizi",
  "ilocos",
  "imarat ra's al khaymah",
  "imarat umm al qaywayn",
  "imereti",
  "imo state",
  "in guezzam",
  "in salah",
  "in-nadur",
  "in-naxxar",
  "incheon",
  "inchiri",
  "indiana",
  "ingushetiya republic",
  "inhambane province",
  "inner mongolia autonomous region",
  "innlandet",
  "ionian islands",
  "iowa",
  "ir-rabat",
  "irbid",
  "iringa",
  "irkutsk oblast",
  "is-siggiewi",
  "is-swieqi",
  "isabel province",
  "isfahan",
  "ishikawa",
  "isiolo district",
  "isla de la juventud",
  "islamabad",
  "islands district",
  "ismailia governorate",
  "isparta",
  "issyk-kul",
  "istanbul",
  "istria",
  "ituri",
  "ivano-frankivsk oblast",
  "ivanovo oblast",
  "iwate",
  "ix-xaghra",
  "ix-xewkija",
  "ix-xghajra",
  "iz-zebbug",
  "iz-zejtun",
  "iz-zurrieq",
  "izmir",
  "izola",
  "iğdır",
  "i̇mişli",
  "i̇smayıllı",
  "jabal al gharbi",
  "jablanica",
  "jabrayil",
  "jakarta",
  "jalal-abad oblast",
  "jalilabad",
  "jalisco",
  "jambi",
  "jammu and kashmir",
  "jan mayen",
  "jazan region",
  "jegunovce",
  "jeju-do",
  "jelgava",
  "jelgavas novads",
  "jenin",
  "jeollabuk-do",
  "jeollanam-do",
  "jerash",
  "jerusalem",
  "jesenice",
  "jezersko",
  "jharkhand",
  "jiangsu",
  "jiangxi",
  "jigawa state",
  "jihocesky kraj",
  "jijel",
  "jilin",
  "jiwaka",
  "jizzakh region",
  "johor",
  "jonglei",
  "jowzjan",
  "jujuy",
  "junin",
  "jura",
  "jurmala",
  "jwaneng",
  "jász-nagykun-szolnok",
  "jämtland county",
  "järvamaa",
  "jõgevamaa",
  "jönköping",
  "jēkabpils municipality",
  "k'alak'i t'bilisi",
  "kaafu atoll",
  "kabardino-balkariya republic",
  "kabul",
  "kachin",
  "kaduna state",
  "kafr el-sheikh",
  "kagawa",
  "kagera",
  "kagoshima",
  "kahramanmaraş",
  "kainuu",
  "kairouan",
  "kajiado district",
  "kakamega district",
  "kakheti",
  "kalasin",
  "kaliningrad oblast",
  "kalmar",
  "kalmykiya republic",
  "kaluga oblast",
  "kamchatka",
  "kamnik",
  "kamphaeng phet",
  "kampong cham",
  "kampong chhnang",
  "kampong speu",
  "kampong thom",
  "kampot",
  "kanagawa",
  "kanchanaburi",
  "kandahar",
  "kandal",
  "kanem region",
  "kankan",
  "kano state",
  "kansas",
  "kanta-häme",
  "kaohsiung",
  "kaolack",
  "kapisa",
  "kara",
  "karabuek",
  "karachayevo-cherkesiya republic",
  "karaganda",
  "karak",
  "karakalpakstan",
  "karaman",
  "karas",
  "kardzhali",
  "karelia",
  "karlovac",
  "karlovarsky kraj",
  "karnali pradesh",
  "karnataka",
  "karposh",
  "kars",
  "kasai",
  "kasai-central",
  "kasaï-oriental",
  "kassala",
  "kastamonu",
  "katavi",
  "katsina state",
  "kaunas",
  "kavadarci",
  "kavango east",
  "kavango west",
  "kayah state",
  "kayanza province",
  "kayes",
  "kayin state",
  "kayseri",
  "kebbi",
  "kedah",
  "keelung",
  "kelantan",
  "kemerovo oblast",
  "kentucky",
  "kep",
  "kerala",
  "kericho district",
  "kerman",
  "kermanshah province",
  "keryneia",
  "kgalagadi district",
  "kgatleng district",
  "khabarovsk",
  "khachmaz rayon",
  "khakasiya republic",
  "khammouan",
  "khan yunis governorate",
  "khanty-mansia",
  "kharkiv",
  "khartoum",
  "khenchela",
  "kherson oblast",
  "khizi rayon",
  "khmelnytskyy oblast",
  "khomas",
  "khon kaen",
  "khoueng oudomxai",
  "khoueng phongsali",
  "khoueng savannakhet",
  "khoueng xekong",
  "khowst",
  "khulna division",
  "khuzestan",
  "khyber pakhtunkhwa",
  "kiambu district",
  "kichevo",
  "kidal",
  "kigali",
  "kigoma",
  "kilifi district",
  "kilimanjaro",
  "kilis",
  "kindia",
  "kingston",
  "kinmen county",
  "kinshasa city",
  "kirinyaga district",
  "kirkop",
  "kirkuk",
  "kirov oblast",
  "kirovohrad oblast",
  "kirundo province",
  "kisii district",
  "kisumu",
  "kitui district",
  "kié-ntem",
  "klaipėda county",
  "kobilje",
  "kocaeli",
  "kochani",
  "kochi",
  "kogi state",
  "koh kong",
  "kohgiluyeh and boyer-ahmad province",
  "kolda",
  "kolubara",
  "komen",
  "komenda",
  "komi",
  "komárom-esztergom",
  "kon tum",
  "konya",
  "koper",
  "koprivinica-krizevci",
  "kosice",
  "kostanjevica na krki",
  "kostel",
  "kostroma oblast",
  "kotayk",
  "kotor",
  "kouffo department",
  "kouilou",
  "koulikoro",
  "kowloon city",
  "kozje",
  "krabi",
  "kraj vysocina",
  "kralovehradecky kraj",
  "kranj",
  "kranjska gora",
  "krapina-zagorje",
  "kraslavas novads",
  "krasnodarskiy kray",
  "krasnoyarskiy kray",
  "kratie",
  "kratovo",
  "kriva palanka",
  "kronoberg county",
  "krushevo",
  "kuala lumpur",
  "kujalleq",
  "kujawsko-pomorskie",
  "kuldigas novads",
  "kumamoto",
  "kumanovo",
  "kunduz",
  "kunene",
  "kungota",
  "kurdistan province",
  "kurgan oblast",
  "kursk oblast",
  "kuzma",
  "kvemo kartli",
  "kwai tsing",
  "kwajalein atoll",
  "kwale district",
  "kwango",
  "kwanza sul",
  "kwara state",
  "kwazulu-natal",
  "kweneng district",
  "kwilu",
  "kwun tong",
  "kyiv city",
  "kyiv oblast",
  "kymenlaakso",
  "kyoto",
  "kyustendil",
  "kémo",
  "kürdǝmir",
  "kütahya",
  "kırklareli",
  "kırıkkale",
  "kırşehir",
  "l-gharb",
  "l-ghasri",
  "l-iklin",
  "l-imdina",
  "l-imgarr",
  "l-imqabba",
  "l-imsida",
  "la digue",
  "la libertad",
  "la massana",
  "la pampa",
  "la rioja",
  "laamu atholhu",
  "laayoune-sakia el hamra",
  "labe",
  "laborie",
  "labuan",
  "lac region",
  "lacs",
  "laghman",
  "laghouat",
  "lagos",
  "lagunes",
  "laikipia",
  "lakes",
  "lakshadweep",
  "lambayeque",
  "lampang",
  "lamphun",
  "lampung",
  "lamu",
  "land berlin",
  "lapland",
  "lara",
  "larnaka",
  "las tunas",
  "latakia governorate",
  "lautém",
  "lavalleja",
  "lazio",
  "laḩij",
  "lebap",
  "leeward islands",
  "leinster",
  "leiria",
  "lemba",
  "lenart",
  "lendava",
  "leningrad oblast",
  "leova",
  "leribe",
  "les mamelles",
  "lesser poland",
  "lhaviyani atholhu",
  "lhuntse",
  "liaoning",
  "liberecky kraj",
  "lienchiang",
  "liepaja",
  "liguria",
  "lija",
  "lika-senj",
  "likouala",
  "lima",
  "lima region",
  "limassol district",
  "limbazu novads",
  "limburg",
  "limpopo",
  "lindi",
  "line islands",
  "lipetsk oblast",
  "lipkovo",
  "liquiçá",
  "lisbon",
  "litija",
  "litoral",
  "littoral",
  "ljubljana",
  "ljubno",
  "ljutomer",
  "lobatse",
  "lobaye",
  "loei",
  "lofa county",
  "logar",
  "logatec",
  "logone occidental region",
  "logone oriental region",
  "log–dragomer",
  "lomami",
  "lombardy",
  "long an",
  "long island",
  "lopburi",
  "lorestan province",
  "loreto",
  "lori",
  "los lagos region",
  "los ríos region",
  "louangnamtha",
  "louga",
  "louisiana",
  "lovech",
  "lovrenc na pohorju",
  "lower austria",
  "lower juba",
  "lower river division",
  "lower saxony",
  "lower shabeelle",
  "lower silesia",
  "loyalty islands",
  "lualaba",
  "luanda norte",
  "luanda province",
  "luang prabang province",
  "luapula province",
  "lublin",
  "lubombo district",
  "lubusz",
  "lucerne",
  "ludzas novads",
  "luhansk",
  "lukovica",
  "lumbini province",
  "lunda sul",
  "luqa",
  "lusaka province",
  "luxembourg",
  "luxor",
  "lviv oblast",
  "lääne",
  "lääne-virumaa",
  "lékoumou",
  "līvāni",
  "lənkəran",
  "m'sila",
  "machakos district",
  "macva",
  "madaba",
  "madang province",
  "madeira",
  "madhya pradesh",
  "madona municipality",
  "madre de dios",
  "madrid",
  "mae hong son",
  "maekel region",
  "mafeteng district",
  "mafraq",
  "magadan oblast",
  "magway region",
  "maha sarakham",
  "mahaica-berbice region",
  "maharashtra",
  "mai-ndombe",
  "maine",
  "maio",
  "majuro atoll",
  "makamba province",
  "makedonska kamenica",
  "makedonski brod",
  "makira-ulawa province",
  "makole",
  "makueni district",
  "malaita province",
  "malampa province",
  "malanje province",
  "malatya",
  "maldonado",
  "male",
  "maluku",
  "mambéré-kadéï",
  "mamou",
  "manama",
  "manatuto",
  "manawatu-wanganui",
  "manchester",
  "mandalay region",
  "mandera district",
  "mandoul",
  "mangistauskaya oblast'",
  "manica province",
  "manicaland",
  "maniema",
  "manipur",
  "manisa",
  "manitoba",
  "manouba",
  "manu'a district",
  "manufahi",
  "manus province",
  "manyara",
  "manzini",
  "maputo province",
  "mara",
  "maradi",
  "marakwet district",
  "maramureş",
  "maranhao",
  "mardin",
  "margibi county",
  "maribor",
  "marijampolė county",
  "maritime",
  "mariy-el republic",
  "markazi",
  "markovci",
  "marlborough",
  "marrakesh-safi",
  "marsabit district",
  "marsaskala",
  "marsaxlokk",
  "mary",
  "maryland",
  "maryland county",
  "masally",
  "mascara",
  "maseru",
  "mashonaland central",
  "mashonaland east province",
  "mashonaland west",
  "massachusetts",
  "masvingo province",
  "matabeleland north",
  "matabeleland south province",
  "matam",
  "mato grosso",
  "mato grosso do sul",
  "matruh",
  "maule region",
  "mauren",
  "mayabeque",
  "mayaro",
  "mayo-kebbi est",
  "mayo-kebbi ouest",
  "maysan",
  "mazovia",
  "ma’an",
  "ma’rib",
  "mbeya",
  "mbomou",
  "mecca region",
  "mecklenburg-vorpommern",
  "medea",
  "medimurje",
  "medina region",
  "medvode",
  "meemu atholhu",
  "meghalaya",
  "mehedinti",
  "melaka",
  "melilla",
  "mendoza",
  "meneng",
  "mersch",
  "mersin",
  "meru",
  "mestna obcina novo mesto",
  "metlika",
  "metro manila",
  "mexico city",
  "miaoli",
  "michigan",
  "michoacán",
  "middle govĭ",
  "middle island",
  "middle juba",
  "midlands province",
  "mie",
  "migori district",
  "mila",
  "milne bay province",
  "mimaropa",
  "minas gerais",
  "mingǝcevir",
  "minnesota",
  "minsk",
  "minsk city",
  "minya",
  "miquelon-langlade",
  "miranda",
  "mirna",
  "misiones",
  "mislinja",
  "mississippi",
  "missouri",
  "miyagi",
  "miyazaki",
  "mizoram",
  "mogilev",
  "mohafazat aakkar",
  "mohafazat baalbek-hermel",
  "mohafazat beqaa",
  "mohafazat liban-nord",
  "mohafazat mont-liban",
  "mohafazat nabatiye",
  "mohale's hoek district",
  "mohéli",
  "mojkovac",
  "moka district",
  "mokhotlong",
  "mokronog-trebelno",
  "molise",
  "mombasa district",
  "mon state",
  "monagas",
  "mondolkiri",
  "mongala",
  "mongar",
  "mono",
  "mont buxton",
  "mont fleuri",
  "montagnes",
  "montana",
  "montserrado county",
  "monufia",
  "moore's island district",
  "mopti",
  "morava",
  "moravske toplice",
  "moravskoslezsky kraj",
  "mordoviya republic",
  "morelos",
  "morobe province",
  "morogoro",
  "moscow",
  "moscow oblast",
  "mostaganem",
  "mosteiros",
  "mount athos",
  "moxico",
  "moyen-chari region",
  "moyen-ogooué",
  "mozirje",
  "mpumalanga",
  "mtarfa",
  "mtskheta-mtianeti",
  "mtwara",
  "mubārak al kabīr",
  "muchinga",
  "mudug",
  "muhafazat abyan",
  "muhafazat al jahra'",
  "muhafazat al muthanna",
  "muhafazat al qadisiyah",
  "muhafazat babil",
  "muhafazat hadramaout",
  "muhafazat karbala'",
  "muhafazat wasit",
  "muharraq",
  "mukdahan",
  "municipiul balti",
  "munster",
  "muramvya province",
  "murang'a district",
  "murcia",
  "mures",
  "murmansk",
  "murska sobota",
  "murzuq",
  "musandam governorate",
  "muscat",
  "muta",
  "muyinga province",
  "muğla",
  "muş",
  "mwanza",
  "mwaro",
  "mykolayiv oblast",
  "mymensingh division",
  "mérida",
  "méxico",
  "møre og romsdal",
  "mārupe",
  "māzandarān",
  "naama",
  "nablus",
  "nacional",
  "naftalan",
  "nagaland",
  "nagano",
  "nagasaki",
  "nairobi province",
  "najran region",
  "nakhichevan",
  "nakhon nayok",
  "nakhon pathom",
  "nakhon phanom",
  "nakhon ratchasima",
  "nakhon sawan",
  "nakhon si thammarat",
  "naklo",
  "nakuru district",
  "nalut",
  "namangan",
  "namibe province",
  "nampula",
  "nan",
  "nana-grébizi",
  "nana-mambéré",
  "nandi south district",
  "nangarhar",
  "nantou",
  "nanumanga",
  "nanumea",
  "nara",
  "narathiwat",
  "narok district",
  "naryn oblast",
  "nasarawa state",
  "national capital",
  "national capital territory of delhi",
  "navarre",
  "navoiy region",
  "nay pyi taw",
  "nayarit",
  "nazarje",
  "ndzuwani",
  "neamt",
  "nebraska",
  "neftçala",
  "negeri sembilan",
  "negotino",
  "nelson",
  "nenets",
  "neuchâtel",
  "neuquen",
  "nevada",
  "nevsehir",
  "new brunswick",
  "new hampshire",
  "new ireland",
  "new jersey",
  "new mexico",
  "new providence district",
  "new south wales",
  "new taipei",
  "new valley",
  "new york",
  "newfoundland and labrador",
  "ngoebe-bugle",
  "ngouni",
  "ngozi province",
  "ngwaketsi",
  "niamey",
  "niari",
  "niassa province",
  "nibok",
  "nicosia",
  "nidwalden",
  "nigde",
  "niger state",
  "niigata",
  "nimba county",
  "nimroz",
  "nineveh",
  "ningxia hui autonomous region",
  "nisava",
  "nisporeni",
  "nitra",
  "niuas",
  "niutao",
  "nizhny novgorod oblast",
  "njombe",
  "nograd megye",
  "nong bua lam phu",
  "nong khai",
  "nonthaburi",
  "nord",
  "nord kivu",
  "nord-ubangi",
  "nordland",
  "normandy",
  "norrbotten county",
  "north",
  "north abaco district",
  "north aegean",
  "north andros district",
  "north bank",
  "north brabant",
  "north caribbean coast",
  "north carolina",
  "north central province",
  "north chungcheong",
  "north dakota",
  "north denmark",
  "north east",
  "north gaza",
  "north holland",
  "north kalimantan",
  "north karelia",
  "north kazakhstan",
  "north khorasan",
  "north kordofan",
  "north maluku",
  "north ossetia",
  "north ostrobothnia",
  "north province",
  "north region",
  "north rhine-westphalia",
  "north savo",
  "north sinai",
  "north sulawesi",
  "north sumatra",
  "north west",
  "north western province",
  "north-east",
  "north-west",
  "north-west region",
  "north-western province",
  "northeast",
  "northeastern governorate",
  "northern",
  "northern ari atoll",
  "northern bahr al ghazal",
  "northern borders region",
  "northern cape",
  "northern darfur",
  "northern district",
  "northern ireland",
  "northern islands",
  "northern mindanao",
  "northern province",
  "northern red sea",
  "northern region",
  "northern territory",
  "northland",
  "northwest",
  "northwest territories",
  "nouakchott nord",
  "nouakchott ouest",
  "nouakchott sud",
  "nouvelle-aquitaine",
  "nova gorica",
  "nova scotia",
  "novaci",
  "novgorod oblast",
  "novo selo",
  "novosibirsk oblast",
  "nueva esparta",
  "nuevo león",
  "nugaal",
  "nui",
  "nukufetau",
  "nukulaelae",
  "nukunonu",
  "nunavut",
  "nur-sultan",
  "nyamira district",
  "nyandarua district",
  "nyanga",
  "nyeri district",
  "nzerekore",
  "n’djaména",
  "o'higgins region",
  "oaxaca",
  "obcina ajdovscina",
  "obcina apace",
  "obcina bled",
  "obcina bovec",
  "obcina braslovce",
  "obcina brezice",
  "obcina crensovci",
  "obcina crna na koroskem",
  "obcina crnomelj",
  "obcina divaca",
  "obcina domzale",
  "obcina gorisnica",
  "obcina hoce-slivnica",
  "obcina ivancna gorica",
  "obcina jursinci",
  "obcina kanal ob soci",
  "obcina kidricevo",
  "obcina kobarid",
  "obcina kocevje",
  "obcina krizevci",
  "obcina krsko",
  "obcina lasko",
  "obcina loska dolina",
  "obcina loski potok",
  "obcina luce",
  "obcina majsperk",
  "obcina menges",
  "obcina mezica",
  "obcina miklavz na dravskem polju",
  "obcina mirna pec",
  "obcina moravce",
  "obcina ormoz",
  "obcina podcetrtek",
  "obcina poljcane",
  "obcina race-fram",
  "obcina radece",
  "obcina ravne na koroskem",
  "obcina razkrizje",
  "obcina recica ob savinji",
  "obcina rence-vogrsko",
  "obcina rogaska slatina",
  "obcina rogasovci",
  "obcina ruse",
  "obcina salovci",
  "obcina semic",
  "obcina sempeter-vrtojba",
  "obcina sencur",
  "obcina sentilj",
  "obcina sentjernej",
  "obcina sentrupert",
  "obcina sezana",
  "obcina skocjan",
  "obcina skofljica",
  "obcina smarje pri jelsah",
  "obcina smarjeske toplice",
  "obcina smartno ob paki",
  "obcina smartno pri litiji",
  "obcina sodrazica",
  "obcina solcava",
  "obcina sostanj",
  "obcina sredisce ob dravi",
  "obcina starse",
  "obcina store",
  "obcina straza",
  "obcina sveti andraz v slovenskih goricah",
  "obcina sveti jurij ob scavnici",
  "obcina sveti tomaz",
  "obcina tisina",
  "obcina tolmin",
  "obcina trnovska vas",
  "obcina trzic",
  "obcina turnisce",
  "obcina velike lasce",
  "obcina verzej",
  "obcina zalec",
  "obcina zavrc",
  "obcina zelezniki",
  "obcina zetale",
  "obcina ziri",
  "obcina zirovnica",
  "obcina zrece",
  "obcina zuzemberk",
  "obock",
  "obwalden",
  "occitanie",
  "odessa",
  "odisha",
  "odranci",
  "oecusse",
  "ogooué-ivindo",
  "ogooué-lolo",
  "ogooué-maritime",
  "ogre",
  "ogun state",
  "ohangwena",
  "ohio",
  "ohrid",
  "oio region",
  "oita",
  "okayama",
  "okinawa",
  "oklahoma",
  "olaine",
  "olomoucky kraj",
  "olt",
  "omaheke",
  "ombella-m'poko",
  "omran",
  "omsk oblast",
  "omusati",
  "ondo state",
  "ontario",
  "oplotnica",
  "opole voivodeship",
  "opstina kolasin",
  "opstina niksic",
  "opstina plav",
  "opstina pluzine",
  "opstina rozaje",
  "opstina savnik",
  "opstina zabljak",
  "oran",
  "orange free state",
  "orange walk district",
  "ordino",
  "ordu",
  "oregon",
  "orel oblast",
  "orenburg oblast",
  "orhei",
  "orhon aymag",
  "oriental",
  "oromiya",
  "oruro",
  "osh city",
  "osh oblasty",
  "oshana",
  "oshikoto",
  "osilnica",
  "osjecko-baranjska zupanija",
  "oslo county",
  "osmaniye",
  "ostrobothnia",
  "osun state",
  "otago",
  "otar meanchey",
  "oti",
  "otjozondjupa",
  "ouadaï",
  "ouaka",
  "ouargla",
  "ouham",
  "ouham-pendé",
  "ouled djellal",
  "oum el bouaghi",
  "ouémé",
  "overijssel",
  "oyo state",
  "pafos",
  "paget",
  "pahang",
  "paijat-hame region",
  "pailin",
  "paktika",
  "pamplemousses district",
  "panama oeste",
  "panevėžys",
  "panjab",
  "paola",
  "papua",
  "para",
  "parana",
  "paraíba",
  "pardubicky kraj",
  "parish of charlotte",
  "parish of saint andrew",
  "parish of saint ann",
  "parish of saint david",
  "parish of saint george",
  "parish of saint john",
  "parish of saint mary",
  "parish of saint patrick",
  "parish of saint paul",
  "parish of saint peter",
  "parish of saint philip",
  "paro",
  "parwan",
  "pasco",
  "pathum thani",
  "pattani",
  "paul",
  "pavlodar region",
  "pays de la loire",
  "pazardzhik",
  "pcinja",
  "pehchevo",
  "peloponnese",
  "pemba north",
  "pemba south",
  "pembroke",
  "pembroke parish",
  "penal/debe",
  "penama province",
  "penang",
  "penghu county",
  "pennsylvania",
  "penza oblast",
  "perak",
  "perlis",
  "perm krai",
  "pernambuco",
  "pernik",
  "pesnica",
  "pest megye",
  "petnjica",
  "petrovec",
  "phang nga",
  "phatthalung",
  "phayao",
  "phetchabun",
  "phetchaburi",
  "phichit",
  "phitsanulok",
  "phnom penh",
  "phoenix islands",
  "phra nakhon si ayutthaya",
  "phrae",
  "phuket",
  "piaui",
  "piedmont",
  "pingtung",
  "piran",
  "pirkanmaa",
  "pirot",
  "piura",
  "pivka",
  "plaines wilhems district",
  "plaisance",
  "planken",
  "plateau department",
  "plateau state",
  "plateau-central",
  "plateaux",
  "pleven",
  "pljevlja",
  "plovdiv",
  "plzensky kraj",
  "podgorica",
  "podlasie",
  "podlehnik",
  "podunavlje",
  "podvelka",
  "point fortin",
  "pointe larue",
  "pointe-noire",
  "poltava oblast",
  "polzela",
  "pomerania",
  "pomeroon-supenaam region",
  "pomoravlje",
  "pool",
  "port glaud",
  "port louis district",
  "port of spain",
  "port said",
  "portalegre",
  "portland",
  "porto",
  "porto novo",
  "portuguesa",
  "postojna",
  "potaro-siparuni region",
  "pozega-slavonia",
  "prachin buri",
  "prachuap khiri khan",
  "prahova",
  "praia",
  "preah sihanouk",
  "preah vihear",
  "prebold",
  "preddvor",
  "preili municipality",
  "presov",
  "prevalje",
  "prey veng",
  "prilep",
  "primorskiy (maritime) kray",
  "primorsko-goranska zupanija",
  "prince edward island",
  "princes town",
  "principality of asturias",
  "principe",
  "probishtip",
  "provence-alpes-côte d'azur",
  "province 1",
  "province 2",
  "province of uva",
  "provincia de alajuela",
  "provincia de azua",
  "provincia de baoruco",
  "provincia de barahona",
  "provincia de bocas del toro",
  "provincia de bolivar",
  "provincia de camagueey",
  "provincia de cartago",
  "provincia de chiriqui",
  "provincia de ciego de avila",
  "provincia de cocle",
  "provincia de colon",
  "provincia de cotopaxi",
  "provincia de dajabon",
  "provincia de el oro",
  "provincia de el seibo",
  "provincia de elias pina",
  "provincia de esmeraldas",
  "provincia de francisco de orellana",
  "provincia de galapagos",
  "provincia de guanacaste",
  "provincia de guantanamo",
  "provincia de hato mayor",
  "provincia de heredia",
  "provincia de hermanas mirabal",
  "provincia de herrera",
  "provincia de holguin",
  "provincia de imbabura",
  "provincia de independencia",
  "provincia de la altagracia",
  "provincia de la romana",
  "provincia de la vega",
  "provincia de limon",
  "provincia de loja",
  "provincia de los rios",
  "provincia de los santos",
  "provincia de manabi",
  "provincia de matanzas",
  "provincia de monsenor nouel",
  "provincia de monte cristi",
  "provincia de monte plata",
  "provincia de morona-santiago",
  "provincia de napo",
  "provincia de panama",
  "provincia de pedernales",
  "provincia de peravia",
  "provincia de pichincha",
  "provincia de pinar del rio",
  "provincia de puntarenas",
  "provincia de san cristobal",
  "provincia de san jose",
  "provincia de san jose de ocoa",
  "provincia de san juan",
  "provincia de san pedro de macoris",
  "provincia de sancti spiritus",
  "provincia de santa elena",
  "provincia de santiago",
  "provincia de santiago de cuba",
  "provincia de santiago rodriguez",
  "provincia de santo domingo",
  "provincia de santo domingo de los tsachilas",
  "provincia de sucumbios",
  "provincia de valverde",
  "provincia de veraguas",
  "provincia de villa clara",
  "provincia de zambezia",
  "provincia de zamora-chinchipe",
  "provincia del azuay",
  "provincia del canar",
  "provincia del carchi",
  "provincia del chimborazo",
  "provincia del darien",
  "provincia del guayas",
  "provincia del pastaza",
  "provincia del tungurahua",
  "provincia duarte",
  "provincia espaillat",
  "provincia maria trinidad sanchez",
  "provincia sanchez ramirez",
  "pskov oblast",
  "ptuj",
  "puconci",
  "puebla",
  "puerto plata",
  "punakha dzongkhag",
  "punjab",
  "puno",
  "pursat",
  "putrajaya",
  "pwani",
  "pyongyang",
  "pärnumaa",
  "põlvamaa",
  "qacha's nek",
  "qalyubia",
  "qarku i beratit",
  "qarku i dibres",
  "qarku i durresit",
  "qarku i elbasanit",
  "qarku i fierit",
  "qarku i gjirokastres",
  "qarku i korces",
  "qarku i kukesit",
  "qarku i lezhes",
  "qarku i shkodres",
  "qarku i vlores",
  "qashqadaryo",
  "qax",
  "qazax",
  "qazvin province",
  "qena",
  "qeqqata",
  "qinghai",
  "qom province",
  "qormi",
  "qostanay oblysy",
  "quba rayon",
  "quebec",
  "queensland",
  "querétaro",
  "quinara",
  "quindio department",
  "quintana roo",
  "qusar rayon",
  "quthing",
  "quảng ngãi province",
  "qyzylorda oblysy",
  "qǝbǝlǝ",
  "raa atoll",
  "rabat-sale-kenitra",
  "racha-lechkhumi and kvemo svaneti",
  "radenci",
  "radlje ob dravi",
  "radovish",
  "radovljica",
  "rafah",
  "raionul calarasi",
  "raionul causeni",
  "raionul dubasari",
  "raionul edineţ",
  "raionul ocniţa",
  "raionul soroca",
  "raionul stefan voda",
  "rajasthan",
  "rajshahi division",
  "rakhine state",
  "ramallah",
  "rangoon",
  "rangpur division",
  "ranong",
  "raplamaa",
  "rasina",
  "raska",
  "ratchaburi",
  "raymah",
  "rayong",
  "razavi khorasan",
  "razgrad",
  "red sea",
  "redange",
  "redonda",
  "region de apurimac",
  "region de arica y parinacota",
  "region de huanuco",
  "region de kaffrine",
  "region de kedougou",
  "region de la araucania",
  "region de san martin",
  "region de sedhiou",
  "region de thies",
  "region de valparaiso",
  "region del biobio",
  "region of magallanes",
  "regionname",
  "relizane",
  "remich",
  "rennell and bellona",
  "republic of tyva",
  "republican subordination",
  "republika srpska",
  "resen",
  "rezekne",
  "rezeknes novads",
  "rezina",
  "rheinland-pfalz",
  "rhode island",
  "riau",
  "riau islands",
  "ribeira brava",
  "ribeira grande",
  "ribeira grande de santiago",
  "ribnica",
  "ribnica na pohorju",
  "rif-dimashq",
  "riga",
  "rio de janeiro",
  "rio grande do norte",
  "rio grande do sul",
  "rio negro",
  "river cess county",
  "river gee county",
  "river nile",
  "rivers state",
  "riviere du rempart district",
  "rivne",
  "riyadh region",
  "rize",
  "rodrigues",
  "rogaland",
  "roi et",
  "rondonia",
  "ropazu novads",
  "roraima",
  "rose island",
  "rosoman",
  "rostov oblast",
  "rota",
  "rotuma",
  "ruggell",
  "rukwa",
  "rumonge",
  "ruse",
  "rutana province",
  "ruvuma",
  "ruyigi province",
  "ryazan oblast",
  "rîşcani",
  "sa kaeo",
  "saare",
  "saarland",
  "saba",
  "sabah",
  "sabaragamuwa province",
  "sabha",
  "sabirabad rayon",
  "safi",
  "saga",
  "sagaing region",
  "sahel",
  "sai kung district",
  "saida",
  "saint andrew",
  "saint anne sandy point",
  "saint catherine",
  "saint croix island",
  "saint david",
  "saint elizabeth",
  "saint gallen",
  "saint george",
  "saint george basseterre",
  "saint george gingerland",
  "saint helena",
  "saint james",
  "saint james windward",
  "saint john",
  "saint john capesterre",
  "saint john figtree",
  "saint john island",
  "saint joseph",
  "saint julian",
  "saint lawrence",
  "saint lucia",
  "saint lucy",
  "saint luke",
  "saint mark",
  "saint mary",
  "saint mary cayon",
  "saint michael",
  "saint patrick",
  "saint paul",
  "saint paul capesterre",
  "saint paul charlestown",
  "saint paul’s bay",
  "saint peter",
  "saint peter basseterre",
  "saint philip",
  "saint thomas",
  "saint thomas island",
  "saint thomas lowland",
  "saint venera",
  "saint-louis",
  "saipan",
  "saitama",
  "sakarya",
  "sakha",
  "sakhalin oblast",
  "sakon nakhon",
  "sal",
  "salah ad din",
  "salaj",
  "salamat region",
  "salaspils novads",
  "salavan",
  "saldus municipality",
  "salfit",
  "salta",
  "salyan",
  "salzburg",
  "samaná",
  "samara oblast",
  "samarqand region",
  "samburu district",
  "samdrup jongkhar",
  "samegrelo and zemo svaneti",
  "samsun",
  "samtse dzongkhag",
  "samtskhe-javakheti",
  "samut prakan",
  "samut sakhon",
  "samut songkhram",
  "samux",
  "san andres y providencia",
  "san fernando",
  "san juan",
  "san juan/laventille",
  "san luis",
  "san luis potosí",
  "sanaa",
  "sanaag",
  "sandys parish",
  "sangha",
  "sangha-mbaéré",
  "sangre grande",
  "sankuru",
  "sanma province",
  "sannat",
  "sant julià de loria",
  "santa catarina",
  "santa catarina do fogo",
  "santa cruz",
  "santa fe",
  "santarém",
  "santiago del estero",
  "santiago metropolitan",
  "sar-e pul",
  "saraburi",
  "saratovskaya oblast",
  "sarawak",
  "sardinia",
  "sarpang dzongkhag",
  "saskatchewan",
  "sassandra-marahoue",
  "satakunta",
  "satu mare",
  "satun",
  "satupa'itea",
  "saulkrastu novads",
  "savanes",
  "savannah",
  "savanne district",
  "saxony",
  "saxony-anhalt",
  "schaan",
  "schaffhausen",
  "schellenberg",
  "schleswig-holstein",
  "schwyz",
  "scotland",
  "sebastopol city",
  "seenu",
  "sejong-si",
  "selangor",
  "selenge aymag",
  "selibe phikwe",
  "selnica ob dravi",
  "semnan province",
  "senglea",
  "sentjur",
  "seoul",
  "sergipe",
  "sermersooq",
  "serravalle",
  "sevnica",
  "sha tin",
  "sha'biyat banghazi",
  "sha'biyat misratah",
  "shaanxi",
  "shabran",
  "shabwah",
  "shaki city",
  "sham shui po",
  "shamakhi rayon",
  "shan state",
  "shandong",
  "shanghai",
  "shanxi",
  "sharjah",
  "sharqia",
  "shaviyani atholhu",
  "shefa province",
  "shida kartli",
  "shiga",
  "shimane",
  "shinyanga",
  "shirak",
  "shirvan",
  "shiselweni district",
  "shizuoka",
  "shtip",
  "shumen",
  "shymkent",
  "si sa ket",
  "siauliai",
  "siaya district",
  "siazan rayon",
  "sibenik-knin",
  "sibiu",
  "sichuan",
  "sicily",
  "sidi bel abbès",
  "siem reap",
  "sigave",
  "siguldas novads",
  "siirt",
  "sikasso",
  "sikkim",
  "sila",
  "silesia",
  "silistra",
  "simiyu",
  "sinaloa",
  "sindh",
  "sing buri",
  "singida",
  "sinnār",
  "sinoe county",
  "sinop",
  "sint eustatius",
  "siparia",
  "sirdaryo region",
  "sisak-moslavina",
  "sistan and baluchestan",
  "sivas",
  "skikda",
  "skåne county",
  "slavonski brod-posavina",
  "sliven",
  "slovenj gradec",
  "slovenska bistrica",
  "slovenske konjice",
  "smiltenes novads",
  "smolenskaya oblast’",
  "smolyan",
  "soccsksargen",
  "sofala province",
  "sofia",
  "sofia-capital",
  "sohag",
  "sokoto state",
  "solothurn",
  "somali",
  "somogy megye",
  "songkhla",
  "sonora",
  "sool",
  "sopište",
  "soqatra",
  "soriano",
  "soufriere",
  "souk ahras",
  "souss-massa",
  "south",
  "south aegean",
  "south australia",
  "south caribbean coast",
  "south carolina",
  "south dakota",
  "south denmark",
  "south east",
  "south governorate",
  "south holland",
  "south kalimantan",
  "south karelia",
  "south kazakhstan",
  "south khangay",
  "south khorasan province",
  "south kivu province",
  "south moravian",
  "south ostrobothnia",
  "south province",
  "south sinai",
  "south sulawesi",
  "south sumatra",
  "south west",
  "south-east",
  "south-west region",
  "southampton parish",
  "southeast sulawesi",
  "southeastern governorate",
  "southern",
  "southern ari atoll",
  "southern darfur",
  "southern district",
  "southern governorate",
  "southern highlands province",
  "southern kordofan",
  "southern nations, nationalities, and people's region",
  "southern peninsula",
  "southern province",
  "southern red sea region",
  "southern region",
  "southern savonia",
  "southland",
  "southwest finland",
  "sowa town",
  "spanish wells district",
  "split-dalmatia",
  "st.-petersburg",
  "stann creek district",
  "stara zagora",
  "staro nagorichane",
  "state of aimeliik",
  "state of airai",
  "state of angaur",
  "state of chuuk",
  "state of kayangel",
  "state of koror",
  "state of kosrae",
  "state of melekeok",
  "state of ngarchelong",
  "state of ngardmau",
  "state of ngatpang",
  "state of ngchesar",
  "state of ngeremlengui",
  "state of peleliu",
  "state of pohnpei",
  "state of sonsorol",
  "state of yap",
  "stavropol kray",
  "stockholm county",
  "struga",
  "strumica",
  "strășeni",
  "studenichani",
  "stung treng",
  "styria",
  "subcarpathia",
  "suceava",
  "suchitepeque",
  "sucre",
  "sud",
  "sud-est",
  "sud-ouest",
  "sud-ubangi",
  "sudurpashchim pradesh",
  "suez",
  "suhbaatar aymag",
  "sukhothai",
  "sulaymaniyah",
  "sumadija",
  "sumqayit city",
  "sumy",
  "suphanburi",
  "surat thani",
  "surin",
  "surt",
  "surxondaryo region",
  "svalbard",
  "svay rieng",
  "sverdlovsk oblast",
  "sveta ana",
  "sveta trojica v slovenskih goricah",
  "sveti jurij v slovenskih goricah",
  "sveti nikole",
  "swains island",
  "sylhet division",
  "syunik",
  "szabolcs-szatmár-bereg",
  "são domingos",
  "são filipe",
  "são lourenço dos órgãos",
  "são miguel",
  "são paulo",
  "são salvador do mundo",
  "são vicente",
  "ségou",
  "sétif",
  "sîngerei",
  "södermanland county",
  "ta' kercem",
  "ta' xbiex",
  "tabasco",
  "tabor",
  "tabora",
  "tabuk region",
  "tacna",
  "tadjourah",
  "tafea province",
  "tafielah",
  "tagant",
  "tahoua",
  "tai po district",
  "taichung city",
  "tainan",
  "taipei city",
  "taita taveta district",
  "taitung",
  "tak",
  "takamaka",
  "takeo",
  "takhar",
  "tal-pieta",
  "talas",
  "talsi municipality",
  "tamanrasset",
  "tamaulipas",
  "tambacounda",
  "tambov oblast",
  "tamil nadu",
  "tana river district",
  "tandjilé",
  "tanga",
  "tanganyika",
  "tanger-tetouan-al hoceima",
  "tanintharyi region",
  "taoudénit",
  "taoyuan",
  "taraba state",
  "taraclia",
  "taranaki",
  "tarapacá",
  "targovishte",
  "tarrafal",
  "tarrafal de são nicolau",
  "tartu",
  "tartus governorate",
  "tarxien",
  "tas-sliema",
  "tashkent",
  "tashkent region",
  "tasman",
  "tasmania",
  "tataouine",
  "tatarstan republic",
  "tauragė county",
  "tavush",
  "ta‘izz",
  "tboung khmum",
  "tearce",
  "tehran",
  "tekirdağ",
  "tel aviv",
  "telangana",
  "teleneşti",
  "teleorman",
  "telsiai",
  "temburong",
  "temotu province",
  "tennessee",
  "terengganu",
  "ternopil oblast",
  "tete",
  "tetovo",
  "texas",
  "thaa atholhu",
  "thaba-tseka",
  "tharaka district",
  "the marches",
  "thessaly",
  "thimphu dzongkhag",
  "thurgau",
  "thuringia",
  "tianjin",
  "tiaret",
  "tibesti region",
  "tibet",
  "ticino",
  "tierra del fuego",
  "tigray",
  "tillaberi region",
  "timimoun",
  "timis",
  "tindouf",
  "tinh ba ria-vung tau",
  "tinh bac giang",
  "tinh bac kan",
  "tinh bac lieu",
  "tinh bac ninh",
  "tinh ben tre",
  "tinh binh duong",
  "tinh binh gjinh",
  "tinh binh phuoc",
  "tinh binh thuan",
  "tinh ca mau",
  "tinh cao bang",
  "tinh dien bien",
  "tinh gjong nai",
  "tinh gjong thap",
  "tinh ha giang",
  "tinh ha nam",
  "tinh ha tinh",
  "tinh hai duong",
  "tinh hoa binh",
  "tinh hung yen",
  "tinh khanh hoa",
  "tinh kien giang",
  "tinh lai chau",
  "tinh lam gjong",
  "tinh lang son",
  "tinh lao cai",
  "tinh nam gjinh",
  "tinh nghe an",
  "tinh ninh binh",
  "tinh ninh thuan",
  "tinh phu tho",
  "tinh phu yen",
  "tinh quang binh",
  "tinh quang nam",
  "tinh quang ninh",
  "tinh quang tri",
  "tinh soc trang",
  "tinh son la",
  "tinh thai binh",
  "tinh thai nguyen",
  "tinh thanh hoa",
  "tinh thua thien-hue",
  "tinh tra vinh",
  "tinh tuyen quang",
  "tinh vinh long",
  "tinh vinh phuc",
  "tinh yen bai",
  "tinian",
  "tipaza",
  "tirana",
  "tiris zemmour",
  "tissemsilt",
  "tivat",
  "tizi ouzou",
  "tiền giang",
  "tlaxcala",
  "tlemcen",
  "tobago",
  "tocantins",
  "tochigi",
  "togdheer",
  "tokat",
  "tokushima",
  "tokyo",
  "toledo district",
  "tolna megye",
  "tombali",
  "tombouctou",
  "tomsk oblast",
  "tongatapu",
  "toplica",
  "torba",
  "tottori",
  "touggourt",
  "tovuz rayon",
  "toyama",
  "trabzon",
  "trang",
  "trans nzoia district",
  "transbaikal territory",
  "transcarpathia",
  "transnistria",
  "trarza",
  "trashi yangste",
  "trashigang dzongkhag",
  "trat",
  "trbovlje",
  "trebnje",
  "trelawny",
  "trencin",
  "trentino-alto adige",
  "triesen",
  "triesenberg",
  "trinity palmetto point",
  "tripoli",
  "tripura",
  "tristan da cunha",
  "trnava",
  "troms og finnmark",
  "trongsa dzongkhag",
  "trzin",
  "trøndelag",
  "tshopo",
  "tshuapa",
  "tsuen wan district",
  "tuamasaga",
  "tucuman",
  "tuen mun",
  "tukuma novads",
  "tula oblast",
  "tulcea",
  "tulkarm",
  "tumbes",
  "tunapuna/piarco",
  "tunceli",
  "turkana district",
  "tuscany",
  "tutong",
  "tuzi",
  "tver oblast",
  "tyrol",
  "tyumen’ oblast",
  "táchira",
  "tây ninh province",
  "tébessa",
  "tǝrtǝr",
  "uaboe",
  "uasin gishu",
  "ubon ratchathani",
  "ucayali",
  "udmurtiya republic",
  "udon thani",
  "ujar rayon",
  "ulaanbaatar hot",
  "ulcinj",
  "ulsan",
  "ulster",
  "ulyanovsk oblast",
  "umbria",
  "ungheni",
  "union territory of puducherry",
  "unity",
  "upper austria",
  "upper demerara-berbice region",
  "upper east region",
  "upper river",
  "upper takutu-upper essequibo region",
  "upper west region",
  "uppsala county",
  "uri",
  "ustecky kraj",
  "utah",
  "utena",
  "uthai thani",
  "utrecht",
  "uttar pradesh",
  "uttaradit",
  "uttarakhand",
  "uusimaa",
  "uvea",
  "uvs aymag",
  "uíge",
  "uşak",
  "va'a-o-fonoti",
  "vaavu atholhu",
  "vaduz",
  "vaisigano",
  "vaitupu",
  "vakaga",
  "valais",
  "valandovo",
  "valcea",
  "valencia",
  "valgamaa",
  "valka",
  "vallee du bandama",
  "valletta",
  "valmiera",
  "van",
  "varaklanu novads",
  "varazdin",
  "vardak",
  "vargas",
  "varna",
  "vas",
  "vasilevo",
  "vaslui",
  "vaud",
  "vava'u",
  "vayots dzor",
  "velenje",
  "veles",
  "velika polana",
  "veliko tarnovo",
  "veneto",
  "ventspils",
  "ventspils municipality",
  "veracruz",
  "vermont",
  "vestfold og telemark",
  "vestland",
  "veszprem megye",
  "vevchani",
  "viana do castelo",
  "vianden",
  "victoria",
  "videm",
  "vidin",
  "vienna",
  "vientiane prefecture",
  "vientiane province",
  "vieux-fort",
  "vihiga district",
  "viken",
  "vila real",
  "viljandimaa",
  "vilnius",
  "viloyati khatlon",
  "viloyati sughd",
  "vinica",
  "vinnytsya oblast",
  "vipava",
  "viqueque",
  "virginia",
  "virovitica-podravina",
  "viseu",
  "vitanje",
  "vitebsk",
  "vladimirskaya oblast'",
  "vodice",
  "vojnik",
  "vojvodina",
  "volgograd oblast",
  "vologda oblast",
  "volta region",
  "volyn",
  "vorarlberg",
  "voronezh oblast",
  "vrancea",
  "vransko",
  "vrapchishte",
  "vratsa",
  "vrhnika",
  "vukovar-sirmium",
  "vuzenica",
  "värmland county",
  "västerbotten county",
  "västernorrland county",
  "västmanland county",
  "västra götaland county",
  "võrumaa",
  "wadi al hayat",
  "wadi ash shati'",
  "wadi fira region",
  "waikato",
  "wajir district",
  "wakayama",
  "wales",
  "wallonia",
  "wan chai",
  "wangdue phodrang dzongkhag",
  "warmia-masuria",
  "warrap",
  "washington",
  "wele-nzas",
  "wellington",
  "west",
  "west azerbaijan province",
  "west bengal",
  "west coast",
  "west grand bahama district",
  "west greece",
  "west java",
  "west kalimantan",
  "west kazakhstan",
  "west kordofan state",
  "west macedonia",
  "west new britain province",
  "west nusa tenggara",
  "west papua",
  "west pokot district",
  "west pomerania",
  "west region",
  "west sepik province",
  "west sulawesi",
  "west sumatra",
  "west virginia",
  "western",
  "western area",
  "western australia",
  "western bahr el ghazal",
  "western cape",
  "western darfur",
  "western district",
  "western equatoria",
  "western highlands province",
  "western north",
  "western province",
  "western region",
  "western visayas",
  "westfjords",
  "westmoreland",
  "white nile",
  "wiltz",
  "wisconsin",
  "woleu-ntem",
  "wong tai sin",
  "woqooyi galbeed",
  "woroba",
  "wyoming",
  "xaignabouli",
  "xaisomboun",
  "xiangkhouang",
  "xinjiang uyghur autonomous region",
  "xocalı",
  "xorazm region",
  "yala",
  "yalova",
  "yamagata",
  "yamaguchi",
  "yamalo-nenets",
  "yamanashi",
  "yambol",
  "yamoussoukro autonomous district",
  "yaracuy",
  "yaren",
  "yaroslavl oblast",
  "yasothon",
  "yau tsim mong",
  "yazd province",
  "yerevan",
  "yevlax",
  "yevlax city",
  "yevrey (jewish) autonomous oblast",
  "yilan",
  "yobe state",
  "yogyakarta",
  "yozgat",
  "yucatán",
  "yuen long district",
  "yukon",
  "yunlin",
  "yunnan",
  "zabul",
  "zacatecas",
  "zadar",
  "zagorje ob savi",
  "zagreb county",
  "zaire",
  "zajecar",
  "zala",
  "zambezi region",
  "zamboanga peninsula",
  "zamfara state",
  "zanjan",
  "zanzan",
  "zanzibar central/south",
  "zanzibar north",
  "zanzibar urban/west",
  "zaporizhzhya oblast",
  "zaqatala rayon",
  "zarqa",
  "zealand",
  "zeeland",
  "zelenikovo",
  "zhambyl oblysy",
  "zhejiang",
  "zhelino",
  "zhemgang dzongkhag",
  "zhytomyr",
  "ziguinchor",
  "zilina",
  "zinder",
  "zlatibor",
  "zlín",
  "zonguldak",
  "zou department",
  "zug",
  "zulia",
  "zurich",
  "zərdab",
  "çankırı",
  "çorum",
  "évora",
  "île-de-france",
  "ñuble",
  "ömnögovĭ",
  "örebro county",
  "östergötland county",
  "ādaži",
  "đắk lắk",
  "ķekava",
  "łódź voivodeship",
  "ōsaka",
  "świętokrzyskie",
  "şanlıurfa",
  "şa‘dah",
  "şoldăneşti",
  "şırnak",
  "şǝmkir",
  "škofja loka",
  "ʻeua",
  "ḩajjah",
];
