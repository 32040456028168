import jwtDecode from './jwt';
import { Cookie } from './cookie';

function Auth() {
  return {
    isAuthenticated: () => {
      return !!Cookie.get('_hellobar_db_token');
    },
    getToken: () => {
      return Cookie.get('_hellobar_db_token');
    },
    getTokenData: () => {
      return JSON.parse(Cookie.get('_hellobar_db_token_data'));
    },
    signedIn: () => {
      return sessionStorage.getItem('signed_in') === 'true';
    },
    signedUp: () => {
      return !!sessionStorage.getItem('track_signed_up');
    },
    login: (token) => {
      const decodedToken = jwtDecode(token);
      Cookie.set('_hellobar_db_token_data', JSON.stringify(decodedToken));
      Cookie.set('_hellobar_db_token', token);
      Cookie.set('_hellobar_db_signed_in', true);
      sessionStorage.setItem('signed_in', 'true');
    },
    logout: async () => {
      await fetch(`${process.env.REACT_APP_AUTH_HOST}/users/sign_out`, {
        method: 'DELETE',
        credentials: 'include',
      });
      Cookie.delete('_hellobar_db_token');
      Cookie.delete('_hellobar_db_token_data');
      Cookie.delete('_hellobar_db_signed_in');
      sessionStorage.removeItem('session_signed_up');
      window.location = `${process.env.REACT_APP_AUTH_HOST}/logged_out`;
    },
    loginWithPassword: ({ email, password }) => {
      const form = JSON.stringify({
        user: { email, password },
      });

      return fetch(`${process.env.REACT_APP_AUTH_HOST}/users/sign_in`, {
        method: 'POST',
        body: form,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
    },
  };
}

export const auth = Auth();
