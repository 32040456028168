import CollapsedPanel from '../components/CollapsedPanel';
import React, { useContext, useEffect, useState } from 'react';
import Switcher from '../components/Switcher';
import { Label } from '../Steps/components';
import { EditorContext } from '../../../utils/contexts';
import { AppBar, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SelectInput from '../components/SelectInput';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    marginTop: '12px',
    border: '1px solid #e0e0e0',
  },
  questionFields: {
    marginBottom: '20px !important',
    backgroundColor: 'white !important',
  },
  questionAppBarStyle: {
    padding: '0px !important',
    border: 'none !important',
  },
  questionTabStyle: {
    '& .MuiTab-root': {
      fontSize: '12px !important',
      width: '50%',
      padding: '4 !important',
      textTransform: 'none',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      cursor: 'default',
    },
  },
  leadQuestionPanel: {
    '& .MuiBox-root': {
      backgroundColor: 'white',
    },
  },
}));

const TextInput = ({ label, valueName, placeholderName, onChange }) => {
  const classes = useStyles();
  const { settings } = useContext(EditorContext);

  return (
    <TextField
      className={classes.questionFields}
      onChange={onChange}
      size="small"
      fullWidth
      variant="outlined"
      value={settings[valueName] || ''}
      label={label}
      placeholder={settings[placeholderName] || placeholderName}
    />
  );
};

const TabContent = ({ index }) => {
  const { settings, updateSettings } = useContext(EditorContext);

  const onAnswer1ResponseUpdate = (value) => {
    updateSettings({ answer1response: value });
  };

  const onAnswer1urlUpdate = (event) => {
    updateSettings({ answer1url: event.target.value });
  };

  const onAnswer2ResponseUpdate = (value) => {
    updateSettings({ answer2response: value });
  };

  const onAnswer2urlUpdate = (event) => {
    updateSettings({ answer2url: event.target.value });
  };

  const options = [
    { value: 'close', label: 'Close the popup' },
    { value: 'main', label: 'Open the main screen' },
    { value: 'thank-you', label: 'Open the thank-you screen' },
    { value: 'redirect', label: 'Redirect to another URL' },
  ];

  return (
    <>
      {index === 0 && (
        <>
          <SelectInput
            items={options}
            value={settings.answer1response || 'main'}
            onChange={onAnswer1ResponseUpdate}
            size="small"
          />

          {(settings.answer1response === 'redirect') && (
            <TextInput
              onChange={onAnswer1urlUpdate}
              valueName="answer1url"
              label="Redirect to"
              placeholderName="https://www.example.com"
            />
          )}
        </>
      )}

      {index === 1 && (
        <>
          <SelectInput
            items={options}
            value={settings.answer2response || 'close'}
            onChange={onAnswer2ResponseUpdate}
            size="small"
          />

          {(settings.answer2response === 'redirect') && (
            <TextInput
              onChange={onAnswer2urlUpdate}
              valueName="answer2url"
              label="Redirect to"
              placeholderName="https://www.example.com"
            />
          )}
        </>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export default function LeadingQuestionPanel() {
  const classes = useStyles();
  const { settings, updateSettings, systemUpdate } = useContext(EditorContext);
  const toggleUseQuestion = () => {
    updateSettings({ use_question: !settings.use_question });
  };
  const [value, setValue] = useState(0);

  useEffect(() => {
    settings.use_question && window.hellobar?.displayPage('question');
    !settings.use_question && window.hellobar?.displayPage('main');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <CollapsedPanel open={false} title="Leading Question">
      <Label>
        <span>Boost your conversions by asking a leading question first</span>
        <Switcher
          checked={settings.use_question}
          onChange={toggleUseQuestion}
        />
      </Label>
      {settings.use_question && (
        <div className={classes.questionContainer}>
          <AppBar className={classes.questionAppBarStyle} position="static">
            <Tabs
              className={classes.questionTabStyle}
              value={value}
              onChange={handleChange}
            >
              <Tab label={settings.answer1.replace(/(<([^>]+)>)/gi, "") || 'Answer 1'} />
              <Tab label={settings.answer2.replace(/(<([^>]+)>)/gi, "") || 'Answer 2'} />
            </Tabs>
          </AppBar>
          <TabPanel
            className={classes.leadQuestionPanel}
            value={value}
            index={0}
          >
            <TabContent index={value} />
          </TabPanel>
          <TabPanel
            className={classes.leadQuestionPanel}
            value={value}
            index={1}
          >
            <TabContent index={value} />
          </TabPanel>
        </div>
      )}
    </CollapsedPanel>
  );
}
