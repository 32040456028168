import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import uploadIcon from '../../../assets/images/upload-icon.svg';
import deleteIcon from '../../../assets/images/delete-icon.svg';
import AppLoader from '../../../shared/AppLoader';

const FontDorpzoneDiv = styled.div`
  cursor: pointer;
  text-align: center;
  font-size: 0.93em;
  color: #919191;
  border: 1px dashed #e2e2e2;
  padding: 10px 10px 10px 10px;

  p {
    margin: 15px 0;
  }

  span {
    color: #45b4d6;
  }
`;

const DropzoneDiv = styled.div`
  cursor: pointer;
  text-align: center;
  font-size: 0.93em;
  color: #919191;
  border: 1px solid #e2e2e2;
  padding: 0 10px 10px 10px;

  ${(props) =>
    props.$hasFile &&
    css`
      border-top: 0;
    `}

  img {
    margin-top: 20px;
    max-width: 100%;
    height: auto;
    border: none;
    margin-left: auto;
    margin-right: auto;
    vertical-align: bottom;
  }

  p {
    margin: 15px 0;
  }

  span {
    color: #45b4d6;
  }
`;

const CurrentFile = styled.div`
  border: 1px solid #e2e2e2;
  padding: 0.5em;
  font-size: 0.89em;
  color: #919191;
  display: flex;
  justify-content: space-between;

  img {
    margin: 0;
  }

  span {
    width: 80%;
    overflow-wrap: break-word;
  }
`;

export default function Dropzone({
  settings,
  onDelete,
  onUpdate,
  loading,
  isFont = false,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = function () {
        onUpdate(reader.result, file);
      };
      reader.readAsDataURL(file);
    },
    [onUpdate]
  );

  const options = {
    maxFiles: 1,
    maxFilesize: 20,
    onDrop,
    accept: isFont ? 'font/ttf' : '.jpeg,.jpg,.png,.gif',
  };
  const { getRootProps, getInputProps } = useDropzone(options);

  return (
    <div>
      <AppLoader visible={loading} />
      {isFont ? (
        <div>
          {!loading && (
            <FontDorpzoneDiv {...getRootProps({ refKey: 'innerRef' })}>
              <input {...getInputProps()} />
              <img src={uploadIcon} alt="upload" />
              <p>
                <span>Browse</span> to upload
                <br /> a new font (ttf files accepted)
              </p>
            </FontDorpzoneDiv>
          )}
        </div>
      ) : (
        <div>
          {!loading && settings.active_image_id && (
            <CurrentFile>
              <span>{settings.image_file_name}</span>
              <img onClick={onDelete} src={deleteIcon} alt="delete" />
            </CurrentFile>
          )}
          {!loading && (
            <DropzoneDiv
              $hasFile={!!settings.active_image_id}
              {...getRootProps({ refKey: 'innerRef' })}
            >
              <input {...getInputProps()} />
              <img src={uploadIcon} alt="upload" />
              <p>
                Drag an image or GIF here
                <br /> or <span>browse</span> to replace the
                <br /> uploaded image
              </p>
              <p>
                <small>Recommended minimum is 750 x 900</small>
                <br />
                <small>Maximum file size is 1MB</small>
              </p>
            </DropzoneDiv>
          )}
        </div>
      )}
    </div>
  );
}
