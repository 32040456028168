export const FONT_FAMILIES = {
  '"Abril Fatface", cursive': 'Abril Fatface',
  'Aclonica, sans-serif': 'Aclonica',
  '"Alfa Slab One", cursive': 'Alfa Slab One',
  '"Antic Slab", serif': 'Antic Slab',
  'Anton, sans-serif': 'Anton',
  'Arsenal, sans-serif': 'Arsenal',
  'Arial, Helvetica, sans-serif': 'Arial',
  'Arimo, sans-serif': 'Arimo',
  '"Barlow Condensed", sans-serif': 'Barlow Condensed',
  '"Berkshire Swash", cursive': 'Berkshire Swash',
  'Bevan, cursive': 'Bevan',
  '"Bree Serif", serif': 'Bree Serif',
  '"Bungee Inline", cursive': 'Bungee Inline',
  '"Cantata One", serif': 'Cantata One',
  '"Chela One", cursive': 'Chela One',
  'Cinzel, serif': 'Cinzel',
  'Coiny, cursive': 'Coiny',
  '"DM Sans", sans-serif': 'DM Sans',
  '"EB Garamond", serif': 'EB Garamond',
  '"Fredoka One", cursive': 'Fredoka One',
  '"Fugaz One", cursive': 'Fugaz One',
  'Georgia, serif': 'Georgia',
  'Impact, Charcoal, sans-serif': 'Impact',
  '"IBM Plex Sans", sans-serif': 'IBM Plex Sans',
  '"Josefin Sans", sans-serif': 'Josefin Sans',
  '"Julius Sans One", sans-serif': 'Julius Sans One',
  'Kanit, sans-serif': 'Kanit',
  'Kavoon, cursive': 'Kavoon',
  'Lato, sans-serif': 'Lato',
  '"Libre Baskerville", sans-serif': 'Libre Baskerville',
  'Lobster, cursive': 'Lobster',
  'Lustria, serif': 'Lustria',
  '"Marcellus SC", serif': 'Marcellus SC',
  'Martel, serif': 'Martel',
  'Merienda, cursive': 'Merienda',
  'Metropolis, sans-serif': 'Metropolis',
  'Mohave, sans-serif': 'Mohave',
  'Monoton, cursive': 'Monoton',
  'Montserrat, sans-serif': 'Montserrat',
  '"Montserrat Alternates", sans-serif': 'Montserrat Alternates',
  'Muli, sans-serif': 'Muli',
  '"Nixie One", cursive': 'Nixie One',
  'Notable, sans-serif': 'Notable',
  'Nunito, sans-serif': 'Nunito',
  '"Nunito Sans", sans-serif': 'Nunito Sans',
  '"Oleo Script", cursive': 'Oleo Script',
  '"Open Sans", sans-serif': 'Open Sans',
  '"Open Sans Condensed", sans-serif': 'Open Sans Condensed',
  'Oranienbaum, serif': 'Oranienbaum',
  'Oswald, sans-serif': 'Oswald',
  'Pacifico, cursive': 'Pacifico',
  '"Paytone One", sans-serif': 'Paytone One',
  '"Permanent Marker", cursive': 'Permanent Marker',
  'Philosopher, sans-serif': 'Philosopher',
  '"Playfair Display", serif': 'Playfair Display',
  'Poppins, sans-serif': 'Poppins',
  '"Poiret One", cursive': 'Poiret One',
  '"PT Sans", sans-serif': 'PT Sans',
  '"PT Serif", sans-serif': 'PT Serif',
  'Prata, serif': 'Prata',
  'Quicksand, sans-serif': 'Quicksand',
  'Rajdhani, sans-serif': 'Rajdhani',
  'Raleway, sans-serif': 'Raleway',
  'Righteous, cursive': 'Righteous',
  'Roboto, sans-serif': 'Roboto',
  '"Rock Salt", cursive': 'Rock Salt',
  'Rubik, sans-serif': 'Rubik',
  'Saira Condensed, sans-serif': 'Saira Condensed',
  'Signika, sans-serif': 'Signika',
  'Sintony, sans-serif': 'Sintony',
  '"Source Sans Pro", sans-serif': 'Source Sans Pro',
  '"Special Elite", cursive': 'Special Elite',
  '"Spicy Rice", cursive': 'Spicy Rice',
  'Spirax, cursive': 'Spirax',
  'Tahoma, Geneva, sans-serif': 'Tahoma',
  Taviraj: 'Taviraj',
  '"Times New Roman", Times, serif': 'Times New Roman',
  '"Titan One", cursive': 'Titan One',
  '"Ubuntu Condensed", sans-serif': 'Ubuntu Condensed',
  'Ultra, serif': 'Ultra',
  'Verdana, Geneva, sans-serif': 'Verdana',
  'Vidaloka, serif': 'Vidaloka',
  '"Work Sans", sans-serif': 'Work Sans',
};

export const GOOGLE_FONTS = [
  'Abril+Fatface',
  'Aclonica',
  'Alfa+Slab+One',
  'Antic+Slab',
  'Anton',
  'Arimo',
  'Arsenal',
  'Barlow+Condensed',
  'Berkshire+Swash',
  'Bevan',
  'Bree+Serif',
  'Bungee+Inline',
  'Cantata+One',
  'Chela+One',
  'Cinzel',
  'Coiny',
  'DM+Sans',
  'EB+Garamond',
  'Fredoka+One',
  'Fugaz+One',
  'Josefin+Sans',
  'Julius+Sans+One',
  'Kanit',
  'Kavoon',
  'Lato',
  'Libre+Baskerville',
  'Lobster',
  'Lustria',
  'Marcellus+SC',
  'Martel',
  'Merienda',
  'Mohave',
  'Monoton',
  'Montserrat',
  'Montserrat+Alternates',
  'Muli',
  'Nixie+One',
  'Notable',
  'Oleo+Script',
  'Open+Sans',
  'Open+Sans+Condensed:300',
  'Oranienbaum',
  'Oswald',
  'Pacifico',
  'Paytone+One',
  'Permanent+Marker',
  'Philosopher',
  'Playfair+Display',
  'Poppins',
  'Poiret+One',
  'Poppins',
  'PT+Sans',
  'PT+Serif',
  'Prata',
  'Quicksand',
  'Rajdhani',
  'Raleway',
  'Righteous',
  'Roboto',
  'Rock+Salt',
  'Rubik',
  'Saira+Condensed',
  'Signika',
  'Sintony',
  'Source+Sans+Pro',
  'Special+Elite',
  'Spicy+Rice',
  'Spirax',
  'Titan+One',
  'Ubuntu+Condensed',
  'Ultra',
  'Vidaloka',
  'Work+Sans',
];

export const FONT_WEIGHTS = {
  100: 'Light 100',
  200: 'Light 200',
  300: 'Light 300',
  400: 'Regular',
  500: 'Medium 500',
  600: 'Semi-bold 600',
  700: 'Bold 700',
  800: 'Bold 800',
  900: 'Black 900',
};

export const SUBTYPES = {
  CALL: 'call',
  EMAIL: 'email',
  ANNOUNCEMENT: 'announcement',
  SOCIAL: 'social',
  TRAFFIC: 'traffic',
  RETIREMENT: 'retirement',
  LEADFORM: 'leadform',
  WEBFORM: 'webform',
};

export const TYPES = {
  BAR: 'Bar',
  MODAL: 'Modal',
  SLIDER: 'Slider',
  ALERT: 'Alert',
  TAKEOVER: 'Takeover',
  INLINE: 'Inline',
};

const CONSTANTS = {
  MODE_DESKTOP: 'desktop',
  MODE_MOBILE: 'mobile',
  MODE_TABLET: 'tablet',
  FONT_FAMILIES,
  FONT_WEIGHTS,
  GOOGLE_FONTS,
  SUBTYPES,
  TYPES,
};

export default CONSTANTS;
