import styled from 'styled-components';
import constants from '../constants';
import React, { useContext } from 'react';
import { EditorContext, EditorStateContext } from '../../../utils/contexts';
import { useHistory } from 'react-router-dom';
import TextWithLoader from '../../../shared/TextWithLoader';
import HelpDialog from '../../../shared/HelpDialog';
import { useSnackbar } from 'notistack';
import { withTracking } from '../../../shared/Tracking';
import { FormControl, TextField } from '@mui/material';
import useAPI from '../../../utils/api';
import { useRoleCheck } from '../../../utils/useRoleCheck';
import DOMPurify from 'dompurify';

const NameField = styled.div`
  gap: 1em;
  margin-left: 15px;
  grid-template-columns: 150px 1fr;
`;

const HelpButton = styled.div`
  text-align: right;

  a {
    display: inline-block;
    color: #27ab9e;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButtons = styled.div`
  margin-right: 1em;
  align-items: baseline;
  display: inline-flex;
  justify-content: right;
  gap: 1em;
  white-space: nowrap;
`;

const CancelButton = styled.a`
  display: inline-block;
  color: #27ab9e;
`;

const SaveAndPauseButton = styled.a`
  display: inline-block;
  color: #27ab9e;
`;

const SaveButton = styled.a`
  background-color: #27ab9e;
  color: white;
  border-radius: 5px;
  padding: 8px 17px;
`;

function TopBarNavigation({ className, mode, setMode }) {
  const API = useAPI();
  const history = useHistory();
  const { settings, systemUpdate } = useContext(EditorContext);
  const editor = useContext(EditorStateContext);
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const save = async (settings, { pause }) => {
    if (editor.state.publishing || editor.state.saving) {
      return;
    }

    try {
      const [ok, response] = await editor.state.api.savePopup(settings, {
        pause,
      });
      if (ok) {
        enqueueSnackbar(
          'Popup is' + `${pause ? ' saved' : ' created'}` + ' successfully',
          { variant: 'success' }
        );
        editor.dispatch({
          type: 'EDITOR.SAVING_DONE',
          payload: { systemUpdate, response },
        });
        if (!pause) {
          history.push(`/sites/${editor.state.site.id}/manage`);
        }
      } else {
        enqueueSnackbar(response?.message || response.error, {
          variant: 'error',
        });
        editor.dispatch({
          type: 'EDITOR.SAVING_ERROR',
          payload: response.message,
        });
      }
    } catch (error) {
      enqueueSnackbar(`Couldn't save popup. ${error.message}`, {
        variant: 'error',
      });
      editor.dispatch({ type: 'EDITOR.SAVING_ERROR', payload: error });
    }
  };

  const validate = () => {
    if (
      settings.success_script !== undefined &&
      settings.success_script !== null
    ) {
      const success_script = settings.success_script;
      let hasError = false;
      let errorMsg;

      // Sanitize input using DOMPurify
      const sanitizedInput = DOMPurify.sanitize(success_script);

      // Step 2: Check if sanitized input is different from the original input
      if (sanitizedInput !== success_script) {
        errorMsg = 'Invalid success script!';
        hasError = true;
      }

      // Step 3: Check for external content patterns
      const externalPattern = /<script\s+src=|<iframe\s+src=/gi;
      if (externalPattern.test(success_script)) {
        errorMsg = 'Invalid success script!';
        hasError = true;
      }

      // Step 4: Check for disallowed JavaScript functions/objects
      const disallowedPatterns =
        /(document\.head|document\.body|document\.createElement)/gi;
      if (disallowedPatterns.test(success_script)) {
        errorMsg = 'Invalid success script!';
        hasError = true;
      }

      // If all checks pass, the input is considered safe
      if (hasError) {
        enqueueSnackbar(`${errorMsg}`, { variant: 'error' });
        history.push(
          `/sites/${editor.state.site.id}/popups/${
            settings.id || 'new'
          }/conversion`
        );
        return false;
      }
    }
    if (
      settings.subtype === constants.SUBTYPES.EMAIL &&
      !settings.contact_list_id
    ) {
      enqueueSnackbar(`Please choose a contact list`, { variant: 'error' });
      history.push(
        `/sites/${editor.state.site.id}/popups/${
          settings.id || 'new'
        }/conversion`
      );
      return false;
    }

    if (settings.type === constants.TYPES.INLINE && !settings.inline_css_selector) {
      enqueueSnackbar(
        `Please enter a css selector to insert the inline element`,
        { variant: 'error' }
      );
      history.push(
        `/sites/${editor.state.site.id}/popups/${settings.id || 'new'}/design`
      );
      return false;
    }

    if (
      settings.subtype === constants.SUBTYPES.EMAIL &&
      settings.settings.fields_to_collect
    ) {
      const fields = settings.settings.fields_to_collect;
      const custom_fields = fields.filter((f) => {
        return f.id.startsWith('field_');
      });
      let nameLabelError = false;
      let emptyOptionsError = false;
      custom_fields.forEach((cf) => {
        if (!cf.name) {
          nameLabelError = true;
          return;
        }

        if (
          (cf.type === 'multi_select' || cf.type === 'select') &&
          !cf.options?.trim()
        ) {
          emptyOptionsError = true;
          return;
        }
      });

      fields.forEach((f) => {
        if (!f.label) {
          nameLabelError = true;
          return;
        }
      });

      if (nameLabelError) {
        enqueueSnackbar(`Please enter name and label for each input field`, {
          variant: 'error',
        });
        history.push(
          `/sites/${editor.state.site.id}/popups/${settings.id || 'new'}/design`
        );
        return false;
      }

      if (emptyOptionsError) {
        enqueueSnackbar(
          `Please enter options for Dropdown and Multi-select type input field`,
          {
            variant: 'error',
          }
        );
        history.push(
          `/sites/${editor.state.site.id}/popups/${settings.id || 'new'}/design`
        );
        return false;
      }
    }

    if (
      settings.subtype === constants.SUBTYPES.CALL &&
      !settings.phone_number
    ) {
      enqueueSnackbar(`Please enter a valid phone number`, {
        variant: 'error',
      });
      history.push(
        `/sites/${editor.state.site.id}/popups/${settings.id || 'new'}/design`
      );
      return false;
    }

    if (settings.view_condition === 'event.') {
      enqueueSnackbar(
        `Please enter the event name for the custom event trigger`,
        { variant: 'error' }
      );
      history.push(
        `/sites/${editor.state.site.id}/popups/${settings.id || 'new'}/settings`
      );
      return false;
    }

    if (settings.view_condition === 'onclick' && !settings.click_selector) {
      enqueueSnackbar(
        `Please enter the css selector of the element for the onclick event trigger`,
        { variant: 'error' }
      );
      history.push(
        `/sites/${editor.state.site.id}/popups/${settings.id || 'new'}/settings`
      );
      return false;
    }

    return true;
  };

  const onCancel = () => {
    editor.dispatch({ type: 'EDITOR.CANCEL', payload: { history } });
  };

  const onSaveAndPause = () => {
    // To check allowed role to perform action
    if (handleRoleCheck()) {
      return;
    }
    if (!validate()) {
      return;
    }
    editor.dispatch({
      type: 'EDITOR.SAVE',
      payload: { settings, editor, systemUpdate },
    });
    save(settings, { pause: true });
  };

  const onPublish = async () => {
    // To check allowed role to perform action
    if (handleRoleCheck()) {
      return;
    }
    if (!validate()) {
      return;
    }

    editor.dispatch({
      type: 'EDITOR.PUBLISH',
      payload: { settings, editor, history },
    });
    await save(settings, { pause: false });
  };

  const handleNameChange = (e) => {
    systemUpdate({ name: e.target.value });
  };

  return (
    <nav className={className}>
      <NameField>
        <FormControl>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            type="text"
            value={settings.name}
            onChange={(e) => handleNameChange(e)}
          />
        </FormControl>
      </NameField>
      <Container>
        <ActionButtons>
          <HelpButton>
            <HelpDialog isOpen={false} buttonText={'Help?'} showIcon={false} />
          </HelpButton>
          <CancelButton onClick={onCancel}>
            {settings.userEdited ? 'Cancel' : 'Close'}
          </CancelButton>
          <SaveAndPauseButton onClick={onSaveAndPause}>
            <TextWithLoader loading={editor.state.saving} color="#27ab9e">
              Save & Pause
            </TextWithLoader>
          </SaveAndPauseButton>
          <SaveButton onClick={onPublish}>
            <TextWithLoader loading={editor.state.publishing}>
              Publish Now
            </TextWithLoader>
          </SaveButton>
        </ActionButtons>
      </Container>
    </nav>
  );
}

export default styled(withTracking(TopBarNavigation))`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #8f8f8f;
  background-color: white;
  box-shadow: 0px 0 3px 1px #8f8f8f;
`;
