import { useParams, Redirect, useHistory } from 'react-router-dom';
import jwtDecode from '../utils/jwt';
import Error from '../shared/Error';
import { auth } from '../utils/auth';

function LoginPage() {
  const history = useHistory();
  const { token } = useParams();
  if (!token) {
    history.push('/users/sign_in');
  }

  try {
    const decodedToken = jwtDecode(token);
    auth.login(token);

    return (
      <Redirect
        to={decodedToken.site_id ? '/sites/' + decodedToken.site_id : '/'}
      />
    );
  } catch (error) {
    console.error(error);
    return <Error error={error} />;
  }
}

export default LoginPage;
