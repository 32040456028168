import FormControl from "@mui/material/FormControl/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function NumberCondition({ condition, onChange, placeholder}) {
  const operands =
    [
      { "value": "is", "label": "is" },
      { "value": "is_not", "label": "is not" },
      { "value": "less_than", "label": "is less than" },
      { "value": "greater_than", "label": "is greater than" },
      { "value": "between", "label": "is between" }
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <FormControl variant="outlined">
        <TextField size="small" type="number" name="value" placeholder={placeholder} onChange={onChange} value={condition.value} variant="outlined" InputProps={{ inputProps: { min: 0 } }}/>
      </FormControl>
    </div>
  );
}
