import React from 'react'
import styled from 'styled-components';
import BrandingForm from './Branding/BrandingForm';
import AppLoader from '../../shared/AppLoader';
import Error from '../../shared/Error';
import useFetch from '../../utils/useFetch';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 45% auto;
  padding: 24px;
  column-gap: 50px;
  & h5{
    padding-bottom: 8px;
    border-bottom: 1px solid #E8562A
  }
  & h5 span {
    position: relative;
    top: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  & h5 span svg {
    height: 0.9em;
  }
`

const Branding = ({site}) => {
  const { loading, error, status, data } = useFetch('siteTheme');

  return (<>
    <AppLoader visible={loading}/>
    <Grid>
      {status === 'error' && <Error error={error}/>}
      {status === 'fetched' &&
        <BrandingForm
          site={site}
          data={data}
        />
      }
    </Grid>
  </>
  )
}

export default Branding
