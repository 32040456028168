import styled from 'styled-components'

export const Pagination = styled.div`
z-index: 5;
width: 405px;
position: absolute;
bottom: 0;
left: 0;
transition: all 0.5s;
height: 62px;
`

export const Nav = styled.nav`
justify-content: space-between;
align-items: center;
flex-flow: row nowrap;
display: flex;
background: white;
width: 100%;
height: 100%;
min-height: 100%;
padding: 12px;
box-shadow: 0 0 1px rgb(0 0 0 / 30%);
& .button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.55em 1em;
    font-weight: bold;
    background-color: #27ab9e;
    transition: all 0.5s;
    svg {
        font-size: 0.95em;
    }
    .icon-angle-right {
        margin-left: 10px;
    }
    .icon-angle-left {
        margin-right: 10px;
    }
}
& .button:first-child {
    margin-right: 20px;
}
& .button.disable {
    pointer-events: none;
    background-color: #aeaeae;
    border-color: #aeaeae;
}
& .button:last-child {
    margin-left: 20px;
}
& .button:hover {
    background-color: #27ab9e;
    border-color: #27ab9e;
}
`

export const DotNav = styled(({ className, active, onClick }) => {
    const classes = [
        className,
        active && 'active'
    ]
    return (
        <div className={ classes.join(' ') } onClick={onClick}></div>
    )
})` width: 12px;
    height: 12px;
    border-radius: 1em;
    background-color: #c6c6c6;
    transition: all 0.15s ease-out 0s;
    &.active {
        background-color: #27ab9e;
    }
    &:hover {
        background-color: #27ab9e;
    }
`

export const SideBarPin = styled.div`
    font-size:24px;
    font-weight:bold;
    position: absolute;
    bottom: 10px;
    transform: translate(405px, 0);
    color: #27ab9e;
    background: white;
    width: 35px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 2px 0px 0px rgb(0 0 0 / 20%);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0 10px 10px 0;
    cursor: pointer;
    z-index: 10;
    transition: all 0.2s;
    &.to-the-left {
        transform: translate(0, 0);
    }
`