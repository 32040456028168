import React, { useEffect } from 'react';
import { tracking } from '../utils/tracking';
import { auth } from '../utils/auth';
import {
  TrackingContext,
  useSegmentUser,
  useSite,
  useUser,
} from '../utils/contexts';
import GrowSumo from 'react-growsumojs';
import TrackPage from '../utils/track-page';

const Tracking = (({ children }) => {
  const user = useUser();
  const site = useSite();
  const segmentUser = useSegmentUser(user, site);

  useEffect(() => {
    if (user && site) {
      tracking.freshchat.init(user, site);
    }
  }, [user, site]);

  useEffect(() => {
    if (segmentUser) {
      window.growsumoInit = function () {
        window.growsumo.data.name = `${segmentUser.first_name || ''} ${
          segmentUser.last_name || ''
        }`.trim();
        window.growsumo.data.email = segmentUser.email;
        window.growsumo.data.customer_key = segmentUser.email;
      };
      if (window.growsumo) {
        window.growsumoInit();
      }

      tracking.segmentio.identity(segmentUser);

      if (auth.signedUp()) {
        tracking.segmentio.track('Signed Up', segmentUser);
        tracking.gtm.event({ name: 'signed_up' });

        if (window.growsumo) {
          GrowSumo.createSignup();
        }

        sessionStorage.removeItem('track_signed_up');
      }
      if (auth.signedIn()) {
        tracking.segmentio.track('Signed In', segmentUser);
        sessionStorage.removeItem('signed_in');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentUser, window.growsumo]);
  
  const trackEvent = (event, data = {}) => {
    tracking.segmentio.track(event, { ...segmentUser, ...data });
  };

  const contextValue = { trackEvent };

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
});

export default Tracking;

export const withTracking = (Component, trackingProps = {}) => {
  const Wrapped = (props) => {
    return (
      <Tracking {...trackingProps}>
        <TrackPage />
        <TrackingContext.Consumer>
          {(context) => (
            <Component {...props} trackEvent={context.trackEvent} />
          )}
        </TrackingContext.Consumer>
      </Tracking>
    );
  };
  Wrapped.displayName = `withTracking(${
    Component.displayName || Component.name || 'Unknown'
  })`;
  return Wrapped;
};

export const withEditorPageTracking = (EditorPage, pageName) => {
  return withTracking(({ trackEvent, ...props }) => {
    useEffect(() => {
      trackEvent(`Pop Up ${pageName}`);
    }, [trackEvent]);

    return <EditorPage {...props} />;
  });
};
