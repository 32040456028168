import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import '../../assets/styles/Summary.scss';
import '../../assets/styles/_install.scss';
import '../../assets/styles/_reveal.scss';
import '../../assets/styles/_icons.scss';
import '../../assets/styles/_charts.scss';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Charts from '../../shared/Charts';
import SummaryToolbar, { defaultDateRange } from '../SummaryToolbar';
import useAPI from '../../utils/api';
import moment from 'moment';
import AppLoader from '../../shared/AppLoader';
import AutoGraphOutlinedIcon from '@mui/icons-material/InsightsOutlined';

const goals = [
  { type: 'call', text: 'CALLS', icon: 'call', chart: 'call' },
  {
    type: 'email',
    text: 'EMAILS COLLECTED',
    icon: 'contacts',
    chart: 'contacts',
  },
  { type: 'traffic', text: 'CLICKS', icon: 'clicks', chart: 'clicks' },
  {
    type: 'social',
    text: 'SOCIAL CONVERSIONS',
    icon: 'social',
    chart: 'social',
  },
];

export default function PopupSummaryGraphDialog(props) {
  const api = useAPI();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(defaultDateRange());
  const [currentSeries, setCurrentSeries] = useState(['total']);
  const [graphs, setGraphs] = useState(null);
  const [numbers, setNumbers] = useState(null);

  const setRange = (value) => {
    setDateRange(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const popup_id = props.popup.id;
      const from = dateRange?.from || moment().subtract(7, 'days');
      const to = dateRange?.to || moment();
      if (open) {
        const [ok, response] = await api.popupGraphSummary(popup_id, from, to);
        if (ok) {
          setGraphs(response.graphs);
          setNumbers(response.numbers);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, [dateRange]);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const updateChart = (type) => () => {
    setCurrentSeries((prevValue) => {
      if (prevValue.length === 1 && prevValue.includes(type)) {
        return ['total'];
      } else if (prevValue.includes(type)) {
        return prevValue.filter((value) => value !== type);
      } else {
        return [...prevValue, type];
      }
    });
  };

  const cross = {
    float: 'right',
    cursor: 'pointer',
  };

  return (
    <React.Fragment>
      <AutoGraphOutlinedIcon
        className="icons-graph"
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Summary report for popup:{' '}
          <b>
            {props?.popup?.name
              ? props?.popup?.name
              : props?.popup?.title.substring(0, 50) + '...'}
          </b>
          <CloseIcon style={cross} onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <SummaryToolbar
            site={props.site}
            rangeChanged={setRange}
            initialRange={0}
            popup={props.popup}
          />
          <AppLoader visible={loading} />
          {!loading && graphs && numbers && (
            <>
              <div className="chart-wrapper tabs-data">
                <div
                  className={[
                    'chart-block views',
                    currentSeries.includes('total') && 'activated',
                  ].join(' ')}
                  data-chart=""
                  key={'total'}
                  onClick={updateChart('total')}
                >
                  <i className="icon-views"></i>
                  <p className="chart-overview">
                    {numbers['total_humanized']}
                    <small>VIEWS</small>
                  </p>
                </div>
                {goals
                  .filter((g) => {
                    return g.type === props.popup.subtype;
                  })
                  .map((stat) => (
                    <div
                      className={[
                        'chart-block',
                        stat.chart,
                        currentSeries.includes(stat.type) && 'activated',
                      ].join(' ')}
                      data-chart=""
                      key={stat.type}
                      onClick={updateChart(stat.type)}
                    >
                      <i className={`icon-${stat.icon}`}></i>
                      <p className="chart-overview">
                        {numbers['conversion_humanized']}
                        <small>{stat.text}</small>
                      </p>
                    </div>
                  ))}
              </div>
              <Charts data={graphs} currentSeries={currentSeries} />
            </>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
