import useFetch from '../../utils/useFetch';
import React, { useContext, useState } from 'react';
import {
  EditorContext,
  EditorStateContext,
  SiteContext,
} from '../../utils/contexts';
import { useSettingsReducer } from './settingsReducer';
import UpgradeDialog from '../Modals/UpgradeDialog';
import useAPI from '../../utils/api';
import { useEditorReducer } from './editorReducers';
import AppLoader from '../../shared/AppLoader';
import { checkUpgradeRules, resetUpgradeRules } from './upgradeRules';
import EditorLayout from './EditorLayout';
import { Route, Switch } from 'react-router-dom';
import TypeSelection from './interstitial/TypeSelection';
import GoalSelection from './interstitial/GoalSelection';
import { FONT_FAMILIES } from './constants';
import ThemeOverrideDialog from '../Modals/ThemeOverrideDialog';

export default function EditorWrapper({ initialSettings, match }) {
  const { loading, status, siteSettings } = useFetch('siteSettings');
  const [autodetectTheme, setAutodetectTheme] = useState({});
  const [showTheme, showThemeSelector] = useState(false);
  const [canOverrideTheme, setCanOverrideTheme] = useState(false);
  const [updateThemeContents, setUpdateThemeContents] = useState(false);
  const site = useContext(SiteContext);
  const [settings, dispatch] = useSettingsReducer(initialSettings);

  const [editorState, editorStateDispatch] = useEditorReducer({
    saving: false,
    saveAndPausing: false,
    api: useAPI(),
    site: site,
    initialSettings,
  });

  const editorStateValue = {
    state: editorState,
    dispatch: editorStateDispatch,
  };

  const contextValue = {
    settings,
    siteSettings,
    siteId: match.params.siteId,
    dispatch,
    showTheme,
    setAutodetectTheme,
    showThemeSelector: showThemeSelector,
    canOverrideTheme,
    setCanOverrideTheme: setCanOverrideTheme,
    updateThemeContents,
    setUpdateThemeContents: setUpdateThemeContents,
    systemUpdate: (update) => {
      dispatch({ type: 'update', payload: update });
    },
    updateSettings: (update) => {
      update.userEdited = true;
      dispatch({ type: 'update', payload: update, editor: editorStateValue });
    },
    updateTheme: (theme_id) => {
      dispatch({
        type: 'update_theme',
        payload: { theme_id, siteSettings, autodetectTheme },
      });
    },
  };

  const handleCancelOverrideTheme = () => {
    siteSettings.canOverride = false;
    dispatch({ type: 'update_theme', payload: { siteSettings } });
    setCanOverrideTheme(false);
  };

  const handleOverrideTheme = () => {
    siteSettings.canOverride = true;
    dispatch({ type: 'update_theme', payload: { siteSettings } });
    setCanOverrideTheme(false);
  };

  const handleCancelUpdateThemeContents = () => {
    siteSettings.canUpdateThemeContents = false;
    dispatch({ type: 'update_theme', payload: { siteSettings } });
    setUpdateThemeContents(false);
  };

  const handleUpdateThemeContents = () => {
    siteSettings.canUpdateThemeContents = true;
    dispatch({ type: 'update_theme', payload: { siteSettings } });
    setUpdateThemeContents(false);
  };

  if (!loading && status === 'fetched') {
    siteSettings.fonts = siteSettings.fonts || {};
    if (!siteSettings.fonts) {
      let fonts = {};
      siteSettings.font_uploads.forEach(
        (f) => (fonts[f.font_name] = `url('${f.url}')`)
      );
      Object.keys(FONT_FAMILIES).forEach((f) => (fonts[FONT_FAMILIES[f]] = f));
      siteSettings.fonts = fonts;
    }
  }

  return (
    <EditorStateContext.Provider value={editorStateValue}>
      <EditorContext.Provider value={contextValue}>
        <AppLoader visible={loading} />
        {checkUpgradeRules(site, settings) && (
          <UpgradeDialog onClose={() => resetUpgradeRules(contextValue)} />
        )}
        {canOverrideTheme && (
          <ThemeOverrideDialog
            onConfirm={handleOverrideTheme}
            onClose={handleCancelOverrideTheme}
          >
            Do you want to use your custom site popup theme? This would override
            the theme's default values.
          </ThemeOverrideDialog>
        )}
        {updateThemeContents && (
          <ThemeOverrideDialog
            onConfirm={handleUpdateThemeContents}
            onClose={handleCancelUpdateThemeContents}
            updateThemeFlag={true}
          >
            Do you want to keep your progress?
          </ThemeOverrideDialog>
        )}
        {!loading && status === 'fetched' && (
          <Switch>
            <Route
              exact
              path="/sites/:siteId/popups/:popupId/interstitial/:goalName"
            >
              <TypeSelection />
            </Route>
            <Route path="/sites/:siteId/popups/:popupId/interstitial">
              <GoalSelection />
            </Route>
            <Route>
              <EditorLayout site={site} />
            </Route>
          </Switch>
        )}
      </EditorContext.Provider>
    </EditorStateContext.Provider>
  );
}
