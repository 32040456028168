import React, { useState } from "react";
import Button from '@mui/material/Button';
import CreateIcon from '@mui/icons-material/Create';
import Edit from './Edit';

export default function BillingInfo(props) {
  const emptyBillingInfo = {address: {}, email: ''};
  const [billingInformation, setBillingInformation] = useState(props.billingInformation || emptyBillingInfo);
  const [editing, setEditing] = useState(false);

  const onUpdate = (updated) => {
    setEditing(false);
    setBillingInformation(updated);
    props.onUpdate();
  };

  if (editing) {
    return <Edit onClose={onUpdate} billingInformation={billingInformation}/>;
  }

  const renderAddress = ({ address }) => {
    return (<div>{address.city}, {address.state} {address.postal_code} {address.country}</div>);
  };

  return (
    <div>
      <h5 className="page-header" style={{ marginTop: '2em' }}>Billing Information</h5>
      <div className="BillingInfo Flex Flex-direction--row">
        <div style={{ flexBasis: '160px' }}><span className="TextLabel">Email</span></div>
        <div className="BillingInfo-address">{billingInformation.email}</div>
      </div>
      {billingInformation.address && <div className="BillingInfo Flex Flex-direction--row">
        <div style={{ flexBasis: '160px' }}><span className="TextLabel">Address</span></div>
        <div className="BillingInfo-address">
          <address>
            <div>{billingInformation.address.line1}</div>
            <div>{billingInformation.address.line2}</div>
          </address>
          <div>
            {renderAddress(billingInformation)}
          </div>
        </div>
      </div>}
      <Button color="primary" variant="contained" startIcon={<CreateIcon/>} onClick={() => setEditing(true)}>Update billing information</Button>
    </div>
  );
}
