import React from 'react';
import Button from '../../shared/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { UserContext } from "../../utils/contexts";
import UpgradeDialog from "./UpgradeDialog";
import Search from '../../shared/Search';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SiteSelectionDialog(props) {
  const [open, setOpen] = React.useState(false);

  const { buttonColor, buttonTitle, onSelected } = props;

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const copyPopup = (event, site) => {
    event.preventDefault();
    onSelected(event, site);
  };

  if (open && props.site.plan.free) {
    return <UpgradeDialog onClose={() => setOpen(false)}/>
  }

  return (
    <UserContext.Consumer>
      {user =>
        <React.Fragment>
          {open && props.site.plan.free && <UpgradeDialog/>}
          {props.component && <props.component onClick={handleClickOpen}/>}
          {!props.component && <Button color={buttonColor} onClick={handleClickOpen}>{buttonTitle}</Button>}
          <Dialog
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">COPY TO:</DialogTitle>
            <DialogContent>
              <Search sites={user.sites} isCopySearch={true} copyPopup={copyPopup}/>
            </DialogContent>
          </Dialog>
        </React.Fragment>}
    </UserContext.Consumer>
  );
}
