import React, {Fragment, useState} from 'react';
import {TextField} from '@mui/material';
import Button from '../../../shared/Button';
import {NavLink, useHistory} from "react-router-dom";
import {auth} from "../../../utils/auth";

const UpdatePassword = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const url = new URL(window.location)
  const token = url.searchParams.get('reset_password_token')

  const handleForm = (event) => {
    event.preventDefault();
    if (!isValidPassword() || !isConfirmed()) {
      return
    }

    setLoading(true);

    const form = JSON.stringify({
      user: {
        password,
        password_confirmation: passwordConfirm,
        reset_password_token: token
      }
    })

    fetch(`${process.env.REACT_APP_AUTH_HOST}/users/password`, {
      method: 'PUT', body: form, redirect: 'error', credentials: 'include', headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).then((response) => {
      if (response.status === 422 || response.status === 200) {
        return response.json()
      } else {
        throw Error()
      }
    }).then((json) => {
      if (json.errors?.reset_password_token) {
        history.push('/users/password/new')
      }

      if (json.email) {
        auth.loginWithPassword({email: json.email, password})
          .then(response => response.json())
          .then((response) => {
            if (response?.token) {
              history.push(`/login/jwt/${response.token}`)
            }
          }).finally(() => setLoading(false))
      }
    }).catch(console.log).finally(() => setLoading(false))
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handlePasswordConfirmChange = (event) => {
    setPasswordConfirm(event.target.value)
  }

  const isConfirmed = () => passwordConfirm === password
  const isValidPassword = () => password.toString().length > 7

  return (<div className="popup-form">
    <form onSubmit={handleForm} validate>
      <h3>Set Your Password</h3>
      <Fragment>
        <TextField required autoFocus={true} name="password" label="New Password" type="password" variant="outlined"
                   error={!isValidPassword()}
                   helperText={isValidPassword() ? "" : "Passwords must have at least 8 characters"}
                   onChange={handlePasswordChange}/>
        <TextField error={!isConfirmed()} required name="password_confirmation" label="Confirm New Password"
                   type="password"
                   variant="outlined"
                   onChange={handlePasswordConfirmChange} helperText={isConfirmed() ? "" : "Password mismatch"}/>
        <Button disabled={!isValidPassword() || !isConfirmed()} color="primary" variant="contained" type="submit"
                loading={loading}>Change my password</Button>
        <div className="links-container">
          <NavLink to="/users/sign_in">Sign In</NavLink>
          <br/>
          <a href={`${process.env.REACT_APP_AUTH_HOST}/users/sign_up`}>Sign up for a New Hello Bar Account</a>
        </div>
      </Fragment>
    </form>
  </div>)
}

export default UpdatePassword
