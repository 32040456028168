import { useEffect } from "react";
import { PositionManager } from "../utils/position-manager";

export default function ImagePosition({ updateSettings, settings }) {
  
  useEffect(() => {
    const element = document.querySelector(".hb-modal-img")
    const hasImagePlacement = window.hellobarSiteSettings.modules_filename !== 'modules.js'

    if (!element || !hasImagePlacement) {
      return;
    }
    
    const manager = new PositionManager()
    manager.init(element)
    
    const mousedown = (e) => {
      manager.start(e)
    }
    const mouseup = (e) => {
      updateSettings({
        image_position_y: manager.yCurrent === 0 ? undefined : manager.yCurrent,
        image_position_x: manager.xCurrent === 0 ? undefined : manager.xCurrent,
      })
      manager.end(e)
      manager.reset()
    }
    const mousemove = (e) => {
      manager.move(e)
    }
    
    element.addEventListener("mousedown", mousedown);
    element.addEventListener("mouseup", mouseup);
    element.addEventListener("mousemove", mousemove);
    
    
    return () => {
      element.style.cursor = "inherit";
      element.removeEventListener("mousedown", mousedown);
      element.removeEventListener("mouseup", mouseup);
      element.removeEventListener("mousemove", mousemove);
    }
  }, [updateSettings, settings.image_position_y, settings.image_position_x]);
  
  return null
}
