import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

export default function SidebarItem({ path, className, name }) {
  const match = useRouteMatch(path);

  return (
    <li
      key={name}
      className={match && (path !== '/' || match.isExact) ? 'active' : ''}
    >
      <NavLink to={path}>
        {className ? (
          <i className={className}></i>
        ) : (
          <i>
            <CrisisAlertIcon />
          </i>
        )}
        <span>{name}</span>
      </NavLink>
    </li>
  );
}
