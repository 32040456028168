import React, { useState } from 'react'
import useFetch from "../utils/useFetch"
import styled from "styled-components";
import Button from '../shared/Button';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import '../assets/styles/_rules_page.scss';
import AppLoader from "../shared/AppLoader";
import useAPI from "../utils/api";
import ConfirmationDialog from './Modals/ConfirmationDialog';
import CreateRuleDialog from "./Modals/CreateRuleDialog";
import { getTitleValue } from '../utils/ruleConditions'
import { useRoleCheck } from '../utils/useRoleCheck';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip { ...props } classes={ { popper: className } }/>
))(({ theme }) => ({
  [`& .${ tooltipClasses.tooltip }`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0px !important',
    marginLeft: '15px',
    fontSize: 12,
    border: '1px solid #dadde9',
    maxWidth: '400px',
    
    'ul': {
      paddingLeft: '20px',
      paddingTop: '10px',
      paddingBottom: '10px'
    },
    'ul li': {
      listStyleType: 'disc'
    }
  }
}));

const Rules = ({ site }) => {
  const [update, setUpdating] = useState(false);
  const { loading, status, data } = useFetch('rules', update);
  const API = useAPI();
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const deleteRule = async (rule) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    await API.deleteRule(rule);
    setUpdating(!update);
  }
  
  const deleteConfirmation = ({ onClick }) => (
    <button onClick={ onClick }><DeleteIcon/></button>
  )
  
  return (<div id="rule-lists">
    <div className="page-header">
      <h5>Rules</h5>
      <CreateRuleDialog onUpdate={ (resp) => setUpdating(!update) }
                        handler={ <Button color="secondary" label="Create New Rule"/> }/>
    </div>
    
    <table>
      <tbody>
      <tr>
        <th>Name</th>
        <th>Description</th>
        <th>Active Popups</th>
        <th>Actions</th>
      </tr>
      
      { status === 'fetched' && data.map((rule) => (
        <tr key={ rule.id }>
          <td>{ rule.name }</td>
          { rule.conditions.length < 2 ?
            <td>{ getTitleValue(rule) }</td> :
            <LightTooltip
              title={ getTitleValue(rule).join(", ") }
              placement="bottom-start"
              TransitionComponent={ Fade }
              TransitionProps={ { timeout: 600 } }>
              <td>{ getTitleValue(rule)[0]} & {getTitleValue(rule).length - 1} others</td>
            </LightTooltip>
          }
          <td>{ rule.popups_count }</td>
          <td>
            <div className={ `action-wrapper ${ !rule.editable && 'center' }` }>
              { rule.editable &&
                <CreateRuleDialog onUpdate={ (resp) => setUpdating(!update) } handler={ <button><EditIcon/></button> }
                                  ruleData={ rule } updatingRule={ true }/> }
              <CreateRuleDialog onUpdate={ (resp) => setUpdating(!update) }
                                handler={ <button><ContentCopyIcon/></button> } ruleData={ rule }/>
              { rule.editable && <ConfirmationDialog
                onConfirm={ () => deleteRule(rule) }
                component={ deleteConfirmation }
              >
                Are you sure you want to delete this rule? <br/>
                { rule.popups_count !== 0 && 'Note: This rule has popups attached, all the popups will be deleted if you proceed.' }
              </ConfirmationDialog> }
            </div>
          </td>
        </tr>
      )) }
      </tbody>
    </table>
    <AppLoader visible={ loading }/>
  </div>)
}

export default Rules
