import React, { Fragment, useContext } from 'react';
import InputBlock from '../components/InputBlock';
import ColorPicker from '../components/ColorPicker';
import { EditorContext } from '../../../utils/contexts';
import SelectInput from '../components/SelectInput';
import NumberInput from '../components/NumberInput';

export default function CommonStylingPanel({ placements }) {
  const { settings, updateSettings } = useContext(EditorContext);

  const updateBGColor = (color) => {
    updateSettings({
      background_color: color,
      primary_color: color,
    });
  };

  const updateTextColor = (color) => {
    updateSettings({
      text_color: color,
    });
  };

  const updateXOutColor = (color) => {
    updateSettings({
      cross_color: color,
    });
  };

  const updatePlacement = (placement) => {
    if (settings.type === 'Bar') {
      if (placement === 'bar-top') {
        updateSettings({ pushes_page_down: true });
      }
      if (placement === 'bar-bottom') {
        updateSettings({ pushes_page_down: false });
      }
    }
    updateSettings({
      placement,
    });
  };

  const updateContentPadding = (name, value, oldValue) => {
    let paddingValue = oldValue;
    const newValue = value || 0;
    switch (name) {
      case 'top':
        paddingValue[0] = newValue;
        return updateSettings({ content_padding: paddingValue.join(',') });
      case 'right':
        paddingValue[1] = newValue;
        return updateSettings({ content_padding: paddingValue.join(',') });
      case 'bottom':
        paddingValue[2] = newValue;
        return updateSettings({ content_padding: paddingValue.join(',') });
      case 'left':
        paddingValue[3] = newValue;
        return updateSettings({ content_padding: paddingValue.join(',') });
      default:
        return updateSettings({ content_padding: paddingValue.join(',') });
    }
  };

  const contentPadding = settings.content_padding.split(',');

  return (
    <Fragment>
      <InputBlock label="Background Color">
        <ColorPicker
          value={settings.background_color}
          onChange={updateBGColor}
        />
      </InputBlock>
      {settings.type !== 'Inline' && (
        <InputBlock label="X-Out Color">
          <ColorPicker
            value={settings.cross_color}
            onChange={updateXOutColor}
          />
        </InputBlock>
      )}
      <InputBlock label="Default text color">
        <ColorPicker value={settings.text_color} onChange={updateTextColor} />
      </InputBlock>
      {placements && (
        <InputBlock label="Placement">
          <SelectInput
            items={placements}
            value={settings.placement || placements[0].value}
            onChange={updatePlacement}
            size="small"
          />
        </InputBlock>
      )}
      {settings.type !== 'Bar' && (
        <>
          <InputBlock label="Padding Top">
            <NumberInput
              value={Number(contentPadding[0])}
              onChange={(value) =>
                updateContentPadding('top', value, contentPadding)
              }
              max={100}
              min={0}
              step={1}
              size="small"
            />
          </InputBlock>
          <InputBlock label="Padding Right">
            <NumberInput
              value={Number(contentPadding[1])}
              onChange={(value) =>
                updateContentPadding('right', value, contentPadding)
              }
              max={100}
              min={0}
              step={1}
              size="small"
            />
          </InputBlock>
          <InputBlock label="Padding Bottom">
            <NumberInput
              value={Number(contentPadding[2])}
              onChange={(value) =>
                updateContentPadding('bottom', value, contentPadding)
              }
              max={100}
              min={0}
              step={1}
              size="small"
            />
          </InputBlock>
          <InputBlock label="Padding Left">
            <NumberInput
              value={Number(contentPadding[3])}
              onChange={(value) =>
                updateContentPadding('left', value, contentPadding)
              }
              max={100}
              min={0}
              step={1}
              size="small"
            />
          </InputBlock>
        </>
      )}
    </Fragment>
  );
}
