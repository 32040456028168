import { useEffect } from "react";
import { tracking } from "./tracking";
import { withRouter } from "react-router-dom";

const TrackPage = ({ location }) => {
  useEffect(() => {
    tracking.page();
  }, [location]);
  return null;
};

export default withRouter(TrackPage);
