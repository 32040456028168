import React from 'react'
import { Paper } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

const items = [
    {
        name: "Neil Patel",
        image: "//assets.hellobar.com/signup/Neil_Patel.png",
        post: "Digital Marketing & SEO Expert",
        comapny: "Neil Patel Digital",
        review: "Hello Bar helps me create engaging popups at a lighting fast pace for many of my ventures with no coding skills required!"
    },
    {
        name: "Matt Gross",
        image: "//assets.hellobar.com/signup/Matt_Gross.png",
        post: "Senior Director, Digital Initiatives",
        comapny: "Archetype Media",
        review: "So flexible and functional we've made Hello Bar the core of our email-acquisition strategy."
    },
    {
        name: "Jeremy Ellens",
        image: "//assets.hellobar.com/signup/Jeremy_Ellens.png",
        post: "Co-founder, Conversion Expert",
        company: "LeadQuizzes",
        review: "We used Hello Bar to advertise LeadQuizzes on our website and increased lead capture by over 50% and made an additional $52,223 dollars!"
    }
]

const Item = (props) => {
    return (
        <Paper elevation={0}>
            <div className="info-wrap">
                <img alt="" src={props.item.image}/>
                <div className="info-content">
                    <h2>{props.item.name}</h2>
                    <h4>{props.item.post} <br/> {props.item.company}</h4>
                </div>
            </div>
            <div className="review-wrap">
                <p>{props.item.review}</p>
            </div>
        </Paper>
    )
}

const AuthCarousel = () => {
    return <Carousel
        navButtonsAlwaysInvisible={true}
        activeIndicatorIconButtonProps={{
            style: {
                color: 'white'
            }
        }}>
        {
            items.map( (item, i) => <Item key={i} item={item} /> )
        }
    </Carousel>
}

export default AuthCarousel
