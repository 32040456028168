import styled from 'styled-components';
import React, { useContext, useEffect } from 'react';
import { LabelSelector, ListWrapper, Title, TitleHint } from './components';
import SelectInput from '../components/SelectInput';
import { EditorContext } from '../../../utils/contexts';
import { TextField } from '@mui/material';
import ContactListsInput from '../components/ContactListsInput';
import { withEditorPageTracking } from '../../../shared/Tracking';
import { days, extractPlanTier } from '../utils';
import useAPI from '../../../utils/api';
import { SUBTYPES } from '../constants';
import PaywallLock from '../../PaywallLock';

function ConversionStep({ className, goal }) {
  const api = useAPI();
  const { settings, updateSettings, systemUpdate } = useContext(EditorContext);
  const siteId = api.siteId;
  const planTier = extractPlanTier(api.plan);
  const showConversion = [
    SUBTYPES.EMAIL,
    SUBTYPES.WEBFORM,
    SUBTYPES.RETIREMENT,
    SUBTYPES.LEADFORM,
    SUBTYPES.SOCIAL
  ].includes(goal);

  const afterSubmitOptions = [
    {
      value: 0,
      label: 'Show default message',
      tier: 0,
    },
    {
      value: 1,
      label: 'Show a custom message',
      tier: 2,
    },
    {
      value: 2,
      label: 'Redirect the visitor to a url',
      tier: 2,
    },
  ];

  const updateAfterSubmit = (value) => {
    const customSettings = settings.settings;
    customSettings.after_email_submit_action = value;
    customSettings.after_email_submit_action === 1 &&
      !settings.thank_you_text &&
      showDefaultCustomThankYouText();
    updateSettings({ settings: customSettings });
  };

  const updateSuccessScript = (event) => {
    updateSettings({ success_script: event.target.value });
  };

  const showDefaultCustomThankYouText = () => {
    updateSettings({ thank_you_text: 'This is a custom thank you message.' });
  };

  const updateRedirectURL = (event) => {
    const customSettings = settings.settings;
    customSettings.redirect_url = event.target.value;
    updateSettings({ settings: customSettings });
  };

  const isCustomMessage = settings.settings.after_email_submit_action === 1;
  const isRedirect = settings.settings.after_email_submit_action === 2;

  if (settings.preview_page !== 'conversion') {
    systemUpdate({ preview_page: 'conversion' });
  }

  if (settings.show_thankyou !== true) {
    systemUpdate({ show_thankyou: true });
  }

  const updateContactList = (value) => {
    updateSettings({ contact_list_id: value });
  };

  const updateConvertionDuration = (value) => {
    const customSettings = settings.settings
    customSettings.cookie_settings.success_duration = value

    updateSettings({settings: customSettings})
  }

  useEffect(() => {
    if (window.hellobar && showConversion) {
      window.hellobar.displayPage('thank-you');
      const handler = () => {
        window.hellobar.displayPage('thank-you');
      };
      window.hellobar.addEventListener('show', handler);

      return () => {
        window.hellobar.removeEventListener('show', handler);
      };
    }
  }, [window.hellobar]);

  return (
    <div className={className}>
      <div>
        <Title>Conversion</Title>
        <TitleHint>Conversion settings.</TitleHint>
      </div>
      <ListWrapper>
        {settings.type !== 'Inline' && (
          <LabelSelector>
            <span>After a conversion, don't display this site element to that user for:</span>
            <SelectInput items={days} value={settings.settings.cookie_settings.success_duration}
                        onChange={updateConvertionDuration}/>
          </LabelSelector>
        )}
        {settings.subtype === 'email' && (
          <>
            <LabelSelector>
              <span>
                After a visitor submits an email address, what do you want to do
                on your site?
              </span>
              <SelectInput
                items={afterSubmitOptions}
                value={settings.settings.after_email_submit_action}
                onChange={updateAfterSubmit}
                planTier={planTier}
                siteId={siteId}
              />
            </LabelSelector>
            <LabelSelector>
              {isRedirect && (
                <TextField
                  variant="outlined"
                  label="Redirect URL"
                  value={settings.settings.redirect_url}
                  onChange={updateRedirectURL}
                  placeholder="https://example.com"
                  size="small"
                />
              )}
            </LabelSelector>
            <LabelSelector>
              <span>
                After Hello Bar collects an email address, where should we send
                it?
              </span>
              <ContactListsInput
                items={settings.site.contact_lists}
                value={settings.contact_list_id}
                onChange={updateContactList}
              />
            </LabelSelector>
          </>
        )}
        {settings.subtype !== 'social' && (
          <LabelSelector>
            <span>
              After your popup converts, run a script to fire an advanced pixel
              like Facebook’s tracking pixel and more.
            </span>
            <TextField
              variant="outlined"
              multiline
              rows={3}
              label="Conversion Triggered Script"
              value={settings.success_script}
              onChange={updateSuccessScript}
              placeholder="<script></script>"
              size="small"
              disabled={planTier < 2 ? true : false}
            />
            {planTier < 2 && (
              <PaywallLock
                message="Not available on your plan. Please upgrade your subscription."
                url={siteId ? `/sites/${siteId}/upgrade` : ''}
              />
            )}
          </LabelSelector>
        )}
      </ListWrapper>
    </div>
  );
}

export default styled(withEditorPageTracking(ConversionStep, 'Conversion'))``;
