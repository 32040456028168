import React, {useContext} from "react";
import {EditorContext, useSite} from "../../../utils/contexts";
import CollapsedPanel from "../components/CollapsedPanel";
import {TextField} from "@mui/material";
import styled from "styled-components";
import Switcher from "../components/Switcher";
import {Label} from "../Steps/components";

const Wrapper = styled.div`
  padding: 10px 0;
  
  p {
    margin-top: 10px;
    font-size: 0.8em;
  }
`

export default function UrlTargetPanel() {
  const {settings, updateSettings} = useContext(EditorContext)
  const site = useSite()

  const updateUrl = (event) => {
    const customSettings = {...settings.settings, url: event.target.value.trim()}
    updateSettings({settings: customSettings})
  }

  const toggleOpenInNewWindow = () => {
    updateSettings({ open_in_new_window: !settings.open_in_new_window })
  }

  return (
    <CollapsedPanel open={false} title="URL Target">
      <Wrapper>
        <TextField onChange={updateUrl} fullWidth variant="outlined" value={settings.settings.url} label="Enter link URL"
                   placeholder={site.url} size="small"/>
        <p>This is the web address that your button will link to, e.g. {site.url}/new</p>
        <Label>
          <span>Open link in new tab</span>
          <Switcher checked={settings.open_in_new_window} onChange={toggleOpenInNewWindow}/>
        </Label>
      </Wrapper>
    </CollapsedPanel>
  )
}
