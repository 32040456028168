import React from 'react'

export default function ConversionIcon() {
  return (
    <svg width="32px" height="35px" viewBox="0 0 32 39" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Hello-Bar-New-Creation-Flow" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="6---Conversion" transform="translate(-30.000000, -602.000000)">
          <g id="menu-items" transform="translate(0.000000, 82.000000)">
            <g id="Conversion" transform="translate(12.000000, 520.000000)">
              <g id="Page-1" transform="translate(18.000000, 0.000000)">
                <g id="Group-3" transform="translate(6.066667, 1.733333)">
                  <path d="M25.1568417,4.36454179 C25.1568417,4.36454179 19.4530917,4.60251577 16.4165083,4.9800865 C14.6978,5.19442797 15.775175,1.78529951 15.775175,1.10984911 C15.775175,-1.98930862 0.0749666667,2.52285398 0.0749666667,2.52285398 L0.0749666667,20.786945 C0.0749666667,20.786945 1.267175,21.312906 2.93984167,20.6698816 C7.18325833,19.0370393 14.520675,16.6963207 13.3382167,20.554467 C12.3393833,23.8119076 24.8090917,21.1908963 24.8090917,21.1908963 C24.8090917,21.1908963 23.9137167,14.4012182 25.031175,10.4567857 C26.1480917,6.51235317 25.1568417,4.36454179 25.1568417,4.36454179" id="Fill-1"/>
                </g>
                <path d="M3.46666667,1.92274971 C3.46666667,0.861565926 2.69035696,0 1.73357854,0 C0.775819305,0 0,0.861565926 0,1.92274971 L0,39 L3.46666667,39 L3.46666667,1.92274971 Z" id="Fill-4"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
