import React, { useContext } from 'react';
import { EditorContext } from '../../../utils/contexts';
import CollapsedPanel from '../components/CollapsedPanel';
import { LabelSelector } from '../Steps/components';
import SelectInput from '../components/SelectInput';
import FacebookPage from '../SocialProfileSettings/FacebookPage';
import LikeOnFacebook from '../SocialProfileSettings/LikeOnFacebook';
import TweetOnTwitter from '../SocialProfileSettings/TweetOnTwitter';
import FollowOnTwitter from '../SocialProfileSettings/FollowOnTwitter';
import ShareOnLinkedin from '../SocialProfileSettings/ShareOnLinkedin';
import PinOnPinterest from '../SocialProfileSettings/PinOnPinterest';
import FollowOnPinterest from '../SocialProfileSettings/FollowOnPinterest';
import FollowOnInstagram from '../SocialProfileSettings/FollowOnInstagram';
import FollowOnYoutube from '../SocialProfileSettings/FollowOnYoutube';
import ShareOnFacebook from '../SocialProfileSettings/ShareOnFacebook';
import styled from 'styled-components';

const Wrapper = styled.div`
  .MuiFormControl-root + .MuiFormControl-root {
    margin-top: 1em;
  }
`;

export default function SocialProfilePanel() {
  const { settings, updateSettings } = useContext(EditorContext);

  const components = {
    tweet_on_twitter: TweetOnTwitter,
    follow_on_twitter: FollowOnTwitter,
    like_on_facebook: LikeOnFacebook,
    facebook_page: FacebookPage,
    share_on_linkedin: ShareOnLinkedin,
    pin_on_pinterest: PinOnPinterest,
    follow_on_pinterest: FollowOnPinterest,
    follow_on_instagram: FollowOnInstagram,
    follow_on_youtube: FollowOnYoutube,
    share_on_facebook: ShareOnFacebook
  };

  const socialOptions = [
    { value: 'tweet_on_twitter', label: 'Tweet on Twitter' },
    { value: 'follow_on_twitter', label: 'Follow on Twitter' },
    { value: 'like_on_facebook', label: 'Like on Facebook' },
    { value: 'share_on_facebook', label: 'Share on Facebook' },
    { value: 'facebook_page', label: 'Facebook Page' },
    { value: 'share_on_linkedin', label: 'Share on LinkedIn' },
    { value: 'pin_on_pinterest', label: 'Pin on Pinterest' },
    { value: 'follow_on_pinterest', label: 'Follow on Pinterest' },
    { value: 'follow_on_instagram', label: 'Follow on Instagram' },
    { value: 'follow_on_youtube', label: 'Follow on Youtube' },
  ];

  const updateOption = (value) => {
    updateSettings({ settings: { ...settings.settings, social_type: value } });
  };

  const renderDetails = () => {
    const Component = components[settings.settings.social_type];
    if (!Component) {
      return null;
    }
    return <Component />;
  };

  return (
    <CollapsedPanel open={false} title='Social Profile'>
      <LabelSelector>
        <span>Choose which social profile you want to promote below:</span>
        <SelectInput
          items={socialOptions}
          value={settings.settings.social_type}
          onChange={updateOption}
        />
      </LabelSelector>
      <Wrapper>{renderDetails()}</Wrapper>
    </CollapsedPanel>
  );
}
