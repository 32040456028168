import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";
import MultiTextInput from "../MultiTextInput";

export default function UrlPathCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "is", "label": "is" },
      { "value": "is_not", "label": "is not" },
      { "value": "includes", "label": "includes" },
      { "value": "does_not_include", "label": "does not include" },
      { "value": "keyword", "label": "keyword" },
      { "value": "matches_regex", "label": "matches RegEx" },
      { "value": "not_matches_regex", "label": "not matches RegEx" }
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <MultiTextInput onChange={onChange} value={condition.value} placeholder="/some/path"/>
    </div>
  );
}
