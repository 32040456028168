import React from 'react';
import Button from "../../../shared/Button";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.profile.email,
      first_name: props.profile.first_name,
      last_name: props.profile.last_name
    };
    this.onSubmit = props.onSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  componentDidUpdate(props, state) {
    if (props.profile.email !== state.email) {
      this.setState({ email: props.profile.email });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({loading: true});
    this.onSubmit(this.state).then(() => this.setState({loading: false}));
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Grid>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3} direction="column">
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField disabled value={this.state.email} name="email" label="Email" size='small'/>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField value={this.state.first_name} name="first_name" label="First name" onChange={this.handleInputChange} size='small'/>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField value={this.state.last_name} name="last_name" label="Last name" onChange={this.handleInputChange} size='small'/>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Button type="submit" loading={this.state.loading} color="secondary" label="Save &amp; Update" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default ProfileForm;
