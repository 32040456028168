import styled from 'styled-components';
import iconRetirement from '../../../../assets/images/editor/goal-retirement.svg';
import iconLeadform from '../../../../assets/images/editor/goal-retirement.svg';
import iconEmails from '../../../../assets/images/editor/goal-emails.svg';
import iconCalls from '../../../../assets/images/editor/goal-calls.svg';
import iconSocial from '../../../../assets/images/editor/goal-social.svg';
import iconUrl from '../../../../assets/images/editor/goal-url.svg';
import iconAnnouncement from '../../../../assets/images/editor/goal-announcement.svg';
import iconWebform from '../../../../assets/images/editor/goal-retirement.svg';
import iconBar from '../../../../assets/images/editor/type-bar.svg';
import iconInline from '../../../../assets/images/editor/type-inline.svg';
import iconModal from '../../../../assets/images/editor/type-modal.svg';
import iconSlider from '../../../../assets/images/editor/type-slider.svg';
import iconTakeover from '../../../../assets/images/editor/type-takeover.svg';
import iconAlert from '../../../../assets/images/editor/type-alert.svg';
import iconNotification from '../../../../assets/images/editor/type-notification.svg';
import { SiteContext } from '../../../../utils/contexts';
import { useContext } from 'react';
import PaywallLock from '../../../PaywallLock';
import { useHistory } from 'react-router-dom';

const icons = {
  retirement: iconRetirement,
  leadform: iconLeadform,
  email: iconEmails,
  call: iconCalls,
  social: iconSocial,
  traffic: iconUrl,
  announcement: iconAnnouncement,
  webform: iconWebform,
  Bar: iconBar,
  Modal: iconModal,
  Inline: iconInline,
  Slider: iconSlider,
  Takeover: iconTakeover,
  Alert: iconAlert,
  Notification: iconNotification,
};

export const DashLink = styled.a`
  display: block;
  color: inherit;
  text-align: center;
  margin-top: 10px;
`;

export const SupportLink = styled.a`
  display: block;
  color: inherit;
  margin-bottom: 15px;
  text-decoration: underline;
  text-align: center;
`;

export const Container = styled.div`
  color: #919191;
  text-align: center;
  padding: 15px;
`;

export const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-flow: column nowrap;
  min-height: 65vh;
`;

export const CardWrapper = styled.div`
  padding: 0 0.75em;
  text-align: center;
  margin: 1.5em;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  align-items: stretch;
`;

export const InterstitialCard = styled(
  ({ className, name, title, children, onClick, highlight }) => {
    const history = useHistory();
    const site = useContext(SiteContext);
    const icon = icons[name];
    // const select = () => {
    //   (site.plan.free || site.plan.free_forever) && name === 'Inline'
    //     ? history.push(`/sites/${site.id}/upgrade`)
    //     : onClick(name);
    // };
    const select = () => {
      onClick(name);
    };

    return (
      <div className={className} onClick={select}>
        {/* {(site.plan.free || site.plan.free_forever) && name === 'Inline' && (
          <PaywallLock
            message="Not available on your plan. Please upgrade your subscription."
            url={''}
          />
        )} */}
        {highlight && <div className="recommended">{highlight}</div>}
        <h6>{title}</h6>
        <img src={icon} alt="" />
        <p>{children}</p>
      </div>
    );
  }
)`
  position: relative;
  margin-bottom: 0.67em;
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  margin: 0 0.75em;
  padding: 2em 1.14286em;
  background-color: white;
  box-shadow: 0 0 0 transparent;
  transition: 0.5s all ease-in-out;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  display: flex;
  width: 15.5em;
  height: 15em;

  &:hover {
    transition: 0.3s all ease-in-out;
    border-style: solid;
    border-color: #e8562a;
  }
  img {
    width: 70px;
    height: 50px;
  }
  h6 {
    white-space: nowrap;
    line-height: 133%;
    padding-bottom: 1.2em;
    font-size: 1.2em;
    color: #666666;
  }
  p {
    color: #666666;
    font-size: 0.9em;
    margin-top: 2em;
    line-height: 150%;
  }
  .recommended {
    position: absolute;
    background: #e8562a;
    padding: 5px;
    margin-left: -18px;
    margin-top: -30px;
    color: #fff;
    border-top-left-radius: inherit;
    font-size: 0.8em;
  }
`;

export const Footer = styled.div`
  color: #919191;
  margin-top: 1em;
  padding-top: 1.5em;
  text-align: center;
  border-top: 1px solid #e2e2e2;
  display: inline-block;
`;
