import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { SUBTYPES, TYPES } from './constants';

const popupPages = ({ className, caption, onClick, name, currentType }) => {
  const classes = [
    className,
    (['goal', 'type', 'design', 'settings', 'targeting'].includes(
      currentType
    ) &&
      name === 'main') ||
    (currentType === 'question' && name === 'question') ||
    (currentType === 'conversion' && name === 'thank-you')
      ? 'active'
      : '',
  ];

  return (
    <li className={classes.join(' ')} onClick={onClick}>
      {caption}
    </li>
  );
};

const PopupPage = styled(popupPages)`
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  margin: auto;
  height: 52px;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100px;
  border-right: 1px solid #ffffff;

  &.active {
    background-color: white;
    color: rgb(82, 82, 82);
  }
`;

export const PopupPageSelector = ({ goal, type }) => {
  const history = useHistory();
  const match = useRouteMatch('/sites/:siteId/popups/:popupId/:type');
  const currentType = match?.params?.type;
  const siteId = match?.params?.siteId;
  const popupId = match?.params?.popupId || 'new';
  const showConversion = [
    SUBTYPES.EMAIL,
    SUBTYPES.WEBFORM,
    SUBTYPES.RETIREMENT,
    SUBTYPES.LEADFORM,
  ].includes(goal);
  const showQuestion =
    [SUBTYPES.EMAIL, SUBTYPES.TRAFFIC, SUBTYPES.CALL].includes(goal) &&
    [TYPES.BAR, TYPES.MODAL, TYPES.SLIDER, TYPES.TAKEOVER].includes(type);
  const pages = [
    ...(showQuestion
      ? [{ id: 'question', linkId: 'question', caption: 'Yes/No' }]
      : []),
    { id: 'main', linkId: 'design', caption: 'Popup' },
    ...(showConversion
      ? [{ id: 'thank-you', linkId: 'conversion', caption: 'Success' }]
      : []),
  ];

  const onClick = (id, linkId) => {
    history.push(`/sites/${siteId}/popups/${popupId}/${linkId}`);
    // window.hellobar?.displayPage(id);
  };

  return (
    <>
      {window.hellobar?.popups?.length > 0 && (
        <ul className="popup-page-selector">
          {pages.map((page) => (
            <PopupPage
              name={page.id}
              caption={page.caption}
              onClick={() => onClick(page.id, page.linkId)}
              currentType={currentType}
            />
          ))}
        </ul>
      )}
    </>
  );
};
