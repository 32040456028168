import Grid from "@mui/material/Grid/Grid";
import Button from "../shared/Button";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import moment from "moment";
import useAPI from "../utils/api";
import DownloadIcon from '@mui/icons-material/Download';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const defaultDateRange = () => {
  return {
    from: moment().subtract(30, 'days').format('yyyy-MM-DD'),
    to: moment().format('yyyy-MM-DD')
  }
};

export default function SummaryToolbar({ site, popup, rangeChanged, initialRange }) {
  const last7Days = { label: 'Last 7 days', from: moment().subtract(7, 'days'), to: moment(), days: 7 };
  const last30Days = { label: 'Last 30 days', from: moment().subtract(30, 'days'), to: moment(), days: 30 };
  const thisMonth = {
    label: 'This Month',
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    days: moment.duration(moment().endOf('month').diff(moment().startOf('month'))).days() + 1
  };
  const lastMonth = {
    label: 'Last Month',
    from: moment().subtract(1, 'months').startOf('month'),
    to: moment().subtract(1, 'months').endOf('month'),
    days: moment.duration(moment().subtract(1, 'months').endOf('month').diff(moment().subtract(1, 'months').startOf('month'))).days() + 1
  };
  const initCustom = {
    label: 'Custom',
    from: last30Days.from,
    to: last30Days.to,
    days: last30Days.days
  };

  const [custom, setCustom] = useState(initCustom);

  const ranges = [last7Days, last30Days, thisMonth, lastMonth, custom];
  const api = useAPI();
  const [downloading, setDownloading] = useState(false);
  const [dateRange, setDateRange] = useState(initialRange);

  const downloadReport = async () => {
    setDownloading(true);
    const range = ranges[dateRange];
    await api.downloadReport(site.id, popup && popup.id ? popup.id : '', range, days());
    setDownloading(false);
  };

  const setRange = (event) => {
    setDateRange(event.target.value);
  };

  const renderRanges = (rangeIndex) => {
    const range = ranges[rangeIndex];
    return `${range.from.format('MMMM D, YYYY')} - ${range.to.format('MMMM D, YYYY')}`;
  }

  const days = () => {
    const range = ranges[dateRange];
    return range === custom ? moment(range.to).diff(moment(range.from), 'days') : range.days
  };

  const setCustomRange = (name, value) => {
    setCustom({ ...custom, [name]: value })
  }

  useEffect(() => {
    const range = ranges[dateRange];
    rangeChanged({
      from: range.from.format('yyyy-MM-DD'),
      to: range.to.format('yyyy-MM-DD')
    });
  }, [dateRange, custom]);

  return (
    <Grid container justifyContent="space-between" spacing={2} className="SummaryToolbox">
      <Grid item>
        <div className="inline-div baseline">
          <h5>{days()} Day Totals</h5>
        </div>
      </Grid>
      <Grid item>
        <div className="inline-div">
          {ranges[dateRange].label === 'Custom' && <div className="CustomDateRange">
            <LocalizationProvider dateAdapter={AdapterMoment}  dateLibInstance={moment} adapterLocale={'en-gb'}>
              <FormControl>
                <DatePicker
                  onChange={(value) => setCustomRange('from', value) }
                  name="form"
                  format="DD/MM/YYYY"
                  value={ranges[dateRange].from}
                  renderInput={(startProps) => (
                    <TextField {...startProps} size="small"/>
                  )}
                  maxDate={moment(ranges[dateRange].to).subtract(1, 'days') || new Date()}
                />
              </FormControl>
              <FormControl>
                <DatePicker
                  onChange={(value) => setCustomRange('to', value) }
                  name='to'
                  format="DD/MM/YYYY"
                  value={ranges[dateRange].to}
                  renderInput={(startProps) => (
                    <TextField {...startProps} size="small" />
                  )}
                  minDate={moment(ranges[dateRange].from).add(1, 'days')}
                  disableFuture
                />
              </FormControl>
            </LocalizationProvider>
          </div>}
          <Select name="value" value={dateRange} onChange={setRange} renderValue={renderRanges} className="DateRange" size="small">
            {ranges.map((r, i) => <MenuItem key={i} value={i}>{r.label}</MenuItem>)}
          </Select>
          <Button loading={downloading} onClick={downloadReport}>
            <DownloadIcon /> Download
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
