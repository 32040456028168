import ContactListDialog from './ContactListDialog';
import { providers, hellobar } from '../../shared/providers';
import React, { useEffect, useState } from 'react';
import useAPI from '../../utils/api';

export default function EditContactList(props) {
  const api = useAPI();
  const [remoteLists, setRemoteLists] = useState([]);
  const [contactTags, setContactTags] = useState([]);
  const [fetched, setFetched] = useState(false);

  const provider =
    providers.find((p) => p.key === props.contactList.provider) || hellobar;
  const contactList = {
    ...props.contactList,
    provider: provider,
  };

  useEffect(async () => {
    const lists = await api.connectionLists(provider.key)
    setRemoteLists(lists[1])
    if (provider.key === 'convert_kit') {
      const tags = await api.connectionTags(provider.key)
      setContactTags(tags[1])
    }
    setFetched(true)
  }, [])

  return (
    <>
      {fetched && (
        <ContactListDialog
          {...props}
          lists={remoteLists}
          tags={contactTags}
          showSettings={true}
          contactList={contactList}
          changeProvider={true}
          disableValidateEmail={contactList.validate_email}
        />
      )}
    </>
  );
}
