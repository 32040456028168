import React from "react";
import { useSnackbar } from 'notistack';
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

export default function FreePlanDiscontinueWarning({ site }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const forcedPlans = ['starter_plus-monthly', 'starter_plus-yearly'];

  if (
    site &&
    site.plan &&
    (!site.plan.free || !forcedPlans.includes(site.pre_selected_plan))
  ) {
    return '';
  }

  const action = key => (
    <React.Fragment>
      <Button onClick={() => { history.push(`/sites/${site.id}/upgrade/starter_plus-yearly`); }}>
        Upgrade Now
      </Button>
    </React.Fragment>
  );

  enqueueSnackbar(
    'Free forever plan is being discontinued in a few days. Upgrade to our limited-time special offer.',
    {
      action,
      persist: true,
      preventDuplicate: true,
      key: 'discontinue_warning',
      variant: 'warning',
    }
  );

  return '';
}
