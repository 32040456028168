import { auth } from '../utils/auth';
import { Route, Redirect } from 'react-router-dom';

const storeReturnToPath = (location) => {
  const returnTo = sessionStorage.getItem('returnTo')
  !returnTo && sessionStorage.setItem('returnTo', location.pathname)
}

const restoreReturnToPath = (history) => {
  const returnTo = sessionStorage.getItem('returnTo')

  if (returnTo) {
    sessionStorage.removeItem('returnTo')
    history.push(returnTo)
  }
}

export default function PrivateRoute({ component: Component, ...rest }) {
  const render = (props) => {
    if (auth.isAuthenticated()) {
      restoreReturnToPath(props.history)
      return (<Component {...props} />);
    } else {
      storeReturnToPath(props.location)
      return <Redirect to="/login"/>;
    }
  }
  return (<Route {...rest} render={render}/>);
}
