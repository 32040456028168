import React from 'react';
import useAPI from "../../utils/api";
import Button from "../../shared/Button";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Dialog from "@mui/material/Dialog/Dialog";
import Slide from "@mui/material/Slide/Slide";
import { TextField, FormControl, DialogActions, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRoleCheck } from '../../utils/useRoleCheck';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function InviteUserDialog({ onChange, site }) {
  const API = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [invitation, setInvitation] = React.useState({
    email: "",
    role: '',
    siteId: site.id
  });
  const [open, setOpen] = React.useState(false);
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const inviteUser = async (event) => {
    event.preventDefault();
    setLoading(true);
    const [ok, response] = await API.inviteUser(invitation);
    if (ok) {
      enqueueSnackbar(`${invitation.email} has been invited to ${site.url}`, { variant: 'success' });
      setOpen(false);
      onChange();
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
    setLoading(false);
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setInvitation(data => ({
      ...data,
      [name]: value
    }));
  }

  const handleClickOpen = (event) => {
    event.preventDefault();
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="button-wrapper">
        <Button onClick={handleClickOpen} color="secondary" size='small'>Invite user</Button>
      </div>
      <Dialog
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <h5 style={{marginBottom: '10px'}}>Invite someone to your team</h5>
          <form id="siteForm" onSubmit={inviteUser} validate="validate">
            <Grid container spacing={3} direction="column">
              <Grid item xs>
                <FormControl fullWidth>
                  <TextField required type="email" value={invitation.email} onChange={handleInputChange} name="email" autoFocus
                         label="Email" size='small'/>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth>
                  <InputLabel size='small'>Choose User Role *</InputLabel>
                  <Select
                    required
                    onChange={handleInputChange}
                    value={invitation.role}
                    name='role'
                    size='small'
                  >
                    {['admin', 'viewer'].map((list) => (
                      <MenuItem key={list} value={list}>
                        {list.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button loading={loading} color="primary" type="submit" form="siteForm" size='small'>Invite</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default InviteUserDialog;
