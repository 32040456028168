import React, {useContext, useState} from 'react';
import useFetch from '../../utils/useFetch';
import '../../assets/styles/Editor.scss';
import {SiteContext} from "../../utils/contexts";
import AppLoader from "../../shared/AppLoader";
import EditorWrapper from "./EditorWrapper";
import useFonts from "../../utils/useFonts";

const useOldBuilder = (site) => {
  return site.builder_version === '1'
}

const oldBuilderURL = (params) => {
  const {protocol, host, search} = window.location

  const searchParams = new URLSearchParams(search)
  searchParams.append('return_to', `${protocol}//${host}/sites/${params.siteId}/manage`)

  const path = params.popupId === 'new' ? '/new' : `/${params.popupId}/edit`
  const url = `${process.env.REACT_APP_EDITOR_HOST}/sites/${params.siteId}/site_elements${path}`;

  return [url, searchParams.toString()].join('?');
}

function Editor({siteId, popupId, ruleId, copyId, history, match}) {
  const {loading, status, popupSettings} = useFetch('popupSettings', siteId, copyId || popupId);

  if (ruleId && popupSettings) {
    popupSettings.rule_id = ruleId
  }

  if (copyId && popupSettings) {
    popupSettings.id = null
    popupSettings.copyId = copyId
  }

  return (
    <div className="hb-editor">
      <AppLoader visible={loading}/>
      {!loading && status === 'fetched' &&
      <EditorWrapper history={history} match={match} initialSettings={popupSettings}/>}
    </div>
  )
}

export default function EditorApp({history, match}) {
  const site = useContext(SiteContext)
  const url = new URL(window.location.href)
  const [ruleId] = useState(url.searchParams.get('rule_id'))
  const [copyId] = useState(url.searchParams.get('element_to_copy_id'))

  useFonts()

  if (useOldBuilder(site)) {
    return window.location = oldBuilderURL(match.params)
  }
  
  return (
    <Editor history={history} match={match}
            siteId={match.params.siteId}
            popupId={match.params.popupId}
            copyId={copyId}
            ruleId={ruleId}/>
  )
}
