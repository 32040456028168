import { useEffect, useState } from "react";

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false)

  const isScrolledIntoView = (element) => {
    const rect = element.getBoundingClientRect()
    const {top, bottom} = rect
    return (top >= 0) && (bottom <= window.innerHeight);
  }

  useEffect(() => {
    if (window.IntersectionObserver) {
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
      )
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    } else {
      const check = () => setIntersecting(isScrolledIntoView(ref.current))
      window.addEventListener('scroll', check)
      check()

      return () => {
        window.removeEventListener('scroll', check)
      }
    }
  }, [ref])

  return isIntersecting
}
