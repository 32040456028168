import React, { useState } from "react";
import useAPI from "../../../utils/api";
import Switch from "@mui/material/Switch/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useSnackbar } from "notistack";
import { useRoleCheck } from "../../../utils/useRoleCheck";

function Role(props) {
  const API = useAPI();
  const [role, setRole] = useState(props.role);
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const updateRole = async () => {
    if (handleRoleCheck()) { return; }
    if (role.role === 'owner') {
      enqueueSnackbar(`Not allowed`, { variant: 'error' });
      return;
    }

    await API.updateRole({ ...role, role: 'none', siteId: props.site.id });
    enqueueSnackbar(`Revoked permissions to site ${props.site.url} for user ${props.user.email}`, { variant: 'default' });
    window.location.reload();
  };

  return (
    <td user-id={role.user_id}>
      <FormControlLabel
        checked={role.role !== 'none'}
        control={<Switch size="small" color={ role.role === 'owner' ? 'primary' : role.role === 'viewer' ? 'info' : 'secondary' }/>}
        onChange={updateRole}
        label={role.role}
        labelPlacement="top"
      />
    </td>
  )
}

export default Role
