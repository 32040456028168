import React, { useState } from 'react';
import AppLoader from '../shared/AppLoader';
import '../assets/styles/_contacts_page.scss';
import useFetch from '../utils/useFetch';
import { SitePath } from '../shared/SiteLink';
import NewContactList from './Modals/NewContactList';
import useAPI from '../utils/api';
import { editorURL } from '../utils/editor';
import Button from '../shared/Button';
import { withTracking } from '../shared/Tracking';
import { useHistory } from 'react-router-dom';
import ContactLists from './Contacts/ContactLists';
import {
  ContactListContext,
  SearchContactListContext,
} from '../utils/contexts';
import Tabs from '../shared/Tabs';

function Contacts({ site, scope }) {
  const history = useHistory();
  const api = useAPI();
  const [update, setUpdate] = useState();
  const [query, setQuery] = useState('');
  const { loading, status, data } = useFetch('contactLists', scope, update);

  const saveContactList = async (contactList) => {
    const data = {
      identity_id: contactList.connection.id,
      provider_token: contactList.provider.key,
      name: contactList.name,
      double_optin: contactList.double_optin,
      email_enabled: contactList.email_enabled,
      email_recipients: contactList.email_recipients,
      data: {
        webhook_url: contactList.connection.webhook_url,
        webhook_method: contactList.connection.webhook_method,
        username: contactList.connection.username,
        api_key: contactList.connection.api_key,
        api_secret: contactList?.connection?.credentials?.secret,
        app_url: contactList.connection.app_url,
        embed_code: contactList.connection.embed_code,
        list_api_id: contactList.connection.list_api_id,
        remote_id: contactList.connection.remote_id,
        tags: contactList.connection.tags
      },
    };

    const [ok, response] = await api.createContactList(data);
    if (ok) {
      setUpdate(!update);
      history.replace(`/sites/${site.id}/contacts/all`);
    } else {
      throw response;
    }
  };

  const [activeTab, inactiveTab, allTab] = [
    SitePath('/contacts'),
    SitePath('/contacts/inactive'),
    SitePath('/contacts/all'),
  ];

  const contactLists = data;
  const tabs = (contactLists) => {
    const tabs = [
      {
        name: 'Active',
        path: activeTab,
        render: () => (
          <ContactLists
            contactLists={contactLists}
            name="active"
            query={query}
          />
        ),
      },
      {
        name: 'InActive',
        path: inactiveTab,
        render: () => (
          <ContactLists
            contactLists={contactLists}
            name="inactive"
            query={query}
          />
        ),
      },
      {
        name: 'All',
        path: allTab,
        render: () => (
          <ContactLists contactLists={contactLists} name="all" query={query} />
        ),
      },
    ];

    return tabs;
  };

  return (
    <div id="contact_lists-index">
      <SearchContactListContext.Provider value={{ query, contactLists }}>
        <ContactListContext.Provider value={{ contactLists }}>
          <Tabs
            tabs={tabs(contactLists)}
            showSearch={true}
            changeQuery={setQuery}
            placeholder={'Search by Name or Provider'}
            type="contacts"
          />
          <AppLoader visible={loading} />
        </ContactListContext.Provider>
      </SearchContactListContext.Provider>
      {status === 'fetched' && data?.length > 0 && (
        <NewContactList
          buttonTitle="New Contact List"
          onConfirm={saveContactList}
          siteUsers={site.site_users}
          validateEmail={site.validate_email}
        />
      )}
      {status === 'fetched' && data?.length < 1 && (
        <div className="temporary-block">
          <h5>Grow your email lists</h5>
          <strong>
            Did you know that Hello Bar can collect email addresses for you?
          </strong>
          <p>
            You'll need to create an email bar before you can start collecting
            emails.
          </p>
          <a href={editorURL(site, '/new', {}, '/interstitial/email')}>
            <Button color="secondary" label="New Email Popup" />
          </a>
          <span className="margin-10">or</span>
          <NewContactList
            buttonColor="white"
            buttonTitle="New Contact List"
            onConfirm={saveContactList}
            siteUsers={site.site_users}
            validateEmail={site.validate_email}
          />
        </div>
      )}
    </div>
  );
}

export default withTracking(Contacts);
