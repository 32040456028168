export const placements = {
  Alert: [
    {value: 'bottom-left', label: 'Bottom left'},
    {value: 'bottom-right', label: 'Bottom right'}
  ],
  Bar: [
    {value: 'bar-top', label: 'Top'},
    {value: 'bar-bottom', label: 'Bottom'}
  ],
  Modal: [
    {value: 'middle', label: 'Middle'},
    {value: 'top', label: 'Top'}
  ],
  Slider: [
    {value: 'bottom-right', label: 'Bottom right'},
    {value: 'top-right', label: 'Top right'},
    {value: 'bottom-left', label: 'Bottom left'},
    {value: 'top-left', label: 'Top left'}
  ],
  Inline: [
    {value: 'inline-centered', label: 'Centered'},
  ]
}
