import React from 'react';
import {MenuItem, Select, TextField} from '@mui/material';
import {timezones} from '../../utils/timezones';
import FormControl from '@mui/material/FormControl/FormControl';

export default function TimeField({value, onChange}) {
  const handleChange = (position, updatedValue) => {
    value = value || new Array(3);
    value[position] = updatedValue;
    onChange({target: {name: 'value', value: value}});
  };

  const handleTimeChange = (e) => {
    const [hours, minutes] = e.target.value.split(':')
    handleChange(0, hours);
    handleChange(1, minutes);
  }

  const visitorTzOption = {title: "Use visitor's timezone", id: 'visitor'};

  if (timezones[0].id !== 'visitor') {
    timezones.unshift(visitorTzOption);
  }

  const time = `${value[0] || '08'}:${value[1] || 30}`

  return (
    <div>
      <TextField
        id="time"
        type="time"
        defaultValue={time}
        inputProps={{
          step: 300, // 5 min
        }}
        sx={{width: 100}}
        size="small"
        onChange={handleTimeChange}
      />
      <FormControl size="small">
        <Select
          value={value[2] || 'visitor'}
          onChange={(e) => {
            handleChange(2, e.target.value);
          }}
          displayEmpty
          name="timezone"
        >
          {timezones.map(({title, id}) => (
            <MenuItem style={{cursor: 'pointer'}} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
