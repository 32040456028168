import React, {useContext} from 'react';
import '../../assets/styles/EditRuleDialog.scss';
import Button from '../../shared/Button';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Switch from '@mui/material/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Condition, {
  initCondition,
  isMultipleCondition,
} from '../Rules/Condition';
import TextField from '@mui/material/TextField/TextField';
import FormControl from '@mui/material/FormControl/FormControl';
import UpgradeDialog from "./UpgradeDialog";
import {SiteContext} from "../../utils/contexts";

export default function RuleDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [rule, setRule] = React.useState(props.rule);
  const site = useContext(SiteContext);

  const handleClickOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    event.preventDefault();
    if (reason && reason === "backdropClick")
        return;
    setOpen(false);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setRule({ ...rule, match: 'all' });
    } else {
      setRule({ ...rule, match: 'any' });
    }
  };

  const handleNameChange = (event) => {
    setRule({ ...rule, name: event.target.value });
  };

  const addCondition = () => {
    const newCondition = { ...initCondition, key: Math.random() };
    setRule({ ...rule, conditions: [...rule.conditions, newCondition] });
  };

  const updateCondition = (condition, prop, value) => {
    condition[prop] = value;

    if (prop === 'segment') {
      condition.multiple = isMultipleCondition(condition);
      condition.value = condition.multiple ? [''] : '';
      condition.operand = '';
    }

    setRule({ ...rule, conditions: [...rule.conditions] });
  };

  const removeCondition = (condition) => {
    const conditions = rule.conditions.filter((item) => item !== condition);
    setRule({ ...rule, conditions: conditions });
  };

  const conditionKey = (condition) => {
    return `${condition.id}-${condition.key}-${condition.segment}-${condition.operand}`;
  };

  if (open && site?.plan?.free) {
    return (<UpgradeDialog onClose={() => {setOpen(false)}}/>)
  }

  return (
    <div className="EditRuleDialog">
      <div onClick={handleClickOpen}>{props.buttonComponent}</div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent className="EditRuleDialog-content">
          <div>
            <FormControl fullWidth variant="outlined">
              <TextField
                size="small"
                label="Targeting Rule Name"
                name="name"
                onChange={handleNameChange}
                value={rule.name}
                variant="outlined"
              />
            </FormControl>
            <p className="matching">
              Show to people who match
              <FormControlLabel
                checked={rule.match === 'all'}
                control={<Switch size="small" color="primary" />}
                onChange={handleChange}
                label={rule.match || "any"}
                labelPlacement="start"
              />
              of the following conditions.
            </p>
            <div className="conditions-wrapper">
              {rule.conditions.length < 1 && (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item sm={11}>
                    <small>
                      There are no conditions yet, so this rule matches{' '}
                      <strong>everyone.</strong>
                      <br />
                      To only match certain people click the blue (+) button to
                      the right.
                      <br />
                    </small>
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    className="condition-actions"
                    justifyContent="flex-end"
                  >
                    <span className="condition-add" onClick={addCondition}>
                      +
                    </span>
                  </Grid>
                </Grid>
              )}
              {rule.conditions.length > 0 &&
                rule.conditions.map((condition, index) => (
                  <>
                    <Condition
                      key={conditionKey(condition)}
                      condition={condition}
                      update={updateCondition}
                      addCondition={addCondition}
                      removeCondition={removeCondition}
                    />
                    { rule.conditions.length > 0 && rule.conditions.length != index+1 && (
                      <span className="line">
                        <h2><span>{rule.match == 'all' ? 'AND' : rule.match == 'any' ? 'OR': ''}</span></h2>
                      </span>
                    )}
                  </>
                ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="white">
            Cancel
          </Button>
          <Button
            onClick={props.onConfirm(rule, setOpen)}
            color="secondary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
