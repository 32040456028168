import React, { useState, useEffect } from "react";
import useAPI from "../utils/api";
import Chip from "@mui/material/Chip";
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import Button from "./Button"

export default function CouponInput(props) {
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [coupon, setCoupon] = useState(props.value.code);
  const [error, setError] = useState(null);
  const [promocode, setPromocode] = useState(props.value);
  const [classNames, setClassNames] = useState();
  const onFocused = () => setFocused(true)
  const onLeave = () => setFocused(false)
  const api = useAPI();

  useEffect(() => {
    setClassNames([
      focused || coupon.length > 0 ? 'PromotionCodeEntry-inputWrapper--focused' : 'PromotionCodeEntry-inputWrapper--blurred',
      'PromotionCodeEntry-inputWrapper'
    ].join(' '));
  }, [focused, coupon]);

  const handleChange = (event) => {
    setError(null);
    setCoupon(event.target.value.toUpperCase());
  }
  const commitCoupon = async (event) => {
    event.preventDefault();
    setLoading(true)
    const [, response] = await api.checkCoupon(coupon, props.planId);
    setLoading(false)
    if (response.error) {
      setError(response.error)
    } else if (response.active) {
      setPromocode(response);
      props.onChange(response);
    }
  }

  const handleDelete = () => {
    setCoupon('');
    setError('');
    props.onChange({id: '', code: ''});
  }

  const promocodeInfo = () => {
    if(promocode.coupon.percent_off) {
      return `${promocode.coupon.percent_off} off`;
    }
    if(promocode.coupon.amount_off) {
      return `${promocode.coupon.amount_off} off`;
    }
  }

  return (
    <div className="PromotionCodeEntry">
      {!promocode.id && <label className={classNames}>
        <FormControl variant="outlined" fullWidth size="small">
          <InputLabel htmlFor="coupon">Add promotion code</InputLabel>
          <OutlinedInput
            id="coupon"
            type="text"
            value={coupon}
            onChange={handleChange}
            onFocus={onFocused}
            onBlur={onLeave}
            fullWidth
            size="small"
            endAdornment={
              coupon.length > 0 && <InputAdornment >
                <Button
                  onClick={commitCoupon}
                  edge="end"
                  color="secondary"
                  variant="contained"
                  loading={loading}
                  label="Apply"
                  size='small'
                />
              </InputAdornment>
            }
          />
        </FormControl>
      </label>}
      {
        promocode.id &&
        <div className="PromotionCodeEntry-Chip">
          <Chip label={promocode.code} onDelete={handleDelete} color="primary" variant="outlined" />
          <label>{promocodeInfo()}</label>
        </div>
      }
      {
        error && <div className="PromotionCodeEntry-Error">{error}</div>
      }
    </div>
  );
}
