import React, { useState } from 'react';
import useFetch from '../../utils/useFetch';
import AppLoader from '../../shared/AppLoader';
import Error from '../../shared/Error';
import useAPI from '../../utils/api';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from '../Modals/ConfirmationDialog';
import Grid from '@mui/material/Grid';
import { useRoleCheck } from '../../utils/useRoleCheck';

export default function Identities({ site }) {
  const API = useAPI();
  const [identityLoading, setIdentityLoading] = useState(false);
  const handleRoleCheck = useRoleCheck(API.currentRole);
  const { loading, error, status, identities } = useFetch(
    'identities',
    site.id,
    identityLoading
  );

  const deleteIdentity = async (identity) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) {
      return;
    }

    setIdentityLoading(true);
    await API.deleteIdentity(identity.id);
    setIdentityLoading(false);
  };

  const deleteConfirmation = ({ onClick }) => (
    <button onClick={onClick}>
      <DeleteIcon />
    </button>
  );

  return (
    <div className="identity-section">
      <h5>External Integrations</h5>
      <AppLoader visible={loading} />
      {status === 'error' && <Error error={error} />}
      {status === 'fetched' && (
        <Grid>
          <Grid
            container
            justifyContent="flex-start"
            className="inputs"
            spacing={3}
          >
            <Grid item xs={12}>
              <table className="identity-table">
                <thead>
                  <tr>
                    <th>Provider</th>
                    <th>Connected Contact Lists</th>
                    <th>Created At</th>
                    <th>API Key</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {identities?.map((identity) => (
                    <tr key={identity.id}>
                      <td>{identity.provider_name}</td>
                      <td>{identity.connected_contacts}</td>
                      <td>{identity.created_at}</td>
                      <td>{identity.api_key || '-'}</td>
                      <td>
                        <ConfirmationDialog
                          onConfirm={() => deleteIdentity(identity)}
                          component={deleteConfirmation}
                        >
                          This integration is being used in{' '}
                          {identity.connected_contacts} contact list(s).
                          Deleting would result in storing the contacts in
                          Hellobar only.
                          <br />
                        </ConfirmationDialog>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
