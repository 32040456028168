import React, {useState} from 'react'
import {Route, Switch, useHistory} from 'react-router-dom'
import {Redirect} from 'react-router'
import LoginEmail from './components/LoginEmail'
import LoginPassword from './components/LoginPassword'
import ForgotPassword from './components/ForgotPassword'
import UpdatePassword from './components/UpdatePassword'
import AuthCarousel from './components/AuthCarousel'
import '../../assets/styles/Authenticate.scss'
import {AuthContext} from "../../utils/contexts";

const AuthenticationPage = () => {
  const history = useHistory();
  const [authForm, setAuthForm] = useState({
    email: '',
    password: ''
  })

  const contextValue = { authForm, setAuthForm }

  return (
    <AuthContext.Provider value={contextValue}>
      <div className="signin-container">
        <div className="auth-sidebar">
          <div className="logo" onClick={() => history.push('/')}/>
          <div className="sidebar-image">
            <img alt="" src="https://www.hellobar.com/wp-content/uploads/2021/09/Subscribe-Buy-Now-Submit-tablet.png"></img>
          </div>
          <div className="popup-slider">
            <AuthCarousel/>
          </div>
        </div>
        <div className="auth-main">
          <div className="logo auth-main-logo" onClick={() => history.push('/')}/>
          <Switch>
            <Route path="/users/sign_in" component={LoginEmail}/>
            <Route path="/users/find_email" component={LoginPassword}/>
            <Route path="/users/password/new" component={ForgotPassword}/>
            <Route path="/users/password/edit" component={UpdatePassword}/>
            <Redirect to="/users/sign_in"/>
          </Switch>
        </div>
      </div>
    </AuthContext.Provider>
  )
}

export default AuthenticationPage
