import React, {useContext, useState} from 'react'
import {TextField} from '@mui/material'
import Button from '../../../shared/Button'
import {AuthContext} from "../../../utils/contexts";
import {NavLink, useHistory} from "react-router-dom";
import {auth} from "../../../utils/auth";

const LoginPassword = () => {
  const {authForm, setAuthForm} = useContext(AuthContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleLogin = (event) => {
    event.preventDefault();

    setLoading(true)
    auth.loginWithPassword(authForm).then(response => {
      if (response.status === 200) {
        return response.json()
      } else {
        setError('Invalid email or password')
      }
    }).catch(error => {
      console.log('error', error)
    }).then((response) => {
      if (response?.token) {
        history.push(`/login/jwt/${response.token}`)
      } else {
        setError('Invalid email or password')
      }
    }).finally(() => setLoading(false))
  }

  const handlePasswordChange = (event) => {
    setError('')
    setAuthForm({...authForm, password: event.target.value});
  }

  if (!authForm.email || authForm.email === '') {
    history.push('/users/sign_in')
  }

  return (
    <div className="popup-form">
      <form onSubmit={handleLogin}>
        <h3>Sign In</h3>
        <TextField required autoFocus={true}
                   name="password" label="Password" type="password" variant="outlined"
                   onChange={handlePasswordChange}
                   error={!!error} helperText={error}/>
        <Button disabled={authForm.password === ''} color="primary" variant="contained" type="submit"
                loading={loading}>Continue</Button>
        <div className="links-container">
          <NavLink to="/users/sign_in">Sign In</NavLink>
          <br/>
          <NavLink to="/users/password/new">Forgot password?</NavLink>
          <br/>
          <a href={`${process.env.REACT_APP_AUTH_HOST}/users/sign_up`}>Sign up for a New Hello Bar Account</a>
        </div>
      </form>
    </div>
  )
}

export default LoginPassword
