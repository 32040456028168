import React from 'react';
import "../../assets/styles/EditRuleDialog.scss";
import useAPI from "../../utils/api";
import { useSnackbar } from 'notistack';
import RuleDialog from "./RuleDialog";
import { useRoleCheck } from '../../utils/useRoleCheck';

export default function CreateRuleDialog(props) {
  const API = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const handleConfirm = (rule, setOpen) => async (event) => {
    event.preventDefault();

    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }

    if (rule.conditions.length > 0) {
      const [ok, response] = props.updatingRule ? await API.updateRule({
        id: rule.id,
        name: rule.name,
        match: rule.match,
        conditions: rule.conditions
      }) : await API.createRule({
        id: rule.id,
        name: rule.name,
        match: rule.match,
        conditions: rule.conditions
      });

      if (ok) {
        setOpen(false);
        props.onUpdate(response);
        enqueueSnackbar(`Rule "${rule.name}" has been updated successfully!`, { variant: 'success' });
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Please add atleast 1 condition to rule', { variant: 'error' });
    }

  };

  let rule = { name: 'New Rule', conditions: [], match: 'any' }

  if(props.ruleData){
    if(props.updatingRule){
      rule = props.ruleData
    }else{
      const formattedConditions = props.ruleData.conditions?.map((condition)=>{
        delete condition['id']
        return condition
      })
      rule = {name: props.ruleData.name, conditions: formattedConditions, match: props.ruleData.match}
    }
  }

  return (
    <RuleDialog rule={rule} onConfirm={handleConfirm} buttonComponent={props.handler}/>
  );
}
