import CollapsedPanel from '../components/CollapsedPanel';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { EditorContext } from '../../../utils/contexts';
import { Tooltip, TextField } from '@mui/material';
import Switcher from '../components/Switcher';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { Label } from '../Steps/components';
import InputBlock from '../components/InputBlock';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const FIELD_TYPE = [
  { label: 'Email', value: 'email' },
  { label: 'Text', value: 'text' },
  { label: 'URL', value: 'url' },
  { label: 'Phone', value: 'tel' },
  { label: 'Dropdown', value: 'select' },
  { label: 'Checkbox', value: 'checkbox' },
  { label: 'Multi-select', value: 'multi_select' },
];

const Hint = styled.div`
  font-size: 0.9em;
  font-weight: normal;
  color: #9e9e9e;
`;

const CheckButton = styled.div`
  display: inline;

  svg {
    color: ${(props) => (props.enabled ? '#27ab9e' : '#c6c6c6')};
    line-height: 80%;
    font-size: 1.5em;
    text-align: center;
    font-style: normal;
    font-weight: lighter;
    margin-right: 0.5rem;
    flex: 0 0 2rem;
  }
`;

const CloseButton = styled.div`
  display: inline;

  svg {
    color: #c6c6c6;
    line-height: 80%;
    font-size: 1.5em;
    text-align: center;
    font-style: normal;
    font-weight: lighter;
    margin-right: 0.5rem;
    flex: 0 0 2rem;
  }
`;

const Buttons = styled.div`
  right: 1rem;
  position: absolute;
  font-size: 1.25rem;
  font-weight: lighter;
  transition: all 0.15s ease-out 0s;
  top: 50%;
  transform: translateY(-50%);
`;

export const FieldForm = styled(
  ({ className, builtinField, fieldData, updateField }) => {
    return (
      <div className={className}>
        {!builtinField && (
          <TextField
            size="small"
            margin="dense"
            value={fieldData.name}
            onChange={(e) =>
              updateField(
                'name',
                e.target.value
                  .replace(/[^a-zA-Z0-9_ ]/g, '')
                  .replace(/\s/g, '_')
                  .toLowerCase()
                  .trim()
              )
            }
            variant="outlined"
            label="Field Name (for back-end)"
            placeholder="Name"
            required
          />
        )}
        {builtinField && (
          <TextField
            size="small"
            margin="dense"
            value={fieldData.id.replace('builtin-', '')}
            variant="outlined"
            label="Field Name (for back-end)"
            disabled
          />
        )}
        <TextField
          size="small"
          margin="dense"
          value={fieldData.label}
          onChange={(e) => updateField('label', e.target.value)}
          variant="outlined"
          label="Field Label (for front-end)"
          placeholder="Label"
          required
        />
        {!builtinField && (
          <InputBlock label="Field Type">
            <TextField
              size="small"
              variant="outlined"
              select
              value={fieldData.type}
              onChange={(e) => {
                updateField('type', e.target.value);
                if (e.target.value === 'multi_select') {
                  updateField('options', []);
                }
              }}
            >
              {FIELD_TYPE.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </InputBlock>
        )}
        {(fieldData.type === 'select' || fieldData.type === 'multi_select') && (
          <TextField
            value={fieldData.options}
            multiline
            onChange={(e) => {
              const options = e?.target?.value?.split(',');
              if (
                fieldData.type === 'multi_select' &&
                (!Array.isArray(options) ||
                  (Array.isArray(options) && options.length > 15))
              )
                return;
              updateField('options', e.target.value);
            }}
            label="Options"
            placeholder="Option 1, Option 2, ..."
            size="small"
            required
          />
        )}
        {(fieldData.type === 'multi_select') && (
          <InputBlock label="Option Columns">
            <TextField
              size="small"
              variant="outlined"
              select
              value={fieldData.option_columns || 3}
              onChange={(e) => {
                updateField('option_columns', e.target.value);
              }}
            >
              {[1, 2, 3, 4, 5].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </InputBlock>
        )}
        <Label>
          <span>Required</span>
          <Switcher
            checked={fieldData.required}
            onChange={() => updateField('required', !fieldData.required)}
          />
        </Label>
      </div>
    );
  }
)`
  padding-right: 0.67em;
  background-color: white;
  max-height: ${(props) => (props.active ? 'auto' : '0')};
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  & .input-wrapper {
    width: auto;
  }
`;

const FieldContainer = styled.div`
  margin-top: 0.375rem;
  padding-left: 0.67em;
  border: 1px solid rgba(145, 145, 145, 0.5);
  color: #666666;
  border-radius: 5px;

  ${CloseButton} {
    display: none;
  }

  &:hover ${CloseButton} {
    display: inline;
  }
`;

const Field = styled.div`
  font-weight: normal;
  opacity: 0.75;
  color: #666666;
  line-height: 120%;
  position: relative;
  min-height: 2.75rem;
  justify-content: start;
  align-items: center;
  display: flex;
  transition: all 0.15s ease-out 0s;
  cursor: pointer;

  .drag_icon {
    margin-right: 5px;
    cursor: move;
    cursor: grab;
  }

  & .field-label {
    min-width: 200px;
    min-height: 20px;
    cursor: move;
    cursor: grab;

    &:hover {
      color: #27ab9e;
      text-decoration: underline;
    }
  }

  span {
    width: 100%;
  }
`;

const AddField = styled(Field)`
  color: ${(props) => (props.$disabled ? '#c3c3c3' : '#27ab9e')};
  border: 1px solid rgba(145, 145, 145, 0.5);
  padding: 0.5em 4.9em 0.5em 0.67em;
  border-radius: 5px;
  margin-top: 0.375rem;
  text-decoration: underline;
  justify-content: start;

  span {
    display: inline-flex;
  }

  svg {
    margin-left: 0.1em;
    width: 0.7em;
    height: 0.7em;
  }
`;

function InputField({ field, onChange, onDelete }) {
  const updateField = (key, value) => {
    field[key] = value;
    onChange(field);
  };

  const toggleFieldEnabled = (field) => {
    field.is_enabled = !field.is_enabled;
    onChange(field);
  };

  return (
    <FieldContainer>
      <Field key={field.id} $editing={true}>
        <DragIndicatorIcon className="drag_icon" />
        <div className="field-label">{field.label}</div>
        <Buttons>
          <CloseButton onClick={() => onDelete(field)}>
            <CloseIcon />
          </CloseButton>
          <CheckButton
            enabled={field.is_enabled}
            onClick={() => toggleFieldEnabled(field)}
          >
            <CheckCircleIcon />
          </CheckButton>
        </Buttons>
      </Field>
      <FieldForm active={true} fieldData={field} updateField={updateField} />
    </FieldContainer>
  );
}

function BuiltinInputField({ field, onChange }) {
  const { settings } = useContext(EditorContext);

  const updateField = (key, value) => {
    field[key] = value;
    onChange(field);
  };

  const toggleFieldEnabled = (field) => {
    field.is_enabled = !field.is_enabled;
    onChange(field);
  };

  const isEmail = settings.subtype === 'email' && field.id === 'builtin-email';
  const tooltip = isEmail
    ? "It can't be disabled as it is the goal itself to collect emails"
    : '';

  return (
    <FieldContainer>
      <Field key={field.id} $editing={false}>
        <DragIndicatorIcon className="drag_icon" />
        <div className="field-label">{field.label}</div>
        <Buttons>
          <CheckButton
            enabled={field.is_enabled}
            onClick={() => (isEmail ? null : toggleFieldEnabled(field))}
          >
            <Tooltip title={tooltip} placement="right-start">
              <CheckCircleIcon />
            </Tooltip>
          </CheckButton>
        </Buttons>
      </Field>
      <FieldForm
        builtinField={true}
        active={true}
        fieldData={field}
        updateField={updateField}
      />
    </FieldContainer>
  );
}

export default function TextFieldsPanel() {
  const { settings, updateSettings } = useContext(EditorContext);

  const customFields = settings.settings.fields_to_collect.reduce(
    (memo, field) => {
      return { ...memo, [field.id]: field };
    },
    {}
  );

  const [fields, setFields] = useState(customFields);

  const updateCustomSettings = (props) => {
    const customSettings = { ...settings.settings, ...props };
    updateSettings({ settings: customSettings });
  };

  const customFieldsDisabled = settings.type === 'Bar';

  const addField = () => {
    const lastField = Object.values(fields).slice(-1)[0];
    if (
      Object.values(fields).length > 3 &&
      (lastField.label === '' || lastField.name === '')
    ) {
      onFieldDelete(lastField);
    } else {
      if (!customFieldsDisabled) {
        const newField = {
          id: `field_${Date.now()}`,
          type: 'text',
          label: '',
          name: '',
          required: false,
          is_enabled: true,
          editable: true,
          editing: true,
        };
        onFieldChange(newField);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(fields).length > 0) {
      const fields_to_collect = Object.values(fields);
      updateCustomSettings({ fields_to_collect });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  useEffect(() => {
    const listener = (event) => {
      if (event.key === 'Escape' || event.key === 'Enter') {
        const field = Object.values(fields).find((f) => f.editing);

        if (field) {
          field.editing = false;
          setFields({ ...fields, [field.id]: field });
        }
      }
    };
    document.addEventListener('keydown', listener, false);

    return () => {
      document.removeEventListener('keydown', listener, false);
    };
  }, [fields]);

  const onFieldChange = (field) => {
    setFields({ ...fields, [field.id]: field });
  };

  const onFieldDelete = (field) => {
    delete fields[field.id];
    setFields({ ...fields });
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, newIndex) => {
    e.preventDefault();
    const oldIndex = e.dataTransfer.getData('text/plain');
    const newList = [...Object.values(fields)];
    const [removed] = newList.splice(oldIndex, 1);
    newList.splice(newIndex, 0, removed);
    const reHash = {};
    newList.forEach((field) => (reHash[field.id] = field));
    setFields(reHash);
  };

  return (
    <CollapsedPanel open={false} title="Input Fields">
      <Hint>Select which text fields you want to include in your popup.</Hint>

      <ul>
        {Object.values(fields).map((field, index) => (
          <li
            key={index}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            {field.id.includes('builtin-') ? (
              <BuiltinInputField
                key={`field-${index}`}
                field={field}
                onChange={onFieldChange}
              />
            ) : (
              <InputField
                onChange={onFieldChange}
                onDelete={onFieldDelete}
                field={field}
                key={field.id}
              />
            )}
          </li>
        ))}
      </ul>
      <div>
        <AddField $disabled={customFieldsDisabled} onClick={addField}>
          <Tooltip
            title="Using any special character in the field name may hamper data collection."
            placement="right-start"
          >
            <span>
              Add Field <InfoIcon />
            </span>
          </Tooltip>
        </AddField>
      </div>
      {customFieldsDisabled && (
        <div className="hint">
          In order to add custom fields please select Modal, Slider or Takeover
          type on Type tab.
        </div>
      )}
    </CollapsedPanel>
  );
}
