import React from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  ButtonGroup,
  Grid,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import useAPI from '../../utils/api';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function EmailValidationServiceAddOnDialog(props) {
  const api = useAPI();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(props.isOpen);
  const [credit, setCredit] = React.useState(2);

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
    history.goBack();
  };

  const incrementCredit = () => {
    if (credit < 20) {
      setCredit(Number(credit) + 1);
    }
  };
  const decrementCredit = () => {
    if (credit > 0) {
      setCredit(credit - 1);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const [ok, response] = await api.purchaseEmailValidation(
      props.site,
      credit * 500,
      'email_validation_credit'
    );

    if (ok) {
      setLoading(false);
      setOpen(false);
      history.push('/sites/' + props.site.id);
    } else {
      setLoading(false);
      setOpen(true);
      throw response;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Purchase Email Validation Credits
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loading && (
          <Grid style={{ textAlign: 'center' }}>
            <Loader
              height={10}
              visible={loading}
              type="ThreeDots"
              color="#e8562a"
            />
          </Grid>
        )}
        {!loading && (
          <DialogContent>
            <div>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
              >
                <Button onClick={decrementCredit}>-</Button>
                <Button disabled>{credit}</Button>
                <Button onClick={incrementCredit}>+</Button>
              </ButtonGroup>
            </div>
            <br />
            <h6>
              Total Credits - <span>{credit} X 500</span> = {credit * 500}
            </h6>
            <br />
            <label>*one email validation would use one credit</label>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            color="warning"
            variant="contained"
            disabled={loading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            loading={loading}
            onClick={handleSubmit}
            disabled={credit < 1}
          >
            Pay ${credit * 5}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
