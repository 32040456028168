import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";
import {Label} from "../Steps/components";
import Switcher from "../components/Switcher";

export default function ShareOnLinkedin() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateURL = (event) => {
    const customSettings = {...settings.settings, url_to_share: event.target.value}
    updateSettings({settings: customSettings})
  }

  const toggleUseLocation = () => {
    const customSettings = {...settings.settings, use_location_for_url: !settings.settings.use_location_for_url}
    updateSettings({settings: customSettings})
  }

  return (
    <>
      <Label>
        <span>URL my visitor is on</span>
        <Switcher checked={settings.settings.use_location_for_url} onChange={toggleUseLocation}/>
      </Label>

      {!settings.settings.use_location_for_url &&
      <TextField fullWidth variant="outlined" label="URL to share" value={settings.settings.url_to_share || ''}
                 onChange={updateURL} placeholder="example.com" size='small'/>}
    </>
  )
}
