import React, {Fragment, useRef, useState} from "react";
import {Editor} from '@tinymce/tinymce-react';
import {FONT_FAMILIES} from "../constants";
import AppLoader from "../../../shared/AppLoader";
import {fontsUrl} from "../../../utils/useFonts";

const apiKey = "o2eq3wmxa3cdgu1xiqzoi4nt21q9o03nzlu1p9mcnemxuar5"

const allowEditing = (editor) => {
  const getBody = (event) => event.target.iframeElement.contentDocument.body
  const forEachDataTag = (body, handler) => {
    body.querySelectorAll('.data-tag').forEach(element => handler(element))
  }
  
  editor.on('BeforeExecCommand', (e) => {
    const body = getBody(e)
    forEachDataTag(body, element => {
      element.setAttribute('contenteditable', true)
    })
  });
  
  editor.on('ExecCommand', (e) => {
    const body = getBody(e)
    forEachDataTag(body, element => {
      element.setAttribute('contenteditable', false)
    })
  });
}

const addCustomButtons = function (editor, siteType) {
  editor.ui.registry.addMenuButton('mergefields', {
    text: 'Merge Fields',
    fetch: function (callback) {
      const items = [
        {
          type: 'nestedmenuitem',
          text: 'Peronal',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Name',
                onAction: function () {
                  editor.insertContent('<span class="data-tag">{{name}}</span>');
                }
              },
              {
                type: 'menuitem',
                text: 'Email',
                onAction: function () {
                  editor.insertContent('<span class="data-tag">{{email}}</span>');
                }
              }
            ];
          }
        },
        {
          type: 'nestedmenuitem',
          text: 'Geolocation',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Country',
                onAction: function () {
                  editor.insertContent('<span class="data-tag">{{geolocation.countryName}}</span>');
                }
              },
              {
                type: 'menuitem',
                text: 'City',
                onAction: function () {
                  editor.insertContent('{{geolocation.city}}</span>');
                }
              }
            ];
          }
        }
      ];
      if (siteType.shopifySite) {
        items.push(
          {
            type: 'nestedmenuitem',
            text: 'Shopify Cart',
            getSubmenuItems: function () {
              return [
                {
                  type: 'menuitem',
                  text: 'Product Title',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pt}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Product Description',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pd}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Product Price',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pp}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Product Image',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pi}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Items Count',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_cnt}}</span>'
                    );
                  },
                },
              ];
            },
          },
          {
            type: 'nestedmenuitem',
            text: 'Recommended Product',
            getSubmenuItems: function () {
              return [
                {
                  type: 'menuitem',
                  text: 'Title',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_t}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Description',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_d}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Price',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_p}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Image',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_i}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Link',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_l}}</span>'
                    );
                  },
                },
              ];
            },
          }
        )
      }
      if (siteType.bigcommerceSite) {
        items.push({
          type: 'nestedmenuitem',
          text: 'BigCommerce Cart',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Product Title',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{bc_c_pt}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Product Price',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{bc_c_pp}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Product Image',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{bc_c_pi}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Items Count',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{bc_c_cnt}}</span>'
                  );
                },
              },
            ];
          }
        })
      }
      callback(items);
    }
  });
  editor.ui.registry.addMenuButton('countdown', {
    text: 'Countdown',
    fetch: function (callback) {
      const items = [
        {
          type: 'menuitem',
          text: 'Seconds',
          onAction: function () {
            editor.insertContent('<span class="data-tag">{{cd_seconds}}</span>');
          }
        },
        {
          type: 'menuitem',
          text: 'Minutes',
          onAction: function () {
            editor.insertContent('<span class="data-tag">{{cd_minutes}}</span>');
          }
        },
        {
          type: 'menuitem',
          text: 'Hours',
          onAction: function () {
            editor.insertContent('<span class="data-tag">{{cd_hours}}</span>');
          }
        },
        {
          type: 'menuitem',
          text: 'Days',
          onAction: function () {
            editor.insertContent('<span class="data-tag">{{cd_days}}</span>');
          }
        }
      ];
      callback(items);
    }
  });
}

export default function RichTextEditorPanel({html, defaultContent, defaultFont, onUpdate, options, siteType}) {
  const [loading, setLoading] = useState(true)
  const [content,] = useState(html)
  const editorRef = useRef(null);
  const fontFormats = Object.keys(FONT_FAMILIES).map((key) => `${FONT_FAMILIES[key]}=${key}`).join(';')

  const setAdvancedClass = (e) => {
    if (e.node.firstChild?.classList) {
      e.node.firstChild.classList.remove('simple')
      e.node.firstChild.classList.add('advanced')
    }
  }

  const plugins = [
    'advlist lists image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code emoticons noneditable',
    ...options?.plugins
  ]

  const initSettings = {
    height: 400,
    menubar: false,
    plugins: plugins,
    toolbar1: 'alignleft aligncenter alignright indent outdent lineheight countdown mergefields',
    toolbar2: `bold italic underline emoticons removeformat | forecolor fontselect fontsizeselect ${options?.toolbar}`,
    setup: (editor) => {
      addCustomButtons(editor, siteType);
      allowEditing(editor);
      return [editor.on('PreProcess', setAdvancedClass)]
    },
    elementpath: false,
    font_formats: fontFormats,
    branding: false,
    fontsize_formats: "8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 40pt 44pt 48pt 60pt 72pt 96pt",
    indentation: '20px',
    font_css: fontsUrl(),
    content_style: "span.data-tag { color: gray; }",
    default_link_target: '_blank',
    target_list: false,
    noneditable_noneditable_class: 'data-tag',
  }

  const onInit = (evt, editor) => {
    setLoading(false)
    editorRef.current = editor
  }

  const onEditorChange = (value) => {
    onUpdate(value)
  }

  return (
    <Fragment>
      <AppLoader visible={loading}/>
      <Editor
        scriptLoading={{ async: true }}
        onInit={onInit}
        initialValue={content}
        apiKey={apiKey}
        init={initSettings}
        onEditorChange={onEditorChange}
      />
    </Fragment>
  )
}
