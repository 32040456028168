import FormControl from '@mui/material/FormControl/FormControl';
import TextField from '@mui/material/TextField';
import React from 'react';
import SegmentSelectBox from '../SegmentSelectBox';
import OperandSelectBox from '../OperandSelectBox';

export default function MetaTagContentCondition({ condition, onChange }) {
  const operands = [
    { value: 'includes', label: 'contains' },
    { value: 'does_not_include', label: 'does not contain' },
  ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'includes' } });
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange} />
      <FormControl variant="outlined">
        <TextField
          size="small"
          name="value"
          onChange={(e) =>
            onChange({
              target: { name: 'key_name', value: e.currentTarget.value },
            })
          }
          value={condition.key_name}
          placeholder="name"
          variant="outlined"
        />
      </FormControl>
      <OperandSelectBox
        operands={operands}
        value={condition.operand}
        onChange={onChange}
      />
      <FormControl variant="outlined">
        <TextField
          size="small"
          name="value"
          onChange={(e) =>
            onChange({
              target: { name: 'value', value: e.currentTarget.value },
            })
          }
          value={condition.value}
          placeholder="content"
          variant="outlined"
        />
      </FormControl>
    </div>
  );
}
