import styled from 'styled-components'
import React, {useContext, useState, useEffect } from 'react'
import iconRetirement from '../../../assets/images/editor/goal-retirement.svg'
import iconEmails from '../../../assets/images/editor/goal-emails.svg'
import iconCalls from '../../../assets/images/editor/goal-calls.svg'
import iconSocial from '../../../assets/images/editor/goal-social.svg'
import iconUrl from '../../../assets/images/editor/goal-url.svg'
import iconAnnouncement from '../../../assets/images/editor/goal-announcement.svg'
import { ListWrapper, Title, TitleHint } from './components'
import {EditorContext, SiteContext} from "../../../utils/contexts";
import { withEditorPageTracking } from "../../../shared/Tracking";

const icons = {
  retirement: iconRetirement,
  leadform: iconRetirement,
  email: iconEmails,
  call: iconCalls,
  social: iconSocial,
  traffic: iconUrl,
  announcement: iconAnnouncement,
  webform: iconRetirement
}

const Goal = styled(({ className, goal, title, children, onClick, active }) => {
  const icon = icons[goal]
  const classes = [className, active === goal ? 'active' : '']

  const select = () => {
    onClick(goal)
  }

  return (
    <div className={ classes.join(' ') } onClick={select}>
      <h6>{ title }</h6>
      <img src={ icon } alt=''/>
      <p>{ children }</p>
    </div>
  )
})`
  margin-bottom: 0.67em;
  transition: 0.67s;
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  &.active {
    border-color: #636363;
    border-width: 2px;
  }
  img {
    padding: 10px;
    height: 46px;
    width: 46px;
  }
  h6 {
    padding-bottom: 0.25em;
    white-space: nowrap;
    font-size: 0.95em;
    line-height: 133%;
    color: #666666;
  }
  p {
    font-size: 0.9em;
    line-height: 150%;
  }
`

function GoalStep({ className, onSelect }) {
  const site = useContext(SiteContext)
  const {settings, systemUpdate} = useContext(EditorContext)
  const [active, setActive] = useState(settings.element_subtype);

  const onClick = (goal) => {
    onSelect(goal)
    setActive(goal)
  }

  useEffect(()=> {
    if (settings.show_thankyou === true) {
      systemUpdate({show_thankyou: false})
    }
  }, [settings.show_thankyou, systemUpdate])

  const onFinancialPlan = /financial-/.test(site.plan.name)
  
  useEffect(() => {
    window.hellobar?.displayPage && window.hellobar?.displayPage('default')
  }, [])
  
  return (
    <div className={ className }>
      <div>
        <Title>Goal</Title>
        <TitleHint>What do you want your Hello Bar to do?</TitleHint>
      </div>
      <ListWrapper>
        {onFinancialPlan && <Goal active={active} goal='retirement' title='Financial Advisor' onClick={onClick}>
          Simple financial advisor. Retirement calculator.
        </Goal>}
        {onFinancialPlan && <Goal active={active} goal='leadform' title='Client lead form' onClick={onClick}>
          Multi-Step lead form.
        </Goal>}
        <Goal active={active} goal='email' title='Collect emails' onClick={onClick}>
          I want to collect my visitors' email addresses and other answers.
        </Goal>
        <Goal active={active} goal='call' title='Get phone calls' onClick={onClick}>
          Allow visitors to call you in one click.
        </Goal>
        <Goal active={active} goal='social' title='Social traffic' onClick={onClick}>
          Point visitors to your social media channels.
        </Goal>
        <Goal active={active} goal='traffic' title='Target a URL' onClick={onClick}>
          Drive traffic to a certain part of your site.
        </Goal>
        <Goal active={active} goal='announcement' title='Announcement' onClick={onClick}>
          No buttons, just text.
        </Goal>
        {/* <Goal active={active} goal='webform' title='Web Form' onClick={onClick}>
          I want to collect my visitors' answers.
        </Goal> */}
      </ListWrapper>
    </div>
  )
}

export default styled(withEditorPageTracking(GoalStep, 'Goal'))`
`
