import React from "react";
import Role from "./Role";
import Avatar from "../../../shared/Avatar";

function User({ user, sites }) {
  const findRole = (roles) => {
    const role = roles.find(role => role.user_id === user.id)
    return role || { role: 'none', user_id: user.id, id: `${user.id}-none` };
  };

  return (
    <tr>
      <td>
        <Avatar user={user}/>
        <div className="name-tag">
          <div className="name">{user.name}</div>
          <div>{user.email}</div>
        </div>
      </td>
      {sites.map(({ roles, site }, index) => <Role key={index} user={user} site={site} role={findRole(roles)}/>)}
    </tr>
  )
}

export default User
