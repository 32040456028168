import React from 'react';
import useAPI from '../utils/api';
import { tracking } from '../utils/tracking';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Dialog from '@mui/material/Dialog/Dialog';
import Slide from '@mui/material/Slide/Slide';
import { IconButton, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Close as CloseIcon } from '@mui/icons-material';
import { useTracking } from '../utils/contexts';
import { extractPlanTier } from '../components/Editor/utils';
import PaywallLock from '../components/PaywallLock';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function HelpDialog({ isOpen, buttonText, showIcon }) {
  const API = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useTracking();
  const [message, setMessage] = React.useState('');

  const [open, setOpen] = React.useState(isOpen || false);
  const [saving, setSaving] = React.useState(false);
  const siteId = API.siteId;
  const planTier = extractPlanTier(API.plan);

  const handleUpdate = (value) => {
    setMessage(value);
  };

  const handleClickOpen = () => {
    trackEvent('Help Clicked');
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendMessage = async () => {
    if (!message || message.length < 10) {
      enqueueSnackbar('That is a pretty short message.', { variant: 'error' });
      return;
    }

    setSaving(true);

    const [ok, response] = await API.raiseSupport(
      message.replace(/\n/g, '<br />')
    );

    if (ok) {
      handleClose();
      enqueueSnackbar(
        'Thanks! Our team will get back to you as soon as possible.',
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar('There was a problem submitting your message.', {
        variant: 'error',
      });
    }
    setSaving(false);
  };

  const openChat = () => {
    tracking.freshchat.open();
  };

  return (
    <React.Fragment>
      <li
        style={{ listStyle: 'none', pointerEvents: 'all' }}
        className={isOpen ? 'active' : ''}
      >
        <a onClick={handleClickOpen} color="white" variant="text">
          {showIcon && <i className="icon-support"></i>}
          <span>{buttonText ? buttonText : 'Need Help?'}</span>
        </a>
      </li>
      <Dialog
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth={true}
        scroll="body"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle component="div" className="dialog-closeable">
            <h5 style={{ marginBottom: '10px' }}>Account Support</h5>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <p>
            <a
              href="https://support.hellobar.com/support/home/"
              className="underlined"
              target="_blank"
            >
              Visit Helpdesk
            </a>
          </p>
          <p>
            Have a question, request, or general comment about your Hellobar
            account or subscription? Reach out using the form below and our team
            will be in touch shortly.
          </p>
          <div className="form-row">
            <div className="input-holder">
              <textarea
                style={{ whiteSpace: 'pre' }}
                value={message}
                placeholder="Your message..."
                onChange={(e) => handleUpdate(e.currentTarget.value)}
              ></textarea>
            </div>
          </div>
        </DialogContent>
        <div className="form-row-action-buttons">
          {planTier > 2 && (
            <a onClick={openChat} className="button button-secondary">
              <div className="want-to-chat"></div>
              Want to Chat
            </a>
          )}
          <a onClick={sendMessage} className="button">
            Send Message
          </a>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default HelpDialog;
