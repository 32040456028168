import React, { useContext } from 'react';
import { Link, withRouter, Redirect, generatePath } from 'react-router-dom';
import { SiteContext } from '../utils/contexts';

function SitePath(to, site) {
  const siteContext = useContext(SiteContext);
  const id = site?.id || siteContext?.id;
  return `/sites/${id}${to}`;
}

function SiteLink(props) {
  const newProps = {
    ...props,
    site: undefined,
    to: SitePath(props.to, props.site),
  };

  return <Link {...newProps}>{props.children}</Link>;
}

export const SiteRedirect = withRouter((props) => {
  const url = generatePath(`/sites/:siteId${props.to}`, props.match.params);
  return <Redirect to={url} />;
});
export default SiteLink;
export { SitePath };
