import CollapsedPanel from '../components/CollapsedPanel';
import React, { useContext } from 'react';
import ColorPicker from '../components/ColorPicker';
import InputBlock from '../components/InputBlock';
import { EditorContext } from '../../../utils/contexts';
import NumberInput from '../components/NumberInput';
import Switcher from '../components/Switcher';

export default function ButtonStylingPanel({ open, title }) {
  const { settings, updateSettings } = useContext(EditorContext);

  const maxHeight = settings.type === 'Bar' ? settings.size : 100;

  const updateButtonColor = (color) => {
    updateSettings({
      button_color: color,
      secondary_color: color,
    });
  };

  const updateBorderColor = (color) => {
    updateSettings({
      cta_border_color: color,
    });
  };

  const updateBorderRadius = (radius) => {
    updateSettings({
      cta_border_radius: radius,
    });
  };

  const updateBorderWidth = (width) => {
    updateSettings({
      cta_border_width: width,
    });
  };

  const updateHeight = (height) => {
    updateSettings({
      cta_height: height,
    });
  };

  const toggleCtaShadow = () => {
    updateSettings({
      cta_shadow_enabled: !settings.cta_shadow_enabled,
    });
  };

  return (
    <CollapsedPanel open={open || false} title={title || 'CTA Button Styling'}>
      <InputBlock label="Background Color">
        <ColorPicker
          value={settings.button_color}
          onChange={updateButtonColor}
          size="small"
        />
      </InputBlock>
      <InputBlock label="Shadow">
        <Switcher
          checked={settings.cta_shadow_enabled}
          onChange={toggleCtaShadow}
        />
      </InputBlock>
      <InputBlock label="Rounded corners">
        <NumberInput
          value={settings.cta_border_radius}
          onChange={updateBorderRadius}
          max={45}
        />
      </InputBlock>
      <InputBlock label="Border color">
        <ColorPicker
          value={settings.cta_border_color}
          onChange={updateBorderColor}
        />
      </InputBlock>
      <InputBlock label="Border width">
        <NumberInput
          value={settings.cta_border_width}
          onChange={updateBorderWidth}
          max={10}
          size="small"
        />
      </InputBlock>
      <InputBlock label="Height">
        <NumberInput
          value={settings.cta_height}
          onChange={updateHeight}
          max={maxHeight}
        />
      </InputBlock>
    </CollapsedPanel>
  );
}
