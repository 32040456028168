import ThemePanel from '../panels/ThemePanel';
import SocialProfilePanel from '../panels/SocialProfilePanel';
import PhoneNumberPanel from '../panels/PhoneNumberPanel';
import UrlTargetPanel from '../panels/UrlTargetPanel';
import TextFieldsPanel from '../panels/TextFieldsPanel';
import BarStylingPanel from '../panels/BarStylingPanel';
import ButtonStylingPanel from '../panels/ButtonStylingPanel';
import TextFieldStylingPanel from '../panels/TextFieldStylingPanel';
import LeadingQuestionPanel from '../panels/LeadingQuestionPanel';
import CountdownPanel from '../panels/CountdownPanel';
import { ListWrapper } from '../Steps/components';
import HeadlinePanel from '../panels/HeadlinePanel';
import CTAPanel from '../panels/CTAPanel';

export default function BarDesign() {
  return (
    <ListWrapper>
      <ThemePanel />
      <BarStylingPanel />
      <HeadlinePanel />
      <TextFieldsPanel />
      <TextFieldStylingPanel />
      <PhoneNumberPanel />
      <SocialProfilePanel />
      <UrlTargetPanel />
      <CTAPanel />
      <ButtonStylingPanel />
      <LeadingQuestionPanel />
      <CountdownPanel />
    </ListWrapper>
  );
}
