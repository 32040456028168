import React, { Fragment, useContext, useState } from 'react';
import { EditorContext } from '../../../utils/contexts';
import CollapsedPanel from '../components/CollapsedPanel';
import Switcher from '../components/Switcher';
import { Label, LabelSelector } from '../Steps/components';
import NumberInput from '../components/NumberInput';
import InputBlock from '../components/InputBlock';
import SelectInput from '../components/SelectInput';
import ColorPicker from '../components/ColorPicker';
import Dropzone from '../components/Dropzone';
import useAPI from '../../../utils/api';
import { findTheme } from '../ThemeSelector/updateTheme';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from '../../Modals/ConfirmationDialog';

const LibraryImageButton = styled.div`
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;

  button {
    background-color: #e9562b;
    color: white;
    font-size: 1em;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }
  .delete-button {
    padding: 5px 6px !important;
    margin-left: 5px;
    svg {
      width: 13px;
      height: auto;
    }
  }
`;

const LibraryImageWrapper = styled.div`
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  position: relative;
  margin: 3px;

  &:hover * {
    opacity: 1;
  }

  &:hover img {
    opacity: 0.3;
  }
`;

const LibraryImage = ({ image, onSelect, onDelete }) => {
  const onClick = () => {
    onSelect(image);
  };

  const deleteImage = () => {
    onDelete(image.id);
  };

  const deleteConfirmation = ({ onClick }) => (
    <button title="Delete Image" className="delete-button" onClick={onClick}>
      <CloseIcon />
    </button>
  );

  return (
    <LibraryImageWrapper>
      <img src={image.modal_url} alt="" />
      <LibraryImageButton>
        <button onClick={onClick}>Use it</button>
        <ConfirmationDialog
          onConfirm={() => deleteImage()}
          component={deleteConfirmation}
        >
          Are you sure you want to delete this image? <br />
          The image might be used in one of your popups.
        </ConfirmationDialog>
      </LibraryImageButton>
    </LibraryImageWrapper>
  );
};

const FixedCollapsedPanel = styled(CollapsedPanel)`
  height: 200px;
  overflow-y: auto;
`;

export default function ImagePanel() {
  const { settings, updateSettings, siteSettings, siteId } =
    useContext(EditorContext);
  const theme = findTheme(settings, siteSettings);
  const defaultImage = theme && theme.image?.default_url;
  const API = useAPI();
  const [imageLoading, setImageLoading] = useState(false);

  const toggleDefaultImage = () => {
    const update = { use_default_image: !settings.use_default_image };
    if (update.use_default_image && defaultImage) {
      update.active_image_id = null;
      update.image_url = defaultImage;
      update.image_large_url = defaultImage;
      update.image_modal_url = defaultImage;
      update.image_file_name = null;
      update.image_placement = theme?.image?.position_default;
    } else if (!update.use_default_image) {
      update.image_url = null;
      update.image_large_url = null;
      update.image_modal_url = null;
      update.image_file_name = null;
    }
    updateSettings(update);
  };

  const updateOpacity = (opacity) => {
    updateSettings({ image_opacity: opacity });
  };

  const updateImagePlacement = (placement) => {
    updateSettings({ image_placement: placement });
  };

  const updateOverlayColor = (color) => {
    updateSettings({ image_overlay_color: color });
  };

  const updateOverlayOpacity = (opacity) => {
    updateSettings({ image_overlay_opacity: opacity });
  };

  const updateImagePositionX = (px) => {
    updateSettings({ image_position_x: px });
  };

  const updateImagePositionY = (px) => {
    updateSettings({ image_position_y: px });
  };

  const updateImage = (url, file) => {
    setImageLoading(true);

    API.uploadImage(siteId, file)
      .then(([, json]) => {
        setImageLoading(false);
        updateSettings({
          active_image_id: json.id,
          image_file_name: json.image_file_name,
          image_url: json.url,
          image_large_url: json.large_url,
          image_modal_url: json.modal_url,
        });
      })
      .catch(() => setImageLoading(false));
  };

  const deleteImage = async (id) => {
    setImageLoading(true);

    await API.deleteImage(id)
      .then(([ok]) => {
        if (ok) {
          const images = siteSettings.image_uploads.filter(
            (img) => Number(img.id) !== Number(id)
          );
          siteSettings.image_uploads = images;
          setImageLoading(false);
          unSelectImage();
        }
      })
      .catch(() => setImageLoading(false));
  };

  const unSelectImage = () => {
    updateSettings({
      active_image_id: null,
      image_modal_url: null,
      image_large_url: null,
      image_url: null,
    });
  };

  const placements = [
    { value: 'position-default', label: 'Default' },
    { value: 'position-top', label: 'Top' },
    { value: 'position-left', label: 'Left' },
    { value: 'position-right', label: 'Right' },
    { value: 'position-background', label: 'Background' },
  ];

  const selectImage = (image) => {
    updateSettings({
      active_image_id: image.id,
      image_file_name: image.image_file_name,
      image_url: image.url,
      image_large_url: image.large_url,
      image_modal_url: image.modal_url,
    });
  };

  const imagePlacement =
    placements.find((p) => settings.image_placement === p.value)?.value ||
    'position-default';
  const hasImagePlacement =
    window.hellobarSiteSettings.modules_filename !== 'modules.js';

  return (
    <CollapsedPanel open={false} title="Image">
      {defaultImage && (
        <Label>
          <span>Use default image</span>
          <Switcher
            checked={settings.use_default_image}
            onChange={toggleDefaultImage}
          />
        </Label>
      )}
      {!settings.use_default_image && (
        <Dropzone
          settings={settings}
          loading={imageLoading}
          onDelete={unSelectImage}
          onUpdate={updateImage}
        />
      )}
      {!settings.use_default_image && (
        <FixedCollapsedPanel open={true} title="Your Library">
          {siteSettings.image_uploads.map((image) => (
            <LibraryImage
              key={image.id.toString()}
              onSelect={selectImage}
              onDelete={() => deleteImage(image.id)}
              image={image}
            />
          ))}
        </FixedCollapsedPanel>
      )}
      {hasImagePlacement ? (
        <LabelSelector>
          <span>Placement</span>
          <SelectInput
            items={placements}
            value={imagePlacement}
            onChange={updateImagePlacement}
          />
        </LabelSelector>
      ) : null}
      {hasImagePlacement ? (
        <InputBlock label="Image Position by X">
          <NumberInput
            value={settings.image_position_x}
            onChange={updateImagePositionX}
            type="px"
            min={-1000}
          />
        </InputBlock>
      ) : null}
      {hasImagePlacement ? (
        <InputBlock label="Image Position by Y">
          <NumberInput
            value={settings.image_position_y}
            onChange={updateImagePositionY}
            type="px"
            min={-1000}
          />
        </InputBlock>
      ) : null}
      <InputBlock label="Opacity">
        <NumberInput
          value={settings.image_opacity}
          onChange={updateOpacity}
          type="%"
          min={0}
          max={100}
        />
      </InputBlock>
      {imagePlacement !== 'position-background' &&
        !(
          imagePlacement === 'position-default' &&
          settings.theme?.image?.position_default === 'background'
        ) && (
          <Fragment>
            <InputBlock label="Overlay color">
              <ColorPicker
                value={settings.image_overlay_color}
                onChange={updateOverlayColor}
              />
            </InputBlock>
            <InputBlock label="Overlay Opacity">
              <NumberInput
                value={settings.image_overlay_opacity}
                onChange={updateOverlayOpacity}
                type="%"
                min={0}
                max={100}
              />
            </InputBlock>
          </Fragment>
        )}
    </CollapsedPanel>
  );
}
