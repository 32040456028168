import React from 'react';
import TimeAgo from 'react-timeago';
import { editorURL } from '../../utils/editor';
import ConfirmationDialog from '../Modals/ConfirmationDialog';
import SiteSelectionDialog from '../Modals/SiteSelectionDialog';
import { FileCopy } from '@mui/icons-material';
import { withErrorBoundary } from '../../shared/ErrorFallback';
import { SiteContext } from '../../utils/contexts';
import { NavLink, useHistory } from "react-router-dom";
import iconBar from "../../assets/images/editor/type-bar.svg";
import iconInline from "../../assets/images/editor/type-inline.svg";
import iconModal from "../../assets/images/editor/type-modal.svg";
import iconSlider from "../../assets/images/editor/type-slider.svg";
import iconTakeover from "../../assets/images/editor/type-takeover.svg";
import iconAlert from "../../assets/images/editor/type-alert.svg";
import { Chip, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import PopupSummaryGraphDialog from './PopupSummaryGraphDailog';

const formatter = (value, unit, suffix) => {
  let units = value > 1 ? unit + 's' : unit;
  return (
    <React.Fragment>
      {value} {' '}
      <small>
        {units} {suffix}
      </small>
    </React.Fragment>
  );
};

const iconForSubType = (popup) => {
  const types = {
    email: 'icon-contacts',
    announcement: 'icon-megaphone',
    social: 'icon-social',
    traffic: 'icon-clicks',
    call: 'icon-call',
  };
  const cleanSubtype = popup.subtype.split('/')[0];

  return types[cleanSubtype];
};

const iconForType = (popup) => {
  const types = {
    Bar: iconBar,
    Modal: iconModal,
    Slider: iconSlider,
    Takeover: iconTakeover,
    Alert: iconAlert,
    Inline: iconInline
  };
  return types[popup.type];
};

const gdprString = (popup) => {
  if (popup.subtype === 'email') {
    return popup.enable_gdpr ? 'Yes' : 'No';
  } else {
    return 'N/A';
  }
};

const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

const conversionsPercent = (popup) => {
  if (popup.subtype === 'announcement') {
    return 'N/A';
  }
  const percent = round(popup.conversion_rate * 100);
  return `${percent}%`;
};

const conversions = (popup) => {
  if (popup.subtype === 'announcement') {
    return '--';
  }
  return popup.total_conversions;
};

const colorFlag = (popup) => ({
  backgroundColor: `#${popup.background_color}`,
});

function PopupItem(props) {
  const popup = props.popup;
  const isWinner = props.isWinner;
  const site = React.useContext(SiteContext);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  
  const togglePopup = (event) => {
    // To check allowed role to perform action
    if(site.current_role === 'viewer'){
      enqueueSnackbar('Not allowed to perform this action', { variant: 'error' });
      return;
    }
    event.preventDefault();
    props.togglePopup(popup);
  };

  const deletePopup = () => {
    // To check allowed role to perform action
    if(site.current_role === 'viewer'){
      enqueueSnackbar('Not allowed to perform this action', { variant: 'error' });
      return;
    }
    props.deletePopup(popup);
  };

  const copyPopup = (event, site) => {
    // To check allowed role to perform action
    if(site.current_role === 'viewer'){
      enqueueSnackbar('Not allowed to perform this action', { variant: 'error' });
      return;
    }
    event.preventDefault();
    props.copyPopup(popup, site);
  };

  const deleteConfirmation = ({ onClick }) => (
    <span onClick={onClick}>
      <i className="icon-trash"></i>Remove
    </span>
  );

  const copyConfirmation = ({ onClick }) => (
    <span onClick={onClick}>
      <i>
        <FileCopy style={{ fontSize: 14 }} />
      </i>
      Copy
    </span>
  );

  const winnerOrLooser = props.letter && (isWinner ? 'winner' : 'looser');

  const livePreviewUrl = (siteUrl, popupId) => {
    return siteUrl + '?_hb_preview_element_id=' + window.btoa(popupId);
  };

  const tagChips = popup?.tags.map(tag => {
    return <Chip label={tag} variant="outlined" sx={{ fontSize: '10px', height: '16px', color: '#666666' }}  />
  })

  const edit = () => {
    history.push(editorURL(site, `/${popup.id}/design`))
  }
  
  return (
    <React.Fragment>
      <tr
        className={`site-element-block type-${popup.subtype} ${
          popup.paused ? 'paused' : 'active'
        } ${winnerOrLooser}`}
        style={{ display: 'table-row' }}
      >
        <td className="color-flag" style={colorFlag(popup)}></td>
        <td className="site-element__headline">
          <p onClick={edit}>{popup.name ? popup.name : popup.title}</p>
          <p className='updated_by_details'>
            last updated <TimeAgo date={popup.updated_time} formatter={formatter} /> 
            {popup.updated_by_name ? ` by ${popup.updated_by_name}` : ''}
          </p>
          <Stack direction="row" spacing={0.5} sx={{my: 0.4}}>
            {tagChips}
          </Stack>
        </td>
        <td className="site-element__age">
          <span className="num">
            <TimeAgo date={popup.created_at} formatter={formatter} />
          </span>
        </td>
        <td className="site-element__type">
          <i className={iconForSubType(popup)}></i>
        </td>
        <td className="site-element__gdpr">
          <span className="text-center">{gdprString(popup)}</span>
        </td>
        <td className="site-element__style">
          <img className="popup-type-icon" src={iconForType(popup)} alt='popup-type-icon'/>
        </td>
        <td className="site-element__views">
          <span className="num">{popup.views}</span>
        </td>
        <td className="site-element__conversions">
          <span className="num">{conversions(popup)}</span>
        </td>
        <td className="site-element__convertions-percent">
          <span className="num">{conversionsPercent(popup)}</span>
        </td>
        <td className="site-element__actions">
          <PopupSummaryGraphDialog
            popup={popup}
            site={site}
          />
          <i className={`testing-icon ${props.letter ? '' : 'icon-abtest'}`}>
            <span className="numbers">{props.letter}</span>
          </i>
          <div className="dropdown-wrapper adjusted">
            <i className="icon-settings" />
            <div className="dropdown-block">
              <NavLink to={editorURL(site, `/${popup.id}/design`)}>
                <i className="icon-edit" />
                Edit
              </NavLink>
              <a
                className="create-variation"
                href={editorURL(site, '/new/goal', {
                  element_to_copy_id: popup.id,
                })}
              >
                <i className="icon-docs" />
                A/B Test
              </a>
              <span className="toggle-pause" onClick={togglePopup}>
                <i className="icon-pause" />
                {popup.paused ? 'Unpause' : 'Pause'}
              </span>
              {!popup.paused && !site.plan.free && (
                <a
                  className="live-preview"
                  target="_blank"
                  href={livePreviewUrl(site.url, popup.id)}
                  rel="noreferrer"
                >
                  <i className="icon-magnifyingglass" />
                  Live Preview
                </a>
              )}
              {!popup.paused && site.plan.free && (
                <NavLink to={`/sites/${site.id}/upgrade`}>
                  <i className="icon-magnifyingglass" />
                  Live Preview
                </NavLink>
              )}
              <ConfirmationDialog
                onConfirm={deletePopup}
                component={deleteConfirmation}
              >
                Are you sure you want to delete this popup?
              </ConfirmationDialog>
              <SiteSelectionDialog
                component={copyConfirmation}
                onSelected={copyPopup}
                site={site}
              />
            </div>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default withErrorBoundary(PopupItem);
