import React from 'react';
import '../../assets/styles/site/_edit.scss';
import useAPI from "../../utils/api";
import SiteForm from "./SiteInfo/SiteForm";
import { useRoleCheck } from '../../utils/useRoleCheck';

function SiteInfo({ site }) {
  const API = useAPI();
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const updateSite = async (form) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    const [,updated] = await API.updateSite(form);
    return site.update(updated);
  }

  const deleteSite = async () => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    await API.deleteSite(site);
    window.location.pathname = "/";
  }

  return (
    <div id="sites-edit" style={{ 'padding': '24px' }}>
      <div className="edit_site">
        <SiteForm site={site} onSubmit={updateSite} onSiteDelete={deleteSite}/>
      </div>
    </div>
  )
}

export default SiteInfo;
