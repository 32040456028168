import { useReducer } from 'react';
import {
  availableThemes,
  findTheme,
  themeDefaults,
} from './ThemeSelector/updateTheme';
import { placements } from './components/placements';

const reduceOnSelectGoal = (goal) => {
  switch (goal) {
    case 'call':
      return {
        headline: 'Talk to us to find out more',
        link_text: 'Call Now',
      };
    case 'email':
      return {
        headline:
          'Join our mailing list to stay up to date on our upcoming events',
        link_text: 'Subscribe',
      };
    case 'announcement':
      return {
        headline:
          'Flash Sale: 20% Off Sitewide, Enter Code: "20savings" on Checkout!',
        link_text: 'Shop Now',
      };
    case 'social':
      return {
        headline: 'Like us on Facebook!',
      };
    case 'traffic':
      return {
        headline:
          'Want To Become An Expert In Hosting Webinars? Join Our Free Webinar Masterclass!',
        link_text: 'Save Me A Spot!',
      };
    case 'retirement':
      return {
        headline:
          "Tell us a few things about yourself, and our calculator will show whether you're on track for the retirement you want.",
        link_text: 'See If You Are On Track For Retirement',
        conversion_cta_text: "Let's schedule a call (free)",
        element_subtype: 'retirement',
        type: 'Takeover',
        preview_page: null,
        required_fields: true,
        theme_id: 'dark-green-spring',
      };
    case 'leadform':
      return {
        headline: "Let's connect you with a planner.",
        link_text: "Let's get started",
        conversion_cta_text: 'Submit',
        element_subtype: 'leadform',
        type: 'Modal',
        preview_page: null,
        required_fields: true,
        placement: 'middle',
        theme_id: 'dark-green-spring',
      };
    default:
      break;
  }
};

const reduceOnSelectType = (type) => {
  const defaultPlacement = () => {
    if (placements[type] && placements[type].length > 0) {
      return { placement: placements[type][0].value };
    }
  };

  const pusher = document.querySelector('#hellobar-pusher');
  if (pusher) {
    pusher.style.display = 'none';
  }

  return { type: type, size: 50, ...defaultPlacement() };
};

const reduceTheme = (settings, siteSettings) => {
  const { theme_id, type, subtype } = settings;
  const theme = findTheme({ theme_id, type }, siteSettings);

  function imagePlacement() {
    if (!theme) return settings.image_placement;

    const positionIsSelectable = theme.image.position_selectable;
    const imageIsBackground = settings.image_placement === 'background';
    const positionIsEmpty = settings.image_placement === '';

    if (!positionIsSelectable) {
      return theme.image.position_default;
    } else if (imageIsBackground || positionIsEmpty) {
      return theme.image.position_default;
    } else {
      return settings.image_placement;
    }
  }

  const reset = {
    image_large_url: null,
    image_modal_url: null,
    image_url: null,
    image_file_name: null,
    use_default_image: false,
    size: 50,
  };

  const defaults = themeDefaults(theme, type, subtype);
  const defaultHeadline =
    settings.theme?.defaults && settings.theme.defaults[type]?.headline;
  const defaultLinkText =
    settings.theme?.defaults && settings.theme.defaults[type]?.link_text;

  if (
    settings.headline &&
    settings.headline !== defaultHeadline &&
    settings.headline !== '' &&
    siteSettings.canUpdateThemeContents
  ) {
    delete defaults.headline;
  }

  if (
    settings.link_text &&
    settings.link_text !== '' &&
    settings.link_text !== 'Click Here' &&
    settings.link_text !== defaultLinkText &&
    siteSettings.canUpdateThemeContents
  ) {
    delete defaults.link_text;
  }

  defaults.use_default_image = !!defaults.theme?.image?.default_url;
  defaults.show_optional_caption =
    Boolean(defaults.caption) && defaults.caption.length > 0;
  defaults.show_optional_content =
    Boolean(defaults.content) && defaults.content.length > 0;

  if (siteSettings.canOverride && siteSettings.site_theme) {
    const theme_overide = siteSettings.site_theme;

    defaults.font_id = theme_overide?.popup_font_family || defaults.font_id;
    defaults.background_color =
      theme_overide?.popup_background_color || defaults.background_color;
    defaults.text_color =
      theme_overide?.popup_text_color || defaults.text_color;
    defaults.cross_color = theme_overide?.cross_color || defaults.cross_color;
    defaults.text_field_background_color =
      theme_overide?.text_field_background_color ||
      defaults.text_field_background_color;
    defaults.text_field_text_color =
      theme_overide?.text_field_text_color || defaults.text_field_text_color;
    defaults.text_field_border_color =
      theme_overide?.text_field_border_color ||
      defaults.text_field_border_color;
    defaults.text_field_border_width =
      theme_overide?.text_field_border_width ||
      defaults.text_field_border_width;
    defaults.text_field_font_size =
      theme_overide?.text_field_font_size || defaults.text_field_font_size;
    defaults.text_field_font_family =
      theme_overide?.text_field_font_family || defaults.text_field_font_family;
    defaults.button_color =
      theme_overide?.cta_button_color || defaults.button_color;
    defaults.cta_border_color =
      theme_overide?.cta_border_color || defaults.cta_border_color;
    defaults.cta_border_width =
      theme_overide?.cta_border_width || defaults.cta_border_width;
    defaults.cta_height = theme_overide?.cta_height || defaults.cta_height;
    defaults.link_color = theme_overide?.cta_text_color || defaults.link_color;
    defaults.cta_shadow_enabled =
      theme_overide?.cta_shadow_enabled || defaults.cta_shadow_enabled;
    defaults.conversion_font_size =
      theme_overide?.cta_font_size || defaults.conversion_font_size;
  }

  return { ...reset, ...defaults, image_placement: imagePlacement() };
};

function reducer(state, action) {
  const actionUpdate = (payload) => {
    if (payload.action_link) {
      payload.link_text = payload.action_link;
    }
    if (payload.background_color) {
      payload.primary_color = payload.background_color;
    }
    if (payload.button_color) {
      payload.secondary_color = payload.button_color;
    }

    return { ...state, ...payload };
  };

  const actionUpdateGoal = (payload) => {
    if (payload === state.element_subtype) {
      return actionUpdate({});
    }

    return actionUpdate({
      type: 'Bar',
      element_subtype: payload,
      subtype: payload,
      // ...reduceOnSelectGoal(payload)
    });
  };

  const actionUpdateType = (payload) => {
    if (payload === state.type) {
      return actionUpdate({});
    }
    return actionUpdate(reduceOnSelectType(payload));
  };

  const actionUpdateTheme = (payload) => {
    state.font = state.font_id; // force set font so that it can be used in preview

    if (payload.theme_id === state.theme_id) {
      return actionUpdate({});
    }
    if (!payload.siteSettings) {
      return { ...state };
    }
    if (payload.theme_id) {
      state.theme_id = payload.theme_id;
      const theme = availableThemes(payload.siteSettings, state.type).find(
        ({ id }) => id === payload.theme_id
      );
      state.font_id = theme.defaults[state.type]?.font_id || state.font_id;
      state.font = payload.siteSettings.fonts[state.font_id];
      state.theme = theme;
    }
    if (payload.theme_id === 'autodetect') {
      const defaults = reduceTheme(state, payload.siteSettings);

      return actionUpdate({ ...defaults, ...payload.autodetectTheme.theme });
    }
    return actionUpdate(reduceTheme(state, payload.siteSettings));
  };

  function update(action) {
    switch (action.type) {
      case 'update':
        return actionUpdate(action.payload);
      case 'update_goal':
        return actionUpdateGoal(action.payload);
      case 'update_type':
        return actionUpdateType(action.payload);
      case 'update_theme':
        return actionUpdateTheme(action.payload);
      default:
        throw new Error(`action type "${action.type}" is not valid`);
    }
  }

  return update(action);
}

const useSettingsReducer = (initialSettings) => {
  if (initialSettings.show_optional_caption && !initialSettings.caption) {
    initialSettings.show_optional_caption = false;
  }
  if (initialSettings.show_optional_content && !initialSettings.content) {
    initialSettings.show_optional_content = false;
  }
  return useReducer(reducer, initialSettings);
};

export { useSettingsReducer };
