import React, { useState } from 'react';
import Invoices from './Billing/Invoices';
import PlanInfo from './Billing/PlanInfo';
import BillingInfo from './Billing/BillingInfo';
import useFetch from '../../utils/useFetch';
import AppLoader from '../../shared/AppLoader';
import Error from '../../shared/Error';
import '../../assets/styles/Billing.scss';
import useDocumentTitle from '../../utils/document-title';
import AddOnInfo from './Billing/AddOnInfo';

export default function Billing({ site }) {
  const [updated, setUpdated] = useState();
  const { loading, error, status, subscription } = useFetch(
    'subscription',
    site.id,
    updated
  );

  const onUpdate = () => setUpdated(!updated);
  useDocumentTitle('Billing', site.url);

  return (
    <div id="sites-edit">
      <div className="Billing">
        <AppLoader visible={loading} />
        {status === 'error' && <Error error={error} />}
        {status === 'fetched' && (
          <PlanInfo onUpdate={onUpdate} subscription={subscription} />
        )}
        {status === 'fetched' && !subscription.plan.free && (
          <BillingInfo
            onUpdate={onUpdate}
            billingInformation={subscription.billing_information}
          />
        )}
        {/* {status === 'fetched' &&
          !subscription.plan.free &&
          !subscription.plan.free_forever && <AddOnInfo site={site} />} */}
        {status === 'fetched' && (
          <Invoices
            loading={loading}
            error={error}
            status={status}
            invoices={subscription.invoices}
          />
        )}
      </div>
    </div>
  );
}
