import React, { useEffect, useState } from 'react';
import './App.scss';
import { SiteContext } from './utils/contexts';
import { UserContext } from './utils/contexts';
import AppLoader from './shared/AppLoader';
import SitePage from './SitePage';
import Error from './shared/Error';
import { useParams, generatePath, Redirect } from 'react-router-dom';
import { API } from './utils/api';
import { tracking } from './utils/tracking';
import { auth } from './utils/auth';
import { withErrorBoundary } from './shared/ErrorFallback';
import { useSnackbar } from 'notistack';
import useScript from './utils/useScript';

function getToken() {
  return auth.getToken();
}

function findSite(user, siteId) {
  if (!siteId) {
    return user?.sites[0];
  }
  return user?.sites?.find((site) => site.id.toString() === siteId.toString());
}

function App() {
  const { siteId } = useParams();
  const [loading, setLoading] = useState(true);
  const [scriptLoading, setScriptLoading] = useState(true);
  const [error, setError] = useState();
  const [site, setSite] = useState();
  const [user, setUser] = useState();
  const token = getToken();
  const { closeSnackbar } = useSnackbar();

  const updateUser = (newUser) => {
    newUser.update = updateUser;
    setUser(newUser);
  };

  const updateSite = (newSite) => {
    newSite.update = updateSite;
    setSite(newSite);
  };

  useEffect(() => {
    const site = findSite(user, siteId);
    if (site) {
      site.update = updateSite;
      setSite(site);
      closeSnackbar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, siteId]);

  useEffect(() => {
    API.profile(token).then(([ok, response]) => {
      if (ok) {
        response.update = updateUser;
        setUser(response);
      } else {
        setError(response);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const previewScriptUrl =
    Number(siteId) &&
    process.env.REACT_APP_PREVIEW_SCRIPT.replace(':site_id', siteId);

  useScript(previewScriptUrl, () => {
    setScriptLoading(false)
  }, () => window.hellobar);

  if (user && siteId !== 'new') {
    if (!user.last_site_id) {
      return <Redirect to="/sites/new" />;
    } else if (!siteId || !findSite(user, siteId)) {
      const lastSiteUrl = window.location.pathname == '/upgrade' ? generatePath('/sites/:siteId/upgrade', {
        siteId: user.last_site_id,
      }) : generatePath('/sites/:siteId', {
        siteId: user.last_site_id,
      });
      return <Redirect to={lastSiteUrl} />;
    }
  }

  tracking.initialize(user, site);

  if (error && error?.status === 401) {
    return <Redirect to="/login" />;
  }

  return (
    <UserContext.Provider value={user}>
      <SiteContext.Provider value={site}>
        <AppLoader visible={loading || scriptLoading} />
        {error && <Error error={error} />}
        {!loading && !scriptLoading && !error && <SitePage />}
      </SiteContext.Provider>
    </UserContext.Provider>
  );
}

export default withErrorBoundary(App);
