import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";
import {Label} from "../Steps/components";
import Switcher from "../components/Switcher";

export default function TweetOnTwitter() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateMessage = (event) => {
    const customSettings = { ...settings.settings, message_to_tweet: event.target.value }
    updateSettings({ settings: customSettings })
  }

  const updateURL = (event) => {
    const customSettings = {...settings.settings, url_to_tweet: event.target.value}
    updateSettings({settings: customSettings})
  }

  const toggleUseLocation = () => {
    const customSettings = {...settings.settings, use_location_for_url: !settings.settings.use_location_for_url}
    updateSettings({settings: customSettings})
  }

  return (
    <>
      <TextField fullWidth variant="outlined" label="Message to tweet" value={settings.settings.message_to_tweet}
                 onChange={updateMessage} placeholder="Check out this great site!" size='small'/>

      <Label>
        <span>URL my visitor is on</span>
        <Switcher checked={settings.settings.use_location_for_url} onChange={toggleUseLocation} size='small'/>
      </Label>

      {!settings.settings.use_location_for_url &&
      <TextField fullWidth variant="outlined" label="URL to tweet" value={settings.settings.url_to_tweet || ''}
                 onChange={updateURL} placeholder="example.com" size="small"/>}
    </>
  )
}
