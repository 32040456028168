import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAPI from '../../../utils/api';
import AppLoader from '../../../shared/AppLoader';
import { useSnackbar } from 'notistack';
import ServiceAddOnDialog from '../../Modals/ServiceAddOnDialog';
import { SiteContext } from '../../../utils/contexts';

function StripeCheckoutSuccess(props) {
  const api = useAPI();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');
  const [paymentStatus, setPaymentStatus] = React.useState(null);
  const [showServicePopup, setShowServicePopup] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    sessionId &&
      api.checkoutSession(sessionId).then(([ok, response]) => {
        setPaymentStatus(response.payment_status);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    const displaySuccess = () => {
      enqueueSnackbar('Successfully upgraded', {
        variant: 'success',
        preventDuplicate: true,
      });
      if (props.planId.includes('financial-')) {
        setShowServicePopup(true);
      } else {
        setTimeout(() => (window.location = window.location.pathname), 2000);
      }
    };
    paymentStatus && displaySuccess();
  }, [paymentStatus, props.planId, enqueueSnackbar]);

  return (
    <React.Fragment>
      {showServicePopup && (
        <SiteContext.Consumer>
          {site => (
            <React.Fragment>
              {!site.purchased_onboarding && <ServiceAddOnDialog site={site} isOpen={true}></ServiceAddOnDialog>}
            </React.Fragment>
          )}
        </SiteContext.Consumer>
      )}
      <AppLoader visible={sessionId && !paymentStatus} />
    </React.Fragment>
  );
}

export default StripeCheckoutSuccess;
