import React from "react";
import MultiTextInput from "../MultiTextInput";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function UrlKeywordCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "keyword", "label": "keyword" }
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'keyword' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <MultiTextInput onChange={onChange} value={condition.value} placeholder="keyword"/>
    </div>
  );
}
