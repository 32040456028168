import styled, { css } from 'styled-components';
import { useContext, useState } from 'react';
import { PopupContainer } from './PopupContainer';
import TopBarNavigation from './PreviewArea/TopBarNavigation';
import constants from './constants';
import { Skeleton } from '@mui/material';
import { NewsModal } from './NewsModal';
import { PopupPageSelector } from './PopupPageSelector';
import { EditorStateContext } from '../../utils/contexts';
import DesktopIcon from '@mui/icons-material/DesktopWindows';
import TabletIcon from '@mui/icons-material/TabletAndroid';
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import Tooltips from '../Tooltips';

const PreviewContainer = styled.div`
  top: 0;
  width: calc(100% - 20px);
  height: calc(100% - 170px);
  position: relative;
  overflow: hidden;
  margin: 10px;
  transition: width 0.1s;
  box-shadow: 0px 5px 5px 2px rgb(0 0 0 / 20%);

  ${(props) =>
    props.$mode === constants.MODE_TABLET &&
    css`
      width: 768px;
      margin: 10px auto 10px;
      border: 1px solid #c6c6c6;
    `}

  ${(props) =>
    props.$mode === constants.MODE_MOBILE &&
    css`
      width: 375px;
      max-height: 667px;
      margin: 10px auto 10px;
      border: 1px solid #c6c6c6;
    `}
`;

const PreviewImage = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 0;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: transparent;
  position: relative;
  z-index: 2;

  background-image: url(${(props) => props.$image});
`;

const PopupModes = styled.div`
  display: inline-flex;
  vertical-align: right;
  justify-content: center;
  align-item: center;
`;

const ViewSwitcher = styled.ul`
  display: none;
  vertical-align: middle;
  justify-content: center;

  @media (min-width: 1000px) {
    display: inline-flex;
  }
`;

const ViewSwitcherButton = styled.li`
  ${(props) =>
    props.active &&
    css`
      background-color: #ffffff;
      color: rgb(82, 82, 82);
    `}

  position: relative;
  cursor: pointer;
  display: inline-block;
  height: 52px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1.5;
  width: 50px;
  border-left: 1px solid #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const PreviewButton = styled.a`
  border-left: 1px solid #ffffff;
  width: fit-content;
  display: flex;
  height: 52px;
  align-items: center;
  padding: 0px 10px;
`;

export const PreviewArea = styled(
  ({ className, settings, setMode, mode, updateSettings, hellobar }) => {
    const previewImages = {
      [constants.MODE_DESKTOP]: settings.site_preview_image,
      [constants.MODE_TABLET]: settings.site_preview_image_tablet,
      [constants.MODE_MOBILE]: settings.site_preview_image_mobile,
    };
    const [isToolbarLoaded, setIsToolbarLoaded] = useState(false);

    const editor = useContext(EditorStateContext);

    const containerClasses = () => {
      let classes = [];

      classes.push(settings.placement);
      classes.push(settings.size);
      if (settings.type) {
        classes.push(settings.type.toLowerCase());
      }
      if (settings.type === 'Inline') {
        classes.push('inline-preview');
      }
      if (settings.pushes_page_down && settings.type === 'Bar') {
        classes.push('is-pushed');
      }
      if (mode === constants.MODE_MOBILE) {
        classes.push('hellobar-preview-container-mobile');
      }

      return classes.join(' ');
    };

    const handleLivePreview = () => {
      const url = `${editor.state.site.url}?_hb_live_preview_data=#${encodeURI(
        JSON.stringify(settings)
      )}`;
      window.open(url, '_blank');
    };

    const showDesktop = settings.element_subtype !== 'call';

    return (
      <div className={className}>
        <TopBarNavigation mode={mode} setMode={setMode} />
        <div id="toolbar">
          {isToolbarLoaded ? null : (
            <Skeleton height={39} variant="rectangular" />
          )}
        </div>
        <PreviewContainer
          $mode={mode}
          id="hellobar-preview-container"
          className={containerClasses()}
        >
          {hellobar ? (
            <>
              <PopupContainer
                hellobar={hellobar}
                setIsToolbarLoaded={setIsToolbarLoaded}
                settings={settings}
                type={settings.type}
                updateSettings={updateSettings}
              />
            </>
          ) : null}
          {settings.type !== 'Inline' && <PreviewImage $image={previewImages[mode]} /> } 
          {/* <NewsModal /> */}
        </PreviewContainer>
        <div className="bottom-toolbar">
          <PopupPageSelector goal={settings.subtype} type={settings.type} />
          <PopupModes>
            <PreviewButton>
              <a target="_blank" onClick={handleLivePreview} rel="noreferrer">
                Live Preview{' '}
                <Tooltips
                  icon={true}
                  message={
                    'Preview your changes on live website. Note: Your site must have Hellobar installed.'
                  }
                ></Tooltips>
              </a>
            </PreviewButton>
            <ViewSwitcher>
              {showDesktop ? (
                <ViewSwitcherButton
                  active={mode === constants.MODE_DESKTOP}
                  onClick={() => setMode(constants.MODE_DESKTOP)}
                >
                  <DesktopIcon />
                </ViewSwitcherButton>
              ) : null}
              <ViewSwitcherButton
                active={mode === constants.MODE_TABLET}
                onClick={() => setMode(constants.MODE_TABLET)}
              >
                <TabletIcon />
              </ViewSwitcherButton>
              <ViewSwitcherButton
                active={mode === constants.MODE_MOBILE}
                onClick={() => setMode(constants.MODE_MOBILE)}
              >
                <PhoneIcon />
              </ViewSwitcherButton>
            </ViewSwitcher>
          </PopupModes>
        </div>
      </div>
    );
  }
)`
  height: 100vh;
  background: #f1f0f0;
  width: 100%;
`;
