import React, { useContext, useState } from 'react';
import '../../assets/styles/site/_privacy.scss';
import Button from '../../shared/Button';
import useAPI from '../../utils/api';
import { SiteContext } from '../../utils/contexts';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useRoleCheck } from '../../utils/useRoleCheck';

function Privacy() {
  const API = useAPI();
  const site = useContext(SiteContext);
  const [state, setState] = useState(site.gdpr);
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const checkBoxChange = (event) => {
    setState((state) => ({
      ...state,
      types: { ...state.types, [event.target.value]: event.target.checked },
    }));
  };

  const inputChange = (event) => {
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  const makeParams = () => {
    const reduce = (array, type) => {
      if (state.types[type]) {
        array.push(type);
      }
      return array;
    };
    const types = Object.keys(state.types).reduce(reduce, []);
    return {
      id: site.id,
      gdpr_consent_language: state.language,
      privacy_policy_url: state.privacy_policy_url,
      terms_and_conditions_url: state.terms_and_conditions_url,
      communication_types: types.join(','),
    };
  };

  const updateSite = async () => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    if (!state.privacy_policy_url || !state.terms_and_conditions_url) {
      enqueueSnackbar(
        'Please enter your Privacy policy and Terms URL. These URLs will be displayed to the user before collecting their email or any personal details.',
        {
          variant: 'error',
        }
      );
      return;
    }
    setState((state) => ({ ...state, loading: true }));
    const [, updated] = await API.updateSite(makeParams());
    setState((state) => ({ ...state, loading: false }));
    return site.update(updated);
  };

  return (
    <div id="privacies-edit" style={{ padding: '24px' }}>
      <h5 className="page-header alt">GDPR Compliance</h5>
      <p>
        We've added features to make your site elements GDPR compliant. Any site
        elements that collect personal information such as email addresses,
        names and phone numbers from anyone in the EU will need to be updated
        across your site in order to comply. Please provide the following
        information below and then turn the GDPR Compliance setting on for each
        site element that needs to become compliant.{' '}
      </p>
      <p>
        The GDPR Compliance setting must be enabled for each site element. When
        enabled each site element will display two checkboxes that your visitor
        MUST agree to in order for them to be added to your list and for you to
        be able to contact them.{' '}
      </p>
      <h5 className="site-privacy__subhead">Communications Consent</h5>
      <p>
        Anyone signing up through your site elements will need to consent to
        receive the types of communication you want to send to them. What kind
        of information or data will you be communicating to your users?{' '}
      </p>
      <p>
        <span className="form-group check_boxes optional site_communication_types">
          <span>
            <span className="checkbox">
              <label htmlFor="site_communication_types_newsletter">
                <input
                  className="form-control check_boxes optional"
                  type="checkbox"
                  value="newsletter"
                  checked={state.types.newsletter}
                  onChange={checkBoxChange}
                  id="site_communication_types_newsletter"
                />
                Newsletter (blog articles, announcements, reports)
              </label>
            </span>
            <span className="checkbox">
              <label htmlFor="site_communication_types_promotional">
                <input
                  className="form-control check_boxes optional"
                  type="checkbox"
                  value="promotional"
                  checked={state.types.promotional}
                  onChange={checkBoxChange}
                  id="site_communication_types_promotional"
                />
                Promotional (sales, deals, gifts, offers)
              </label>
            </span>
            <span className="checkbox">
              <label htmlFor="site_communication_types_partnership">
                <input
                  className="form-control check_boxes optional"
                  type="checkbox"
                  value="partnership"
                  checked={state.types.partnership}
                  onChange={checkBoxChange}
                  id="site_communication_types_partnership"
                />
                Partnerships (business deals, partnership opportunities)
              </label>
            </span>
            <span className="checkbox">
              <label htmlFor="site_communication_types_product">
                <input
                  className="form-control check_boxes optional"
                  type="checkbox"
                  value="product"
                  checked={state.types.product}
                  onChange={checkBoxChange}
                  id="site_communication_types_product"
                />
                Product/service (updates, new or removed)
              </label>
            </span>
            <span className="checkbox">
              <label htmlFor="site_communication_types_research">
                <input
                  className="form-control check_boxes optional"
                  type="checkbox"
                  value="research"
                  checked={state.types.research}
                  onChange={checkBoxChange}
                  id="site_communication_types_research"
                />
                Market research (surveys, qual &amp; quant research, feedback,
                ratings and reviews)
              </label>
            </span>
          </span>
        </span>
      </p>
      <h5 className="site-privacy__subhead">Data Privacy Consent</h5>
      <p>
        Your site visitors will need to agree to <b>your</b> Privacy Policy and
        Terms of Use. Please provide the links below. If you do not have a
        Privacy Policy or Terms and Conditions already, we recommend you add
        both of those to your site immediately and turn off any site elements
        that collect personal data such as email addresses, names and phone
        numbers before proceeding with collecting any personal information from
        your site visitors.{' '}
      </p>
      <Grid>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={6}>
              <Grid item xs>
                <FormControl fullWidth className="UrlInput">
                  <TextField
                    required
                    value={state.privacy_policy_url}
                    onChange={inputChange}
                    name="privacy_policy_url"
                    label="Privacy policy URL"
                    size="small"
                  />
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth className="UrlInput">
                  <TextField
                    required
                    value={state.terms_and_conditions_url}
                    onChange={inputChange}
                    name="terms_and_conditions_url"
                    label="Terms and conditions URL"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <h5 className="site-privacy__subhead">Language</h5>
      <p>
        The GDPR consent text that will be seen on your site elements is not
        editable but we have translated the text into a number of different
        languages for you. <br /> Please choose the language you would like to
        display this text in below.
      </p>
      <Grid>
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item xs={6} lg={3}>
            <Grid container spacing={6}>
              <Grid item xs>
                <FormControl fullWidth className="TimezoneInput">
                  <TextField
                    select
                    label="Language"
                    required
                    value={state.language}
                    onChange={inputChange}
                    name="language"
                    size="small"
                  >
                    <MenuItem value="en">English [Default]</MenuItem>
                    <MenuItem value="pt">Portuguese (Europe)</MenuItem>
                    <MenuItem value="pt-BR">Portuguese (Brasil)</MenuItem>
                    <MenuItem value="fr">French</MenuItem>
                    <MenuItem value="es">Spanish (Latin America)</MenuItem>
                    <MenuItem value="it">Italian</MenuItem>
                    <MenuItem value="pl">Polish</MenuItem>
                    <MenuItem value="ru">Russian</MenuItem>
                    <MenuItem value="de">German</MenuItem>
                    <MenuItem value="el">Greek</MenuItem>
                    <MenuItem value="zh-CN">Chinese (Traditional)</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={6}>
              <Grid item xs={3}>
                <Button
                  onClick={updateSite}
                  loading={state.loading}
                  color="secondary"
                  label="Save"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Privacy;
