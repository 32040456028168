const cssToNumber = (css) => {
  return Number(css.replace("px", ""))
}

export class PositionManager {
  init(element) {
    element.style.cursor = "grab"
    this.reset()
  }
  
  reset() {
    this.direction = null
    this.xStart = null
    this.yStart = null
    this.xCurrent = 0
    this.yCurrent = 0
    this.initialX = 0
    this.initialY = 0
  }
  
  start(event) {
    event.preventDefault();
    event.target.style.cursor = "grabbing";
    this.xStart = event.clientX
    this.yStart = event.clientY
    
    const element = event.currentTarget
    
    this.initialX = cssToNumber(element.style.backgroundPositionX)
    this.initialY = cssToNumber(element.style.backgroundPositionY)
    this.xCurrent = this.initialX
    this.yCurrent = this.initialY
  }
  
  end(event) {
    event.preventDefault();
    event.target.style.cursor = "grab";
  }
  
  move(event) {
    event.preventDefault();
    if (event.buttons !== 1) {
      return;
    }
    
    const element = event.currentTarget
    const x = event.clientX
    const y = event.clientY
    const dx = x - this.xStart
    const dy = y - this.yStart
    
    if (Math.abs(dx) < 5 && Math.abs(dy) < 5) {
      return;
    }
    
    this.setDirection(dx, dy);
    this.updatePosition(element, dx, dy);
  }
  
  setDirection(dx, dy) {
    if (this.direction) {
      return;
    }
    
    if (Math.abs(dx) > Math.abs(dy)) {
      this.direction = "horizontal"
    }
    else {
      this.direction = "vertical"
    }
  }
  
  updatePosition(element, dx, dy) {
    if (this.direction === "horizontal") {
      this.xCurrent = this.initialX + this.getDelta(dx, dy)
      element.style.backgroundPositionX = `${ this.xCurrent }px`
    }
    
    if (this.direction === "vertical") {
      this.yCurrent = this.initialY + this.getDelta(dx, dy)
      element.style.backgroundPositionY = `${ this.yCurrent }px`
    }
  }
  
  getDelta(dx, dy) {
    if (this.direction === "horizontal") {
      return dx
    }
    else {
      return dy
    }
  }
}
