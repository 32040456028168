import React, { useEffect, useState } from "react";
import useAPI from "./api";
import braintree from "braintree-web";

const PayPalContext = React.createContext();

function loadPayPal(client_token) {
  return braintree.client.create({ authorization: client_token })
    .then(client => braintree.paypalCheckout.create({ client: client }))
    .then(paypalCheckout => {
      return paypalCheckout.loadPayPalSDK({ vault: true });
    })
}

function PayPalProvider({ children }) {
  const [value, setValue] = useState({});
  const api = useAPI();

  useEffect(() => {
    api.braintreeToken()
      .then(([, { client_token }]) => client_token)
      .then(clientToken => loadPayPal(clientToken))
      .then(paypalCheckout => ({ paypal: window.paypal, checkout: paypalCheckout }))
      .then(context => setValue(context));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PayPalContext.Provider value={value}>{children}</PayPalContext.Provider>;
}

function usePayPal() {
  return React.useContext(PayPalContext);
}

export { PayPalProvider, usePayPal, PayPalContext };
