import styled from 'styled-components';
import Tooltips from '../../Tooltips';

const Block = styled.div`
  display: flex;
  margin: 10px auto;
  justify-content: space-between;
`;

const Label = styled.div`
  align-self: center;
  font-weight: bold;
  white-space: nowrap;
  font-size: 0.8em;
`;

const InputWrapper = styled.div`
  width: auto;
`;

export default function InputBlock({
  label,
  children,
  helpText,
  helpUrl,
  videoUrl,
}) {
  return (
    <Block>
      <Label>
        {label}{' '}
        {helpText && (
          <Tooltips
            icon={true}
            message={helpText}
            url={helpUrl}
            videoUrl={videoUrl}
          ></Tooltips>
        )}
      </Label>

      <InputWrapper className="input-wrapper">{children}</InputWrapper>
    </Block>
  );
}
