import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";

export default function FollowOnYoutube() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateURL = (event) => {
    const customSettings = { ...settings.settings, youtube_channel: event.target.value }
    updateSettings({ settings: customSettings })
  }

  return (
    <>
      <TextField fullWidth variant="outlined" label="Youtube Channel Name or ID" value={settings.settings.youtube_channel}
                 onChange={updateURL} placeholder="Enter Channel Name or ID" size='small'/>
    </>
  )
}
