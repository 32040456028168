import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../utils/contexts';
import '../../assets/styles/_todo.scss';
import CloseIcon from '@mui/icons-material/Close';
import SiteLink from '../../shared/SiteLink';
import Button from '../../shared/Button';
import caret_up from '../../assets/images/caret-up.svg';

export default function Todo() {
  const site = useContext(SiteContext);
  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const isInstalled = site.installed;
  const hasPopup = site.has_popup;
  const hasRules = site.has_rules;
  const hasContactLists = site.has_contacts;

  useEffect(() => {
    if (sessionStorage.getItem('_hb_todo_closed')) {
      setShowButton(true);
      setOpen(false);
    } else {
      setOpen(true);
      setShowButton(false);
    }
  }, []);

  if (isInstalled && hasPopup && hasRules && hasContactLists) {
    return <></>;
  }

  const showList = () => {
    setShowButton(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowButton(true);
    sessionStorage.setItem('_hb_todo_closed', 'true');
  };

  const calculateProgress = (progress = 0, value) => {
    return progress + (value ? 25 : 0);
  };

  const totalProgress = [
    isInstalled,
    hasPopup,
    hasRules,
    hasContactLists,
  ].reduce(calculateProgress, 0);

  const CheckListDone = (
    <svg
      className="svg-icon filled"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 3.75a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5zM2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12z"
      />
      <path
        clipRule="evenodd"
        d="M16.465 8.411a.75.75 0 01.124 1.054l-4.404 5.577a1.25 1.25 0 01-1.827.145l-2.866-2.635a.75.75 0 111.016-1.104l2.667 2.453 4.236-5.366a.75.75 0 011.054-.124z"
      />
    </svg>
  );

  const CheckListBlack = (
    <svg
      className="svg-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        stroke="#000"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );

  const progressDetails = [
    {
      title: 'Install Hello Bar',
      url: '/install',
      isCompleted: isInstalled,
    },
    {
      title: 'Design & Publish your first popup',
      url: '/popups/new/interstitial',
      isCompleted: hasPopup,
    },
    {
      title: 'Create a new custom targeting rule',
      url: '/rules',
      isCompleted: hasRules,
    },
    {
      title: 'Create a contact list integration',
      url: '/contacts',
      isCompleted: hasContactLists,
    },
  ];

  return (
    <div className="checklist-notification left visible">
      {open && (
        <div className="checklist-notification-inner">
          <h3 className="checklist-title">
            Your Progress
            <span>
              <CloseIcon className="cross" onClick={handleClose} />
            </span>
          </h3>
          <div className="checklist-content">
            <span className="checklist-progress-value">{totalProgress}%</span>
            <div className="checklist-progress">
              <div
                className="checklist-progress-bar"
                style={{ width: `${totalProgress}%` }}
              />
            </div>
          </div>
          <div className="checklist-actions">
            {progressDetails.map((item) => (
              <SiteLink to={item.url} disabled={item.isCompleted}>
                <Button
                  color="white"
                  sx={{ textTransform: 'none' }}
                  className={`checklist-button ${
                    item.isCompleted ? 'done' : ''
                  }`}
                >
                  {item.isCompleted ? CheckListDone : CheckListBlack}
                  <span>{item.title}</span>
                </Button>
              </SiteLink>
            ))}
          </div>
        </div>
      )}
      {showButton && (
        <Button className="get-started" onClick={showList}>
          Your progress &nbsp;
          <img src={caret_up} width={14} alt="Caret down" />
        </Button>
      )}
    </div>
  );
}
