import CollapsedPanel from '../components/CollapsedPanel';
import React, { useContext } from 'react';
import CommonStylingPanel from './CommonStylingPanel';
import NumberInput from '../components/NumberInput';
import SelectInput from '../components/SelectInput';
import InputBlock from '../components/InputBlock';
import { EditorContext } from '../../../utils/contexts';
import { TextField } from '@mui/material';

export default function InlineStylingPanel() {
  const { settings, updateSettings } = useContext(EditorContext);

  const updateWidth = (width) => {
    updateSettings({
      width,
    });
  };

  return (
    <CollapsedPanel open={false} title="Inline Styling">
      <CommonStylingPanel />
      <InputBlock label="Width">
        <NumberInput
          value={settings.width || 500}
          onChange={updateWidth}
          max={1600}
          step={50}
          size="small"
        />
      </InputBlock>
      <InputBlock
        label="CSS Selector"
        helpText="Select the CSS Selector of the element on your page after where you'd like to insert this inline popup."
        helpUrl="https://support.hellobar.com/support/solutions/articles/44002532599-hello-bar-inline-form"
      >
        <TextField
          onChange={(e) =>
            updateSettings({ inline_css_selector: e.target.value })
          }
          size="small"
          multiline
          maxRows={7}
          fullWidth
          variant="outlined"
          value={settings.inline_css_selector || ''}
          label=""
          placeholder=".classname or #id"
        />
      </InputBlock>
      <InputBlock
        label="Insert at"
        helpText="Select the position where you'd like to insert the new inine popup relative to the css selector element."
      >
        <SelectInput
          items={[
            { value: 'after', label: 'after selected element' },
            { value: 'before', label: 'before selected element' },
            { value: 'replace', label: 'replace selected element' },
          ]}
          value={settings.inline_position || 'after'}
          onChange={(value) => updateSettings({ inline_position: value })}
          size="small"
        />
      </InputBlock>
    </CollapsedPanel>
  );
}
