import { TextField } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close';

export const Bar = styled(({ className }) => {
  return (
    <div className={ className }>
      <div className='hb-bar-content'>
        <div id='headline'>Sign up Now to receive the special offers.</div>
        <TextField
          size='small'
          placeholder="Your Email"
        />
        <button>Okay</button>
        <div>
        </div>
      </div>
      <CloseIcon className='closeButton' />
    </div>
  )
})`
  background-color: ${(props) => '#' + props.themeData.popup_background_color};
  font-family: ${(props) => props.themeData.popup_font_family};
  color: ${(props) => '#' + props.themeData.popup_text_color};
  padding: 10px 0px 10px 10px;
  margin: 10px auto;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px 2px rgb(0 0 0 / 15%);
  & .hb-bar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    margin: 5px auto;
    & #headline {
      text-align: center;
      padding: 0 20px;
      width: min-content;
      min-width: 185px;
      font-size: ${(props) => props.method == "showAsTheme" ? '18px' : '14px'};
    }
    & fieldset {
      border: none;
    }
    & input[type='text']{
      padding: 0px 5px;
      display: block;
      opacity: 100%;
      max-width: 170px;
      height: ${(props) => props.themeData.cta_height + 'px' || '40px'};
      color: ${(props) => '#' + props.themeData.text_field_text_color};
      background-color: ${(props) => '#' + props.themeData.text_field_background_color};
      border-color: ${(props) => '#' + props.themeData.text_field_border_color };
      border: ${(props) => props.themeData.text_field_border_width > 0 ? props.themeData.text_field_border_width + 'px solid' +'#' + props.themeData.text_field_border_color  : 'none' };
      font-size: ${(props) => props.themeData.text_field_font_size + 'px' };
      font-family: ${(props) => props.themeData.text_field_font_family };
      outline: none;
    }
    & button{
      padding: 0px 5px;
      height: ${(props) => props.themeData.cta_height + 'px' || '40px'};
      color: ${(props) => '#' + props.themeData.cta_text_color};
      background-color: ${(props) => '#' + props.themeData.cta_button_color};
      border-top: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-bottom: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-left: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-right: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-color: ${(props) => '#' + props.themeData.cta_border_color };
      box-shadow: ${(props) => props.themeData.cta_shadow_enabled ? '0px 4px 20px -7px black' : 'none' };
      font-size: ${(props) => props.themeData.cta_font_size + 'px' || '16px' };
      font-family: ${(props) => props.themeData.popup_font_family };
    }
  }
  & .closeButton {
    color: ${(props) => '#' + props.themeData.cross_color};
    line-height: 80%;
    font-size: 1.5em;
    text-align: center;
    font-style: normal;
    font-weight: lighter;
    margin-right: 1rem;
  }
`

export const Modal = styled(({ className }) => {
  return (
    <div className={ className }>
      <CloseIcon className='closeButton' />
      <div id='headline'>Sign up Now to receive the special offers.</div>
      <br />
      <div className="hb-form-content">
        <TextField
          size="small"
          placeholder="Your Email"
        />
        <TextField
          size="small"
          placeholder="Your Name"
        />
        <button>Okay</button>
      </div>
    </div>
  )
})`
  background-color: ${(props) => '#' + props.themeData.popup_background_color};
  font-family: ${(props) => props.themeData.popup_font_family};
  padding: 35px 20px;
  color: ${(props) => '#' + props.themeData.popup_text_color};
  margin: ${(props) => props.method == "showAsTheme" ? '0px' : '10px auto'};
  height: ${(props) => props.method == "showAsTheme" ? 'auto' : 'auto'};
  cursor: default;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0 1px 3px 2px rgb(0 0 0 / 15%);
  & #headline {
    padding: 0 20px;
    text-align: center;
    font-size: ${(props) => props.method == "showAsTheme" ? '1.2vw' : '16px'};
  }
  & .hb-form-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    margin: 5px auto;
    & fieldset {
      border: none;
    }
    & input{
      opacity: 100%;
      padding: 0px 10px;
      display: block;
      height: ${(props) => props.themeData.cta_height + 'px' || '40px'};
      color: ${(props) => '#' + props.themeData.text_field_text_color};
      background-color: ${(props) => '#' + props.themeData.text_field_background_color};
      border-top: ${(props) => props.themeData.text_field_border_width + 'px solid' };
      border-bottom: ${(props) => props.themeData.text_field_border_width + 'px solid' };
      border-left: ${(props) => props.themeData.text_field_border_width + 'px solid' };
      border-right: ${(props) => props.themeData.text_field_border_width + 'px solid' };
      border-color: ${(props) => '#' + props.themeData.text_field_border_color };
      font-size: ${(props) => props.themeData.text_field_font_size + 'px' };
      font-family: ${(props) => props.themeData.text_field_font_family };
      outline: none;
    }
    & button{
      width: 100%;
      height: ${(props) => props.themeData.cta_height + 'px' || '40px'};
      color: ${(props) => '#' + props.themeData.cta_text_color};
      background-color: ${(props) => '#' + props.themeData.cta_button_color};
      border-top: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-bottom: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-left: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-right: ${(props) => props.themeData.cta_border_width + 'px solid' };
      border-color: ${(props) => '#' + props.themeData.cta_border_color };
      box-shadow: ${(props) => props.themeData.cta_shadow_enabled ? '0px 4px 20px -7px black' : 'none' };
      font-size: ${(props) => props.themeData.cta_font_size + 'px' || '16px' };
      font-family: ${(props) => props.themeData.popup_font_family };
    }
  }
  & .closeButton {
    color: ${(props) => '#' + props.themeData.cross_color};
    line-height: 80%;
    font-size: 1.5em;
    text-align: center;
    font-style: normal;
    font-weight: lighter;
    margin-right: 1rem;
    flex: 0 0 2rem;
    position: absolute;
    right: 66px;
    top: 31%;
    transform: translateY(-50%);
  }
`

const BrandingPreview = ({ heading, themeData }) => {
  return <div>
    <h5>{heading || 'Preview'}</h5>
    <h6>Bar Preview</h6><br/>
    <Bar themeData={themeData}/>
    <br/>
    <h6>Modal Preview</h6>
    <br/>
    <Modal themeData={themeData}/>
  </div>
}

export default BrandingPreview
