import React from 'react';
import { ruleSegments, multipleSegments } from '../../utils/ruleConditions';
import UtmCondition from '../Rules/conditions/UTMCondition';
import GeoCondition from '../Rules/conditions/GeoCondition';
import CountryCondition from '../Rules/conditions/CountryCondition';
import CookieCondition from '../Rules/conditions/CookieCondition';
import UrlQueryCondition from '../Rules/conditions/UrlQueryCondition';
import UrlKeywordCondition from '../Rules/conditions/UrlKeywordCondition';
import UrlPathCondition from '../Rules/conditions/UrlPathCondition';
import UrlCondition from '../Rules/conditions/UrlCondition';
import DayOfWeekCondition from '../Rules/conditions/DayOfWeekCondition';
import NumberCondition from '../Rules/conditions/NumberCondition';
import DeviceCondition from '../Rules/conditions/DeviceCondition';
import SimpleCondition from '../Rules/conditions/SimpleCondition';
import WordpressTagsCondition from '../Rules/conditions/WordpressTagsCondition';
import EveryXSessionCondition from '../Rules/conditions/EveryXSessionCondition';
import TimeCondition from '../Rules/conditions/TimeCondition';
import DateCondition from '../Rules/conditions/DateCondition';
import PreviousPageUrlCondition from '../Rules/conditions/PreviousPageUrlCondition';
import Grid from '@mui/material/Grid/Grid';
import AdBlockerCondition from './conditions/AdBlockerCondition';
import MetaTagContentCondition from './conditions/MetaTagContentCondition';

const components = {
  UTMCampaignCondition: (props) => (
    <UtmCondition {...props} placeholder="UTM Campaign" />
  ),
  UTMContentCondition: (props) => (
    <UtmCondition {...props} placeholder="UTM Content" />
  ),
  UTMMediumCondition: (props) => (
    <UtmCondition {...props} placeholder="UTM Medium" />
  ),
  UTMSourceCondition: (props) => (
    <UtmCondition {...props} placeholder="UTM Source" />
  ),
  UTMTermCondition: (props) => (
    <UtmCondition {...props} placeholder="UTM Term" />
  ),
  AdBlockerCondition: (props) => (
    <AdBlockerCondition {...props} placeholder="Ad Blocker Detected" />
  ),
  LocationCityCondition: (props) => (
    <GeoCondition {...props} placeholder="New York" />
  ),
  LocationRegionCondition: (props) => (
    <GeoCondition {...props} placeholder="California" />
  ),
  LocationCountryCondition: (props) => <CountryCondition {...props} />,
  CookieValueCondition: (props) => <CookieCondition {...props} />,
  DaysOfWeekCondition: (props) => <DayOfWeekCondition {...props} />,
  LastVisitCondition: (props) => (
    <NumberCondition {...props} placeholder="No. of days" />
  ),
  NumberOfVisitsCondition: (props) => (
    <NumberCondition {...props} placeholder="No. of visits" />
  ),
  DeviceCondition: (props) => <DeviceCondition {...props} />,
  UrlQueryCondition: (props) => <UrlQueryCondition {...props} />,
  UrlKeywordCondition: (props) => <UrlKeywordCondition {...props} />,
  UrlPathCondition: (props) => <UrlPathCondition {...props} />,
  UrlCondition: (props) => <UrlCondition {...props} />,
  ReferrerCondition: (props) => (
    <SimpleCondition {...props} placeholder="Referrer" />
  ),
  ReferrerDomainCondition: (props) => (
    <SimpleCondition {...props} placeholder="https://example.com/" />
  ),
  PreviousPageURL: (props) => <PreviousPageUrlCondition {...props} />,
  DateCondition: (props) => <DateCondition {...props} />,
  TimeCondition: (props) => <TimeCondition {...props} />,
  EveryXSession: (props) => <EveryXSessionCondition {...props} />,
  WordpressTagsCondition: (props) => <WordpressTagsCondition {...props} />,
  MetaTagContentCondition: (props) => <MetaTagContentCondition {...props} />,
};

export const initCondition = {
  operand: 'is',
  segment: ruleSegments['AD']?.[0]?.value,
  value: [''],
  multiple: true,
};

export const isMultipleCondition = (condition) => {
  return multipleSegments.includes(condition.segment);
};

export default function Condition({
  condition,
  update,
  addCondition,
  removeCondition,
}) {
  const updateCondition = (event) => {
    update(condition, event.target.name, event.target.value);
  };

  const getComponent = (condition) => {
    let component =
      components[condition.segment] || components['UTMCampaignCondition'];
    return component({ onChange: updateCondition, condition: condition });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={5}
      className="condition-block"
    >
      <Grid item sm={10}>
        {getComponent(condition)}
      </Grid>
      <Grid item sm={2} className="condition-actions">
        <span
          className="condition-remove"
          onClick={() => removeCondition(condition)}
        >
          -
        </span>
        <span className="condition-add" onClick={addCondition}>
          +
        </span>
      </Grid>
    </Grid>
  );
}
