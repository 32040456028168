import styled from 'styled-components';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { EditorContext } from '../../utils/contexts';
import logo from '../../assets/images/hb-logo.png';
import StepLinks from './StepLinks';
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import GoalStep from './Steps/Goal';
import TypeStep from './Steps/Type';
import QuestionStep from './Steps/Question';
import DesignStep from './Steps/Design';
import SettingsStep from './Steps/Settings';
import TargetingStep from './Steps/Targeting';
import ConversionStep from './Steps/Conversion';
import ThemeSelector from './ThemeSelector';
import { PreviewArea } from './PreviewArea';
import constants from './constants';
import ColorThief from 'colorthief';
import oneColor from 'onecolor';
import UpgradeDialog from '../Modals/UpgradeDialog';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SideBarPin } from './components/navigation/PageNavigation';

const Layout = styled.div`
  display: grid;
  grid-auto-rows: 3em;
  grid-template-columns: ${(props) =>
    props.showSidebar ? '90px 315px 1fr' : 'auto'};
  height: 100vh;
  font-family: 'Roboto', sans-serif;
`;

const SideNav = styled.div`
  height: 100vh;
  background-color: #f6f6f6;
`;

const Logo = styled.img`
  display: flex;
  width: 58px;
`;

const Step = styled.div`
  height: 100vh;
  padding: 0 1.1em 0;
  border-right: 1px solid #e2e2e2;
  transition: 0.5s;
  z-index: 1;
  background-color: white;
  overflow-y: auto;
`;

const ColorPickerImage = styled.img`
  top: -99999px;
  left: -99999px;
  width: initial;
  position: absolute;
`;
const COLOR_THIEF = new ColorThief();

function formatRGB(rgbArray) {
  rgbArray.push(1);
  return rgbArray;
}

function brightness(color) {
  const rgb = Array.from(color);
  [0, 1, 2].forEach((i) => {
    const val = rgb[i] / 255;
    rgb[i] = val < 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
}

function EditorLayout({ className, site }) {
  const match = useRouteMatch();
  const {
    siteSettings,
    settings,
    dispatch,
    setAutodetectTheme,
    showTheme,
    showThemeSelector,
    updateSettings,
    setCanOverrideTheme,
    setUpdateThemeContents,
  } = useContext(EditorContext);
  const [mode, setMode] = useState(constants.MODE_DESKTOP);
  const [showSidebar, setShowSidebar] = useState(true);
  const [hellobar, setHellobar] = useState(null);

  const onSelectGoal = useCallback(
    (goal) => {
      if (goal === constants.SUBTYPES.CALL) {
        setMode(constants.MODE_MOBILE);
      } else {
        setMode(constants.MODE_DESKTOP);
      }
      dispatch({ type: 'update_goal', payload: goal });

      if (goal === 'webform') {
        dispatch({ type: 'update_type', payload: 'Modal' });
      }
      if (!settings.copyId) {
        dispatch({ type: 'update_theme', payload: { siteSettings } });
      }
    },
    [dispatch, settings.copyId, siteSettings]
  );
  const onSelectType = useCallback(
    (type) => {
      dispatch({ type: 'update_type', payload: type });
    },
    [dispatch]
  );

  useEffect(() => {
    onSelectType(settings.type);
    handleTypeChanges(settings.type);
  }, [onSelectType, settings.type]);

  const handleTypeChanges = (type) => {
    if (type === 'Inline') {
      const customSettings = settings.settings;
      customSettings.cookie_settings.duration = 0;
      customSettings.cookie_settings.success_duration = 0;

      updateSettings({
        animated: false,
        closable: false,
        view_condition: 'immediately',
        settings: customSettings,
      });
    }
  };

  useEffect(() => {
    async function init() {
      window.hellobarSiteSettings.container = '#hellobar-preview-container';
      if (window.hellobar) {
        setHellobar(window.hellobar);
      } else if (window.Hellobar) {
        await window.Hellobar.init(window.hellobarSiteSettings);
        setHellobar(window.hellobar);
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (match.params.popupId === 'new' && !settings.theme?.id) {
      onSelectGoal(settings.element_subtype);
    } else {
      dispatch({ type: 'update', payload: { previewInitialized: true } });
    }
  }, [
    dispatch,
    match.params.popupId,
    onSelectGoal,
    settings.element_subtype,
    settings.theme?.id,
  ]);

  const previewImages = {
    [constants.MODE_DESKTOP]: settings.site_preview_image,
    [constants.MODE_TABLET]: settings.site_preview_image_tablet,
    [constants.MODE_MOBILE]: settings.site_preview_image_mobile,
  };

  const setPalette = (event) => {
    const image = event.target;
    const dominantColor = formatRGB(COLOR_THIEF.getColor(image));
    const colorPalette = COLOR_THIEF.getPalette(image, 4).map((color) =>
      formatRGB(color)
    );
    const backgroundColor = oneColor(dominantColor).hex().replace('#', '');

    const white = 'ffffff';
    const black = '000000';
    let theme = {};
    if (brightness(dominantColor) < 0.5) {
      const bright = oneColor(dominantColor).hex().replace('#', '');

      theme = {
        backgroundColor: backgroundColor,
        text_color: white,
        button_color: white,
        link_color: bright,
        trigger_color: bright,
        trigger_icon_color: white,
      };
    } else {
      colorPalette.sort((a, b) => brightness(a) - brightness(b));

      const darkest =
        brightness(colorPalette[0]) >= 0.5
          ? black
          : oneColor(colorPalette[0]).hex().replace('#', '');

      theme = {
        backgroundColor: backgroundColor,
        text_color: darkest,
        button_color: darkest,
        link_color: white,
        trigger_color: darkest,
        trigger_icon_color: white,
      };
    }

    setAutodetectTheme({ dominantColor, colorPalette, theme });
  };

  const upgradeBackUrl = window.location.pathname.replace('/upgrade', '');

  return (
    <div className={className}>
      <Layout showSidebar={showSidebar}>
        {showSidebar && (
          <>
            <SideNav>
              <Logo src={logo} />
              <StepLinks goal={settings.subtype} type={settings.type} />
            </SideNav>
            <Step>
              <Switch>
                <Route path="/sites/:siteId/popups/:popupId/:anything/upgrade">
                  <UpgradeDialog returnTo={upgradeBackUrl} />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/goal">
                  <GoalStep onSelect={onSelectGoal} />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/type">
                  <TypeStep onSelect={onSelectType} />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/question">
                  <QuestionStep goal={settings.subtype} type={settings.type} />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/design">
                  <DesignStep />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/settings">
                  <SettingsStep site={site} />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/targeting">
                  <TargetingStep />
                </Route>
                <Route path="/sites/:siteId/popups/:popupId/conversion">
                  <ConversionStep goal={settings.subtype} />
                </Route>
                <Redirect
                  to={generatePath(
                    '/sites/:siteId/popups/:popupId/goal',
                    match.params
                  )}
                />
              </Switch>
            </Step>
          </>
        )}
        {showTheme && (
          <ThemeSelector
            mode={mode}
            showThemeSelector={showThemeSelector}
            setCanOverrideTheme={setCanOverrideTheme}
            setUpdateThemeContents={setUpdateThemeContents}
          />
        )}
        {!showTheme && (
          <PreviewArea
            settings={settings}
            mode={mode}
            setMode={setMode}
            updateSettings={updateSettings}
            hellobar={hellobar}
          />
        )}
        <ColorPickerImage
          alt="preview"
          crossOrigin="Anonymous"
          onLoad={setPalette}
          src={previewImages[mode]}
        />
        <SideBarPin
          className={`${!showSidebar && 'to-the-left'}`}
          onClick={(e) => setShowSidebar(!showSidebar)}
        >
          {showSidebar ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
        </SideBarPin>
      </Layout>
    </div>
  );
}

export default EditorLayout;
