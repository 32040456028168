export function checkUpgradeRules(site, settings) {
  const isFree = site?.plan?.free;
  const isFreeForever = site?.plan?.free_forever;

  if (isFreeForever || isFree) {
    if (!settings.show_branding) {
      return true;
    }

    if (settings.custom_rule) {
      return true;
    }

    if (settings.use_question) {
      return true;
    }

    if (settings.show_countdown_timer) {
      return true;
    }

    if (settings.edit_conversion_cta_text) {
      return true;
    }

    if (settings.image_opacity !== 100) {
      return true;
    }

    if (settings.image_overlay_opacity !== 0) {
      return true;
    }

    if (settings.gdpr_enabled) {
      return true;
    }

    if (settings.settings.after_email_submit_action !== 0) {
      return true;
    }
  }

  if (!isFree) {
    return false;
  }
}

export function resetUpgradeRules({ updateSettings, settings }) {
  const customSettings = { ...settings.settings, after_email_submit_action: 0 };

  updateSettings({
    show_branding: true,
    use_question: false,
    show_countdown_timer: false,
    edit_conversion_cta_text: false,
    image_opacity: 100,
    image_overlay_opacity: 0,
    gdpr_enabled: false,
    custom_rule: false,
    settings: customSettings,
  });
}
