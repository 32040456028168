import React, { useEffect } from 'react';
import Header from './components/Header';
import PartnerHeader from './components/PartnerHeader';
import PartnerSidebar from './components/PartnerSidebar';
import Sidebar from './components/Sidebar';
import SiteRouter from './SiteRouter';
import { SiteContext, UserContext } from './utils/contexts';
import { Elements, stripePromise } from './utils/stripe';
import DeactivationWarning from './components/DeactivationWarning';
import FreePlanDiscontinueWarning from './components/FreePlanDiscontinueWarning';
import PaymentWarning from './components/PaymentWarning';
import VmarketplaceProductNavBar from './components/Partners/VmarketplaceProductNavBar';
import { Route, Switch } from 'react-router-dom';
import EditorApp from './components/Editor';
import PlanSelection from './components/Onboarding/PlanSelection';

const PartnerLayout = ({ site, user }) => {
  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.show();
    }
  }, []);

  return (
    <div>
      {window.location.pathname === `/sites/${site.id}/select-plan` ? (
        <div className="App">
          <PlanSelection user={user} site={site} />
        </div>
      ) : (
        <div className="App">
          {site.partner === 'vmarketplace' && (
            <VmarketplaceProductNavBar site={site} />
          )}
          <PartnerHeader site={site} />
          <PartnerSidebar site={site}>
            <SiteRouter user={user} site={site} />
          </PartnerSidebar>
        </div>
      )}
    </div>
  );
};

const DefaultLayout = ({ site, user }) => {
  useEffect(() => {
    if (window.fcWidget) {
      window.fcWidget.show();
    }
  }, []);

  const disableSidebar = window.location.pathname.includes(
    `sites/${site.id}/upgrade/`
  );

  return (
    <div>
      {window.location.pathname === `/sites/${site.id}/select-plan` ? (
        <div className="App">
          <PlanSelection user={user} site={site} />
        </div>
      ) : (
        <div className="App">
          {/* <ExpeditionBar /> */}
          <Header site={site} isDisable={disableSidebar} />
          <Sidebar site={site} isDisable={disableSidebar}>
            <DeactivationWarning site={site} />
            <PaymentWarning site={site} />
            <FreePlanDiscontinueWarning site={site} />
            <SiteRouter user={user} site={site} />
          </Sidebar>
        </div>
      )}
    </div>
  );
};

const isPartnerLayout = (site) => {
  if (site.partner === 'tcoursebuilder' || site.partner === 'shopify') {
    return false;
  }

  return site.partner && site.partner_app_id;
};

const EditorAppWrapper = ({ history, match }) => {
  return <EditorApp history={history} match={match} />;
};

const Layout = ({ site, user }) => {
  const Component = isPartnerLayout(site) ? PartnerLayout : DefaultLayout;
  return (
    <Switch>
      <Route
        path="/sites/:siteId/popups/:popupId"
        component={EditorAppWrapper}
      />
      <Route>
        <Component site={site} user={user} />
      </Route>
    </Switch>
  );
};

export default function SitePage() {
  return (
    <UserContext.Consumer>
      {(user) => (
        <SiteContext.Consumer>
          {(site) => (
            <Elements stripe={stripePromise}>
              {site && <Layout user={user} site={site} />}
              {!site && (
                <div className="App">
                  <Header />
                  <Sidebar />
                </div>
              )}
            </Elements>
          )}
        </SiteContext.Consumer>
      )}
    </UserContext.Consumer>
  );
}
