import React from 'react';
import useAPI from '../../utils/api';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import Dialog from '@mui/material/Dialog/Dialog';
import onboardingPoster from '../../assets/images/onboarding-poster.png';
import '../../assets/styles/ServicePurchaseModal.scss';
import { InlineWidget } from 'react-calendly';
import { UserContext } from '../../utils/contexts';
import CloseIcon from '@mui/icons-material/Close';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Grid from '@mui/material/Grid';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function ServiceAddOnDialog(props) {
  const api = useAPI();
  const [step1, setStep1] = React.useState(props.isOpen || false);
  const [step2, setStep2] = React.useState(false);
  const [step3, setStep3] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  if (props.site.purchased_onboarding) {
    history.push('/sites/' + props.site.id);
  }

  const handleSubmit = async () => {
    setStep1(false);
    setLoading(true);
    const [ok, response] = await api.purchaseOnboarding(props.site);

    if (ok) {
      console.log('success');
    } else {
      console.log('failure');
      throw response;
    }
    setLoading(false);
    setStep2(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    event && event.preventDefault();
    setStep1(false);
    setStep2(false);
    setStep3(false);
    history.push(`/sites/${props.site.id}`);
  };

  const showThanks = (event) => {
    event.preventDefault();
    setStep2(false);
    setStep3(true);
  };

  return (
    <div>
      {loading && (
        <Grid style={{ textAlign: 'center' }}>
          <Loader
            height={10}
            visible={loading}
            type='ThreeDots'
            color='#e8562a'
          />
        </Grid>
      )}
      <UserContext.Consumer>
        {(user) => (
          <Dialog
            TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth={true}
            open={step1 || step2 || step3}
            onClose={handleClose}
            className='ServicePurchaseModal'
          >
            {step1 && (
              <div>
                <DialogContent>
                  <div className='title'>
                    WAIT! Special ONE TIME ONLY Offer...
                    <CloseIcon
                      className='cross'
                      onClick={handleClose}
                    ></CloseIcon>
                  </div>
                  <div className='poster'>
                    <img
                      src={onboardingPoster}
                      alt='Save Time. Leverage Our Experts.'
                    />
                  </div>
                  <span className='submit' onClick={handleSubmit}>
                    <AddShoppingCartIcon fontSize='large'></AddShoppingCartIcon>
                    YES!! Let's Set It Up!*
                  </span>
                </DialogContent>
                <div className='blurb'>
                  <span style={{ textAlign: 'left' }}>
                    Purchase will be made immediately on click.
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    By signing up you agree to Hello Bar Terms of Use & Privacy
                    Policy
                  </span>
                </div>
              </div>
            )}
            {step2 && (
              <div>
                <DialogContent>
                  <div className='title'>
                    Great! Our Experts Are Excited To Get You Up & Rolling...
                    <CloseIcon
                      className='cross'
                      onClick={handleClose}
                    ></CloseIcon>
                  </div>
                </DialogContent>
                <InlineWidget
                  url='https://calendly.com/komal-23/30min'
                  prefill={{
                    email: user.email,
                    name: user.name,
                  }}
                  styles={{
                    height: '550px',
                  }}
                  pageSettings={{
                    hideEventTypeDetails: true,
                  }}
                />
                <div class='no-thanks' onClick={showThanks}>
                  I'll schedule it later
                </div>
              </div>
            )}
            {step3 && (
              <div>
                <DialogContent>
                  <div className='title'>
                    Great! Our Experts Are Excited To Get You Up & Rolling...
                    <CloseIcon
                      className='cross'
                      onClick={handleClose}
                    ></CloseIcon>
                  </div>
                  <div className='thanks'>
                    <div className='bold'>
                      OK. We’ll contact you via email to set up your call with
                      our Onboarding expert. We’ll just ask you a few questions
                      & then quickly implement HelloBar on your website to turn
                      visitors into new leads.
                    </div>
                    <div>
                      If you don’t receive the scheduling email from us in the
                      next 24 hours please send us an email at
                      support@hellobar.com
                    </div>
                    <div className='bold'>
                      In the meantime, you can{' '}
                      <span
                        onClick={handleClose}
                        style={{
                          color: '#ff4200',
                          textDecoration: 'underline',
                        }}
                      >
                        close
                      </span>{' '}
                      this message and explore the pop-up dashboard.
                    </div>
                  </div>
                </DialogContent>
              </div>
            )}
          </Dialog>
        )}
      </UserContext.Consumer>
    </div>
  );
}
