import CollapsedPanel from "../components/CollapsedPanel";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {EndsAtBlock, Label, LabelSelector} from "../Steps/components";
import Switcher from "../components/Switcher";
import {EditorContext} from "../../../utils/contexts";
import NumberInput from "../components/NumberInput";
import InputBlock from "../components/InputBlock";
import ColorPicker from "../components/ColorPicker";
import FontPicker from "../components/FontPicker";
import SelectInput from "../components/SelectInput";
import { TextField, Typography } from "@mui/material";

const endsAtOptions = [
  {value: 'on-moment', label: 'on a given moment'},
  {value: 'after-session', label: 'after the visitor session starts'}
]

export default function CountdownPanel() {
  const {settings, updateSettings} = useContext(EditorContext)
  
  const [endsAt, setEndsAt] = useState();
  let endAtTime = (endsAt === 'after-session' && settings.after_session_time_ends_at) ? (settings.after_session_time_ends_at || '0,0,0').split(',') : '0,0,0'.split(',')

  useEffect(() => {
    setEndsAt((settings.timer_ends_at !== undefined && settings.timer_ends_at !== null) ? 'on-moment' : 'after-session')
  }, [])

  const toggleCountdownTimer = () => {
    updateSettings({ show_countdown_timer: !settings.show_countdown_timer })
  }

  const handleAfterSessionEndsAtTimer = (name, value, oldValue) => {
    switch (name) {
      case 'days':
        oldValue[0] = value;
        return updateSettings({ after_session_time_ends_at: oldValue.join(',') })
      case 'hours':
        oldValue[1] = value;
        return updateSettings({ after_session_time_ends_at: oldValue.join(',') })
      case 'minutes':
        oldValue[2] = value;
        return updateSettings({ after_session_time_ends_at: oldValue.join(',') })
      default:
        return updateSettings({ after_session_time_ends_at: oldValue.join(',') })
    }
  }

  const updateFont = (value) => {
    updateSettings({ timer_font_family: value })
  }

  const updateFontColor = (value) => {
    updateSettings({ timer_text_color: value })
  }

  const updateFontSize = (value) => {
    updateSettings({ timer_text_size: value })
  }

  const updateEndDate = (event) => {
    updateSettings({ timer_ends_at: event.target.value })
  }

  return (
    <CollapsedPanel open={false} title="Countdown Timer">
      <Label>
        <span>Show Default Countdown Timer</span>
        <Switcher checked={settings.show_countdown_timer} onChange={toggleCountdownTimer}/>
      </Label>
      <InputBlock label="Timer Ends At" />
      {(endsAt === 'after-session') && (
        <>
          <EndsAtBlock>
            <TextField
              value={Number(endAtTime[0] || 0)}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 30) {
                  handleAfterSessionEndsAtTimer('days', e.target.value, endAtTime)
                }
              }}
              InputProps={{ inputProps: { min: 0, max: 30 } }}
              size="small"
              type="number"
              sx={{mt: 0}}
            />
            <TextField
              value={Number(endAtTime[1] || 0)}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 23) {
                  handleAfterSessionEndsAtTimer('hours', e.target.value, endAtTime)
                }
              }}
              InputProps={{ inputProps: { min: 0, max: 23 } }}
              size="small"
              type="number"
              sx={{mt: 0}}
            />
            <TextField
              value={Number(endAtTime[2] || 0)}
              onChange={(e) => {
                if (e.target.value >= 0 && e.target.value <= 59) {
                  handleAfterSessionEndsAtTimer('minutes', e.target.value, endAtTime)
                }
              }}
              size="small"
              type="number"
              sx={{mt: 0}}
              InputProps={{ inputProps: { min: 0, max: 59 } }}
            />
          </EndsAtBlock>
          <EndsAtBlock>
            <Typography variant='subtitle1' sx={{fontSize: '12px'}}>
              Days
            </Typography>
            <Typography variant='subtitle1' sx={{fontSize: '12px'}}>
              Hours
            </Typography>
            <Typography variant='subtitle1' sx={{fontSize: '12px'}}>
              Minutes
            </Typography>
          </EndsAtBlock>
        </>
      )}
      <SelectInput
        items={endsAtOptions}
        value={endsAt}
        onChange={(value) => {
          (value === 'on-moment') ? updateSettings({ after_session_time_ends_at: null }) : updateSettings({ timer_ends_at: null })
          setEndsAt(value)
        }}
        size="small"
      />
      {(endsAt === 'on-moment') && (
        <LabelSelector>
          <TextField
            id="datetime-local"
            label="End date"
            type="datetime-local"
            value={(settings.timer_ends_at||"").slice(0, 16)}
            onChange={updateEndDate}
            InputLabelProps={{
              shrink: true
            }}
            size="small"
          />
        </LabelSelector>
      )}
      <Fragment>
        <InputBlock label="Font family">
          <FontPicker value={settings.timer_font_family} onChange={updateFont}/>
        </InputBlock>
        <InputBlock label="Font Color">
          <ColorPicker value={settings.timer_text_color} onChange={updateFontColor}/>
        </InputBlock>
        <InputBlock label="Font Size">
          <NumberInput value={settings.timer_text_size} onChange={updateFontSize} max={48}/>
        </InputBlock>
      </Fragment>
    </CollapsedPanel>
  )
}
