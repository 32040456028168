import React  from 'react'
import { Button, TextField } from '@mui/material'
import AuthCarousel from './components/AuthCarousel'

import '../../assets/styles/Authenticate.scss'
import { Link } from 'react-router-dom'

const SignUp = () => {

    return (<div className="signup-container">
        <div className="popup-inner">
        <div className="popup-form signup-form">
            <h4>Free Forever. No Credit Card Required.</h4>
            <Button color="blue" variant="contained" className="google-button">
                <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512"><path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
                </span>
                <span className="button-text">Continue With Google</span>
            </Button>
            <div className="strike signup-strike"><span>or</span></div>

            <TextField autoFocus={true} name="url" label="Your Website URL" type="url" variant="outlined"/>
            <br/>
            <TextField name="email" label="Email Address" type="email" variant="outlined"/>
            <br/>
            <TextField name="password" label="Password" type="password" variant="outlined"/>
            <br/>
            <span className="terms-policy">
                By signing up you agree to Hello Bar {" "}
                <a rel="noreferrer" target="_blank" href="https://www.hellobar.com/terms-of-use">Terms of Use</a>
                {" & "}
                <a rel="noreferrer" target="_blank" href="https://www.hellobar.com/privacy-policy">Privacy Policy</a>
            </span>
            <Button color="primary" variant="contained" className="margin-top">Create My Free Account</Button>
            <div className="links-container">
                Already have an account? {" "}
                <Link to="/users/sign_in" className="bold-link">Sign in</Link>
            </div>
        </div>
        <div className="popup-slider popup-slider-signup">
            <div className="inner-slider-container">
                <AuthCarousel/>
            </div>
        </div>
        </div>
    </div>
    )
}

export default SignUp
