import React, { useContext, useState } from 'react';
import { EditorBinding, EditorContext } from '../../../utils/contexts';
import CollapsedPanel from '../components/CollapsedPanel';
import InputBlock from '../components/InputBlock';
import SelectInput from '../components/SelectInput';
import Switcher from '../components/Switcher';
import { Label } from '../Steps/components';
import TextField from '@mui/material/TextField/TextField';

export default function NoThanksPanel() {
  const { settings, updateSettings } = useContext(EditorContext);
  const [action, setAction] = useState(
    settings.no_thanks_action ? 'redirect' : 'close'
  );

  const toggleNoThanks = () => {
    updateSettings({ show_no_thanks: !settings.show_no_thanks });
  };

  const options = [
    { value: 'close', label: 'Close the popup' },
    { value: 'redirect', label: 'Redirect to another URL' },
  ];

  const updateAction = (a) => {
    setAction(a);
    a === 'close' && updateSettings({ no_thanks_action: '' });
  };

  const editorBindings = useContext(EditorBinding);

  return (
    <CollapsedPanel
      open={editorBindings.showNoThanks}
      title="No Thanks Settings"
    >
      <Label>
        <span>Show No, Thanks</span>
        <Switcher checked={settings.show_no_thanks} onChange={toggleNoThanks} />
      </Label>
      {settings.show_no_thanks && (
        <InputBlock label="No Thanks Action">
          <SelectInput
            items={options}
            value={action}
            onChange={updateAction}
            size="small"
          />
        </InputBlock>
      )}
      {settings.show_no_thanks && action !== 'close' && (
        <TextField
          value={settings.no_thanks_action}
          multiline
          inputProps={{
            maxLength: 64,
          }}
          onChange={(e) => updateSettings({ no_thanks_action: e.target.value })}
          label="Redirect to"
          placeholder="https://google.com"
          size="small"
        />
      )}
    </CollapsedPanel>
  );
}
