import {FONT_WEIGHTS} from "../constants";
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl/FormControl";

const StyledFormControl = styled(FormControl)`
  width: 100%;
  
  & .MuiOutlinedInput-input {
    padding: 15.5px 14px;
  }
`

export default function FontWeight({value, onChange, family}) {
  const weights = Object.keys(FONT_WEIGHTS).map(value => ({
    label: FONT_WEIGHTS[value],
    value
  }))

  const fontStyle = (font) => {
    return {fontWeight: font.value, fontFamily: family}
  }

  return (
    <StyledFormControl variant="outlined">
      <TextField
        variant="outlined"
        select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        size="small"
      >
        {weights.map(item => <MenuItem key={item.value} value={item.value}><span
          style={fontStyle(item)}>{item.label}</span></MenuItem>)}
      </TextField>
    </StyledFormControl>
  )
}
