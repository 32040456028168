import React from 'react';
import Header from '../components/interstitial/Header';
import {
  DashLink,
  Container,
  OuterWrapper,
  CardWrapper,
  InterstitialCard,
  Footer,
} from '../components/interstitial/styled';
import HelpDialog from '../../../shared/HelpDialog';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { withTracking } from "../../../shared/Tracking";

const GoalSelection = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const onClick = (goal) => {
    history.push(`${match.url}/${goal}`);
  };

  return (
    <div>
      <Header text="CHOOSE GOAL" />
      <Container>
        <h5>Step 1 is to choose your goal</h5>
        <p>What do you want to do with this campaign?</p>
        <OuterWrapper>
          <CardWrapper>
            <InterstitialCard
              name="email"
              title="Grow email list"
              onClick={onClick}
            >
              I want to collect my visitors' email addresses and other answers.
            </InterstitialCard>
            <InterstitialCard
              name="traffic"
              title="Target a URL"
              onClick={onClick}
            >
              Drive traffic to a certain part of your site.
            </InterstitialCard>
            <InterstitialCard
              name="social"
              title="Social traffic"
              onClick={onClick}
            >
              Point visitors to your social media channels.
            </InterstitialCard>
          </CardWrapper>
          <CardWrapper>
            <InterstitialCard
              name="announcement"
              title="Announcement"
              onClick={onClick}
            >
              No buttons, just text.
            </InterstitialCard>
            <InterstitialCard
              name="call"
              title="Get phone calls"
              onClick={onClick}
            >
              Allow visitors to call you in one click.
            </InterstitialCard>
            {/* <InterstitialCard name="webform" title="Web form" onClick={onClick}>
              I want to collect my visitors' answers.
            </InterstitialCard> */}
          </CardWrapper>
        </OuterWrapper>
        <Footer>
          <HelpDialog isOpen={false} showIcon={true} />
          <DashLink href={`/sites/${match.params.siteId}/summary`}>
            I’ll do this later, take me to my dashboard
          </DashLink>
        </Footer>
      </Container>
    </div>
  );
};

export default withTracking(GoalSelection);
