import React, { useContext } from "react";
import { icons } from "./icons";
import { SearchContactListContext } from '../../utils/contexts'
import SiteLink from "../../shared/SiteLink";
import ContactHeader from "./ContactHeader";

export default function ContactLists({ contactLists }) {
  const searchContactListData = useContext(SearchContactListContext)

  const filterContactLists = (contacts, query) => {
    return contacts.filter(contactList => {if (
      contactList.name.toLowerCase().includes(query.toLowerCase()) ||
      contactList?.provider?.toLowerCase()?.includes(query.toLowerCase())
    ){
      return contactList
    }})
  }

  const contacts = searchContactListData.query !== '' ? filterContactLists(contactLists, searchContactListData.query) : contactLists

  return (
    <React.Fragment>
      <table>
        <ContactHeader />
        <tbody>
          {contacts.map((contactList) => {
            return (
              <tr key={contactList.id}>
                <td>
                  <SiteLink to={`/contacts/${contactList.id}`}>
                    {contactList.name}
                  </SiteLink>
                </td>
                <td>
                  <span>{contactList.provider_name}</span>
                  <img
                    src={icons[contactList.provider]}
                    alt={contactList.provider_name}
                  />
                </td>
                <td>{contactList.contacts_count}</td>
                <td>{contactList.created}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </React.Fragment>
  )
}
