import React from "react";
import MultiSelectInput from "../MultiSelectInput";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function DayOfWeekCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "is", "label": "is" }
    ];

  const days = [
    { "value": "0", "label": "Sunday" },
    { "value": "1", "label": "Monday" },
    { "value": "2", "label": "Tuesday" },
    { "value": "3", "label": "Wednesday" },
    { "value": "4", "label": "Thursday" },
    { "value": "5", "label": "Friday" },
    { "value": "6", "label": "Saturday" }
  ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  if (condition.value && condition.value[0] === '') {
    onChange({ target: { name: 'value', value: [] }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <MultiSelectInput value={condition.value} items={days} onChange={onChange} defaultContent="Select weekdays"/>
    </div>
  );
}
