import TagManager from 'react-gtm-module'
import { debounce } from "./debounce";

const segmentio = {
  page: (name) => {
    window.analytics.page(name);
  },
  identity: (segmentUser) => {
    window.analytics.identify(segmentUser.user_id, segmentUser);
  },
  track: debounce((event, data) => {
    window.analytics.track(event, data);
  }, 300)
};

const freshchat = {
  init: (user, site) => {
    if (!window.fcWidget) return;

    if(!window.fcWidget.isInitialized()) {
      window.fcWidget.init({
        config: {
          headerProperty: {
            hideChatButton: true,
          },
        },
        token: process.env.REACT_APP_FRESH_TOKEN,
        host: "https://wchat.freshchat.com",
        email: user.email || 'N/A',
        externalId: user.id
      });
    }

    window.fcWidget.user?.setProperties({
      dashboard: 'true',
      externalId: user.id,
      SiteURL: site.url || 'N/A',
      script_installed: site.installed || 'N/A'
    });
  },
  open: (props) => {
    segmentio.track('Chat Opened');
    window.fcWidget?.open(props);
  }
};

// docs - https://www.npmjs.com/package/react-gtm-module#environments
const gtm = {
  init: () => {
    const id = process.env.REACT_APP_GTM_ID;
    if (id) {
      TagManager.initialize({
        gtmId: id,
        auth: process.env.REACT_APP_GTM_AUTH,
        preview: process.env.REACT_APP_GTM_PREVIEW,
        dataLayer: {
          js: new Date(),
          config: id,
          dashboard: 'true'
        }
      });
    }
  },
  dataLayer: (props = {}) => {
    TagManager.dataLayer(props);
  },
  event: ({ name, label }) => {
    TagManager.dataLayer({
      dataLayer: {
        event: name,
        event_category: name,
        non_interaction: true,
        event_label: label || ''
      }
    });
  }
};

export const tracking = {
  freshchat,
  gtm,
  segmentio,
  initialize: (user, site) => {
    gtm.init();
  },
  page: (name = null) => {
    segmentio.page(name);
  }
};
