import styled from 'styled-components';
import { useEffect, useReducer, useState, useCallback } from 'react';

const Controls = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  width: 49px;
  margin: 0 0 0 5px;
  display: flex;
  flex-direction: column;
`;

const ControlUp = styled(({ className, onMouseUp, onMouseDown }) => {
  return (
    <div className={className} onMouseUp={onMouseUp} onMouseDown={onMouseDown}>
      <svg
        id='ember2839'
        data-prefix='fas'
        data-icon='caret-up'
        aria-hidden='true'
        role='img'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 320 512'
        className='ember-view svg-inline--fa fa-caret-up fa-w-10 fa-sm '
      >
        <path
          fill='currentColor'
          d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'
        />
      </svg>
    </div>
  );
})`
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0 0;
  color: #e0e0e0;
  text-align: center;
  cursor: pointer;
  height: 50%;
  :hover {
    background-color: #27ab9e;
    color: white;
  }
  svg {
    width: 0.625em;
  }
`;

const ControlDown = styled(({ className, onMouseUp, onMouseDown }) => {
  return (
    <div className={className} onMouseUp={onMouseUp} onMouseDown={onMouseDown}>
      <svg
        id='ember2840'
        data-prefix='fas'
        data-icon='caret-down'
        aria-hidden='true'
        role='img'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 320 512'
        className='ember-view svg-inline--fa fa-caret-down fa-w-10 fa-sm '
      >
        <path
          fill='currentColor'
          d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'
        />
      </svg>
    </div>
  );
})`
  border-radius: 0 0 5px 5px;
  color: #e0e0e0;
  text-align: center;
  cursor: pointer;
  height: 50%;
  :hover {
    background-color: #27ab9e;
    color: white;
  }
  svg {
    width: 0.625em;
  }
`;

function reducer(state, action) {
  switch (action.type) {
    case 'increment':
      if (state.number >= state.max) {
        return { ...state, number: state.max };
      } else {
        return { ...state, number: state.number + state.step };
      }
    case 'decrement':
      if (state.number <= state.min) {
        return { ...state, number: state.min };
      } else {
        return { ...state, number: state.number - state.step };
      }
    case 'reset':
      return { ...state, number: Number(action.value) };
    default:
      throw new Error();
  }
}

function NumberInput({
  className,
  value,
  onChange,
  type = 'px',
  max,
  min = 0,
  step = 1
}) {
  const [state, dispatch] = useReducer(reducer, {
    number: Number(value),
    min: Number(min),
    max: Number(max),
    step: Number(step)
  });

  const [interval, setIntervalId] = useState(null);
  const [timeout, setTimeoutId] = useState(null);

  const resetTimers = useCallback(() => {
    if (interval) {
      clearInterval(interval);
      setIntervalId(null);
    }

    if (timeout) {
      clearTimeout(timeout);
      setTimeoutId(null);
    }
  }, [interval, timeout]);

  const formatValue = (value) => {
    if (Number.isNaN(value)) {
      return '';
    }
    return `${value}${type}`;
  };

  const repeatAction = (action) => {
    const intervalId = setInterval(() => {
      action();
    }, 50);
    setIntervalId(intervalId);
  };

  const delayAction = (action) => {
    const timeoutId = setTimeout(() => {
      repeatAction(action);
    }, 200);
    setTimeoutId(timeoutId);
  };

  const increase = () => {
    dispatch({ type: 'increment' });
    delayAction(() => dispatch({ type: 'increment' }));
  };

  const decrease = () => {
    dispatch({ type: 'decrement' });
    delayAction(() => dispatch({ type: 'decrement' }));
  };

  useEffect(() => {
    onChange(state.number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.number]);

  useEffect(() => {
    dispatch({ type: 'reset', value: value });
  }, [value]);

  return (
    <div className={className}>
      <input value={formatValue(state.number)} />
      <Controls>
        <ControlUp onMouseDown={increase} onMouseUp={resetTimers} />
        <ControlDown onMouseDown={decrease} onMouseUp={resetTimers} />
      </Controls>
    </div>
  );
}

export default styled(NumberInput)`
  display: flex;
  height: 36px;

  input {
    width: 56px;
    border: 1px solid #e2e2e2;
    font-size: 0.75em;
    padding: 1.15em 0.67em;
    border-radius: 5px;
    color: #9e9e9e;
    background-color: #f6f6f6;
  }
`;
