import styled from 'styled-components';
import { useContext, useState, useMemo } from 'react';
import { EditorContext } from '../../utils/contexts';
import { Section, Container, Icon } from './ThemeSelector/components';
import Theme from './ThemeSelector/Theme';
import { availableThemes } from './ThemeSelector/updateTheme';
import { Autocomplete, Chip, TextField } from '@mui/material';

function ThemeSelector({
  className,
  showThemeSelector,
  setCanOverrideTheme,
  setUpdateThemeContents,
  mode,
}) {
  const { settings, siteSettings, updateTheme } = useContext(EditorContext);
  const themes = availableThemes(siteSettings, settings.type).filter(
    ({ id }) => id !== 'autodetect'
  );
  const tagOptions = [
    ...new Set(
      themes
        .map((theme) => {
          return theme.tags;
        })
        .flat()
    ),
  ];
  const [tag, setTag] = useState([]);

  const displayThemes = useMemo(
    () =>
      tag.length === 0
        ? themes
        : themes.filter((theme) =>
            tag.some((tag) => theme.tags.includes(tag.toLowerCase()))
          ),
    [settings.type, tag]
  );

  const chooseTheme = (theme) => {
    updateTheme(theme.id);
    showThemeSelector(false);
    siteSettings.site_theme && setCanOverrideTheme(true);
    setUpdateThemeContents(true);
  };

  return (
    <div className={className}>
      <Container>
        <Icon onClick={() => showThemeSelector(false)}>
          <i className="icon-close" />
        </Icon>

        {/* <Section>
          <h3>Color autodetection <span>Colors grabbed from the site</span></h3>
        </Section>

        <Section>
          <AutodetectButton onClick={() => chooseTheme({id: 'autodetect'})}>
            <img alt="Autodetection" src={AutodetectionImg}/>
            <span>Autodetect colors from site</span>
          </AutodetectButton>
        </Section> */}

        <Section>
          <h3>{settings.type} Templates </h3>
        </Section>

        <Section>
          <Autocomplete
            multiple
            options={tagOptions || []}
            getOptionLabel={(option) => option.toUpperCase()}
            filterSelectedOptions
            value={tag}
            renderTags={(value, getThemeProps) =>
              value.map((option, index) => (
                <Chip
                  {...getThemeProps({ index })}
                  variant="outlined"
                  label={option.toUpperCase()}
                  sx={{
                    fontSize: '12px',
                    backgroundColor: 'antiquewhite',
                  }}
                />
              ))
            }
            onChange={(event, newValue) => {
              setTag(newValue);
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Filter templates"
                  placeholder="Select a category"
                  margin="normal"
                  fullWidth
                />
              );
            }}
          />
        </Section>

        <Section>
          {displayThemes.length > 0 ? (
            displayThemes.map((theme) => (
              <Theme
                key={theme.id}
                type={settings.type}
                theme={theme}
                onClick={chooseTheme}
              />
            ))
          ) : (
            <h6>No Theme Found</h6>
          )}
        </Section>
      </Container>
    </div>
  );
}

export default styled(ThemeSelector)`
  height: 100vh;
  background: #f1f0f0;
  position: relative;
`;
