import React from 'react';
import '../assets/styles/Header.scss';
import logo from '../assets/images/hellobar-logo-white.png';

function PartnerHeader({ site }) {
  return (
    <div className="header">
      <div className="header-logo">
        <a href="/"><img src={ logo } alt="logo"/></a>
      </div>
      { site && <div className="header-site-wrapper">
        <div className="site-flex dropdown-wrapper">
          <div className="site-label">
            { site.plan && <span className={ `pill plan ${ site.plan.label }` }>{ site.plan.name } plan</span> }
          </div>
          <span className="site-name">{ site.url }</span>
        </div>
      </div> }
    </div>
  )
}

export default PartnerHeader;
