import CollapsedPanel from "../components/CollapsedPanel";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";
import styled from 'styled-components'

const ThemeName = styled.div`
  font-size: 1.1em;
  font-weight: bold;
`

const Hint = styled.div`
  margin: 0 0 15px 0;
  font-size: 0.9em;
`

const ThemeButton = styled.div`
  background: #27ab9e;
  padding: 12px 22px;
  border-radius: 3px;
  color: white;
  text-align: center;
  cursor: pointer;
`

export default function ThemePanel() {
  const {settings, siteSettings, showThemeSelector} = useContext(EditorContext)

  const themes = siteSettings?.available_themes || []

  const theme = themes.find(t => t.id === settings.theme_id)

  const onButtonClick = () => {
    showThemeSelector(true)
  }

  return (
    <CollapsedPanel open={true} title="Theme">
      <ThemeName>{theme?.name}</ThemeName>
      <Hint>Check out our other templates by clicking the button below.</Hint>
      <ThemeButton onClick={onButtonClick}>Change template</ThemeButton>
    </CollapsedPanel>
  )
}
