import { TextField } from '@mui/material';
import React, { useContext } from 'react';
import { EditorContext } from '../../../utils/contexts';

export default function ShareOnFacebook() {
  const { settings, updateSettings } = useContext(EditorContext);

  const updateURL = (event) => {
    const customSettings = {
      ...settings.settings,
      url_to_share: event.target.value,
    };
    updateSettings({ settings: customSettings });
  };

  return (
    <>
      <TextField
        fullWidth
        variant='outlined'
        label='Facebook Page URL'
        value={settings.settings.url_to_share}
        onChange={updateURL}
        placeholder='https://www.facebook.com/hellobar'
        size='small'
      />
    </>
  );
}