import Gravatar from "react-gravatar";
import React from "react";

function Avatar(props) {
  const user = props.user;
  const size = props.size || 50;

  return (
    <Gravatar size={size} className="user-avatar" email={user.email}/>
  );
}

export default Avatar;
