import React, { useContext, useState } from 'react';
import { TextField } from '@mui/material';
import Button from '../../../shared/Button';
import { AuthContext } from '../../../utils/contexts';
import { NavLink, useHistory, Redirect } from 'react-router-dom';
import { openSignInWindow } from '../../Modals/ConnectPopup';
import GoogleOneTapLogin from 'react-google-one-tap-login';
import { auth } from '../../../utils/auth';

const LoginEmail = () => {
  const { authForm, setAuthForm } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  if (auth.isAuthenticated()) {
    const token = auth.getToken();
    return <Redirect to={token.site_id ? '/sites/' + token.site_id : '/'} />;
  }

  const handleLogin = (event) => {
    event.preventDefault();

    setLoading(true);

    const form = new FormData();
    form.append('user[email]', authForm.email);
    fetch(`${process.env.REACT_APP_AUTH_HOST}/users/find_email`, {
      method: 'POST',
      body: form,
      credentials: 'include',
    })
      .then((response) => {
        if (response.redirected) {
          handleGoogle();
        } else {
          history.push('/users/find_email/');
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const handleEmailChange = (event) => {
    setAuthForm({ email: event.target.value, password: '' });
  };

  const handleGoogle = () => {
    openSignInWindow(
      `${process.env.REACT_APP_AUTH_HOST}/auth/google_oauth2`,
      'oauth'
    )
      .then((response) => {
        setLoading(false);
        if (response?.token) {
          history.push(`/login/jwt/${response.token}`);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleGoogleOneTapLogin = async (data) => {
    setLoading(true);

    const form = new FormData();
    form.append('user[data]', JSON.stringify(data));
    form.append('user[auth_provider]', 'google_onetap');
    await fetch(`${process.env.REACT_APP_AUTH_HOST}/users/google_onetap`, {
      method: 'POST',
      body: form,
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          history.push('/users/find_email/');
        }
      })
      .catch((error) => {
        console.log('error', error);
      })
      .then((response) => {
        if (response?.token) {
          history.push(`/login/jwt/${response.token}`);
        } else {
          history.push('/users/find_email/');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="popup-form">
      <GoogleOneTapLogin
        onError={(error) => console.log(error)}
        onSuccess={(response) => handleGoogleOneTapLogin(response)}
        googleAccountConfigs={{ client_id: process.env.REACT_APP_OAUTH_CLIENT }}
      />
      <form onSubmit={handleLogin}>
        <h3>
          Log In <br />
          to your account
        </h3>
        <TextField
          autoFocus={true}
          name="email"
          label="Your Email"
          type="email"
          variant="outlined"
          onChange={handleEmailChange}
          required
        />
        <Button
          disabled={authForm.email === ''}
          color="primary"
          variant="contained"
          type="submit"
          loading={loading}
        >
          Continue
        </Button>
        <div className="strike">
          <span>OR</span>
        </div>
        <Button
          color="blue"
          variant="contained"
          className="google-button"
          onClick={handleGoogle}
        >
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
            </svg>
          </span>
          <span className="button-text">Login With Google</span>
        </Button>
        <div className="links-container">
          <NavLink to="/users/password/new">Forgot your password?</NavLink>
          <br />
          <a href={`${process.env.REACT_APP_AUTH_HOST}/users/sign_up`}>
            Sign up for a New Hello Bar Account
          </a>
        </div>
      </form>
    </div>
  );
};

export default LoginEmail;
