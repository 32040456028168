import GoalIcon from '../../assets/images/goal-icon';
import TypeIcon from '../../assets/images/type-icon';
import DesignIcon from '../../assets/images/design-icon';
import SettingsIcon from '../../assets/images/settings-icon';
import TargetingIcon from '../../assets/images/targeting-icon';
import ConversionIcon from '../../assets/images/conversion-icon';
import QuestionIcon from '../../assets/images/question-icon';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SUBTYPES, TYPES } from './constants';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Pagination,
  Nav,
  DotNav,
} from './components/navigation/PageNavigation';

const StepLinkProgress = styled.div`
  .done &,
  .active & {
    background-color: #27ab9e;
  }
  width: 5px;
  height: calc(100% + 1px);
  position: absolute;
  top: -1px;
  transition: all 0.3s ease;
`;

const StepLinkIcon = styled.div`
  .active & {
    fill: #636363;
  }
  fill: #aeaeae;
`;

const StepLinkCaption = styled.div`
  .active & {
    color: #636363;
  }
  color: #aeaeae;
  display: block;
  font-size: 0.9em;
  line-height: 30px;
`;

const stepLink = ({ className, caption, icon, active, done, onClick }) => {
  const classes = [className, active && 'active', done && 'done'];
  const icons = {
    goal: GoalIcon,
    type: TypeIcon,
    design: DesignIcon,
    settings: SettingsIcon,
    targeting: TargetingIcon,
    conversion: ConversionIcon,
    question: QuestionIcon,
  };
  const Icon = icons[icon];

  if (!Icon) {
    throw new Error(`unrecognized icon: ${icon}`);
  }

  return (
    <li className={classes.join(' ')} onClick={onClick}>
      <StepLinkProgress />
      <StepLinkIcon>
        <Icon />
      </StepLinkIcon>
      <StepLinkCaption>{caption}</StepLinkCaption>
    </li>
  );
};

const StepLink = styled(stepLink)`
  padding: 15px 0 6px 0;
  text-align: center;
  position: relative;
  border: 1px solid #e0e0e0;
  border-right: none;
  cursor: pointer;
  user-select: none;
  & + & {
    margin-top: -1px;
  }
  &.active {
    background-color: white;
    border-right: 1px solid white;
    margin-right: -1px;
  }
`;

const StepLinks = ({ className, goal, type }) => {
  const history = useHistory();
  const match = useRouteMatch('/sites/:siteId/popups/:popupId/:type');
  const currentType = match?.params?.type;
  const siteId = match?.params?.siteId;
  const popupId = match?.params?.popupId || 'new';

  const [active, setActive] = useState(match?.params?.type || 'goal');

  const showConversion = [
    SUBTYPES.EMAIL,
    SUBTYPES.WEBFORM,
    SUBTYPES.RETIREMENT,
    SUBTYPES.LEADFORM,
    SUBTYPES.CALL,
    SUBTYPES.TRAFFIC,
    SUBTYPES.SOCIAL,
  ].includes(goal);

  const showQuestion =
    [SUBTYPES.EMAIL, SUBTYPES.TRAFFIC, SUBTYPES.CALL].includes(goal) &&
    [TYPES.BAR, TYPES.MODAL, TYPES.SLIDER, TYPES.TAKEOVER].includes(type);

  const steps = useMemo(
    () => [
      { id: 'goal', caption: 'Goal', done: false },
      { id: 'type', caption: 'Layout', done: false },
      { id: 'targeting', caption: 'Targeting', done: false },
      ...(showQuestion
        ? [{ id: 'question', caption: 'Yes/No', done: false }]
        : []),
      { id: 'design', caption: 'Design', done: false },
      { id: 'settings', caption: 'Settings', done: false },
      ...(showConversion
        ? [{ id: 'conversion', caption: 'Conversion', done: false }]
        : []),
    ],
    [showConversion, showQuestion]
  );

  const [links, setLinks] = useState(steps);

  const setDone = useCallback(
    (linkId) => {
      let done = true;
      links.forEach((link) => {
        link.done = done;
        if (link.id === linkId) {
          done = false;
        }
      });
    },
    [links]
  );

  useEffect(() => {
    setLinks(steps);
  }, [goal, steps]);

  useEffect(() => {
    if (active !== currentType) {
      setActive(currentType);
      setDone(currentType);
    }
  }, [currentType, active, setDone]);

  const onClick = (linkId) => () => {
    setActive(linkId);
    setDone(linkId);
    setLinks([...links]);
    history.push(`/sites/${siteId}/popups/${popupId}/${linkId}`);
  };

  const navButtonClick = (isNext) => {
    const currentStepIndex = links.findIndex((link) => link.id === active);
    const nextIndex = isNext ? currentStepIndex + 1 : currentStepIndex - 1;
    onClick(links[nextIndex].id)();
  };

  setDone(match?.params?.type);

  return (
    <>
      <ul className={className}>
        {links.map((link) => (
          <StepLink
            active={active === link.id}
            done={link.done}
            key={link.id}
            icon={link.id}
            caption={link.caption}
            onClick={onClick(link.id)}
          />
        ))}
      </ul>
      <Pagination>
        <Nav>
          <div
            className={`button ${active === links[0].id ? 'disable' : ''}`}
            onClick={(e) => navButtonClick(false)}
          >
            <div className="icon-angle-left">
              <ArrowBackIosIcon />
            </div>
            Prev
          </div>
          {links.map((link) => (
            <DotNav
              key={link.id}
              active={active === link.id}
              onClick={onClick(link.id)}
            />
          ))}
          <div
            className={`button ${
              active === links[links.length - 1].id ? 'disable' : ''
            }`}
            onClick={(e) => navButtonClick(true)}
          >
            Next
            <div className="icon-angle-right">
              <ArrowForwardIosIcon />
            </div>
          </div>
        </Nav>
      </Pagination>
    </>
  );
};

export default styled(StepLinks)`
  max-width: 90px;
  height: 83vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
