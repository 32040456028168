import { useState } from 'react';
import {
  Button,
  ButtonOverlay,
  ThemeName,
  ThemeTileWrapper,
  Thumbnail,
} from './components';

const images = require.context('../../../assets/images/themes/tiles', true);

export default function Theme({ type, theme, onClick }) {
  const [visible, setVisible] = useState(false);
  const imgSrc = images(`./${type.toLowerCase()}/${theme.id}.png`).default;

  return (
    <ThemeTileWrapper
      className={type}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="theme-tile">
        <Thumbnail className="thumbnail" alt="Theme thumbnail" src={imgSrc} />
        <ThemeName className={type} style={{maxWidth: "30%"}}>{theme.name}</ThemeName>
        <ButtonOverlay visible={visible}>
          <Button onClick={() => onClick(theme)}>Select</Button>
        </ButtonOverlay>
        <div className='tag-tiles'>
          {theme?.tags.length > 0 && theme.tags.map(tag => 
            <span>{tag}</span>
          )}
        </div>
      </div>
    </ThemeTileWrapper>
  );
}
