import Loader from "react-loader-spinner";
import React from "react";

export default function TextWithLoader ({ loading, color, children }) {
  if (loading) {
    return <Loader height={10} visible={loading} type="ThreeDots" color={color || 'white'}/>
  } else {
    return children
  }
}
