import React from "react";

function buildButtons({ paypalContext, onApprove }) {
  return paypalContext.paypal
    .Buttons({
      style: {
        size: 'small',
        height: 36,
        color: 'silver',
        shape: 'rect',
        label: 'pay',
        tagline: false
      },
      fundingSource: paypalContext.paypal.FUNDING.PAYPAL,
      createBillingAgreement: () => {
        return paypalContext.checkout.createPayment({
          flow: 'vault',
          enableShippingAddress: false,
          shippingAddressEditable: false,
          displayName: 'Hello Bar'
        })
      },
      onApprove: function (data, _actions) {
        return paypalContext.checkout.tokenizePayment(data, function (err, payload) {
          onApprove(err, payload);
        });
      },
      onCancel: function (data) {
        console.log('PayPal payment canceled', JSON.stringify(data, 0, 2));
      },
      onError: function (err) {
        console.error('PayPal error', err);
      }
    })
}

export default class PayPalButton extends React.Component {
  constructor(props) {
    super(props);
    this.paypalButtons = buildButtons(props);
  }

  componentDidMount() {
    this.paypalButtons.render('#paypal-button');
  }

  render() {
    return (<div id="paypal-button"/>);
  }
}
