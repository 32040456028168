import React, {useContext} from "react";
import {EditorContext, EditorBinding} from "../../../utils/contexts";
import CollapsedPanel from "../components/CollapsedPanel";
import {findTheme} from "../ThemeSelector/updateTheme";
import ContentEditorPanel from "../components/ContentEditorPanel";

export default function HeadlinePanel() {
  const {settings, siteSettings, updateSettings} = useContext(EditorContext)
  const theme = findTheme(settings, siteSettings)
  const defaultHeadline = (theme && theme.defaults[settings.type]?.headline) || ""

  const onUpdate = (content, css) => {
    const update = { headline: content }
    if (css?.fontFamily) {
      update.font = css.fontFamily
    }
    updateSettings(update)
  }

  return (
    <EditorBinding.Consumer>
      {(settings)=> <CollapsedPanel open={settings? settings.showHeading : false} title="Headline">
        <ContentEditorPanel contentKey="headline" defaultContent={defaultHeadline} onUpdate={onUpdate}/>
      </CollapsedPanel>}
    </EditorBinding.Consumer>
  )
}
