import CollapsedPanel from "../components/CollapsedPanel";
import React from "react";
import {placements} from "../components/placements";
import CommonStylingPanel from "./CommonStylingPanel";

export default function SliderStylingPanel() {
  return (
    <CollapsedPanel open={false} title="Slider Styling">
      <CommonStylingPanel placements={placements.Slider}/>
    </CollapsedPanel>
  )
}
