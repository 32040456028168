const DAY = 24 * 60 * 60 * 1000

export const Cookie = {
  set: function(name, value, expires = 7) {
    const expiresAfter = expires * DAY

    const date = new Date();
    date.setTime(date.getTime() + expiresAfter);

    const cookie = `${name}=${value}; path=/; expires=${date.toGMTString()}; SameSite=None; Secure`
    document.cookie = cookie;
  },

  getAll: function() {
    let cookie = {};
    document.cookie.split(';').forEach( el => {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    })
    return cookie;
  },

  get: function(name) {
    if (name === '_hellobar_db_token' && sessionStorage.getItem('token')) {
      this.set('_hellobar_db_token', sessionStorage.getItem('token'))
      this.set('_hellobar_db_token_data', sessionStorage.getItem('tokenData'))
      this.set('_hellobar_db_signed_in', sessionStorage.getItem('signed_in'))
      return sessionStorage.getItem('token')
    }
		const cookies = this.getAll()
    return cookies[name];
  },

  delete: function(name) {
    this.set(name, '', -1);
  }
};
