import React, { useContext } from 'react';
import { EditorBinding, EditorContext } from '../../../utils/contexts';
import CollapsedPanel from '../components/CollapsedPanel';
import Switcher from '../components/Switcher';
import { Label } from '../Steps/components';

export default function Subheadline2Panel() {
  const { settings, updateSettings } = useContext(EditorContext);

  const toggleHide = () => {
    updateSettings({ show_optional_content: !settings.show_optional_content });
  };

  const editorBindings = useContext(EditorBinding);

  return (
    <CollapsedPanel
      open={editorBindings.showContent}
      title="Subheadline 2"
      allowed={true}
    >
      <Label>
        <span>Show</span>
        <Switcher
          checked={settings.show_optional_content}
          onChange={toggleHide}
        />
      </Label>
    </CollapsedPanel>
  );
}
