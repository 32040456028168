import Grid from "@mui/material/Grid/Grid";
import React from "react";

export default function AppGrid(props) {
  return (
    <Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={props.justify || "center"} {...props}/>
      </Grid>
    </Grid>
  );
}
