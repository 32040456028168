import React from 'react'

export default function SettingsIcon() {
  return (
    <svg width="35px" height="30px" viewBox="0 0 39 34" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Hello-Bar-New-Creation-Flow" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="4---Settings" transform="translate(-27.000000, -392.000000)">
          <g id="menu-items" transform="translate(0.000000, 82.000000)">
            <g id="Behavior" transform="translate(21.000000, 310.000000)">
              <g id="Page-1" transform="translate(6.000000, 0.000000)">
                <path d="M2.47719452,5.30742275e-05 L2.47719452,7.56525346 L-0.000106849315,7.56525346 L-0.000106849315,17.4243219 L2.47719452,17.4243219 L2.47719452,33.7844526 L6.31361918,33.7844526 L6.31361918,17.4243219 L8.71879726,17.4243219 L8.71879726,7.56525346 L6.31361918,7.56525346 L6.31361918,5.30742275e-05 L2.47719452,5.30742275e-05 Z M1.31627671,16.116573 L7.4024137,16.116573 L7.4024137,8.87300242 L1.31627671,8.87300242 L1.31627671,16.116573 Z" id="Fill-1"/>
                <path d="M12.4018397,5.30742275e-05 L12.4018397,19.4846635 L9.87859315,19.4846635 L9.87859315,29.343732 L12.4018397,29.343732 L12.4018397,33.7844526 L16.2377301,33.7844526 L16.2377301,29.343732 L18.5964288,29.343732 L18.5964288,19.4846635 L16.2377301,19.4846635 L16.2377301,5.30742275e-05 L12.4018397,5.30742275e-05 Z M11.1939082,28.0365137 L17.2800452,28.0365137 L17.2800452,20.7929432 L11.1939082,20.7929432 L11.1939082,28.0365137 Z" id="Fill-2"/>
                <path d="M22.2716712,5.30742275e-05 L22.2716712,3.95567525 L19.7986438,3.95567525 L19.7986438,13.814213 L22.2716712,13.814213 L22.2716712,33.7844526 L26.1080959,33.7844526 L26.1080959,13.814213 L28.5175479,13.814213 L28.5175479,3.95567525 L26.1080959,3.95567525 L26.1080959,5.30742275e-05 L22.2716712,5.30742275e-05 Z M21.1155616,12.5069948 L27.2006301,12.5069948 L27.2006301,5.26342421 L21.1155616,5.26342421 L21.1155616,12.5069948 Z" id="Fill-3"/>
                <path d="M32.1424644,5.30742275e-05 L32.1424644,15.5751279 L29.7538479,15.5751279 L29.7538479,25.4348126 L32.1424644,25.4348126 L32.1424644,33.7865643 L35.978889,33.7865643 L35.978889,25.4348126 L38.4722178,25.4348126 L38.4722178,15.5751279 L35.978889,15.5751279 L35.978889,5.30742275e-05 L32.1424644,5.30742275e-05 Z M31.0696973,24.1275127 L37.1558342,24.1275127 L37.1558342,16.8834894 L31.0696973,16.8834894 L31.0696973,24.1275127 Z" id="Fill-4"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
