import paypal from '../../../assets/icons/paypal.png';
import React from "react";
import Box from "@mui/material/Box";

export default function PayPalAccount ({ email }) {
  return (
    <div className="PayPalAccount">
      <Box fontWeight="600" fontSize={16} color="text.primary">
        <img src={paypal} alt="PayPal"/>
        {email}
      </Box>
    </div>
  );
}
