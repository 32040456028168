export const CONDITION_TOOLTIPS = {
  DeviceCondition:
    'This condition would check if a user is using Desktop/Mobile/Tablet device to visit your website',
  EveryXSession:
    "This condition would check for a specific session repitition. Example, the popup should fire on every 5th session of the visitor. The popup would trigger when it's 5th, 10th, 15th etc session",
  LastVisitCondition:
    'This condition would check when did the user visit your website for the last time',
  NumberOfVisitsCondition:
    'This number would check how many times the visitor has visited your website, this would allow you to target new, recurring and frequent visitors',
  PreviousPageURL:
    'This condition checks for the URL of the referring page. If you landed on the page https://example.com/one from https://www.google.com/search?q=example.com the previous page would be google.com/search?q=example.com but if you navigate to another page https://example.com/two the previous page would be example.com/one',
  ReferrerCondition:
    'This condition checks for the URL of the referring page. If you landed on the page from https://www.google.com/search?q=example.com the referrer would be google.com/search?q=example.com',
  ReferrerDomainCondition:
    'This condition checks for the domain of the referring page. If you landed on the page from https://www.google.com/search?q=example.com the referring domain would be google.com',
  UrlCondition:
    'This condition checks for the page URL. For a URL https://example.com/ the URL would be https://example.com/ and for https://example.com/blog/one the URL would be https://example.com/blog/one',
  UrlPathCondition:
    'This condition checks for the directory of the page URL. For a URL https://example.com/one/ the path would be /one and for https://example.com/blog/one/ the path would be /blog/one',
  UrlKeywordCondition:
    'This condition checks for all the keywords in the path of the page URL. For a URL https://example.com/blog/this-is-first-page/ the keywords would be "blog", "this", "is", "first" and "page"',
  UrlQueryCondition:
    'This condition checks for all query parameter of your page URL',
  UTMCampaignCondition:
    'This condition checks for utm_campaign query parameter of your page URL',
  UTMContentCondition:
    'This condition checks for utm_content query parameter of your page URL',
  UTMMediumCondition:
    'This condition checks for utm_medium query parameter of your page URL',
  UTMSourceCondition:
    'This condition checks for utm_source query parameter of your page URL',
  UTMTermCondition:
    'This condition checks for utm_term query parameter of your page URL',
  CookieValueCondition:
    "This condition would check for a specific cookie's existence or value in the visitor browser, this allows you to target logged in/logged out visitors along with lots of other use cases",
  WordpressTagsCondition:
    'This conditions would check if the page is a wordpress post and it includes or not certain tag(s)',
  AdBlockerCondition:
    'This condition would check if there is any Ad Blocker plugin enabled in visitor browser',
  MetaTagContentCondition:
    'This condition would check the content of the given meta tag',
};
