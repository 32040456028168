import {useReducer} from "react";

function reducer(state, action) {
  switch (action.type) {
    case 'EDITOR.CANCEL':
      action.payload.history.push(`/sites/${state.site.id}/manage`)
      return { ...state }
    case 'EDITOR.PUBLISH':
      return { ...state, saving: false, publishing: true }
    case 'EDITOR.SAVE':
      return { ...state, saving: true, publishing: false }
    case 'EDITOR.SAVING_ERROR':
      return { ...state, saving: false, publishing: false }
    case 'EDITOR.SAVING_DONE':
      const { systemUpdate, response } = action.payload
      if (systemUpdate) {
        systemUpdate({id: response.id, userEdited: false})
      }
      return { ...state, saving: false, publishing: false, savedAt: new Date() }
    case 'EDITOR.SAVED':
      action.payload.history.push(`/sites/${state.site.id}/manage`)
      return { ...state }
    default:
      throw new Error(`action type "${action.type}" is not valid`);
  }
}

const useEditorReducer = (initialSettings) => useReducer(reducer, initialSettings)

export { useEditorReducer }
