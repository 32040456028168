import {FormControlLabel, Switch} from "@mui/material";
import React from "react";
import styled from 'styled-components'

const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiSwitch-colorPrimary.Mui-checked {
    color: #27ab9e;
  }
  
  & .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #26ab9e;
  }
  
  &.MuiFormControlLabel-root {
    color: #919191;
    text-transform: uppercase;
    transition: all 0.15s ease-out 0s;
  }
`

export default function Switcher({ checked, onChange }) {
  return (
    <StyledFormControlLabel
      value="end"
      control={<Switch color="primary" checked={checked} onChange={onChange}/>}
      label={checked ? 'On' : 'Off'}
      labelPlacement="end"
    />
  )
}
