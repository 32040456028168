const analytics = window.analytics = window.analytics || [];

const buildSegmentUser = (user, site) => {
  const utmForm = user.utm_form || {};

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  let exp = getCookie('_gaexp');
  const experiments = {};

  if (exp) {
    exp = exp.split('!');
    if (exp.length > 0) {
      experiments["experiment_" + exp[0].split('.')[2] + "_var"] = exp[0].split('.')[4];

      for (let i = 1; i < exp.length; i++) {
        experiments["experiment_" + exp[i].split('.')[0] + "_var"] = exp[i].split('.')[2];
      }
    }
  }

  const partner = (user) => {
    const map = {
      tcoursebuilder: 'thinkific',
      dsitebuilder: 'duda',
      vmarketplace: 'vendasta'
    }
    return map[user.partner]
  }

  return {
    site_url: site.url,
    plan: site.plan.name,
    admin_site_link: `https://app.hellobar.com/active_admin/sites/${site.id}`,
    admin_user_link: `https://app.hellobar.com/active_admin/user/${user.user_id}`,
    current_session: utmForm['Current session'],
    email: user.email,
    employee_count: user.employee_count,
    first_name: user.first_name,
    industry: user.industry,
    initial_referrer: utmForm['Initial Referrer'],
    landing_page: utmForm['Landing Page'],
    last_name: user.last_name,
    last_referrer: utmForm['Last Referrer'],
    logins: user.logins,
    monthly_traffic: user.monthly_traffic,
    phone: user.phone,
    partner: partner(user),
    primary_domain: user.primary_domain,
    user_id: user.user_id,
    utm_campaign: utmForm['UTM Campaign'],
    utm_content: utmForm['UTM Content'],
    utm_form: utmForm,
    utm_medium: utmForm['UTM Medium'],
    utm_source: utmForm['UTM Source'],
    utm_term: utmForm['UTM Term'],
    visits: utmForm['Visits'],
    website_count: user.website_count,
    authenticator: user.authenticator,
    customer_id: user.customer_id,
    views: user.views,
    conversions: user.conversions,
    script_version: site.modules_filename === 'modules.js' ? 'v1' : 'v2',
    ...experiments
  };
}

export { analytics as Segmentio, buildSegmentUser };
