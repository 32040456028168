import styled from 'styled-components'

export const Title = styled.div`
  text-align: center;
  font-weight: bold;
  line-height: 4em;
  border-bottom: 1px solid #f6f6f6;
  height: 59px;
  margin: 0 -0.75em 1em;
`

export const TitleHint = styled.div`
  font-size: 0.8em;
  color: #7e7e7e;
  text-align: center;
  margin-bottom: 2em;
`

export const ListWrapper = styled.div`
  overflow-x: hidden;
  height: calc(100vh - 170px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Label = styled.div`
  font-size: 14px;
  font-size: 0.85em;
  line-height: 133%;
  color: #4f4f4f;
  transition: all 0.15s ease-out 0s;
  display: grid;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-items: stretch;
  grid-template-columns: 1fr 80px;
  
  & > span {
    font-weight: bold;
    color: #636363;
    align-self: center;
    display: inline-flex;
  }
`

export const LabelSelector = styled(Label)`
  margin-top: 20px;
  margin-bottom: 20px;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
  
  &:first-child, &:empty {
    margin-top: 0;
  }
  
  & + & {
    margin-top: 0;
  }

  & .paywall-lock{
    width: 314px;
    position: absolute;
    top: auto;
    bottom: unset;
    left: 90px;
    padding: 60px 10px;
  }
`

export const EndsAtBlock = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 8px;
  input {
    width: 60px;
    padding: 8.5px 8px;
  }
`;