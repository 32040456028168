import {useContext, useEffect, useState} from "react";
import styled, {css} from 'styled-components';
import {EditorContext} from "../../../utils/contexts";
import {panelForSubtype} from "../utils";

const Caret = styled(function ({ className, closed }) {
  const closedCaret = (
    <svg id="ember946" data-prefix="fas" data-icon="caret-left" aria-hidden="true" role="img"
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"
         className="ember-view fa-caret-left fa-w-6 fa-sm svg-inline--fa ">
      <path fill="currentColor" d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"/>
    </svg>
  )

  const openCaret = (
    <svg id="ember946" data-prefix="fas" data-icon="caret-down" aria-hidden="true"
         role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
         className="ember-view fa-caret-down fa-w-10 fa-sm svg-inline--fa ">
      <path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/>
    </svg>
  )

  return (
    <span className={className}>{ closed ? closedCaret : openCaret }</span>
  )
})`
  height: 20px;
  float: right;
  color: white;
  width: 0.625em;
  
  ${props => props.closed && css`
    width: 0.375em;
    color: #e0e0e0;
  `}
`

const PanelWrapper = styled.div`
`

const PanelHeader = styled.div`
  font-weight: bold;
  cursor: pointer;
  padding: 10px 15px;
  
  background: #e9562b;
  border: none;
  border-radius: 5px 5px 0 0;
  color: white;
      
  ${props => props.closed && css`
    background: #f6f6f6;
    border-radius: 5px;
    border: 1px solid #e2e2e2;
    color: #636363;    
  `}
`

const PanelBody = styled.div`
  border: 1px solid #e0e0e0;
  border-top: none;
  border-radius: 0 0 5px 5px;
  display: block;
  padding: 10px 15px;
  
  ${props => props.closed && css`
    display: none;
  `}
  
  .MuiFormControl-root + .MuiFormControl-root {
    margin-top: 1em;
  }
`

const Panel = styled.div`
  border-radius: 5px;
  margin: 0.5em auto;
  user-select: none;
`

export default function CollapsedPanel({ open, title, allowed, children, className }) {
  const [closed, setClosed] = useState(!open)
  const {settings} = useContext(EditorContext)

  useEffect(() => {
    setClosed(!open)
  }, [open])

  if (!allowed && !panelForSubtype(title, settings.subtype)) {
    return ''
  }

  return (
    <PanelWrapper>
      <Panel>
        <PanelHeader onClick={() => setClosed(!closed)} closed={closed}>
          <Caret closed={closed}/>
          {title}
        </PanelHeader>
        <PanelBody closed={closed} className={className}>
          { !closed && children }
        </PanelBody>
      </Panel>
    </PanelWrapper>
  )
}
