import React from 'react';
import './Button.scss';
import Loader from "react-loader-spinner";

import {Button as MuiButton} from '@mui/material'

export default function Button({ loading, ...props }) {
  const className = props.class;
  const label = loading ? <Loader height={10} visible={loading} type="ThreeDots" color="white"/> : props.label;
  const disabled = props.disabled || loading;
  const color = className || props.color || "secondary";
  const variant = props.variant || "contained"

  return <MuiButton variant={variant} {...props} color={color} disabled={disabled}>{label || props.children}</MuiButton>
}
