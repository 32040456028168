import moment from 'moment';
import React from 'react';

const VALID_RESULTS = ['catch_all', 'valid', 'ok']
const INVALID_RESULTS = ['underiverable', 'blacklist', 'invalid']

function ContactItem({ id, headers, contact }) {
  const resultClass = INVALID_RESULTS.includes(contact['email_validation_result']) ? 'invalid' : VALID_RESULTS.includes(contact['email_validation_result']) ? 'valid' : 'warning'
  return (
    <tr>
      <td>{id}</td>
      {headers.map((header) => (
        <td key={header.key}>
          {(header.key === 'email_validation_result') ?
            <p className={resultClass}>
              { contact[header.key]?.toString() }
            </p> : (
              contact[header.key]?.toString() || '-'
            )
          }
        </td>
      ))}
      <td>
        <time>{moment(contact.subscribed).format('DD/MM/YYYY')}</time>
      </td>
    </tr>
  );
}

function ContactItems({ list, recordNo }) {
  return (
    <React.Fragment>
      {list.items.map((contact, index) => (
        <ContactItem
          key={index}
          id={recordNo + index + 1}
          headers={list.headers}
          contact={contact}
        />
      ))}
    </React.Fragment>
  );
}

export default ContactItems;
