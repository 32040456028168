import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";
import {Label} from "../Steps/components";
import Switcher from "../components/Switcher";

export default function PinOnPinterest() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateURL = (event) => {
    const customSettings = {...settings.settings, pinterest_url: event.target.value}
    updateSettings({settings: customSettings})
  }

  const updateImageURL = (event) => {
    const customSettings = {...settings.settings, pinterest_image_url: event.target.value}
    updateSettings({settings: customSettings})
  }

  const updateDescription = (event) => {
    const customSettings = {...settings.settings, pinterest_description: event.target.value}
    updateSettings({settings: customSettings})
  }

  const toggleUseLocation = () => {
    const customSettings = {...settings.settings, use_location_for_url: !settings.settings.use_location_for_url}
    updateSettings({settings: customSettings})
  }

  return (
    <>
      <Label>
        <span>URL my visitor is on</span>
        <Switcher checked={settings.settings.use_location_for_url} onChange={toggleUseLocation}/>
      </Label>

      {!settings.settings.use_location_for_url &&
      <TextField fullWidth variant="outlined" label="URL to pin" value={settings.settings.pinterest_url || ''}
                 onChange={updateURL} placeholder="example.com" size='small'/>}


      <TextField fullWidth variant="outlined" label="Image URL (optional)"
                 value={settings.settings.pinterest_image_url || ''}
                 onChange={updateImageURL} placeholder="example.com/image.png" size='small'/>


      <TextField fullWidth variant="outlined" label="Description" value={settings.settings.pinterest_description || ''}
                 onChange={updateDescription} placeholder="Check out this great site!" size='small'/>
    </>
  )
}
