import FormControl from "@mui/material/FormControl/FormControl";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import {TextField} from "@mui/material";
import styled from "styled-components";
import PaywallLock from "../../PaywallLock";

const StyledFormControl = styled(FormControl)`
  width: 100%;
`

const IconOptions = styled(({className, label, icon})=>{
  return (
    <div className={className}>
      <span>{icon}</span>
    </div>
  )
})`
  width: 100%;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
  align-items: center;
  & span:first-child{
    width: 20px;
    height: 20px
  }
`

export default function SelectInput({ value, items, onChange, planTier, siteId }) {

  return (
    <StyledFormControl variant="outlined">
      <TextField
        select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        size="small"
      >
        {items.map(item =>
          <MenuItem key={item.value} value={(planTier < item.tier) ? '' : item.value}>
            { (planTier < item.tier) &&
              <PaywallLock
                message="Not available on your plan. Please upgrade your subscription."
                url={siteId ? `/sites/${siteId}/upgrade` : ''}
              />
            }
            {item.icon ? <IconOptions icon={item.icon} label={item.label} /> : item.label}
          </MenuItem>)
        }
      </TextField>
    </StyledFormControl>
  );
}
