import React, {useContext, useState} from "react";
import {EditorContext} from "../../../utils/contexts";
import CollapsedPanel from "../components/CollapsedPanel";
import {COUNTRY_CODES} from "../../../utils/country_codes";
import {TextField} from "@mui/material";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import styled from 'styled-components'
import parsePhoneNumber, {AsYouType} from 'libphonenumber-js'

const Hint = styled.div`
  margin-bottom: 1em;
  color: #9e9e9e;
`

const updatePhone = (number, code, { setPhone, setFormatted }) => {
  const phone = parsePhoneNumber(number, code)
  setPhone(phone)
  setFormatted(new AsYouType(code).input(number))
  return phone
}

export default function PhoneNumberPanel() {
  const {settings, updateSettings} = useContext(EditorContext)
  const [code, setCode] = useState(settings?.phone_country_code || 'US');
  const [formatted, setFormatted] = useState(settings?.phone_number || '');
  const [phone, setPhone] = useState(parsePhoneNumber(settings?.phone_number || '', code));

  const updatePhoneNumber = (event) => {
    const newPhone =
      updatePhone(event.target.value, code, { setPhone, setFormatted })

    if (newPhone && newPhone.isValid()) {
      updateSettings({phone_number: newPhone.number, phone_country_code: newPhone.country})
    }
  }

  const isError = () => {
    return !phone || !phone.isValid()
  }

  const updateCode = (event) => {
    setCode(event.target.value)
    const number = phone?.number || settings.phone_number || ''
    updatePhone(number, event.target.value, { setPhone, setFormatted })
  }

  return (
    <CollapsedPanel open={false} title="Phone Number">
      <Hint>Enter the phone number that will be dialed when a user clicks on the call button.</Hint>
      <TextField fullWidth variant="outlined" select label="Country" value={code} onChange={updateCode} size="small">
        {COUNTRY_CODES.map(item =>
          <MenuItem key={item.code} value={item.code}>
            {item.name}
          </MenuItem>)
        }
      </TextField>
      <TextField fullWidth variant="outlined" label="Phone number" value={formatted} onChange={updatePhoneNumber}
                 error={isError()} helperText={isError() && "Incorrect phone number."} size="small"/>
    </CollapsedPanel>
  )
}
