import React, { useRef, useState } from "react";
import useOnScreen from "../../utils/use_on_screen";
import RuleItems from "./RuleItems";

export default function LazyRuleItems({ rule, scope, actions, resetCache }) {
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const [lazyLoaded, setLazyLoaded] = useState(false);
  if (isVisible && !lazyLoaded) setLazyLoaded(true)

  return (
    <tbody ref={ref}>
      {lazyLoaded && <RuleItems togglePopup={actions.togglePopup}
                 deletePopup={actions.deletePopup}
                 copyPopup={actions.copyPopup}
                 key={rule.id}
                 rule={rule}
                 scope={scope}
                 resetCache={resetCache} />}
    </tbody>
  )
}
