export const editorURL = (site, path, params, anchor) => {
  if (site.plan.free && site.pre_selected_plan && site.pre_selected_plan.includes('financial-')) { 
    return "/sites/" + site.id +"/upgrade/" + site.pre_selected_plan
  }

  const url = `/sites/${site.id}/popups${path}`;

  const buildURLQuery = obj =>
    Object.entries(obj)
      .map(pair => pair.map(encodeURIComponent).join('='))
      .join('&');

  const urlQuery = buildURLQuery({ ...params });

  if (urlQuery) {
    return [url, urlQuery].join('?') + `${ anchor ? '#' + anchor : ''}`;
  } else {
    return url + `${ anchor ? '#' + anchor : ''}`;
  }
}
