import React from 'react';
import { styled } from '@mui/material/styles';
import '../assets/styles/_tooltips.scss';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} placement="right" classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    opacity: 0.7,
    color: 'rgba(255, 255, 255, 0.87)',
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(12),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#000000',
  },
}));

export default function Tooltips(props) {
  const title = props.title;
  const url = props?.url;
  const videoUrl = props?.videoUrl;
  const message = props.message;
  const icon = props?.icon;
  const className = props?.className || '';
  const canShowTooltip = title || message || videoUrl;

  const handleClick = () => {
    window.open(url, '_blank', 'noopener');
  };
  if (canShowTooltip) {
    return (
      <HtmlTooltip
        title={
          <Box className="tooltip">
            <Typography color="inherit" variant="h3" className="tooltip-title">
              {title}
            </Typography>
            {videoUrl && (
              <video
                src={videoUrl}
                width="220"
                height="200"
                className="tooltip-video"
                controls
              />
            )}
            {message && (
              <Typography
                color="inherit"
                variant="body2"
                className="tooltip-message"
              >
                {message}
              </Typography>
            )}
            {url && (
              <Button className="tooltip-read-more" onClick={handleClick}>
                Read More
              </Button>
            )}
          </Box>
        }
      >
        {icon ? (
          <i className={`icon-support icon-support-position ${className}`}></i>
        ) : (
          <i className="paywall-lock-icon" />
        )}
      </HtmlTooltip>
    );
  } else {
    return ``;
  }
}
