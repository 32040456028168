import React from "react";

export default function ContactHeader() {
  return (
    <thead>
      <tr>
        <th>List</th>
        <th>Email Service</th>
        <th>Contacts</th>
        <th>Date Created</th>
      </tr>
    </thead>
  )
}
