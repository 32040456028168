import styled from 'styled-components';
import React, { useContext, useEffect } from 'react';
import { Title, TitleHint } from './components';
import AlertDesign from '../designs/AlertDesign';
import BarDesign from '../designs/BarDesign';
import ModalDesign from '../designs/ModalDesign';
import SliderDesign from '../designs/SliderDesign';
import TakeoverDesign from '../designs/TakeoverDesign';
import { EditorContext, EditorBinding } from '../../../utils/contexts';
import { useLocation } from 'react-router-dom';
import { withEditorPageTracking } from '../../../shared/Tracking';
import InlineDesign from '../designs/InlineDesign';

function DesignStep({ className }) {
  const { settings, systemUpdate } = useContext(EditorContext);
  const location = useLocation();

  const designs = {
    Alert: AlertDesign,
    Bar: BarDesign,
    Inline: InlineDesign,
    Modal: ModalDesign,
    Slider: SliderDesign,
    Takeover: TakeoverDesign,
  };

  const Component = designs[settings.type] || BarDesign;

  useEffect(() => {
    if (settings.show_thankyou === true) {
      systemUpdate({ show_thankyou: false });
    }
  }, [settings.show_thankyou, systemUpdate]);

  useEffect(() => {
    if (window.hellobar) {
      window.hellobar.displayPage('main');
      const handler = () => {
        window.hellobar.displayPage('main');
      };
      window.hellobar.addEventListener('show', handler);

      return () => {
        window.hellobar.removeEventListener('show', handler);
      };
    }
  }, [window.hellobar]);

  return (
    <EditorBinding.Provider value={location.state || {}}>
      <div className={className}>
        <div>
          <Title>Design</Title>
          <TitleHint>Change how your Hello Bar looks.</TitleHint>
        </div>
        <Component />
      </div>
    </EditorBinding.Provider>
  );
}

export default styled(withEditorPageTracking(DesignStep, 'Design'))``;
