import React from "react";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ScheduleSwitch(props) {
  const [schedule, setSchedule] = React.useState(props.default || "monthly");

  const handleScheduleChange = (event) => {
    if (schedule === "yearly") {
      setSchedule("monthly");
      props.onChange("monthly");
    } else {
      setSchedule("yearly");
      props.onChange("yearly");
    }
  };

  return (
    <FormControlLabel
      style={{textTransform: "capitalize"}}
      control={<Switch checked={schedule === "yearly"} color="secondary" onChange={handleScheduleChange}/>}
      label={`${props.label} ${schedule}`}
      labelPlacement="start"
    />
  );
}
