import React from 'react'

export default function DesignIcon() {
  return (
    <svg width="35px" height="30px" viewBox="0 0 39 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Hello-Bar-New-Creation-Flow" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="3.1---Design,-tooltip" transform="translate(-27.000000, -294.000000)">
          <g id="menu-items" transform="translate(0.000000, 82.000000)">
            <g id="Design" transform="translate(25.000000, 212.000000)">
              <g id="Page-1" transform="translate(2.000000, 0.000000)">
                <path d="M0,29.2442042 L38.9550417,29.2442042 L38.9550417,0.0001625 L0,0.0001625 L0,29.2442042 Z M3.25,25.9942042 L35.7050417,25.9942042 L35.7050417,3.2501625 L3.25,3.2501625 L3.25,25.9942042 Z" id="Fill-1"/>
                <polygon id="Fill-2" points="7.16755 23.29275 32.766175 23.29275 29.3011333 12.042875 26.7022167 12.9084583 24.4505083 8.66233333 17.1737583 19.1674167 13.2753833 16.6557083"/>
                <path d="M10.3940417,13.0566042 C12.0921667,13.0566042 13.4690833,11.6802292 13.4690833,9.98210417 C13.4690833,8.28289583 12.0921667,6.90652083 10.3940417,6.90652083 C8.695375,6.90652083 7.31845833,8.28289583 7.31845833,9.98210417 C7.31845833,11.6802292 8.695375,13.0566042 10.3940417,13.0566042" id="Fill-3"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
