export const timezones = [
  { title: "(GMT+00:00) UTC", id: "Etc/UTC" },
  { title: "(GMT-12:00) International Date Line West", id: "Etc/GMT+12" },
  { title: "(GMT-11:00) American Samoa", id: "Pacific/Pago_Pago" },
  { title: "(GMT-11:00) Midway Island", id: "Pacific/Midway" },
  { title: "(GMT-10:00) Hawaii", id: "Pacific/Honolulu" },
  { title: "(GMT-09:00) Alaska", id: "America/Juneau" },
  { title: "(GMT-08:00) Pacific Time (US \u0026 Canada)", id: "America/Los_Angeles" },
  { title: "(GMT-08:00) Tijuana", id: "America/Tijuana" },
  { title: "(GMT-07:00) Arizona", id: "America/Phoenix" },
  { title: "(GMT-07:00) Chihuahua", id: "America/Chihuahua" },
  { title: "(GMT-07:00) Mazatlan", id: "America/Mazatlan" },
  { title: "(GMT-07:00) Mountain Time (US \u0026 Canada)", id: "America/Denver" },
  { title: "(GMT-06:00) Central America", id: "America/Guatemala" },
  { title: "(GMT-06:00) Central Time (US \u0026 Canada)", id: "America/Chicago" },
  { title: "(GMT-06:00) Guadalajara", id: "America/Mexico_City" },
  { title: "(GMT-06:00) Monterrey", id: "America/Monterrey" },
  { title: "(GMT-06:00) Saskatchewan", id: "America/Regina" },
  { title: "(GMT-05:00) Bogota", id: "America/Bogota" },
  { title: "(GMT-05:00) Eastern Time (US \u0026 Canada)", id: "America/New_York" },
  { title: "(GMT-05:00) Indiana (East)", id: "America/Indiana/Indianapolis" },
  { title: "(GMT-05:00) Lima", id: "America/Lima" },
  { title: "(GMT-04:00) Atlantic Time (Canada)", id: "America/Halifax" },
  { title: "(GMT-04:00) Caracas", id: "America/Caracas" },
  { title: "(GMT-04:00) Georgetown", id: "America/Guyana" },
  { title: "(GMT-04:00) La Paz", id: "America/La_Paz" },
  { title: "(GMT-04:00) Puerto Rico", id: "America/Puerto_Rico" },
  { title: "(GMT-04:00) Santiago", id: "America/Santiago" },
  { title: "(GMT-03:30) Newfoundland", id: "America/St_Johns" },
  { title: "(GMT-03:00) Brasilia", id: "America/Sao_Paulo" },
  { title: "(GMT-03:00) Buenos Aires", id: "America/Argentina/Buenos_Aires" },
  { title: "(GMT-03:00) Greenland", id: "America/Godthab" },
  { title: "(GMT-03:00) Montevideo", id: "America/Montevideo" },
  { title: "(GMT-02:00) Mid-Atlantic", id: "Atlantic/South_Georgia" },
  { title: "(GMT-01:00) Azores", id: "Atlantic/Azores" },
  { title: "(GMT-01:00) Cape Verde Is.", id: "Atlantic/Cape_Verde" },
  { title: "(GMT+00:00) Casablanca", id: "Africa/Casablanca" },
  { title: "(GMT+00:00) Dublin", id: "Europe/Dublin" },
  { title: "(GMT+00:00) Edinburgh", id: "Europe/London" },
  { title: "(GMT+00:00) Lisbon", id: "Europe/Lisbon" },
  { title: "(GMT+00:00) Monrovia", id: "Africa/Monrovia" },
  { title: "(GMT+01:00) Amsterdam", id: "Europe/Amsterdam" },
  { title: "(GMT+01:00) Belgrade", id: "Europe/Belgrade" },
  { title: "(GMT+01:00) Berlin", id: "Europe/Berlin" },
  { title: "(GMT+01:00) Bern", id: "Europe/Zurich" },
  { title: "(GMT+01:00) Bratislava", id: "Europe/Bratislava" },
  { title: "(GMT+01:00) Brussels", id: "Europe/Brussels" },
  { title: "(GMT+01:00) Budapest", id: "Europe/Budapest" },
  { title: "(GMT+01:00) Copenhagen", id: "Europe/Copenhagen" },
  { title: "(GMT+01:00) Ljubljana", id: "Europe/Ljubljana" },
  { title: "(GMT+01:00) Madrid", id: "Europe/Madrid" },
  { title: "(GMT+01:00) Paris", id: "Europe/Paris" },
  { title: "(GMT+01:00) Prague", id: "Europe/Prague" },
  { title: "(GMT+01:00) Rome", id: "Europe/Rome" },
  { title: "(GMT+01:00) Sarajevo", id: "Europe/Sarajevo" },
  { title: "(GMT+01:00) Skopje", id: "Europe/Skopje" },
  { title: "(GMT+01:00) Stockholm", id: "Europe/Stockholm" },
  { title: "(GMT+01:00) Vienna", id: "Europe/Vienna" },
  { title: "(GMT+01:00) Warsaw", id: "Europe/Warsaw" },
  { title: "(GMT+01:00) West Central Africa", id: "Africa/Algiers" },
  { title: "(GMT+01:00) Zagreb", id: "Europe/Zagreb" },
  { title: "(GMT+02:00) Athens", id: "Europe/Athens" },
  { title: "(GMT+02:00) Bucharest", id: "Europe/Bucharest" },
  { title: "(GMT+02:00) Cairo", id: "Africa/Cairo" },
  { title: "(GMT+02:00) Harare", id: "Africa/Harare" },
  { title: "(GMT+02:00) Helsinki", id: "Europe/Helsinki" },
  { title: "(GMT+02:00) Jerusalem", id: "Asia/Jerusalem" },
  { title: "(GMT+02:00) Kaliningrad", id: "Europe/Kaliningrad" },
  { title: "(GMT+02:00) Kyiv", id: "Europe/Kiev" },
  { title: "(GMT+02:00) Pretoria", id: "Africa/Johannesburg" },
  { title: "(GMT+02:00) Riga", id: "Europe/Riga" },
  { title: "(GMT+02:00) Sofia", id: "Europe/Sofia" },
  { title: "(GMT+02:00) Tallinn", id: "Europe/Tallinn" },
  { title: "(GMT+02:00) Vilnius", id: "Europe/Vilnius" },
  { title: "(GMT+03:00) Baghdad", id: "Asia/Baghdad" },
  { title: "(GMT+03:00) Istanbul", id: "Europe/Istanbul" },
  { title: "(GMT+03:00) Kuwait", id: "Asia/Kuwait" },
  { title: "(GMT+03:00) Minsk", id: "Europe/Minsk" },
  { title: "(GMT+03:00) Moscow", id: "Europe/Moscow" },
  { title: "(GMT+03:00) Nairobi", id: "Africa/Nairobi" },
  { title: "(GMT+03:00) Riyadh", id: "Asia/Riyadh" },
  { title: "(GMT+03:00) Volgograd", id: "Europe/Volgograd" },
  { title: "(GMT+03:30) Tehran", id: "Asia/Tehran" },
  { title: "(GMT+04:00) Abu Dhabi", id: "Asia/Muscat" },
  { title: "(GMT+04:00) Baku", id: "Asia/Baku" },
  { title: "(GMT+04:00) Samara", id: "Europe/Samara" },
  { title: "(GMT+04:00) Tbilisi", id: "Asia/Tbilisi" },
  { title: "(GMT+04:00) Yerevan", id: "Asia/Yerevan" },
  { title: "(GMT+04:30) Kabul", id: "Asia/Kabul" },
  { title: "(GMT+05:00) Ekaterinburg", id: "Asia/Yekaterinburg" },
  { title: "(GMT+05:00) Islamabad", id: "Asia/Karachi" },
  { title: "(GMT+05:00) Tashkent", id: "Asia/Tashkent" },
  { title: "(GMT+05:30) Chennai", id: "Asia/Kolkata" },
  { title: "(GMT+05:30) Sri Jayawardenepura", id: "Asia/Colombo" },
  { title: "(GMT+05:45) Kathmandu", id: "Asia/Kathmandu" },
  { title: "(GMT+06:00) Almaty", id: "Asia/Almaty" },
  { title: "(GMT+06:00) Astana", id: "Asia/Dhaka" },
  { title: "(GMT+06:00) Urumqi", id: "Asia/Urumqi" },
  { title: "(GMT+06:30) Rangoon", id: "Asia/Rangoon" },
  { title: "(GMT+07:00) Bangkok", id: "Asia/Bangkok" },
  { title: "(GMT+07:00) Jakarta", id: "Asia/Jakarta" },
  { title: "(GMT+07:00) Krasnoyarsk", id: "Asia/Krasnoyarsk" },
  { title: "(GMT+07:00) Novosibirsk", id: "Asia/Novosibirsk" },
  { title: "(GMT+08:00) Beijing", id: "Asia/Shanghai" },
  { title: "(GMT+08:00) Chongqing", id: "Asia/Chongqing" },
  { title: "(GMT+08:00) Hong Kong", id: "Asia/Hong_Kong" },
  { title: "(GMT+08:00) Irkutsk", id: "Asia/Irkutsk" },
  { title: "(GMT+08:00) Kuala Lumpur", id: "Asia/Kuala_Lumpur" },
  { title: "(GMT+08:00) Perth", id: "Australia/Perth" },
  { title: "(GMT+08:00) Singapore", id: "Asia/Singapore" },
  { title: "(GMT+08:00) Taipei", id: "Asia/Taipei" },
  { title: "(GMT+08:00) Ulaanbaatar", id: "Asia/Ulaanbaatar" },
  { title: "(GMT+09:00) Osaka", id: "Asia/Tokyo" },
  { title: "(GMT+09:00) Seoul", id: "Asia/Seoul" },
  { title: "(GMT+09:00) Yakutsk", id: "Asia/Yakutsk" },
  { title: "(GMT+09:30) Adelaide", id: "Australia/Adelaide" },
  { title: "(GMT+09:30) Darwin", id: "Australia/Darwin" },
  { title: "(GMT+10:00) Brisbane", id: "Australia/Brisbane" },
  { title: "(GMT+10:00) Canberra", id: "Australia/Melbourne" },
  { title: "(GMT+10:00) Guam", id: "Pacific/Guam" },
  { title: "(GMT+10:00) Hobart", id: "Australia/Hobart" },
  { title: "(GMT+10:00) Port Moresby", id: "Pacific/Port_Moresby" },
  { title: "(GMT+10:00) Sydney", id: "Australia/Sydney" },
  { title: "(GMT+10:00) Vladivostok", id: "Asia/Vladivostok" },
  { title: "(GMT+11:00) Magadan", id: "Asia/Magadan" },
  { title: "(GMT+11:00) New Caledonia", id: "Pacific/Noumea" },
  { title: "(GMT+11:00) Solomon Is.", id: "Pacific/Guadalcanal" },
  { title: "(GMT+11:00) Srednekolymsk", id: "Asia/Srednekolymsk" },
  { title: "(GMT+12:00) Auckland", id: "Pacific/Auckland" },
  { title: "(GMT+12:00) Fiji", id: "Pacific/Fiji" },
  { title: "(GMT+12:00) Kamchatka", id: "Asia/Kamchatka" },
  { title: "(GMT+12:00) Marshall Is.", id: "Pacific/Majuro" },
  { title: "(GMT+12:45) Chatham Is.", id: "Pacific/Chatham" },
  { title: "(GMT+13:00) Nuku'alofa", id: "Pacific/Tongatapu" },
  { title: "(GMT+13:00) Samoa", id: "Pacific/Apia" },
  { title: "(GMT+13:00) Tokelau Is.", id: "Pacific/Fakaofo" }
];
