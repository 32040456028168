import styled from 'styled-components';
import React, { useContext, useEffect, useState } from 'react';
import Switcher from '../components/Switcher';
import { LabelSelector, Label, Title, TitleHint } from './components';
import { EditorContext } from '../../../utils/contexts';
import { AppBar, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SelectInput from '../components/SelectInput';
import makeStyles from '@mui/styles/makeStyles';
import { withEditorPageTracking } from '../../../shared/Tracking';
import { SUBTYPES, TYPES } from '../constants';
import ButtonStylingPanel from '../panels/ButtonStylingPanel';
import SecondaryButtonStylingPanel from '../panels/SecondaryButtonStylingPanel';
import BarStylingPanel from '../panels/BarStylingPanel';
import ModalStylingPanel from '../panels/ModalStylingPanel';
import SliderStylingPanel from '../panels/SliderStylingPanel';
import TakeoverStylingPanel from '../panels/TakeoverStylingPanel';

const useStyles = makeStyles((theme) => ({
  questionContainer: {
    marginTop: '12px',
    border: '1px solid #e0e0e0',
  },
  questionFields: {
    marginBottom: '20px !important',
    backgroundColor: 'white !important',
  },
  questionAppBarStyle: {
    padding: '0px !important',
    border: 'none !important',
  },
  questionTabStyle: {
    '& .MuiTab-root': {
      fontSize: '12px !important',
      width: '50%',
      padding: '4 !important',
      textTransform: 'none',
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
    },
    '& .Mui-selected': {
      borderBottom: 'none !important',
      cursor: 'default',
    },
  },
  leadQuestionPanel: {
    '& .MuiBox-root': {
      backgroundColor: 'white',
    },
  },
}));

const TextInput = ({ label, valueName, placeholderName, onChange }) => {
  const classes = useStyles();
  const { settings } = useContext(EditorContext);

  return (
    <TextField
      className={classes.questionFields}
      onChange={onChange}
      size="small"
      fullWidth
      variant="outlined"
      value={settings[valueName] || ''}
      label={label}
      placeholder={settings[placeholderName] || placeholderName}
    />
  );
};

const TabContent = ({ index, goal }) => {
  const { settings, updateSettings } = useContext(EditorContext);

  const onAnswer1ResponseUpdate = (value) => {
    updateSettings({ answer1response: value });
  };

  const onAnswer1urlUpdate = (event) => {
    updateSettings({ answer1url: event.target.value });
  };

  const onAnswer2ResponseUpdate = (value) => {
    updateSettings({ answer2response: value });
  };

  const onAnswer2urlUpdate = (event) => {
    updateSettings({ answer2url: event.target.value });
  };

  const showConversion = [
    SUBTYPES.EMAIL,
    SUBTYPES.WEBFORM,
    SUBTYPES.RETIREMENT,
    SUBTYPES.LEADFORM,
  ].includes(goal);

  const options = [
    { value: 'close', label: 'Close the popup' },
    { value: 'main', label: 'Display the popup view' },
    ...(showConversion
      ? [{ value: 'thank-you', label: 'Display the success view' }]
      : []),
    { value: 'redirect', label: 'Redirect to another URL' },
  ];

  return (
    <>
      {index === 0 && (
        <LabelSelector>
          <span>Button action</span>
          <SelectInput
            items={options}
            value={settings.answer1response || 'main'}
            onChange={onAnswer1ResponseUpdate}
            size="small"
          />

          {settings.answer1response === 'redirect' && (
            <TextInput
              onChange={onAnswer1urlUpdate}
              valueName="answer1url"
              label="Redirect to"
              placeholderName="https://www.example.com"
            />
          )}
        </LabelSelector>
      )}

      {index === 1 && (
        <LabelSelector>
          <span>Button action</span>
          <SelectInput
            items={options}
            value={settings.answer2response || 'close'}
            onChange={onAnswer2ResponseUpdate}
            size="small"
          />

          {settings.answer2response === 'redirect' && (
            <TextInput
              onChange={onAnswer2urlUpdate}
              valueName="answer2url"
              label="Redirect to"
              placeholderName="https://www.example.com"
            />
          )}
        </LabelSelector>
      )}
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

function QuestionStep({ className, goal, type }) {
  const classes = useStyles();
  const { settings, updateSettings, systemUpdate } = useContext(EditorContext);
  const toggleUseQuestion = () => {
    updateSettings({ use_question: !settings.use_question });
  };
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (window.hellobar) {
      const page = settings.use_question ? 'question' : 'main';
      window.hellobar.displayPage(page);
      const handler = () => {
        window.hellobar.displayPage(page);
      };
      window.hellobar.addEventListener('show', handler);

      return () => {
        window.hellobar.removeEventListener('show', handler);
      };
    }
  }, [window.hellobar, settings.use_question]);

  useEffect(() => {
    systemUpdate({
      question: settings.question || 'Is this your first time here?',
      answer1: settings.answer1 || 'Yes',
      answer2: settings.answer2 || 'No',
      answer1response: settings.answer1response || 'main',
      answer2response: settings.answer2response || 'close',
      question_font_family: settings.question_font_family || 'Source Sans Pro',
      question_text_color: settings.question_text_color || '000000',
      question_text_size: settings.question_text_size || 20,
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={className}>
      <div>
        <Title>Yes/No Question</Title>
        <TitleHint>
          Display a question before the primary popup to boost conversions.
        </TitleHint>

        <Label>
          <span>Enable Yes/No question</span>
          <Switcher
            checked={settings.use_question}
            onChange={toggleUseQuestion}
          />
        </Label>
      </div>
      {settings.use_question && (
        <>
          <div className={classes.questionContainer}>
            <AppBar className={classes.questionAppBarStyle} position="static">
              <Tabs
                className={classes.questionTabStyle}
                value={value}
                onChange={handleChange}
              >
                <Tab
                  label={
                    settings.answer1.replace(/(<([^>]+)>)/gi, '') || 'Answer 1'
                  }
                />
                <Tab
                  label={
                    settings.answer2.replace(/(<([^>]+)>)/gi, '') || 'Answer 2'
                  }
                />
              </Tabs>
            </AppBar>
            <TabPanel
              className={classes.leadQuestionPanel}
              value={value}
              index={0}
            >
              <TabContent index={value} goal={goal} />
              <ButtonStylingPanel open={true} title={'Yes Button Styling'} />
            </TabPanel>
            <TabPanel
              className={classes.leadQuestionPanel}
              value={value}
              index={1}
            >
              <TabContent index={value} goal={goal} />
              <SecondaryButtonStylingPanel
                open={true}
                title={'No Button Styling'}
              />
            </TabPanel>
          </div>
          {type === TYPES.BAR && <BarStylingPanel />}
          {type === TYPES.MODAL && <ModalStylingPanel />}
          {type === TYPES.SLIDER && <SliderStylingPanel />}
          {type === TYPES.TAKEOVER && <TakeoverStylingPanel />}
        </>
      )}
    </div>
  );
}

export default styled(withEditorPageTracking(QuestionStep, 'Question'))`
  overflow-y: auto;
  overflow-x: hidden;
`;
