import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Typography } from '@mui/material';

function TablePaginationActions(props) {
  const { page, pageDetails, onPageChange, currentPage, totalPages, handlePageNo } = props;

  const { next_page, previous_page } = pageDetails

  const handleBackButtonClick = (event) => {
    if (previous_page) {
      onPageChange(event, previous_page);
      if (currentPage !== 0) {
        handlePageNo(currentPage - 1)
      }
    }
  };

  const handleNextButtonClick = (event) => {
    if (next_page) {
      onPageChange(event, next_page);
      if (currentPage !== totalPages) {
        handlePageNo(currentPage + 1)
      }
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={previous_page === undefined || page === previous_page}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
        <Typography>Previous</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={next_page === undefined || page === next_page}
        aria-label="next page"
      >
        <Typography>Next</Typography>
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

export default TablePaginationActions;
