import React from 'react';
import '../../assets/styles/NewContactList.scss';
import ContactListDialog from './ContactListDialog';
import { hellobar } from '../../shared/providers';

const initContactList = {
  name: '',
  provider: hellobar,
  connection: { connected: true },
};

export default function NewContactList(props) {
  initContactList.validate_email = props.validateEmail;
  return (
    <ContactListDialog
      {...props}
      contactList={initContactList}
      changeProvider={true}
      disableValidateEmail={props.validateEmail}
    />
  );
}
