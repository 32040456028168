import React, { useState } from 'react'
import ColorPicker from '../../Editor/components/ColorPicker';
import InputBlock from '../../Editor/components/InputBlock';
import FontPicker from "../../Editor/components/FontPicker";
import Button from "../../../shared/Button";
import useAPI from "../../../utils/api";
import { useSnackbar } from 'notistack';
import BrandingPreview from './BrandingPreview';
import NumberInput from '../../Editor/components/NumberInput';
import Switcher from '../../Editor/components/Switcher';
import { Grid } from '@mui/material';
import useFetch from '../../../utils/useFetch';
import useFonts from '../../../utils/useFonts';
import { useRoleCheck } from '../../../utils/useRoleCheck';

const BrandingForm = ({ site, data }) => {
  const API = useAPI()
  const { enqueueSnackbar } = useSnackbar()
  const { customFonts } = useFetch('customFonts', site.id);
  const defaultTheme = {
    popup_font_family: 'Lato, sans-serif',
    popup_background_color: 'FFFFFF',
    popup_text_color: 'AAAAAA',
    cross_color: '000000',
    cta_button_color: '000000',
    cta_text_color: 'FFFFFF',
    text_field_background_color: '000000',
    text_field_text_color: 'FFFFFF',
    text_field_border_color: '000000',
    text_field_font_family: 'Lato, sans-serif'
  }
  const [themeData, setThemeData] = useState(data || defaultTheme)
  const handleRoleCheck = useRoleCheck(API.currentRole);

  useFonts();

  const handleSubmit = async(e) => {
    e.preventDefault();
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }

    const [ok, response] = await API.updateTheme(themeData)

    if (ok) {
      enqueueSnackbar(`Theme has been updated successfully!`, { variant: 'success' });
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }

  const handleDelete = async(e) => {
    e.preventDefault();
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }

    const [ok, response] = await API.deleteTheme()

    if (ok) {
      setThemeData(defaultTheme);
      enqueueSnackbar(`Theme has been deleted successfully!`, { variant: 'success' });
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }

  return <>
    <Grid container justifyContent="flex-start" spacing={1}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <h5>Branding Kit</h5>
          <InputBlock label="Popup Font Family">
            <FontPicker
              customFonts={customFonts || []}
              value={themeData.popup_font_family}
              onChange={(font)=>{setThemeData({...themeData, popup_font_family: font})}}
            />
          </InputBlock>
          <InputBlock label="Popup Background Color">
            <ColorPicker
              value={themeData.popup_background_color}
              onChange={(color)=>{setThemeData({...themeData, popup_background_color: color})}}
            />
          </InputBlock>
          <InputBlock label="Popup Text Color">
            <ColorPicker
              value={themeData.popup_text_color}
              onChange={(color)=>{setThemeData({...themeData, popup_text_color: color})}}
            />
          </InputBlock>
          <InputBlock label="Cross Color">
            <ColorPicker
              value={themeData.cross_color}
              onChange={(color)=>{setThemeData({...themeData, cross_color: color})}}
            />
          </InputBlock>

          <h5>Input Field Styling</h5>
          <InputBlock label="Background Color">
            <ColorPicker
              value={themeData.text_field_background_color}
              onChange={(color)=>{setThemeData({...themeData, text_field_background_color: color})}}
            />
          </InputBlock>
          <InputBlock label="Text Color">
            <ColorPicker
              value={themeData.text_field_text_color}
              onChange={(color)=>{setThemeData({...themeData, text_field_text_color: color})}}
            />
          </InputBlock>
          <InputBlock label="Border Color">
            <ColorPicker
              value={themeData.text_field_border_color}
              onChange={(color)=>{setThemeData({...themeData, text_field_border_color: color})}}
            />
          </InputBlock>
          <InputBlock label="Border Width">
            <NumberInput
              value={themeData.text_field_border_width|| 0}
              onChange={(width)=>{setThemeData({...themeData, text_field_border_width: width})}}
              max={10}
            />
          </InputBlock>
          <InputBlock label="Font Size">
            <NumberInput
              value={themeData.text_field_font_size || 14}
              onChange={(fontSize)=>{ setThemeData({...themeData, text_field_font_size: fontSize})}}
              min={8}
              max={24}
            />
          </InputBlock>
          <InputBlock label="Font Family">
            <FontPicker
              customFonts={customFonts || []}
              value={themeData.text_field_font_family}
              onChange={(fontFamily)=>{setThemeData({...themeData, text_field_font_family: fontFamily})}}
            />
          </InputBlock>

          <h5>CTA Button Styling</h5>
          <InputBlock label="CTA Button Color">
            <ColorPicker
              value={themeData.cta_button_color}
              onChange={(color)=>{setThemeData({...themeData, cta_button_color: color})}}
            />
          </InputBlock>
          <InputBlock label="CTA Text Color">
            <ColorPicker
              value={themeData.cta_text_color}
              onChange={(color)=>{setThemeData({...themeData, cta_text_color: color})}}
            />
          </InputBlock>
          <InputBlock label="CTA Font Size">
            <NumberInput
              value={themeData.cta_font_size || 14}
              onChange={(fontSize)=>{ setThemeData({...themeData, cta_font_size: fontSize})}}
              min={8}
              max={24}
            />
          </InputBlock>
          <InputBlock label="CTA Shadow">
            <Switcher
              checked={themeData.cta_shadow_enabled}
              onChange={()=>{setThemeData({...themeData, cta_shadow_enabled: !themeData.cta_shadow_enabled})}}
            />
          </InputBlock>
          <InputBlock label="CTA Border Color">
            <ColorPicker
              value={themeData.cta_border_color}
              onChange={(color)=>{setThemeData({...themeData, cta_border_color: color})}}
            />
          </InputBlock>
          <InputBlock label="CTA Border Width">
            <NumberInput
              value={themeData.cta_border_width || 0}
              onChange={(width)=>{setThemeData({...themeData, cta_border_width: width})}}
              max={10}
              size="small"
            />
          </InputBlock>
          <InputBlock label="CTA Height">
            <NumberInput
              value={themeData.cta_height || 27}
              onChange={(height)=>{setThemeData({...themeData, cta_height: height})}}
              max={50}
            />
          </InputBlock>

          <Button type="submit" color="secondary" label="Save &amp; Update" />
          <Button color="error" sx={{ml: 1}} label="Delete" onClick={handleDelete} />
        </form>
      </Grid>
    </Grid>
    <Grid container justifyContent="flex-start" spacing={1}>
      <Grid item xs={12}>
        <BrandingPreview themeData={themeData}/>
      </Grid>
    </Grid>
  </>
}

export default BrandingForm
