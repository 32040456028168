import DescriptionIcon from '@mui/icons-material/Description';
import PaymentIcon from '@mui/icons-material/Payment';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CancelIcon from '@mui/icons-material/Cancel';
import React from "react";
import Chip from "@mui/material/Chip";
import AppTooltip from "../../../shared/AppTooltip";

function PaymentMethod({invoice}) {
  if (invoice.paypal_email) {
    return `PayPal ${invoice.paypal_email}`;
  } else if(invoice.payment_method?.last4) {
    return `${invoice.payment_method.brand} •••• ${invoice.payment_method.last4}`
  } else {
    return '';
  }
}

function Invoice({invoice}) {
  const isUpcoming = invoice.id === 'upcoming';
  const isNotPaid = !isUpcoming && invoice.status === 'open';

  return (
    <tr>
      <td>
        {isUpcoming &&
          <Chip icon={<WatchLaterIcon />} label="upcoming" variant="outlined" />
        }
        {invoice.status === 'void' &&
          <Chip icon={<CancelIcon />} label="void" variant="outlined" />
        }
        {!isUpcoming && invoice.receipt_url &&
          <Chip
          icon={<DescriptionIcon />}
          label={invoice.status}
          color="secondary"
          variant="outlined"
          component="a"
          clickable
          target="_blank" rel="noreferrer" href={invoice.receipt_url}
          />
        }
        {isNotPaid && invoice.hosted_invoice_url &&
        <AppTooltip title={invoice.failure_message} placement="top">
          <Chip
            icon={<PaymentIcon />}
            label="Unpaid"
            color="primary"
            variant="outlined"
            component="a"
            clickable
            target="_blank" rel="noreferrer" href={invoice.hosted_invoice_url}
          />
        </AppTooltip>
        }
        {invoice.status === 'failed' && <AppTooltip title={invoice.failure_message} placement="top">
          <Chip
            icon={<CancelIcon />}
            label="Failed"
            color="primary"
            variant="outlined"
          />
        </AppTooltip>}
      </td>
      <td>{invoice.date}</td>
      <td>{invoice.humanized_amount}</td>
      <td>{invoice.description}</td>
      <td><PaymentMethod invoice={invoice}/></td>
    </tr>
  );
}

export default function Invoices({ status, invoices }) {
  const upcomingInvoices = invoices.filter(i => i.id === 'upcoming');
  const closedInvoices = invoices.filter(i => i.id !== 'upcoming');
  const isLastInvoicePaid = closedInvoices[0]?.status === 'paid';

  return (
    <React.Fragment>
      <h5 className="page-header" style={{marginTop: '2em'}}>Invoices</h5>
      {status === 'fetched' && invoices.length > 0 && <React.Fragment>
        <section>
          <table>
            <thead>
            <tr>
              <th>Status</th>
              <th>Date</th>
              <th>Cost</th>
              <th>Description</th>
              <th>Payment Method</th>
            </tr>
            </thead>
            <tbody>
            {isLastInvoicePaid && upcomingInvoices.map((invoice) => <Invoice key={invoice.id} invoice={invoice}/>)}
            {closedInvoices.map((invoice) => <Invoice key={invoice.id} invoice={invoice}/>)}
            </tbody>
          </table>
        </section>
      </React.Fragment>}
      {status === 'fetched' && invoices.length === 0 && <div className="half-block free_block">
        <div className="padding">
          There are no invoices yet
        </div>
      </div>}
    </React.Fragment>
  );
}
