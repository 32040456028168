import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import IconButton from "@mui/material/IconButton/IconButton";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";

export default function MultiTextInput({ value, onChange, placeholder }) {
  const [values, setValues] = useState(value);

  const addValue = () => {
    setValues([...values, '']);
  };

  const removeValue = (index) => {
    values.splice(index, 1);
    setValues([...values]);
  };

  const adornment = (index) => (
    <InputAdornment position="end">
      {index > 0 && <IconButton onClick={() => removeValue(index)} size="large">
        <RemoveIcon/>
      </IconButton>}
      <IconButton onClick={addValue} size="large">
        <AddIcon/>
      </IconButton>
    </InputAdornment>
  );

  const handleValueChange = (index, event) => {
    values[index] = event.target.value;
    setValues([...values]);
  };

  useEffect(() => {
    onChange({ target: { name: 'value', value: values } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <FormControl size="small" variant="outlined">
      {values.map((v, i) => <OutlinedInput key={`value-${i}`} name="value" onChange={(event) => handleValueChange(i, event)} value={v}
                                           placeholder={placeholder}
                                           variant="outlined" endAdornment={adornment(i)}/>)}
    </FormControl>
  );
}
