import React, { Fragment, useState } from 'react';
import { TextField } from '@mui/material';
import Button from '../../../shared/Button';
import { NavLink } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleForm = (event) => {
    event.preventDefault();
    setLoading(true);
    const form = new FormData();
    form.append('user[email]', email);
    fetch(`${process.env.REACT_APP_AUTH_HOST}/users/password`, {
      method: 'POST',
      body: form,
    })
      .then(() => {
        setSuccess(true);
      })
      .finally(() => {
        setLoading(false);
        setSuccess(true);
      });
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="popup-form">
      <form onSubmit={handleForm}>
        <h3>Reset Password</h3>
        {success && (
          <Fragment>
            <span>
              You will receive an email with instructions on how to reset your
              password in a few minutes.
            </span>
          </Fragment>
        )}
        {!success && (
          <Fragment>
            <TextField
              required
              autoFocus={true}
              name="email"
              label="Your Email"
              type="email"
              variant="outlined"
              onChange={handleEmailChange}
            />
            <Button
              disabled={email === ''}
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
            >
              Send Reset Instructions
            </Button>
            <div className="links-container">
              <NavLink to="/users/sign_in">Sign In</NavLink>
              <br />
              <a href={`${process.env.REACT_APP_AUTH_HOST}/users/sign_up`}>
                Sign up for a New Hello Bar Account
              </a>
            </div>
          </Fragment>
        )}
      </form>
    </div>
  );
};

export default ForgotPassword;
