import React from 'react';
import useAPI from "../utils/api";
import './NewSiteDialog.scss';
import { timezones } from "../utils/timezones";
import Button from "./Button";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import Dialog from "@mui/material/Dialog/Dialog";
import Slide from "@mui/material/Slide/Slide";
import { TextField, FormControl, MenuItem, Select, DialogActions, InputLabel } from "@mui/material";
import { useSnackbar } from "notistack";
import { useRoleCheck } from '../utils/useRoleCheck';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function NewSiteDialog({ isOpen }) {
  const API = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(API.currentRole);
  const [loading, setLoading] = React.useState(false);
  const [newSite, setNewSite] = React.useState({
    url: "",
    timezone: "Etc/UTC"
  });

  const [open, setOpen] = React.useState(isOpen || false);

  const createSite = async (form) => {
    setLoading(true);
    const [ok, response] = await API.createSite(form);
    if (ok) {
      enqueueSnackbar(`Site "${form.url}" has been added successfully!`, { variant: 'success' });
      sessionStorage.setItem('session_signed_up', 'true');
      window.location.pathname = `/sites/${response.id}/`;
      setOpen(false);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
    setLoading(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    await createSite(newSite);
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setNewSite(data => ({
      ...data,
      [name]: value
    }));
  }

  const handleClickOpen = (event) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  return (
    <React.Fragment>
      <div className="button-wrapper">
        <Button onClick={handleClickOpen} color="white" variant="text">Add New Site</Button>
      </div>
      <Dialog
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <h4 style={{marginBottom: '10px'}}>Create A New Site</h4>
          <form id="siteForm" onSubmit={handleSubmit} validate="validate">
            <FormControl fullWidth>
              <TextField required type="url" pattern="https?://.*" value={newSite.url ? newSite.url : 'https://'} onChange={handleInputChange} name="url" autoFocus
                         label="URL (http://)" size='small'/>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: '20px' }}>
              <InputLabel>Timezone</InputLabel>
              <Select required value={newSite.timezone} onChange={handleInputChange} name="timezone" size='small'>
                {timezones.map(({ title, id }) => <MenuItem key={id} value={id}>{title}</MenuItem>)}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button type="submit" loading={loading} color="primary" form="siteForm">Create Site</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default NewSiteDialog;
