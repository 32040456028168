import * as Sentry from "@sentry/browser";
import { useSnackbar } from 'notistack';
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { withErrorBoundary as coreWithErrorBoundary } from 'react-error-boundary';
import { tracking } from "../utils/tracking";

function ErrorFallback({ error, resetErrorBoundary }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    tracking.segmentio.track('Error Seen', { error_message: error?.message || error });
    return enqueueSnackbar(error?.message || error, { variant: 'error' });
  }, [error, enqueueSnackbar]);

  const reload = (event) => {
    resetErrorBoundary(event);
    history.goBack();
  }

  const reset = (event) => {
    resetErrorBoundary(event);
  }

  return (
    <Box textAlign="left">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error?.message || error}</pre>
      <Box justifyContent="space-between" display="flex" width="200px">
        <Button color="primary" variant="outlined" onClick={reset}>Try again</Button>
        <Button color="primary" variant="outlined" onClick={reload}>Go back</Button>
      </Box>
    </Box>
  )
}

export const withErrorBoundary = (ComponentThatMayError, wrapper = null) => {
  return coreWithErrorBoundary(ComponentThatMayError, {
    FallbackComponent: wrapper ? wrapper(ErrorFallback) : ErrorFallback,
    onError(error, info) {
      Sentry.captureException(error, { extra: { info }});
    }
  })
}
