import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";

export default function FollowOnTwitter() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateTwittername = (event) => {
    const customSettings = { ...settings.settings, twitter_handle: event.target.value }
    updateSettings({ settings: customSettings })
  }

  return (
    <>
      <TextField fullWidth variant="outlined" label="Your Twitter username" value={settings.settings.twitter_handle}
                 onChange={updateTwittername} placeholder="@yourtwitter" size='small'/>
    </>
  )
}
