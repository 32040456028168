import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";

export default function FollowOnPinterest() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateURL = (event) => {
    const customSettings = { ...settings.settings, pinterest_user_url: event.target.value }
    updateSettings({ settings: customSettings })
  }

  const updateName = (event) => {
    const customSettings = { ...settings.settings, pinterest_full_name: event.target.value }
    updateSettings({ settings: customSettings })
  }

  return (
    <>
      <TextField fullWidth variant="outlined" label="Pinterest user URL" value={settings.settings.pinterest_user_url}
                 onChange={updateURL} placeholder="pinterest.com/pinterest" size='small'/>
      <TextField fullWidth variant="outlined" label="Full name" value={settings.settings.pinterest_full_name}
                 onChange={updateName} placeholder="Pindiana Jones" size='small'/>
    </>
  )
}
