import React from 'react';
import visa from '../assets/icons/visa.svg';
import mastercard from '../assets/icons/mastercard.svg';
import amex from '../assets/icons/amex.svg';
import jcb from '../assets/icons/jcb.svg';
import unionpay from '../assets/icons/unionpay.svg';
import discover from '../assets/icons/discover.svg';
import diners from '../assets/icons/diners.svg';

export const icons = {
  visa,
  mastercard,
  amex,
  discover,
  diners,
  unionpay,
  jcb
};

export const iconImages = Object.keys(icons).reduce((result, key) => {
  result[key] = (<img src={icons[key]} alt={key}/>);
  return result;
}, {});
