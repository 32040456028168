import React, { useContext, useMemo, useState } from 'react';
import {
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Web } from '@mui/icons-material';
import { Site } from '../components/Header';
import { SiteContext } from '../utils/contexts';

export default function Search(props) {
  const [searchText, setSearchText] = useState('');
  const currentSite = useContext(SiteContext);

  const containsText = (text, searchText) =>
    text.url.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

  const displayedOptions = useMemo(
    () => props.sites.filter((option) => containsText(option, searchText)),
    [searchText]
  );

  return (
    <>
      <TextField
        size="small"
        fullWidth
        autoFocus
        placeholder="Search site..."
        className="site-search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== 'Escape') {
            e.stopPropagation();
          }
        }}
      />
      {props.isCopySearch && (
        <List component="nav" aria-label="sites">
          {displayedOptions.map((site) => (
            <ListItem
              key={site.id}
              onClick={(event) => props.copyPopup(event, site)}
            >
              <ListItemIcon>
                <Web />
              </ListItemIcon>
              <ListItemText primary={site.url} />
            </ListItem>
          ))}
          {displayedOptions.length === 0 && (
            <ListItem key="">No Site Found</ListItem>
          )}
        </List>
      )}
      {props.isHeaderSearch && (
        <>
          {displayedOptions.map((site) => (
            <Site key={site.id} site={site} currentSiteId={currentSite.id} />
          ))}
          {displayedOptions.length === 0 && (
            <li className="padded">No Site Found</li>
          )}
        </>
      )}
    </>
  );
}
