import React, { useState }  from 'react';
import User from './Teams/User';
import '../../assets/styles/_team.scss';
import AppLoader from "../../shared/AppLoader";
import useFetch from "../../utils/useFetch";
import InviteUserDialog from "../Modals/InviteUserDialog";

function Teams({ site }) {
  const [reload, setReload] = useState(false);
  const { loading, status, data: { sites, users } } = useFetch('team', reload);

  return (
    <React.Fragment>
      <AppLoader visible={loading}/>
      {status === 'fetched' && <div id="sites-team" style={{ 'padding': '24px' }}>
        <div className="table-wrapper">
          <InviteUserDialog onChange={() => setReload(!reload)} site={site}/>
          <table className="team-table">
            <tbody>
            {users.map((user) => <User key={user.id} sites={sites} user={user}/>)}
            </tbody>
          </table>
        </div>
        <div className="permission-block">
          <div className="role-icon permission-icon owner"/>
          <strong>Owner:</strong><span>Has full access to the site</span></div>
        <div className="permission-block">
          <div className="role-icon permission-icon admin"/>
          <strong>Admin:</strong><span>Has full access, but cannot edit or view billing information</span></div>
        <div className="permission-block">
          <div className="role-icon permission-icon viewer"/>
          <strong>Viewer:</strong><span>Has view only access, cannot edit or view billing information</span>
        </div>
        <div className="permission-block">
          <div className="role-icon permission-icon none"/>
          <strong>None:</strong><span>Revokes user's access to the site</span></div>
      </div>}
    </React.Fragment>
  );
}

export default Teams;
