export const ruleSegments = {
  AD: [
    { value: 'UTMCampaignCondition', label: 'Ad Campaign' },
    { value: 'UTMContentCondition', label: 'Ad Content' },
    { value: 'UTMMediumCondition', label: 'Ad Medium' },
    { value: 'UTMSourceCondition', label: 'Ad Source' },
    { value: 'UTMTermCondition', label: 'Ad Term' },
    { value: 'AdBlockerCondition', label: 'Ad Blocker Detected' },
  ],
  COOKIE: [{ value: 'CookieValueCondition', label: 'Cookies' }],
  GEO: [
    { value: 'LocationCountryCondition', label: 'Country' },
    { value: 'LocationRegionCondition', label: 'Region/State' },
    { value: 'LocationCityCondition', label: 'City' },
  ],
  PAGE: [
    { value: 'UrlCondition', label: 'Page URL' },
    {
      value: 'UrlPathCondition',
      label: 'URL Path',
      placehodler: '/specific/path',
    },
    {
      value: 'UrlKeywordCondition',
      label: 'URL Keyword',
      placehodler: 'specific keyword',
    },
    {
      value: 'UrlQueryCondition',
      label: 'URL Query',
      placehodler: 'query=value',
    },
    { value: 'PreviousPageURL', label: 'Previous page url' },
    { value: 'ReferrerCondition', label: 'Referrer' },
    { value: 'ReferrerDomainCondition', label: 'Referrer Domain' },
    { value: 'MetaTagContentCondition', label: 'Meta Tag' },
  ],
  OTHER: [
    { value: 'DateCondition', label: 'Date' },
    { value: 'DaysOfWeekCondition', label: 'Day of the week' },
    { value: 'LastVisitCondition', label: 'Days since last visit' },
    { value: 'DeviceCondition', label: 'Device' },
    { value: 'EveryXSession', label: 'Every Nth session' },
    { value: 'NumberOfVisitsCondition', label: 'Number of visits' },
    { value: 'TimeCondition', label: 'Time' },
    {
      value: 'WordpressTagsCondition',
      label: 'Wordpress Post Tags',
      placehodler: 'specific_tag',
    },
  ],
};

export const segmentNameValue = {
  UTMCampaignCondition: 'Ad Campaign',
  UTMContentCondition: 'Ad Content',
  UTMMediumCondition: 'Ad Medium',
  UTMSourceCondition: 'Ad Source',
  UTMTermCondition: 'Ad Term',
  AdBlockerCondition: 'Ad Blocker Detected',
  LocationCityCondition: 'City',
  CookieValueCondition: 'Cookies',
  LocationCountryCondition: 'Country',
  DateCondition: 'Date',
  DaysOfWeekCondition: 'Day of the week',
  LastVisitCondition: 'Days since last visit',
  DeviceCondition: 'Device',
  EveryXSession: 'Every Nth session',
  MetaTagContentCondition: 'Meta tag',
  NumberOfVisitsCondition: 'Number of visits',
  PreviousPageURL: 'Previous page url',
  ReferrerCondition: 'Referrer',
  ReferrerDomainCondition: 'Referrer Domain',
  LocationRegionCondition: 'Region/State',
  TimeCondition: 'Time',
  UrlCondition: 'Page URL',
  UrlPathCondition: 'URL Path',
  UrlKeywordCondition: 'URL Keyword',
  UrlQueryCondition: 'URL Query',
  WordpressTagsCondition: 'Wordpress Post Tags',
};

export const multipleSegments = [
  'UrlCondition',
  'UrlPathCondition',
  'UrlKeywordCondition',
  'LocationCountryCondition',
  'LocationRegionCondition',
  'LocationCityCondition',
  'UTMCampaignCondition',
  'UTMContentCondition',
  'UTMMediumCondition',
  'UTMSourceCondition',
  'UTMTermCondition',
  'DaysOfWeekCondition',
];

export const countries = [
  { value: 'US', label: 'United States' },
  { value: 'EU', label: 'European Union' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua And Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia' },
  { value: 'BA', label: 'Bosnia And Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Columbia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: "Cote D'Ivorie (Ivory Coast)" },
  { value: 'HR', label: 'Croatia (Hrvatska)' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'CD', label: 'Democratic Republic Of Congo (Zaire)' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'TP', label: 'East Timor' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'FX', label: 'France Metropolitan' },
  { value: 'GF', label: 'French Guinea' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard And McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Laos' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macau' },
  { value: 'MK', label: 'Macedonia' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'MD', label: 'Moldova' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar (Burma)' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'AN', label: 'Netherlands Antilles' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'KP', label: 'North Korea' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'SH', label: 'Saint Helena' },
  { value: 'KN', label: 'Saint Kitts And Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'PM', label: 'Saint Pierre And Miquelon' },
  { value: 'VC', label: 'Saint Vincent And The Grenadines' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome And Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SK', label: 'Slovak Republic' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia And South Sandwich Islands' },
  { value: 'KR', label: 'South Korea' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard And Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syria' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad And Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks And Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VA', label: 'Vatican City (Holy See)' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VG', label: 'Virgin Islands (British)' },
  { value: 'VI', label: 'Virgin Islands (US)' },
  { value: 'WF', label: 'Wallis And Futuna Islands' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'WS', label: 'Western Samoa' },
  { value: 'YE', label: 'Yemen' },
  { value: 'YU', label: 'Yugoslavia' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const countriesNameValue = {
  US: 'United States',
  EU: 'European Union',
  AF: 'Afghanistan',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua And Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia',
  BA: 'Bosnia And Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  CF: 'Central African Republic',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Columbia',
  KM: 'Comoros',
  CG: 'Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote D'Ivorie (Ivory Coast)",
  HR: 'Croatia (Hrvatska)',
  CU: 'Cuba',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  CD: 'Democratic Republic Of Congo (Zaire)',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  TP: 'East Timor',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  FX: 'France Metropolitan',
  GF: 'French Guinea',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HT: 'Haiti',
  HM: 'Heard And McDonald Islands',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IR: 'Iran',
  IQ: 'Iraq',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LR: 'Liberia',
  LY: 'Libya',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macau',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  MM: 'Myanmar (Burma)',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  KP: 'North Korea',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  SH: 'Saint Helena',
  KN: 'Saint Kitts And Nevis',
  LC: 'Saint Lucia',
  PM: 'Saint Pierre And Miquelon',
  VC: 'Saint Vincent And The Grenadines',
  SM: 'San Marino',
  ST: 'Sao Tome And Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovak Republic',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  GS: 'South Georgia And South Sandwich Islands',
  KR: 'South Korea',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SD: 'Sudan',
  SR: 'Suriname',
  SJ: 'Svalbard And Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  SY: 'Syria',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  TH: 'Thailand',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad And Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks And Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UM: 'United States Minor Outlying Islands',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VA: 'Vatican City (Holy See)',
  VE: 'Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands (British)',
  VI: 'Virgin Islands (US)',
  WF: 'Wallis And Futuna Islands',
  EH: 'Western Sahara',
  WS: 'Western Samoa',
  YE: 'Yemen',
  YU: 'Yugoslavia',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};

export const weekdaysNameValue = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const getTitleValue = (rule) => {
  return rule.conditions.map((condition) => {
    const name = segmentNameValue[condition.segment];
    const operand = condition.operand.replaceAll('_', ' ');

    if (
      condition.segment === 'DateCondition' &&
      condition.operand === 'between'
    ) {
      return name + ' is ' + operand + ' ' + condition.value.join(' and ');
    } else if (condition.segment === 'LocationCountryCondition') {
      return (
        name +
        ' ' +
        operand +
        ' ' +
        condition.value.map((country) => countriesNameValue[country]).join(', ')
      );
    } else if (condition.segment === 'DaysOfWeekCondition') {
      return (
        name +
        ' ' +
        operand +
        ' ' +
        condition.value.map((day) => weekdaysNameValue[day]).join(', ')
      );
    } else if (condition.segment === 'TimeCondition') {
      let conditionValue = '';

      if (condition.value.length === 1) {
        conditionValue = condition.value.join();
      } else if (condition.value.length === 2) {
        conditionValue = condition.value.join(':');
      } else {
        conditionValue =
          condition.value.slice(0, 2).join(':') + condition.value[2];
      }
      return name + ' is ' + operand + ' ' + conditionValue;
    } else if (condition.segment === 'AdBlockerCondition') {
      const value = condition.value === '1' ? 'Yes' : 'No';
      return name + ' ' + operand + ' ' + value;
    } else if (condition.segment === 'MetaTagContentCondition') {
      return (
        name + ' ' + condition.key_name + ' ' + operand + ' ' + condition.value
      );
    } else if (condition.segment === 'CookieValueCondition') {
      if (operand === 'matches regex') {
        return name + ' ' + condition.key_name + ' exists';
      } else if (operand === 'not matches regex') {
        return name + ' ' + condition.key_name + ' does not exist';
      } else {
        return (
          name +
          ' ' +
          condition.key_name +
          ' ' +
          operand +
          ' ' +
          condition.value
        );
      }
    } else {
      return (
        name +
        ' ' +
        operand +
        ' ' +
        (condition.multiple ? condition.value.join(', ') : condition.value)
      );
    }
  });
};
