import { useSnackbar } from "notistack";

export function useRoleCheck(role) {
  const { enqueueSnackbar } = useSnackbar();

  const handleRoleCheck= () => {
    if (role === 'viewer') {
      enqueueSnackbar('Not allowed to perform this action', { variant: 'error' });
      return true;
    }
    return false;
  };

  return handleRoleCheck;
}
