import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
  },
  palette: {
    text: {
      primary: '#1a1a1a',
      secondary: 'rgba(26,26,26,.5)',
    },
    primary: {
      main: '#E8562A',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#27AB9E',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    success: {
      main: '#27AB9E',
    },
    warning: {
      main: '#E8562A',
    },
    blue: {
      main: '#0070E0',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    action: {
      hoverOpacity: 0.5,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text', invert: true },
          style: ({ theme, ownerState }) => ({
            color: theme.palette[ownerState.color].main,
            '&:hover': {
              color: theme.palette[ownerState.color].contrastText,
            },
          }),
        },
      ],
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color: theme.palette.primary.contrastText,
        }),
        containedWhite: ({ ownerState, theme }) => ({
          color: theme.palette.white.contrastText,
        }),
        containedPrimary: ({ ownerState, theme }) => ({
          color: theme.palette.primary.contrastText,
        }),
        outlinedPrimary: ({ ownerState, theme }) => ({
          color: theme.palette.primary.main,
        }),
        outlinedSecondary: ({ ownerState, theme }) => ({
          color: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
          },
        }),
        textSecondary: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
          },
        }),
        textPrimary: ({ ownerState, theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
        textWhite: ({ ownerState, theme }) => ({
          color: theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: 'white',
          },
        }),
      },
    },
    Snackbar: {
      styleOverrides: {
        root: ({ ownerState }) => {
          debugger;
        },
      },
    },
  },
});
