import { useEffect, useState } from 'react'


export default function VmarketplaceProductNavBar ({ site }) {
  const [vmarketplaceProduct, setVmarketplaceProduct] = useState({})

  if (!vmarketplaceProduct.product_id) {
    fetch(`${ process.env.REACT_APP_AUTH_HOST }/auth/vmarketplace/user_info`, {credentials: 'include'})
      .then((res) => res.json())
      .then(({ product_navbar_data_url, product_id }) => {
        setVmarketplaceProduct({ product_navbar_data_url, product_id })
      })
  }

  useEffect(() => {
    if (vmarketplaceProduct.product_id) {
      const script = document.createElement('script');
      script.src = '//www.cdnstyles.com/static/product_navbar/v1/product_navbar.js';
      script.async = true;
      script.dataset.url = vmarketplaceProduct.product_navbar_data_url
      script.dataset.accountId = site.partner_app_id
      script.dataset.appId = vmarketplaceProduct.product_id
      document.body.appendChild(script)
    }
  }, [site.partner_app_id, vmarketplaceProduct.product_navbar_data_url, vmarketplaceProduct.product_id])

  return ''
}
