import CollapsedPanel from "../components/CollapsedPanel";
import React, {useContext} from "react";
import InputBlock from "../components/InputBlock";
import {EditorContext} from "../../../utils/contexts";
import NumberInput from "../components/NumberInput";
import {placements} from "../components/placements";
import CommonStylingPanel from "./CommonStylingPanel";

export default function BarStylingPanel() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateSize = (size) => {
    updateSettings({
      size
    })
  }

  const size = Number(settings.size) || 50

  return (
    <CollapsedPanel open={false} title="Bar Styling">
      <CommonStylingPanel placements={placements.Bar}/>
      <InputBlock label="Size">
        <NumberInput value={size} onChange={updateSize} min={30} max={100}/>
      </InputBlock>
    </CollapsedPanel>
  )
}
