import React, { createContext, useContext } from 'react';
import { buildSegmentUser } from "./segmentio";

export const AppContext = createContext({
  user: {},
  couponCode: 'HBFREEONEMONTH',
  siteId: null,
  updateUser: () => {}
});

export const SiteContext = createContext({});

export const UserContext = createContext({});
export const TrackingContext = createContext(null);

export function useTracking () {
  return React.useContext(TrackingContext);
};

export const useUser = () => {
  return useContext(UserContext);
};

export const useSite = () => {
  return useContext(SiteContext);
};

export const useSegmentUser = (user, site) => {
  if (user && site) {
    return buildSegmentUser(user, site);
  }
};

export const EditorContext = createContext({
  settings: {},
  siteSettings: {},
  updateSettings: () => {},
  updateTheme: () => {},
  showThemeSelector: () => {}
})


export const EditorStateContext = createContext({
  state: {},
  dispatch: () => {}
})

export const EditorBinding = createContext({
  showCTA: false,
  showHeading: false,
  showNoThanks: false,
  showCloseButton: false,
  showCaption: false,
  showContent: false
})

export const AuthContext = createContext({
  email: '',
  password: ''
})

export const SearchContext = createContext({
  query: '',
  rules: []
})

export const ManageContext = createContext({
  rules: []
})

export const SearchContactListContext = createContext({
  query: '',
  contactLists: []
})

export const ContactListContext = createContext({
  contactLists: []
})