import React from 'react';
import MenuTabs from '../shared/Tabs';
import ProfileInfo from '../components/Settings/ProfileInfo';
import Teams from '../components/Settings/Teams';
import Privacy from './Settings/Privacy';
import SiteInfo from '../components/Settings/SiteInfo';
import Billing from '../components/Settings/Billing';
import { SitePath } from '../shared/SiteLink';
import { withTracking } from '../shared/Tracking';
import Branding from './Settings/Branding';
import Fonts from './Settings/Fonts';
import Todo from './Modals/Todo';
import Identities from './Settings/Identities';

function Settings({ user, site }) {
  const tabs = [
    {
      name: 'You',
      path: SitePath('/settings'),
      render: () => <ProfileInfo user={user} />,
    },
    {
      name: 'Team',
      path: SitePath('/settings/team'),
      render: () => <Teams site={site} />,
    },
    {
      name: 'Site',
      path: SitePath('/settings/site'),
      render: () => <SiteInfo site={site} />,
    },
    {
      name: 'Branding',
      path: SitePath('/settings/branding'),
      render: () => <Branding site={site} />,
    },
    {
      name: 'Fonts',
      path: SitePath('/settings/fonts'),
      render: () => <Fonts site={site} />,
    },
    {
      name: 'Privacy',
      path: SitePath('/settings/privacy'),
      render: () => <Privacy />,
    },
    {
      name: 'Integrations',
      path: SitePath('/settings/identities'),
      render: () => <Identities site={site} />,
    },
  ];
  const tcoursebuilderTabs = [
    {
      name: 'Site',
      path: SitePath('/settings/site'),
      render: () => <SiteInfo site={site} />,
    },
    {
      name: 'Fonts',
      path: SitePath('/settings/fonts'),
      render: () => <Fonts site={site} />,
    },
    {
      name: 'Privacy',
      path: SitePath('/settings/privacy'),
      render: () => <Privacy />,
    },
  ];
  const dsitebuilderTabs = [
    {
      name: 'Site',
      path: SitePath('/settings/site'),
      render: () => <SiteInfo site={site} />,
    },
    {
      name: 'Fonts',
      path: SitePath('/settings/fonts'),
      render: () => <Fonts site={site} />,
    },
    {
      name: 'Privacy',
      path: SitePath('/settings/privacy'),
      render: () => <Privacy />,
    },
  ];

  const shopifyTabs = [
    {
      name: 'Site',
      path: SitePath('/settings/site'),
      render: () => <SiteInfo site={site} />,
    },
    {
      name: 'Fonts',
      path: SitePath('/settings/fonts'),
      render: () => <Fonts site={site} />,
    },
    {
      name: 'Privacy',
      path: SitePath('/settings/privacy'),
      render: () => <Privacy />,
    },
    {
      name: 'Branding',
      path: SitePath('/settings/branding'),
      render: () => <Branding site={site} />,
    },
    {
      name: 'Integrations',
      path: SitePath('/settings/identities'),
      render: () => <Identities site={site} />,
    },
  ];

  const bigcommerceTabs = [
    {
      name: 'Site',
      path: SitePath('/settings/site'),
      render: () => <SiteInfo site={site} />,
    },
    {
      name: 'Branding',
      path: SitePath('/settings/branding'),
      render: () => <Branding site={site} />,
    },
    {
      name: 'Fonts',
      path: SitePath('/settings/fonts'),
      render: () => <Fonts site={site} />,
    },
    {
      name: 'Privacy',
      path: SitePath('/settings/privacy'),
      render: () => <Privacy />,
    },
    {
      name: 'Integrations',
      path: SitePath('/settings/identities'),
      render: () => <Identities site={site} />,
    },
  ];

  if (site.current_role !== 'viewer') {
    const billingTab = {
      name: 'Billing & Invoices',
      path: SitePath('/settings/billing'),
      render: () => <Billing site={site} />,
    };
    tabs.push(billingTab);
    tcoursebuilderTabs.push(billingTab);
    bigcommerceTabs.push(billingTab);
  }

  return (
    <div id="user-edit">
      {!site.partner && <MenuTabs tabs={tabs} />}
      {site.partner && site.partner === 'dsitebuilder' && (
        <MenuTabs tabs={dsitebuilderTabs} />
      )}
      {site.partner && site.partner === 'tcoursebuilder' && (
        <MenuTabs tabs={tcoursebuilderTabs} />
      )}
      {site.partner && site.partner === 'shopify' && (
        <MenuTabs tabs={shopifyTabs} />
      )}
      {site.partner && site.partner === 'bigcommerce' && (
        <MenuTabs tabs={bigcommerceTabs} />
      )}
      <Todo />
    </div>
  );
}

export default withTracking(Settings);
