import React from 'react';
import Tooltips from './Tooltips';

const PaywallLock = (props) => {
  const { message, url } = props;

  const handleClick = () => {
    window.open(url, '_self');
  };

  return (
    <div className="paywall-lock" onClick={handleClick}>
      <Tooltips icon={false} message={message} />
    </div>
  );
};

export default PaywallLock;
