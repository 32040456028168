import { SUBTYPES } from './constants';
import { createRef } from 'react';

const common = [
  'Alert Styling',
  'Bar Styling',
  'Modal Styling',
  'Inline Styling',
  'Slider Styling',
  'Page Takeover Styling',
  'Image',
  'Theme',
  'Button text',
  'Your Library',
];

const EMAIL_PANELS = [
  ...common,
  'Input Fields',
  'CTA Button Styling',
  'Input Field Styling',
  'Yes Button Styling',
  'No Button Styling',
  'Countdown Timer',
  'No Thanks Settings',
];

const CALL_PANELS = [
  ...common,
  'Phone Number',
  'CTA Button Styling',
  'Yes Button Styling',
  'No Button Styling',
  'Countdown Timer',
  'No Thanks Settings',
];

const SOCIAL_PANELS = [...common, 'Social Profile', 'No Thanks Settings'];

const ANNOUNCEMENT_PANELS = [...common, 'Countdown Timer'];

const TRAFFIC_PANELS = [
  ...common,
  'URL Target',
  'CTA Button Styling',
  'Yes Button Styling',
  'No Button Styling',
  'Countdown Timer',
  'No Thanks Settings',
];

const RETIREMENT_PANELS = [
  ...common,
  'Page Takeover Styling',
  'CTA Button Styling',
  'Input Field Styling',
  'Input Fields',
  'URL Target',
];

const LEADFORM_PANELS = [
  ...common,
  'CTA Button Styling',
  'Input Field Styling',
  'Input Fields',
  'URL Target',
];

const WEBFORM_PANELS = [
  ...common,
  'Input Fields',
  'CTA Button Styling',
  'Input Field Styling',
  'Web Form Options',
  'No Thanks Settings',
];

export const days = [
  {label: 'Display after every page load', value: 0},
  {label: '1 day', value: 1},
  {label: '2 days', value: 2},
  {label: '3 days', value: 3},
  {label: '4 days', value: 4},
  {label: '5 days', value: 5},
  {label: '6 days', value: 6},
  {label: '7 days', value: 7},
  {label: '8 days', value: 8},
  {label: '9 days', value: 9},
  {label: '10 days', value: 10},
  {label: '15 days', value: 15},
  {label: '20 days', value: 20},
  {label: '30 days', value: 30},
  {label: '60 days', value: 60},
  {label: '90 days', value: 90},
  {label: '120 days', value: 120},
  {label: 'Never display again', value: 150}
]

export function extractPlanTier(plan) {
  const planlabel = plan && plan.label;
  let planTier = 0;

  switch (planlabel) {
    case 'FREE':
      return (planTier = 0);
    case 'FFR':
      return (planTier = 1);
    case 'STR':
      return (planTier = 2);
    case 'GRW':
      return (planTier = 3);
    case 'PRM':
      return (planTier = 4);
    case 'ELT':
      return (planTier = 5);
    default:
      return (planTier = 4);
  }
}

export function panelForSubtype(title, subtype) {
  const type = subtype.split('/')[0];

  switch (type) {
    case SUBTYPES.EMAIL:
      return EMAIL_PANELS.includes(title);
    case SUBTYPES.CALL:
      return CALL_PANELS.includes(title);
    case 'social':
      return SOCIAL_PANELS.includes(title);
    case SUBTYPES.ANNOUNCEMENT:
      return ANNOUNCEMENT_PANELS.includes(title);
    case SUBTYPES.TRAFFIC:
      return TRAFFIC_PANELS.includes(title);
    case SUBTYPES.RETIREMENT:
      return RETIREMENT_PANELS.includes(title);
    case SUBTYPES.LEADFORM:
      return LEADFORM_PANELS.includes(title);
    case SUBTYPES.WEBFORM:
      return WEBFORM_PANELS.includes(title);
    default:
      return true;
  }
}

export function extractContent(html) {
  const span = document.createElement('span');
  span.innerHTML = html;

  return [span.textContent || span.innerText].toString().replace(/ +/g, ' ');
}

export function generateHtml(text, css, className) {
  const p = document.createElement('p');
  if (className) {
    p.classList.add(className);
  }
  const content = document.createElement('span');
  content.innerText = text;

  content.style.fontFamily = css.fontFamily;
  content.style.fontSize = `${css.fontSize}px`;
  content.style.fontWeight = css.fontWeight;

  p.appendChild(content);

  return p.outerHTML;
}

export function parseCss(html, defaultFont) {
  const div = document.createElement('div');
  div.innerHTML = html;
  const content = div.querySelector('p :first-child');

  return {
    fontFamily: content?.style?.fontFamily || defaultFont,
    fontWeight: content?.style?.fontWeight || '400',
    fontSize: parseInt(content?.style?.fontSize) || 20,
  };
}

export const headlineRef = createRef();
export const ctaRef = createRef();
