import {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";
import CollapsedPanel from "../components/CollapsedPanel";
import {TextField} from "@mui/material";

export default function WebformOptionsPanel() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateOptions = (event) => {
    const customSettings = { ...settings.settings, webform_options: event.target.value }
    updateSettings({ settings: customSettings })
  }

  return (
    <CollapsedPanel open={false} title="Web Form Options">
      <TextField value={settings.settings.webform_options} multiline onChange={updateOptions} label="Options" size="small"/>
    </CollapsedPanel>
  )
}
