import FormControl from "@mui/material/FormControl/FormControl";
import TextField from "@mui/material/TextField";
import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function WordpressTagsCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "includes", "label": "includes" },
      { "value": "does_not_include", "label": "does not include" },
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'includes' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <FormControl variant="outlined">
        <TextField size="small" name="value" onChange={onChange} value={condition.value} placeholder="specific_tag" variant="outlined"/>
      </FormControl>
    </div>
  );
}
