import React, {useState, useEffect, Fragment} from "react";
import {TextField} from "@mui/material";
import {LabelSelector} from "../Steps/components";
import InputBlock from "./InputBlock";
import FontPicker from "./FontPicker";
import FontWeight from "./FontWeight";
import NumberInput from "./NumberInput";
import { parseCss, extractContent, generateHtml } from "../utils";

export default function SimpleTextEditorPanel({ html, defaultContent, onUpdate, defaultFont }) {
  const [css, setCss] = useState(parseCss(html, defaultFont))
  const content = extractContent(html)
  const updateContent = (event) => {
    if (event.target.value === '') {
      onUpdate('')
    } else {
      onUpdate(generateHtml(event.target.value, css, 'simple'))
    }
  }

  const updateFont = (font) => {
    setCss({ ...css, fontFamily: font })
  }

  const updateFontSize = (size) => {
    setCss({ ...css, fontSize: size })
  }

  const updateFontWeight = (weight) => {
    setCss({ ...css, fontWeight: weight })
  }

  useEffect(() => {
    setCss(parseCss(html, defaultFont))
  }, [html, defaultContent, defaultFont])

  useEffect(() => {
    if (content) {
      onUpdate(generateHtml(content, css, 'simple'), css)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, css])

  return (
    <Fragment>
      <LabelSelector/>
      <LabelSelector>
        <TextField multiline variant="outlined" value={content}
                   onChange={updateContent} placeholder={defaultContent} size='small'/>
      </LabelSelector>
      <InputBlock label="Font family">
        <FontPicker value={css.fontFamily} onChange={updateFont}/>
      </InputBlock>
      <InputBlock label="Font Size">
        <NumberInput value={css.fontSize} onChange={updateFontSize} max={48}/>
      </InputBlock>
      <InputBlock label="Font Weight">
        <FontWeight value={css.fontWeight} onChange={updateFontWeight} family={css.fontFamily}/>
      </InputBlock>
    </Fragment>
  )
}
