import { useEffect } from 'react';
import {FONT_FAMILIES, GOOGLE_FONTS} from "../components/Editor/constants";
import useFetch from './useFetch';

const fontsUrl = () => {
  const fonts = Object.values(FONT_FAMILIES).filter(f => GOOGLE_FONTS.includes(f.replace(/\s/g, '+')))
  const families = fonts.join('&family=')
  return `https://fonts.googleapis.com/css2?family=${families}`
}

const useFonts = () => {
  const {loading, status, siteSettings} = useFetch('siteSettings');
  useEffect(() => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = fontsUrl();
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    }
  }, []);

  useEffect(() => {
    if (!loading && status === 'fetched' && siteSettings?.font_uploads) {
      siteSettings?.font_uploads.map((f) => {
        const font = `@font-face {
          font-family: '${f.font_name}';
          src: url("${f.url}");
        }`

        const newLink = document.createElement('style');
        newLink.append(font);
        document.head.appendChild(newLink);
      })
    }
  }, [loading])
};

export default useFonts;
export { fontsUrl };
