import React from 'react';
import { timezones } from '../../../utils/timezones';
import Button from '../../../shared/Button';
import SiteLink from '../../../shared/SiteLink';
import ConfirmationDialog from '../../Modals/ConfirmationDialog';
import DialogContentText from '@mui/material/DialogContentText/DialogContentText';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import UpgradeDialog from '../../Modals/UpgradeDialog';
import EmailValidationServiceAddOnDialog from '../../Modals/EmailValidationServiceAddOnDialog';
import { Checkbox, FormControlLabel } from '@mui/material';
import Tooltips from '../../Tooltips';

class SiteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFree: props.site.plan.free || props.site.plan.free_forever,
      shouldBuyEmailValidationCredits:
        !this.isFree && props.site.validate_email_credit === 0,
      id: props.site.id,
      url: props.site.url,
      timezone: props.site.timezone,
      opted_in_to_email_digest: props.site.opted_in_to_email_digest,
      gaEnabled: props.site.ga_enabled,
      validateEmail: props.site.validate_email,
      showUpgradedModal: false,
    };
    this.onSubmit = props.onSubmit.bind(this);
    this.onSiteDelete = props.onSiteDelete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteSite = this.handleDeleteSite.bind(this);
    this.gaEnableChange = this.gaEnableChange.bind(this);
    this.handleValidateEmail = this.handleValidateEmail.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  gaEnableChange(event) {
    this.state.isFree
      ? this.setState({
          showUpgradedModal: true,
        })
      : this.setState({
          gaEnabled: event.target.checked,
        });
  }

  // handleValidateEmail() {
  //   this.setState({
  //     validateEmail: !this.state.validateEmail,
  //   });
  // }

  handleValidateEmail = () => {
    this.state.isFree
      ? this.setState({
          showUpgradedModal: true,
        })
      : this.state.shouldBuyEmailValidationCredits
      ? this.setState({
          showValidationAddOnModal: true,
        })
      : this.setState({
          validateEmail: !this.state.validateEmail,
        });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    this.onSubmit({
      id: this.state.id,
      url: this.state.url,
      timezone: this.state.timezone,
      opted_in_to_email_digest: this.state.opted_in_to_email_digest,
      ga_enabled: this.state.gaEnabled ? 1 : 0,
      validate_email: this.state.validateEmail,
    }).then(() => this.setState({ loading: false }));
  }

  handleDeleteSite() {
    return this.onSiteDelete(this.state.id);
  }

  render() {
    return (
      <>
        {this.state.showUpgradedModal && (
          <UpgradeDialog
            onClose={() => this.setState({ showUpgradedModal: false })}
          />
        )}
        {this.state.showValidationAddOnModal && (
          <EmailValidationServiceAddOnDialog
            site={this.props.site}
            isOpen={true}
          />
        )}
        <form onSubmit={this.handleSubmit}>
          <Grid>
            <Grid
              container
              justifyContent="flex-start"
              className="inputs"
              spacing={3}
            >
              <Grid item xs={12}>
                <Grid container spacing={6}>
                  <Grid item xs={3}>
                    <FormControl fullWidth className="UrlInput">
                      <TextField
                        required
                        value={this.state.url}
                        onChange={this.handleInputChange}
                        name="url"
                        label="URL"
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth className="TimezoneInput">
                      <TextField
                        select
                        label="Timezone"
                        required
                        value={this.state.timezone}
                        onChange={this.handleInputChange}
                        name="timezone"
                        size="small"
                      >
                        {timezones.map(({ title, id }) => (
                          <MenuItem key={id} value={id}>
                            {title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.gaEnabled}
                          onChange={this.gaEnableChange}
                          size="small"
                        />
                      }
                      label="Send Popup events to Google Analytics"
                    />
                    <Tooltips
                      icon={true}
                      title="Send Popup events to Google Analytics"
                      url="https://support.hellobar.com/support/solutions/articles/44002395028-how-to-sync-your-hello-bar-popup-views-and-conversion-to-google-analytics-ga4-"
                      message="For better visibility and tracking we will send any popup View/Click/Conversion event on your site to your installed Google Analytics property."
                    />
                  </Grid>
                </Grid>
              </Grid>
              {this.props.site?.opted_in_to_email_digest && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.validateEmail}
                            id="site_validate_email"
                            onChange={this.handleValidateEmail}
                          />
                        }
                        label="Validate email deliverability to ensure a clean email list"
                      />
                      <Tooltips
                        icon={true}
                        message="We'll validate the deliverabity of the email entered in the lead form by your visitor and add the results in the contact list"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="button-wrapper">
                      <SiteLink to="/install">
                        Installation instructions
                      </SiteLink>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <footer>
            <Button
              type="submit"
              loading={this.state.loading}
              color="secondary"
              label="Save &amp; Update"
            />{' '}
            &nbsp;&nbsp;
            <ConfirmationDialog
              onConfirm={this.handleDeleteSite}
              buttonColor="primary"
              buttonTitle="Delete Site"
            >
              <DialogContentText>
                Historical data will be deleted and you will not be able to
                recover it after this. Are you sure you wish to delete this
                site?
              </DialogContentText>
            </ConfirmationDialog>
          </footer>
        </form>
      </>
    );
  }
}

export default SiteForm;
