import React, { useState } from "react";
import { useElements, useStripe, CardElement } from '@stripe/react-stripe-js';
import { Button, FormControl, Paper, FormHelperText } from "@mui/material";
import { useSnackbar } from "notistack";
import useAPI from "../../../utils/api";
import Loader from "react-loader-spinner";

export default function UpdateCreditCard({ onUpdate, onCancel }) {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const API = useAPI();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    setLoading(true);
    // Use your card Element with other Stripe.js APIs
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } else {
      const [ok, response] = await API.updateCreditCard(paymentMethod.id);
      if (ok) {
        const { brand, last4, exp_month, exp_year } = paymentMethod.card;
        onUpdate({ brand, last4, exp_month, exp_year }, paymentMethod);
        enqueueSnackbar("Credit Card successfully updated.", { variant: 'success' });
      } else {
        enqueueSnackbar(response?.message || response.error, { variant: 'error' });
      }
    }
    setLoading(false);
  };

  const label = loading ? <Loader height={10} visible={loading} type="ThreeDots" color="white"/> : 'Save';

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Paper elevation={3}>
          <FormControl className="StripeElement--wrapper">
            <CardElement onReady={element => element.focus()}/>
            <FormHelperText id="my-helper-text"></FormHelperText>
          </FormControl>
          <Button color="primary" variant="contained" onClick={handleSubmit}>{label}</Button>
          <Button color="white" variant="contained" onClick={onCancel}>Cancel</Button>
        </Paper>
      </form>
    </React.Fragment>
  );
}
