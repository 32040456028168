import CollapsedPanel from "../components/CollapsedPanel";
import React, { useContext } from "react";
import {placements} from "../components/placements";
import CommonStylingPanel from "./CommonStylingPanel";
import NumberInput from "../components/NumberInput";
import InputBlock from "../components/InputBlock";
import { EditorContext } from "../../../utils/contexts";

export default function ModalStylingPanel() {
  const {settings, updateSettings} = useContext(EditorContext)
  
  const updateSize = (size) => {
    updateSettings({
      size
    })
  }
  
  const size = Math.max(Number(settings.size), 250)
  
  return (
    <CollapsedPanel open={false} title="Modal Styling">
      <CommonStylingPanel placements={placements.Modal}/>
      <InputBlock label="Min Height">
        <NumberInput value={size} onChange={updateSize} max={1000} step={50} size='small'/>
      </InputBlock>
    </CollapsedPanel>
  )
}
