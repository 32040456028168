import React from 'react';
import '../../assets/styles/_table_rules.scss';
import { withErrorBoundary } from "../../shared/ErrorFallback";
import RuleHeader from "./RuleHeader";
import Rules from "./Rules";

function AllRules({ rules, togglePopup, deletePopup, copyPopup, resetCache }) {
  const actions = { togglePopup, deletePopup, copyPopup }
  return (
    <div style={{ 'padding': '24px' }}>
      <table className="rules-wrapper">
        <RuleHeader/>
        <Rules rules={rules} actions={actions} scope="all" resetCache={resetCache} />
      </table>
    </div>
  )
}

export default withErrorBoundary(AllRules);
