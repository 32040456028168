import React from 'react';
import Button from '../../shared/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme }) => ({
  fontSize: '1.1em',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ThemeOverrideDialog(props) {
  const [open, setOpen] = React.useState(true);

  const { onConfirm, onClose, updateThemeFlag } = props;

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
    onClose();
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    setOpen(false);
    onConfirm();
  };

  return (
    <React.Fragment>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <StyledAlert variant="outlined" severity="warning">
            {props.children}
          </StyledAlert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="white">
            {updateThemeFlag ? "No, Keep new theme's defaults" : 'Cancel'}
          </Button>
          <span />
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {updateThemeFlag
              ? "Yes, Override theme's defaults with my progress"
              : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
