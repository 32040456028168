import { useEffect } from 'react';

const useScript = (url, callback, check = () => false) => {
  useEffect(() => {
    if (url && !check()) {
      const script = document.createElement('script');

      script.src = url;
      script.async = true;
      script.addEventListener('load', callback || (() => {}))

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    } else {
      callback && callback()
    }
  }, [url, callback]);
};

export default useScript;
