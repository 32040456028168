import React from 'react';

export default function Error({error}) {
  if (!error) {
    error = { message: "Unexpected error. Please try again later" };
  }

  return (
    <div>{error.message}</div>
  );
}
