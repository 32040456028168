import React from 'react'

export default function TypeIcon() {
  return (
    <svg width="35px" height="35px" viewBox="0 0 39 39" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Hello-Bar-New-Creation-Flow" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="2---Type" transform="translate(-28.000000, -187.000000)">
          <g id="menu-items" transform="translate(0.000000, 82.000000)">
            <g id="Type" transform="translate(28.000000, 105.000000)">
              <g id="Group">
                <rect id="Rectangle-8" x="0" y="0" width="18" height="18" rx="2"/>
                <rect id="Rectangle-8" fill="#FFFFFF" x="2" y="2" width="14" height="3"/>
                <rect id="Rectangle-8" x="0" y="21" width="18" height="18" rx="2"/>
                <rect id="Rectangle-8" x="21" y="0" width="18" height="18" rx="2"/>
                <rect id="Rectangle-8" x="21" y="21" width="18" height="18" rx="2"/>
                <rect id="Rectangle-8" fill="#FFFFFF" x="23" y="5" width="14" height="8"/>
                <rect id="Rectangle-8" fill="#FFFFFF" x="23" y="23" width="14" height="14"/>
                <rect id="Rectangle-8" fill="#FFFFFF" x="8" y="32" width="8" height="5"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
