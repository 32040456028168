import React, {useState} from 'react'
import reactCSS from 'reactcss'
import { ChromePicker } from 'react-color'

export default function ColorPicker({ value, onChange }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const color = value ?
    (value.indexOf('#') === -1 ? `#${value}` : value) :
    '#000000'

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color) => {
    onChange && onChange(color.hex.replace('#', ''))
  };

  const styles = reactCSS({
    'default': {
      container: {
        position: 'relative'
      },
      color: {
        width: '100px',
        height: '30px',
        borderRadius: '2px',
        background: `${ color }`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '10',
        margin: '0 -115px',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      },
    },
  })

  return (
    <div style={styles.container}>
      {displayColorPicker && <div style={ styles.cover } onClick={ handleClose }/>}
      <div style={ styles.swatch } onClick={ handleClick }>
        <div style={ styles.color } />
      </div>
      { displayColorPicker && <div style={ styles.popover }>
        <ChromePicker disableAlpha={true} color={ color } onChange={ handleChange } />
      </div> }
    </div>
  )
}
