import { countries } from "../../../utils/ruleConditions";
import MultiSelectInput from "../MultiSelectInput";
import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function CountryCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "is", "label": "is" },
      { "value": "is_not", "label": "is not" }
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  if (condition.value && condition.value[0] === '') {
    onChange({ target: { name: 'value', value: [] }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <MultiSelectInput items={countries} value={condition.value} onChange={onChange} defaultContent="Select countries"/>
    </div>
  );
}
