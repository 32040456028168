import EditContactList from '../../../components/Modals/EditContactList.js';
import useAPI from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import styled from 'styled-components';

const EditContactListButton = styled.div`
  color: #27ab9e;
  margin: 2em 0 0 0;
  cursor: pointer;
`;

export const EditCurrentContactList = ({ id, setUpdateList, siteUsers }) => {
  const api = useAPI();

  const {
    status,
    data: { name, provider_name, provider, connection, contacts, validate_email },
  } = useFetch('contactList', id);
  const contactList = { name, provider_name, provider, connection, contacts, validate_email };

  const saveContactList = async (contactList) => {
    const data = {
      identity_id: contactList.connection.id,
      provider_token: contactList.provider.key,
      name: contactList.name,
      double_optin: contactList.connection.double_optin,
      email_enabled: contactList.email_enabled,
      validate_email: contactList.validate_email,
      email_recipients: contactList.email_recipients,
      data: {
        webhook_url: contactList.connection.webhook_url,
        webhook_method: contactList.connection.webhook_method,
        username: contactList.connection.username,
        api_key: contactList.connection.api_key,
        api_secret: contactList.connection.api_secret,
        app_url: contactList.connection.app_url,
        embed_code: contactList.connection.embed_code,
        list_api_id: contactList.connection.list_api_id,
        remote_id: contactList.connection.remote_id,
        tags: contactList.connection.tags
      },
    };

    const [ok, response] = await api.updateContactList(id, data);

    if (ok) {
      setUpdateList(response);
    } else {
      throw response;
    }
  };

  if (status !== 'fetched') {
    return null;
  }

  return (
    <EditContactList
      contactList={contactList}
      siteUsers={siteUsers}
      button={(handleClickOpen) => (
        <EditContactListButton onClick={handleClickOpen}>
          Edit '{name}'
        </EditContactListButton>
      )}
      onConfirm={saveContactList}
    />
  );
};
