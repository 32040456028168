import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";
import { FormControl, MenuItem, Select } from "@mui/material";

export default function AdBlockerCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "is", "label": "is" }
    ];

  const blockerValues = [
    { "value": "1", "label": "Yes" },
    { "value": "0", "label": "No" },
  ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <FormControl size="small" variant="outlined">
        <Select name="value" value={condition.value} onChange={onChange} displayEmpty
          renderValue={condition.value ? undefined : () => "Select Value"} >
          {blockerValues.map(value => <MenuItem key={value.label} value={value.value}>{value.label}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
}
