import { icons } from '../components/Contacts/icons';

export const providers = [
  {
    key: 'aweber',
    title: 'AWeber',
    message: 'AWeber',
    icon: icons.aweber,
    tier: 2,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'active_campaign',
    title: 'Active Campaign',
    message: 'Active Campaign',
    icon: icons.active_campaign,
    tier: 2,
    settings: {
      requires_app_url: true,
      requires_api_key: true,
    },
  },
  {
    key: 'brevo',
    title: 'Brevo',
    message: 'Brevo',
    icon: icons.brevo,
    tier: 2,
    settings: {
      requires_api_key: true
    },
  },
  // {
  //   key: 'bronto',
  //   title: 'Bronto',
  //   message: 'Bronto',
  //   icon: icons.bronto,
  //   tier: 2,
  //   settings: {
  //     requires_account_id: true,
  //     requires_list_api_id: true,
  //   },
  // },
  {
    key: 'createsend',
    title: 'Campaign Monitor',
    message: 'Campaign Monitor',
    icon: icons.createsend,
    tier: 2,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'constantcontact',
    title: 'Constant Contact',
    message: 'Constant Contact',
    icon: icons.constantcontact,
    tier: 2,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'convert_kit',
    title: 'ConvertKit',
    message: 'ConvertKit',
    icon: icons.convert_kit,
    tier: 2,
    settings: {
      requires_api_key: true,
      tags_enabled: true
    },
  },
  {
    key: 'drip',
    title: 'Drip',
    message: 'Drip',
    icon: icons.drip,
    tier: 0,
    settings: {
      oauth: true,
      double_optin: true,
    },
  },
  {
    key: 'get_response_api',
    title: 'GetResponse',
    message: 'GetResponse',
    icon: icons.get_response_api,
    tier: 2,
    settings: {
      requires_api_key: true,
    },
  },
  {
    key: 'hubspot',
    title: 'HubSpot',
    message: 'HubSpot',
    icon: icons.hubspot,
    tier: 4,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'infusionsoft',
    title: 'Infusionsoft',
    message: 'Infusionsoft',
    icon: icons.infusionsoft,
    tier: 2,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'integrately',
    title: 'Integrately',
    message: 'Integrately',
    icon: icons.integrately,
    tier: 0,
    settings: {
      requires_webhook_url: true,
    },
  },
  {
    key: 'intercom',
    title: 'Intercom',
    message: 'Intercom',
    icon: icons.intercom,
    tier: 2,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'iterable',
    title: 'Iterable',
    message: 'Iterable',
    icon: icons.iterable,
    tier: 2,
    settings: {
      requires_api_key: true,
    },
  },
  {
    key: 'klaviyo',
    title: 'Klaviyo',
    message: 'Klaviyo',
    icon: icons.klaviyo,
    tier: 4,
    settings: {
      requires_api_key: true,
    },
  },
  {
    key: 'mad_mimi_api',
    title: 'MadMimi',
    message: 'MadMimi',
    icon: icons.mad_mimi_api,
    tier: 2,
    settings: {
      requires_api_key: true,
      requires_username: true,
    },
  },
  {
    key: 'mailchimp',
    title: 'MailChimp',
    message: 'MailChimp',
    icon: icons.mailchimp,
    tier: 0,
    settings: {
      oauth: true,
      double_optin: true,
    },
  },
  {
    key: 'mailerlite',
    title: 'MailerLite',
    message: 'MailerLite',
    icon: icons.mailerlite,
    tier: 2,
    settings: {
      requires_api_key: true,
    },
  },
  {
    key: 'maropost',
    title: 'Maropost',
    message: 'Maropost',
    icon: icons.maropost,
    tier: 2,
    settings: {
      requires_account_id: true,
      requires_api_key: true,
    },
  },
  {
    key: 'my_emma',
    title: 'MyEmma',
    message: 'MyEmma',
    icon: icons.my_emma,
    tier: 2,
    settings: {
      requires_embed_code: true,
    },
  },
  {
    key: 'sailthru',
    title: 'SailThru',
    message: 'Sailthru',
    icon: icons.sailthru,
    tier: 4,
    settings: {
      requires_api_key: true,
      requires_api_secret: true,
    },
  },
  {
    key: 'salesforce',
    title: 'Salesforce',
    message: 'Salesforce',
    icon: icons.salesforce,
    tier: 4,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'sender_api',
    title: 'Sender',
    message: 'Sender',
    icon: icons.sender_api,
    tier: 2,
    settings: {
      requires_api_key: true,
    },
  },
  {
    key: 'slack',
    title: 'Slack',
    message: 'Slack',
    icon: icons.slack,
    tier: 2,
    settings: {
      oauth: true,
    },
  },
  {
    key: 'verticalresponse',
    title: 'Vertical Response',
    message: 'Vertical Response',
    icon: icons.verticalresponse,
    tier: 2,
    settings: {
      oauth: true,
      double_optin: true,
    },
  },
  {
    key: 'webhooks',
    title: 'Webhooks',
    message: 'Webhooks',
    icon: icons.webhooks,
    tier: 2,
    settings: {
      requires_webhook_url: true,
    },
  },
  {
    key: 'zapier',
    title: 'Zapier',
    message: 'Zapier',
    icon: icons.zapier,
    tier: 0,
    settings: {
      requires_webhook_url: true,
    },
  },
];

export const hellobar = {
  key: 'hellobar',
  title: 'Hellobar',
  message: 'Hellobar',
  icon: icons.hellobar,
  tier: 0,
  connected: true,
  settings: {},
};
