import React, {useContext} from "react";
import {EditorContext, EditorBinding} from "../../../utils/contexts";
import CollapsedPanel from "../components/CollapsedPanel";
import {findTheme} from "../ThemeSelector/updateTheme";
import ContentEditorPanel from "../components/ContentEditorPanel";

export default function CTAPanel() {
  const {settings, siteSettings, updateSettings} = useContext(EditorContext)
  const theme = findTheme(settings, siteSettings)
  const defaultCTA = (theme && theme.defaults[settings.type]?.link_text) || ""

  const editorBindings = useContext(EditorBinding)

  const onUpdate = (content) => {
    updateSettings({ link_text: content })
  }

  return (
    <CollapsedPanel open={editorBindings.showCTA} title="CTA Button">
      <ContentEditorPanel contentKey="link_text" defaultContent={defaultCTA} onUpdate={onUpdate}/>
    </CollapsedPanel>
  )
}
