import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import { ruleSegments } from "../../utils/ruleConditions";
import Tooltips from "../Tooltips";
import { CONDITION_TOOLTIPS } from "../../utils/constants";
import ListSubheader from '@mui/material/ListSubheader';

export default function SegmentSelectBox({ value, onChange }) {
  const segments = Object.keys(ruleSegments)

  const renderSelectGroup = segment => {
    const segments = ruleSegments[segment].map(s => {
      return (
        <MenuItem key={s.label} value={s.value} className="dropdown-label">
          {s.label}{ '  ' }<Tooltips icon={true} message={CONDITION_TOOLTIPS[s.value]} />
        </MenuItem>
      );
    });
    return [<ListSubheader color="primary">{segment}</ListSubheader>, segments];
  };

  return (
    <FormControl variant="outlined" size="small">
      <Select name="segment" value={value} onChange={onChange}>
        {segments.map(segment => { return renderSelectGroup(segment) })}
      </Select>
    </FormControl>
  );
}
