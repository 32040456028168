import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function DeviceCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "is", "label": "is" },
      { "value": "is_not", "label": "is not" }
    ];

  const devices = [
    { "value": "mobile", "label": "mobile" },
    { "value": "tablet", "label": "tablet" },
    { "value": "computer", "label": "computer" }
  ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <FormControl size="small" variant="outlined">
        <Select name="value" value={condition.value} onChange={onChange} displayEmpty
          renderValue={condition.value ? undefined : () => "Select device type"} >
          {devices.map(device => <MenuItem key={device.label} value={device.value}>{device.label}</MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
}
