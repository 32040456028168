import React from "react";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

export default function DeactivationWarning ({ site }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  if (!site.deactivated) {
    return '';
  }

  const action = key => (
    <React.Fragment>
      <Button onClick={() => { history.push(`/sites/${site.id}/upgrade`); closeSnackbar(key) }}>
        Upgrade Now To Reactivate
      </Button>
    </React.Fragment>
  );

  enqueueSnackbar(
    'You have exceeded the plan view limit. Your popups and bars have been deactivated for now.',
    {
      action,
      persist: true,
      preventDuplicate: true,
      key: 'deactivated',
      variant: 'success'
    }
  );

  return '';
}
