import ButtonBase from '@mui/material/ButtonBase/ButtonBase';
import React from 'react';
import PaywallLock from '../PaywallLock';

export default function Provider({
  planTier,
  siteId,
  disabled,
  className,
  provider,
  onChooseProvider,
}) {
  return (
    <div key={provider.key} className={`Provider ${className}`}>
      <ButtonBase
        disabled={disabled}
        onClick={() =>
          planTier < provider.tier
            ? ''
            : onChooseProvider && onChooseProvider(provider)
        }
      >
        {planTier < provider.tier && (
          <PaywallLock
            message="Not available on your plan. Please upgrade your subscription."
            url={siteId ? `/sites/${siteId}/upgrade` : ''}
          />
        )}
        <img src={provider.icon} alt={provider.title} />
        <span className="ProviderTitle">
          {provider.title} 
        </span>
      </ButtonBase>
    </div>
  );
}
