import { useRef, useEffect } from 'react';
import { tracking } from "./tracking";

export default function useDocumentTitle(pageName, siteUrl) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${pageName} | ${siteUrl} | Hello Bar`;
  }, [siteUrl, pageName]);

  useEffect(() => {
    tracking.page(pageName);
  }, [pageName]);

  useEffect(() => {
    const defaultTitleCurrent = defaultTitle.current
    return () => {
      document.title = defaultTitleCurrent;
    }
  }, []);
}
