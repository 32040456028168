export function availableThemes (siteSettings, type) {
  const themes = siteSettings?.available_themes || []

  return themes.filter(theme => {
    const available = (theme.element_types || []).indexOf(type) !== -1
    return theme.type === 'generic' && available;
  })
}

export function themeDefaults(theme, type, subtype) {
  if (!theme?.defaults) return {}
  
  if (theme.defaults && theme.defaults[type]) {
    const ignoredKeys = subtype !== 'retirement' ? [] : [
      'headline',
      'link_text',
      'conversion_cta_text',
      'question',
      'answer1',
      'answer2'
    ];

    const themeStyleDefaults = theme.defaults[type] || {};

    const defaults = Object.keys(themeStyleDefaults).reduce((update, key) => {
      const value = themeStyleDefaults[key]

      if (!ignoredKeys.includes(key)) {
        update[key] = value
      }

      return update
    }, {});

    return { ...defaults, theme_id: theme.id, theme: theme }
  }
}

export function findTheme({ type, theme_id }, siteSettings) {
  const themes = availableThemes(siteSettings, type)
  return themes.find(({ id }) => theme_id === id)
}
