import Loader from 'react-loader-spinner';
import { useEffect, useState } from "react";

export default function AppLoader ({className, visible}) {
  const [display, setDisplay] = useState(false);
  const delay = 10;

  // delay loader visibility to avoid blinking
  useEffect(() => {
    let timeout;
    clearTimeout(timeout);

    if (!visible) {
      setDisplay(visible);
    } else {
      timeout = setTimeout(() => {
        setDisplay(visible);
      }, delay);
    }

    return () => {
      clearTimeout(timeout);
    }
  }, [visible]);

  return (
    <div className="loader-container">
      <Loader visible={display} type="ThreeDots" color="#e8562a"/>
    </div>
  )
}
