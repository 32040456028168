import TextField from '@mui/material/TextField';
import React, { Fragment, useState } from 'react';
import SegmentSelectBox from '../SegmentSelectBox';
import OperandSelectBox from '../OperandSelectBox';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

export default function DateCondition({ condition, onChange }) {
  const operands = [
    { value: 'after', label: 'is after' },
    { value: 'before', label: 'is before' },
    { value: 'is', label: 'is' },
    { value: 'is_not', label: 'is not' },
    { value: 'between', label: 'is between' },
  ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'after' } });
  }

  const [left, setLeft] = useState(
    condition.value instanceof Array ? condition.value[0] : null
  );
  const [right, setRight] = useState(
    condition.value instanceof Array ? condition.value[1] : null
  );

  const updateLeft = (value) => {
    setLeft(value?.format('yyyy-MM-DD'));
    onChange({
      target: { name: 'value', value: [value?.format('yyyy-MM-DD'), right] },
    });
  };

  const updateRight = (value) => {
    setRight(value?.format('yyyy-MM-DD'));
    onChange({
      target: { name: 'value', value: [left, value?.format('yyyy-MM-DD')] },
    });
  };

  const updateDate = (value) => {
    onChange({ target: { name: 'value', value: value.format('yyyy-MM-DD') } });
  };

  const betweenControls = (
    <Fragment>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        adapterLocale={'en-gb'}
      >
        <DatePicker
          onChange={updateLeft}
          name="value"
          format="DD/MM/YYYY"
          value={moment(left)}
          renderInput={(startProps) => (
            <TextField
              {...startProps}
              variant="outlined"
              placeholder="date"
              size="small"
            />
          )}
          minDate={moment()}
          maxDate={moment(right).subtract(1, 'days')}
        />
        <DatePicker
          onChange={updateRight}
          name="value"
          format="DD/MM/YYYY"
          value={moment(right)}
          renderInput={(startProps) => (
            <TextField
              {...startProps}
              variant="outlined"
              placeholder="date"
              size="small"
            />
          )}
          minDate={moment(left).add(1, 'days')}
        />
      </LocalizationProvider>
    </Fragment>
  );

  const otherControls = (
    <Fragment>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        adapterLocale={'en-gb'}
      >
        <DatePicker
          onChange={updateDate}
          name="value"
          format="DD/MM/YYYY"
          value={moment(condition.value) || new Date()}
          renderInput={(startProps) => (
            <TextField
              {...startProps}
              variant="outlined"
              placeholder="date"
              size="small"
            />
          )}
        />
      </LocalizationProvider>
    </Fragment>
  );

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange} />
      <OperandSelectBox
        operands={operands}
        value={condition.operand}
        onChange={onChange}
      />
      {condition.operand === 'between' && betweenControls}
      {condition.operand !== 'between' && otherControls}
    </div>
  );
}
