import React from 'react';
import '../../assets/styles/_profile.scss';
import useAPI from "../../utils/api";
import Avatar from '../../shared/Avatar';
import ProfileForm from "./ProfileInfo/ProfileForm";
import PasswordForm from "./ProfileInfo/PasswordForm";
import { useSnackbar } from 'notistack';

function ProfileInfo(props) {
  const API = useAPI();
  const profile = props.user;
  const { enqueueSnackbar } = useSnackbar();

  const updateProfile = async (form) => {
    const [, updated] = await API.updateProfile(form);
    profile.update(updated);
  }

  const updatePassword = async (form) => {
    const [ok, response] = await API.updatePassword(form);
    if (ok) {
      profile.update(response);
      enqueueSnackbar('Email and password has been updated successfully!', { variant: 'success' });
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }

  return (
    <React.Fragment>
      <div className="profile-wrapper" style={{ 'padding': '24px' }}>
        <div className="column">
          <form className="avatar-form">
            <Avatar user={profile} size={300}/>
            <a target="_blank" rel="noreferrer" href="https://en.gravatar.com/emails/">Import from Gravatar</a>
          </form>
        </div>
        <div className="personal-form">
          <div className="column">
            <ProfileForm profile={profile} onSubmit={updateProfile}/>
          </div>
          <div className="column">
            <PasswordForm email={profile.email} onSubmit={updatePassword}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProfileInfo;
