import CollapsedPanel from '../components/CollapsedPanel';
import React, { useContext } from 'react';
import ColorPicker from '../components/ColorPicker';
import InputBlock from '../components/InputBlock';
import { EditorContext } from '../../../utils/contexts';
import NumberInput from '../components/NumberInput';
import FontPicker from '../components/FontPicker';

export default function TextFieldStylingPanel() {
  const { settings, updateSettings, siteSettings } = useContext(EditorContext);

  const updateInputsTextColor = (color) => {
    updateSettings({
      text_field_text_color: color,
    });
  };

  const updateInputsBackgroundColor = (color) => {
    updateSettings({
      text_field_background_color: color,
    });
  };

  const updateInputsBorderColor = (color) => {
    updateSettings({
      text_field_border_color: color,
    });
  };

  const updateBorderRadius = (radius) => {
    updateSettings({
      text_field_border_radius: radius,
    });
  };

  const updateOpacity = (opacity) => {
    updateSettings({
      text_field_background_opacity: opacity,
    });
  };

  const updateBorderWidth = (width) => {
    updateSettings({
      text_field_border_width: width,
    });
  };

  const updateFontSize = (size) => {
    updateSettings({
      text_field_font_size: size,
    });
  };

  const updateFont = (font) => {
    updateSettings({
      text_field_font_family: font,
    });
  };

  return (
    <CollapsedPanel open={false} title="Input Field Styling">
      <InputBlock label="Background color">
        <ColorPicker
          value={settings.text_field_background_color}
          onChange={updateInputsBackgroundColor}
        />
      </InputBlock>
      <InputBlock label="Text color">
        <ColorPicker
          value={settings.text_field_text_color}
          onChange={updateInputsTextColor}
        />
      </InputBlock>
      <InputBlock label="Rounded corners">
        <NumberInput
          value={settings.text_field_border_radius}
          onChange={updateBorderRadius}
          max={45}
        />
      </InputBlock>
      <InputBlock label="Opacity">
        <NumberInput
          value={settings.text_field_background_opacity}
          onChange={updateOpacity}
          max={100}
          type="%"
        />
      </InputBlock>
      <InputBlock label="Border color">
        <ColorPicker
          value={settings.text_field_border_color}
          onChange={updateInputsBorderColor}
        />
      </InputBlock>
      <InputBlock label="Border width">
        <NumberInput
          value={settings.text_field_border_width}
          onChange={updateBorderWidth}
          max={10}
        />
      </InputBlock>
      <InputBlock label="Font size">
        <NumberInput
          value={settings.text_field_font_size}
          onChange={updateFontSize}
          min={8}
          max={24}
        />
      </InputBlock>
      <InputBlock label="Font family">
        <FontPicker
          customFonts={siteSettings.font_uploads}
          value={settings.text_field_font_family}
          onChange={updateFont}
        />
      </InputBlock>
    </CollapsedPanel>
  );
}
