import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import useAPI from '../utils/api'

export default function PaymentWarning ({ site }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const API = useAPI();
  const [unpaidInvoice, setUnpaidInvoice] = useState(null);

  const isOpen = ({ status, id }) => id !== 'upcoming' && status === 'open'

  useEffect(() => {
    const fetch = async () => {
      const [ok, subscription] = await API.subscription(site.id);
      if (ok && subscription) {
        const invoice = (subscription.invoices || []).find(isOpen)
        setUnpaidInvoice(invoice)
      }
    }

    if (site.payment_status === 'past_due') {
      fetch()
    }
  }, [site.id, site.payment_status, API])

  if (site.payment_status !== 'past_due') {
    return '';
  }

  const openInvoice = (key) => () => {
    if (unpaidInvoice?.hosted_invoice_url) {
      window.open(unpaidInvoice.hosted_invoice_url)
    } else {
      history.push(`/sites/${site.id}/settings/billing`)
    }
    closeSnackbar(key)
  }

  const action = key => (
    <React.Fragment>
      <Button onClick={openInvoice(key)}>
        Update Your Card Now
      </Button>
    </React.Fragment>
  );

  enqueueSnackbar(
    'We’re having trouble processing your most recent payment. Would you mind updating the card on file?',
    {
      action,
      persist: true,
      preventDuplicate: true,
      key: 'payment_issue',
      variant: 'warning'
    }
  );

  return '';
}
