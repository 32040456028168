import CollapsedPanel from "../components/CollapsedPanel";
import React, {useContext} from "react";
import ColorPicker from '../components/ColorPicker';
import InputBlock from "../components/InputBlock";
import {EditorContext} from "../../../utils/contexts";
import SelectInput from "../components/SelectInput";
import {placements} from "../components/placements";
import {icons} from "../components/alertIcons"

export default function AlertStylingPanel() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateBGColor = (color) => {
    updateSettings({
      background_color: color,
      primary_color: color
    })
  }

  const updateTextColor = (color) => {
    updateSettings({
      text_color: color
    })
  }

  const updateTColor = (color) => {
    updateSettings({
      trigger_color: color
    })
  }

  const updateTIconColor = (color) => {
    updateSettings({
      trigger_icon_color: color
    })
  }

  const updatePlacement = (placement) => {
    updateSettings({
      placement
    })
  }

  const updateAlertIcon = (alert_icon) => {
    updateSettings({
      alert_icon
    })
  }

  return (
    <CollapsedPanel open={false} title="Alert Styling">
      <InputBlock label="Color">
        <ColorPicker value={settings.background_color} onChange={updateBGColor}/>
      </InputBlock>
      <InputBlock label="Default text color">
        <ColorPicker value={settings.text_color} onChange={updateTextColor}/>
      </InputBlock>
      <InputBlock label="Trigger Icon">
        <SelectInput items={icons} value={settings.alert_icon} onChange={updateAlertIcon}/>
      </InputBlock>
      <InputBlock label="Trigger color">
        <ColorPicker value={settings.trigger_color} onChange={updateTColor}/>
      </InputBlock>
      <InputBlock label="Trigger icon color">
        <ColorPicker value={settings.trigger_icon_color} onChange={updateTIconColor}/>
      </InputBlock>
      <InputBlock label="Placement">
        <SelectInput items={placements.Alert} value={settings.placement} onChange={updatePlacement}/>
      </InputBlock>
    </CollapsedPanel>
  )
}
