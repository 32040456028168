import {TextField} from "@mui/material";
import React, {useContext} from "react";
import {EditorContext} from "../../../utils/contexts";

export default function FollowOnInstagram() {
  const {settings, updateSettings} = useContext(EditorContext)

  const updateURL = (event) => {
    const customSettings = {...settings.settings, instagram_user_name: event.target.value}
    updateSettings({settings: customSettings})
  }

  return (
    <TextField fullWidth variant="outlined" label="Your Instagram username" value={settings.settings.instagram_user_name}
               onChange={updateURL} placeholder="yourinstragram" size='small'/>
  )
}
