import React from 'react';
import { withErrorBoundary } from "../../shared/ErrorFallback";
import RuleHeader from "./RuleHeader";
import Rules from "./Rules";

function PausedRules({ rules, togglePopup, deletePopup, copyPopup, resetCache }) {
  const actions = { togglePopup, deletePopup, copyPopup }

  return (
    <div style={{ 'padding': '24px' }}>
      <table className="rules-wrapper">
        <RuleHeader/>
        <Rules rules={rules} actions={actions} scope="paused" resetCache={resetCache}>
          {rules.length === 0 &&
          <tr className="paused elements-paused" id="active-guidance" style={{ "display": "table-row;" }}>
            <td colSpan="10">Everything is currently active</td>
          </tr>
          }
        </Rules>
      </table>
    </div>
  )
}

export default withErrorBoundary(PausedRules)
