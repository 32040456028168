import React, {useContext} from "react";
import {EditorBinding, EditorContext} from "../../../utils/contexts";
import CollapsedPanel from "../components/CollapsedPanel";
import Switcher from "../components/Switcher";
import {Label} from "../Steps/components";

export default function Subheadline1Panel() {
  const {settings, updateSettings} = useContext(EditorContext)

  const toggleHide = () => {
    updateSettings({show_optional_caption: !settings.show_optional_caption})
  }

  const editorBindings = useContext(EditorBinding)

  return (
    <CollapsedPanel open={editorBindings.showCaption} title="Subheadline 1" allowed={true}>
      <Label>
        <span>Show</span>
        <Switcher checked={settings.show_optional_caption} onChange={toggleHide}/>
      </Label>
    </CollapsedPanel>
  )
}
