import ThemePanel from '../panels/ThemePanel';
import TextFieldsPanel from '../panels/TextFieldsPanel';
import ModalStylingPanel from '../panels/ModalStylingPanel';
import ButtonStylingPanel from '../panels/ButtonStylingPanel';
import TextFieldStylingPanel from '../panels/TextFieldStylingPanel';
import LeadingQuestionPanel from '../panels/LeadingQuestionPanel';
import CountdownPanel from '../panels/CountdownPanel';
import { ListWrapper } from '../Steps/components';
import UrlTargetPanel from '../panels/UrlTargetPanel';
import WebformOptionsPanel from '../panels/WebformOptionsPanel';
import PhoneNumberPanel from '../panels/PhoneNumberPanel';
import SocialProfilePanel from '../panels/SocialProfilePanel';
import ImagePanel from '../panels/ImagePanel';
import HeadlinePanel from '../panels/HeadlinePanel';
import CTAPanel from '../panels/CTAPanel';
import NoThanksPanel from '../panels/NoThanksPanel';
import Subheadline1Panel from '../panels/Subheadline1Panel';
import Subheadline2Panel from '../panels/Subheadline2Panel';

export default function ModalDesign() {
  return (
    <ListWrapper>
      <ThemePanel />
      <ModalStylingPanel />
      <HeadlinePanel />
      <Subheadline1Panel />
      <Subheadline2Panel />
      <TextFieldsPanel />
      <TextFieldStylingPanel />
      <PhoneNumberPanel />
      <SocialProfilePanel />
      <WebformOptionsPanel />
      <ImagePanel />
      <UrlTargetPanel />
      <CTAPanel />
      <ButtonStylingPanel />
      <NoThanksPanel />
      <LeadingQuestionPanel />
      <CountdownPanel />
    </ListWrapper>
  );
}
