import FormControl from "@mui/material/FormControl/FormControl";
import TimeField from "../TimeField";
import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function TimeCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "after", "label": "is after" },
      { "value": "before", "label": "is before" },
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'after' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <FormControl variant="outlined">
        <TimeField value={condition.value} onChange={onChange} />
      </FormControl>
    </div>
  );
}
