import active_campaign from '../../assets/providers/active_campaign.png';
import aweber from '../../assets/providers/aweber.png';
import brevo from '../../assets/providers/brevo.png';
import bronto from '../../assets/providers/bronto.png';
import constantcontact from '../../assets/providers/constantcontact.png';
import convert_kit from '../../assets/providers/convert_kit.png';
import createsend from '../../assets/providers/createsend.png';
import campaignmonitor from '../../assets/providers/campaignmonitor.png';
import drip from '../../assets/providers/drip.png';
import get_response_api from '../../assets/providers/get_response_api.png';
import hellobar from '../../assets/providers/hellobar.png';
import hubspot from '../../assets/providers/hubspot.png';
import integrately from '../../assets/providers/integrately.png';
import intercom from '../../assets/providers/intercom.png';
import infusionsoft from '../../assets/providers/infusionsoft.png';
import iterable from '../../assets/providers/iterable.png';
import klaviyo from '../../assets/providers/klaviyo.png';
import mad_mimi_api from '../../assets/providers/mad_mimi_api.png';
import mad_mimi_form from '../../assets/providers/mad_mimi_form.png';
import mailchimp from '../../assets/providers/mailchimp.png';
import mailerlite from '../../assets/providers/mailerlite.png';
import maropost from '../../assets/providers/maropost.png';
import my_emma from '../../assets/providers/my_emma.png';
import sailthru from '../../assets/providers/sailthru.png';
import salesforce from '../../assets/providers/salesforce.png';
import sender_api from '../../assets/providers/sender-api.png';
import slack from '../../assets/providers/slack.png';
import vertical_response from '../../assets/providers/vertical_response.png';
import verticalresponse from '../../assets/providers/verticalresponse.png';
import webhooks from '../../assets/providers/webhooks.png';
import zapier from '../../assets/providers/zapier.png';

export const icons = {
  active_campaign,
  aweber,
  brevo,
  bronto,
  constantcontact,
  convert_kit,
  createsend,
  campaignmonitor,
  drip,
  get_response_api,
  hellobar,
  hubspot,
  integrately,
  intercom,
  infusionsoft,
  infusion_soft: infusionsoft,
  iterable,
  klaviyo,
  mad_mimi_api,
  mad_mimi_form,
  mailchimp,
  mailerlite,
  maropost,
  my_emma,
  sailthru,
  salesforce,
  sender_api,
  slack,
  vertical_response,
  verticalresponse,
  webhooks,
  zapier,
};
