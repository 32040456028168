import React, { useState } from "react";
import { iconImages } from "../../../shared/cardIcons";
import Button from "@mui/material/Button";
import { Add as IconPlus } from "@mui/icons-material";
import "../../../assets/styles/PaymentMethod.scss";
import UpdateCreditCard from "./UpdateCreditCard";

export default function PaymentMethod(props) {
  const [paymentMethod, setPaymentMethod] = useState(props.paymentMethod);
  const [formVisible, setFormVisible] = useState(false);

  if (!paymentMethod) {
    return '';
  }

  const handleUpdate = (newPaymentMethod) => {
    setFormVisible(false);
    setPaymentMethod(newPaymentMethod);
    props.onUpdate();
  };

  return (
    <div className="PaymentMethod">
      {!formVisible && <div>
        <span className="PaymentMethod--icon">{iconImages[paymentMethod.brand.toLowerCase()]}</span>
        <span className="PaymentMethod--last4">•••• {paymentMethod.last4}</span>
        <span className="PaymentMethod--expires">Expires {paymentMethod.exp_month}/{paymentMethod.exp_year}</span>
      </div>}
      <div className="PaymentMethod--update">
        {formVisible && <UpdateCreditCard onCancel={() => setFormVisible(false)} onUpdate={handleUpdate}/>}
        {!formVisible && <Button startIcon={<IconPlus/>} onClick={() => setFormVisible(true)} color="primary" variant="contained">Update payment method</Button>}
      </div>
    </div>
  );
}
