import React from 'react'

export default function QuestionIcon() {
  return (
    
<svg xmlns="http://www.w3.org/2000/svg"  width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
<g id="surface1">
<path d="M 6.484375 1.945312 C 5.425781 2.039062 4.335938 2.539062 3.53125 3.296875 C 2.800781 3.996094 2.367188 4.699219 2.070312 5.664062 C 1.859375 6.367188 1.84375 7.492188 2.039062 8.285156 C 2.515625 10.183594 3.808594 11.515625 5.695312 12.023438 C 6.398438 12.210938 7.664062 12.210938 8.378906 12.023438 C 9.832031 11.636719 10.960938 10.722656 11.609375 9.40625 C 12.015625 8.566406 12.152344 7.957031 12.148438 6.972656 C 12.148438 6.289062 12.128906 6.109375 11.992188 5.664062 C 11.703125 4.699219 11.261719 3.996094 10.53125 3.304688 C 9.445312 2.273438 8.070312 1.8125 6.484375 1.945312 Z M 9.4375 5.5625 C 9.632812 5.757812 9.65625 5.964844 9.507812 6.140625 C 9.363281 6.316406 6.5625 8.609375 6.40625 8.683594 C 6.335938 8.71875 6.21875 8.730469 6.140625 8.710938 C 6.0625 8.6875 5.65625 8.328125 5.226562 7.890625 C 4.558594 7.210938 4.453125 7.085938 4.453125 6.921875 C 4.453125 6.667969 4.675781 6.445312 4.933594 6.445312 C 5.097656 6.445312 5.203125 6.527344 5.703125 7.03125 L 6.273438 7.609375 L 7.546875 6.570312 C 8.242188 5.992188 8.871094 5.496094 8.9375 5.460938 C 9.125 5.355469 9.257812 5.378906 9.4375 5.5625 Z M 9.4375 5.5625 "/>
<path d="M 22.421875 1.945312 C 21.363281 2.039062 20.273438 2.539062 19.46875 3.296875 C 18.738281 3.996094 18.304688 4.699219 18.007812 5.664062 C 17.796875 6.367188 17.78125 7.492188 17.976562 8.285156 C 18.453125 10.183594 19.746094 11.515625 21.632812 12.023438 C 22.335938 12.210938 23.601562 12.210938 24.316406 12.023438 C 25.769531 11.636719 26.898438 10.722656 27.546875 9.40625 C 27.953125 8.566406 28.089844 7.957031 28.085938 6.972656 C 28.085938 6.289062 28.066406 6.109375 27.929688 5.664062 C 27.640625 4.699219 27.199219 3.996094 26.46875 3.304688 C 25.382812 2.273438 24.007812 1.8125 22.421875 1.945312 Z M 22.25 5.695312 L 22.96875 6.414062 L 23.6875 5.695312 C 24.34375 5.039062 24.421875 4.980469 24.621094 4.980469 C 24.785156 4.980469 24.867188 5.015625 24.953125 5.132812 C 25.183594 5.425781 25.132812 5.53125 24.375 6.296875 C 23.988281 6.6875 23.671875 7.03125 23.671875 7.0625 C 23.671875 7.089844 23.988281 7.4375 24.375 7.820312 C 24.925781 8.378906 25.078125 8.570312 25.078125 8.695312 C 25.078125 8.917969 24.835938 9.140625 24.597656 9.140625 C 24.429688 9.140625 24.316406 9.046875 23.6875 8.425781 L 22.96875 7.703125 L 22.25 8.425781 C 21.59375 9.082031 21.515625 9.140625 21.316406 9.140625 C 21.152344 9.140625 21.070312 9.105469 20.984375 8.988281 C 20.753906 8.695312 20.804688 8.589844 21.5625 7.820312 C 21.949219 7.4375 22.265625 7.089844 22.265625 7.0625 C 22.265625 7.03125 21.949219 6.6875 21.5625 6.296875 C 20.804688 5.53125 20.753906 5.425781 20.984375 5.132812 C 21.070312 5.015625 21.152344 4.980469 21.316406 4.980469 C 21.515625 4.980469 21.59375 5.039062 22.25 5.695312 Z M 22.25 5.695312 "/>
<path d="M 13.859375 10.890625 C 12.210938 11.3125 11.027344 12.457031 10.488281 14.148438 C 10.289062 14.777344 10.253906 15.976562 10.421875 16.640625 C 10.769531 18.03125 11.648438 19.09375 12.996094 19.769531 C 13.664062 20.101562 14.304688 20.234375 15.117188 20.195312 C 15.898438 20.15625 16.300781 20.0625 16.992188 19.742188 C 18.265625 19.140625 19.207031 18.039062 19.582031 16.71875 C 19.75 16.117188 19.765625 15 19.605469 14.382812 C 19.160156 12.65625 17.84375 11.332031 16.125 10.890625 C 15.546875 10.746094 14.445312 10.746094 13.859375 10.890625 Z M 13.859375 10.890625 "/>
<path d="M 10.34375 20.484375 C 8.804688 21 8.566406 21.101562 8.144531 21.390625 C 7.28125 21.992188 6.757812 22.722656 6.476562 23.707031 C 6.320312 24.222656 6.28125 26.578125 6.410156 27.015625 C 6.527344 27.433594 6.785156 27.726562 7.195312 27.929688 L 7.53125 28.09375 L 22.46875 28.09375 L 22.78125 27.953125 C 23.171875 27.78125 23.472656 27.433594 23.589844 27.023438 C 23.726562 26.570312 23.679688 24.222656 23.523438 23.703125 C 23.289062 22.867188 22.75 22.066406 22.089844 21.546875 C 21.664062 21.210938 21.085938 20.960938 19.699219 20.5 L 18.390625 20.070312 L 18.203125 20.179688 C 16.957031 20.929688 15.992188 21.195312 14.734375 21.132812 C 13.617188 21.078125 12.65625 20.753906 11.75 20.132812 C 11.640625 20.0625 11.492188 20.097656 10.34375 20.484375 Z M 10.34375 20.484375 "/>
</g>
</svg>

  )
}
