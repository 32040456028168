import React from 'react';
import Button from "../../../shared/Button";
import Grid from "@mui/material/Grid/Grid";
import FormControl from "@mui/material/FormControl/FormControl";
import TextField from "@mui/material/TextField/TextField";

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: props.email, password: '', password_confirmation: '' };
    this.onSubmit = props.onSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    this.onSubmit(this.state).then(() => this.setState({ ...this.state, loading: false, hidden: false }));
  }

  toggleShow(event) {
    event.preventDefault();
    this.setState({ hidden: !this.state.hidden })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} validate="validate" autoComplete="off">
        <div className={this.state.hidden ? 'hidden' : ''}>
          <Button onClick={this.toggleShow}>Change email / password</Button>
        </div>
        <Grid className={`${this.state.hidden ? '' : 'hidden'}`}>
          <Grid container justifyContent="flex-start" spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3} direction="column">
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField autoComplete="off" value={this.state.current_password} required minLength="6"
                               pattern=".*[A-Z\d]{2,}.*"
                               type="password" name="current_password" label="Current Password"
                               onChange={this.handleInputChange}
                               size='small' />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField autoComplete="off" required value={this.state.email} name="email" label="Email"
                               onChange={this.handleInputChange}
                               size='small'/>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField value={this.state.password} minLength="6" pattern=".*[A-Z\d]{2,}.*"
                               type="password" name="password" label="New Password"
                               onChange={this.handleInputChange}
                               size='small'/>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <TextField value={this.state.password_confirmation} required={this.state.password.length > 0} minLength="6"
                               pattern={this.state.password} type="password" name="password_confirmation"
                               label="Repeat Password" onChange={this.handleInputChange}
                               helperText="Must be 6 characters with one uppercase letter and a number"
                               size='small'/>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" direction="row">
                <Grid item>
                  <Button type="submit" loading={this.state.loading} color="secondary" label="Save &amp; Update"/>
                </Grid>
                <Grid item>
                  <Button onClick={this.toggleShow} color="white" label="Cancel"/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

export default PasswordForm;
