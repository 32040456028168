import React, { useState } from "react";
import useFetch from "../../utils/useFetch";
import AppLoader from "../../shared/AppLoader";
import Error from "../../shared/Error";
import useAPI from "../../utils/api";
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from "../Editor/components/Dropzone";
import ConfirmationDialog from '../Modals/ConfirmationDialog';
import Grid from '@mui/material/Grid';
import opentype from 'opentype.js'
import { useRoleCheck } from "../../utils/useRoleCheck";
import { useSnackbar } from 'notistack';

export default function Fonts({ site }) {
  const API = useAPI()
  const [loader, setLoader] = useState(false);
  const [fontLoading, setFontLoading] = useState(false);
  const handleRoleCheck = useRoleCheck(API.currentRole);
  const { loading, error, status, customFonts } = useFetch('customFonts', site.id, fontLoading);
  const { enqueueSnackbar } = useSnackbar();

  const updateFontUpload = async (url, file) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    const fileData = await opentype.parse(await file.arrayBuffer());
    const familyName = `${fileData.names.fontFamily.en} ${fileData.names.fontSubfamily.en}`
    setLoader(true)
    API.uploadFont(site.id, file, familyName).then((response) => {
      if (response[0]) {
        setFontLoading(true)
        enqueueSnackbar('Font uploaded successfully', {
          variant: 'success',
        })
      } else {
        enqueueSnackbar(response[1]['message'], {
          variant: 'error',
        })
      }
      setFontLoading(false)
    }).catch((error) => {
      enqueueSnackbar(error, {
        variant: 'error',
      })
      setFontLoading(false)
    })
    setLoader(false)
  }

  const deleteFont = async (font) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    setLoader(true)
    await API.deleteCustomFont(font.id).then((response) => {
      if (response[0]) {
        setFontLoading(true)
        enqueueSnackbar('Font deleted successfully', {
          variant: 'success'
        })
      } else {
        enqueueSnackbar(response[1]['message'], {
          variant: 'error',
        })
      }
    }).catch((error) => {
      enqueueSnackbar(error, {
        variant: 'error',
      })
      setFontLoading(false)
    })
    setLoader(false)
  }

  const deleteConfirmation = ({ onClick }) => (
    <button onClick={ onClick }><DeleteIcon/></button>
  )

  return (
    <div className='font-section'>
      {status === 'error' && <Error error={error}/>}
      <div className="font-section-header">Custom Fonts</div>
      <Grid>
        <Grid container justifyContent="flex-start" className="inputs" spacing={3}>
          <Grid item xs={3}>
            <Dropzone loading={loader} onUpdate={updateFontUpload} isFont={true} />
          </Grid>
          <Grid item xs={9}>
            <table className="font-table">
              <tbody>
                {status === 'fetched' && customFonts.map((font) =>
                  <tr key={font.url}>
                    <td>{font.font_file_name} ({font.font_name})</td>
                    <td>
                      <ConfirmationDialog onConfirm={ () => deleteFont(font) } component={ deleteConfirmation } >
                        Are you sure you want to delete this font? <br/>
                      </ConfirmationDialog>
                    </td>
                  </tr>
                )}
                <AppLoader visible={loader}/>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
