import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './index.css';
import App from './App';
import PrivateRoute from './components/PrivateRoute';
import LoginPage from './components/LoginPage';
import AuthenticationPage from './components/Auth/AuthenticationPage';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import GrowSumo from 'react-growsumojs';
import { SnackbarProvider } from 'notistack';
import { theme } from './utils/theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useStyles } from './styles';
import SignUp from './components/Auth/SignUp';

process.env.REACT_APP_SENTRY_DNS &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

function RootRoutes() {
  const setSignedUpAndRedirect = ({ history, match }) => {
    sessionStorage.setItem('track_signed_up', 'true');
    sessionStorage.setItem('session_signed_up', 'true');
    // if (match?.params?.plan && match.params.plan !== 'starter') {
    //   sessionStorage.setItem('preselected_plan', match?.params?.plan);
    // }
    try {
      const params = new URLSearchParams(history.location.search);
      if (params.get('token')) {
        history.replace(`/login/jwt/${params.get('token')}`);
      } else {
        history.replace('/sites');
      }
    } catch (e) {
      history.replace('/sites');
    }
  };

  if (process.env.REACT_APP_GROWSUMO_KEY) {
    GrowSumo.initialize(process.env.REACT_APP_GROWSUMO_KEY);
  }

  const classes = useStyles();
  const snackbarClasses = {
    variantSuccess: classes.successColor,
    variantWarning: classes.warningColor,
  };

  return (
    <SnackbarProvider maxSnack={3} classes={snackbarClasses}>
      <Switch>
        <Route exact path="/users/sign_up">
          <SignUp />
        </Route>
        <Route path="/users">
          <AuthenticationPage />
        </Route>
        <Route path="/login/jwt/:token">
          <LoginPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>

        <Route exact path="/welcome" render={setSignedUpAndRedirect} />
        <Route path="/welcome/:plan" render={setSignedUpAndRedirect} />
        <PrivateRoute exact path="/">
          <Redirect to="/sites" />
        </PrivateRoute>
        <PrivateRoute exact path="/sites" component={App} />
        <PrivateRoute exact path="/upgrade" component={App} />
        <PrivateRoute path="/sites/:siteId" component={App} />
      </Switch>
    </SnackbarProvider>
  );
}

function Root() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <RootRoutes />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
