import Box from "@mui/material/Box";
import styled from "styled-components";
import { useState } from "react";
import Button from "../../shared/Button";

const style = {
  position: 'absolute',
  top: '240px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'transparent',
  p: 0,
  zIndex: 100
};

const Modal = styled.div`
  cursor: pointer;
  h1 {
    text-align: center;
    padding-top: 1em;
    font-size: 2em;
  }
  p {
    padding: 20px 60px;
    text-align: center;
    margin: 0;
  }
  p.subheadline {
    font-size: 1.5em;
    padding-bottom: 0;
  }
`

const Backdrop = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: 10;
`

export function NewsModal() {
  const [isNewsModalOpen, setIsNewsModalOpen] = useState(
    localStorage.getItem('isNewsModalOpen') !== 'false'
  );
  
  const onClick = () => {
    localStorage.setItem('isNewsModalOpen', 'false')
    setIsNewsModalOpen(false)
    const tinymce = window.tinymce && window.tinymce.get(0)
    tinymce && tinymce.bodyElement.focus()
  }
  
  return isNewsModalOpen ? (
    <Modal
      onClick={onClick}
    >
      <Backdrop/>
      <Box sx={ style }>
        <img alt="Good news! Updated editor!" src="https://assets.hellobar.com/hb-arrow-animation.gif"/>
        <Box sx={{background: "white", padding: "20px"}}>
          <h1>New & Improved Editing Feature</h1>
          <p className="subheadline">
            The new toolbar contains all the functionality to aid you in editing your custom modals quickly.
          </p>
          <p>
            The new toolbar’s state makes it clear which icon button is active. Hover and focus states express the available selection options for icon buttons in a toolbar.
          </p>
          <p>
            <Button onClick={onClick}>Close</Button>
          </p>
        </Box>
      </Box>
    </Modal>
  ) : null;
}
