import React  from 'react';
import { withStyles } from '@mui/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Button from '../shared/Button';
import '../assets/styles/_install.scss';
import '../assets/styles/_reveal.scss';
import '../assets/styles/_icons.scss';
import useAPI from "../utils/api";
import TextField from "@mui/material/TextField/TextField";
import FormControl from "@mui/material/FormControl/FormControl";
import { useSnackbar } from 'notistack';
import { generatePath, Redirect } from "react-router-dom";
import { tracking } from "../utils/tracking";
import { withTracking } from "../shared/Tracking";

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    marginTop: '0.75em',
    borderRadius: '5px',
    border: '2px solid #f6f6f6',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '0.75em',
    }
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 50,
    '&$expanded': {
      minHeight: 50,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const makeSnippet = (site) =>
  `<script src="https://my.hellobar.com/${site.snippet_id}.js" type="text/javascript" charset="utf-8" async="async"> </script>`;

const Summary = ({ site, user, trackEvent }) => {
  const API = useAPI();
  const [expanded, setExpanded] = React.useState('panel1');
  const [devEmail, setDevEmail] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // if (sessionStorage.getItem('preselected_plan')) {
  //   const plan = sessionStorage.getItem('preselected_plan');
  //   const upgradeUrl = generatePath("/sites/:id/upgrade/:plan", { id: site.id, plan });
  //   return (<Redirect to={upgradeUrl}/>);
  // }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCopy = (event) => {
    if(event.target.select) {
      event.target.select();
    } else {
      const input = event.target.getElementsByTagName('input')[0] || event.target.getElementsByTagName('textarea')[0];
      input?.select();
    }
    document.execCommand('copy');
    enqueueSnackbar('Copied!', { variant: 'success' });
  };

  const checkInstallation = async () => {
    setLoading(true);
    window.open(site.url + '?hb-check-install');
    setTimeout(async () => {
      const [, updated] = await API.checkSiteInstalled();
      setLoading(false);
      if (updated.installed) {
        enqueueSnackbar('Congrats! The snippet has been installed properly!', { variant: 'success' });
      } else {
        enqueueSnackbar('Not installed yet. Need help? Let us know!', { variant: 'success' });
      }
    }, 2000);
  };

  const devEmailChanged = (event) => {
    setDevEmail(event.target.value);
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    await API.emailDeveloper(site, devEmail);
    enqueueSnackbar("We've sent the installation instructions to your developer!", { variant: 'success' });
  };

  const openFreshchat = () => {
    tracking.freshchat.open();
  };

  return (
    <div id="sites-install" className="installation-page">
      <header>
        <h1>Install Hello Bar Script</h1>
        <p>Once the script is installed, your dashboard will begin populating data all about your bars and visitors. This only needs to be done once per site.</p>
        <Button loading={loading} onClick={checkInstallation}>Let's check it</Button>
      </header>
      <h5>Installation Methods</h5>
      <Accordion className='reveal-wrapper' square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <i className="icon-installmyself"/>
          <div className='summary-info'>
            <div className="reveal-title">I can install code myself</div>
            <p>If you know HTML and can edit your site, our install only takes one line of code.</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block myself-reveal">
            <p>To install Hello Bar, copy and paste the following code right before your closing &lt;/body&gt; tag.</p>
            <FormControl fullWidth>
              <TextField onClick={handleCopy} value={makeSnippet(site)} readOnly multiline rows={2} size="small"/>
            </FormControl>
            <br/>
            <br/>
            <p>That's it! You'll never need to update the code on your site again.</p>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion className='reveal-wrapper' square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <i className="icon-wordpress"/>
          <div className='summary-info'>
            <div className="reveal-title">I use WordPress</div>
            <p>If you use WordPress you'll love our super easy copy and paste integration. Get your site snippet here.</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block wordpress-reveal">
            <div className="title">1) Copy Your Site Snippet</div>
            <div className="form-inline">
              <FormControl fullWidth>
                <TextField onClick={handleCopy} value={site.snippet_id} size="small" readOnly/>
              </FormControl>
            </div>
            <div className="title">2) Find Our Plugin</div>
            <div className="find-plugin">
              Go to your website's Wordpress admin and select <b>PLUGINS &gt; ADD NEW</b> and then search for <b>Hellobar</b>. Press the <b>INSTALL NOW</b> button on the official Hello Bar plugin and <b>ACTIVATE</b>.<br/>Need help locating the Hello Bar Wordpress Plugin?&nbsp;<a href="https://support.hellobar.com/support/solutions/articles/44001406925-how-to-locate-install-the-wordpress-plugin-for-hello-bar" rel="noreferrer" target="_blank">Click here</a>
            </div>
            <div className="title">3) Paste Site Snippet</div>
            <p>Now you should see a new <b>Hello Bar</b> option in your WP admin side menu. Select that to open the plugin and paste the site snippet that you copied in <b>step 1</b>.
            </p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className='reveal-wrapper' square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <i className="icon-wix"/>
          <div className='summary-info'>
            <div className="reveal-title">I use Wix</div>
            <p>If your site is hosted on Wix, we've got a super easy copy and paste integration. Get your trackng ID here.</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block wix-reveal">
            <div className="title">1) Copy your ID:</div>
            <div className="form-inline">
              <FormControl fullWidth>
                <TextField onClick={handleCopy} value={site.snippet_id} size="small" readOnly/>
              </FormControl>
            </div>
            <div className="title">2) Paste it to Wix</div>
            <p>Go to your <strong>Wix account</strong>, click on <strong>Promote &gt; Marketing integrations &gt;</strong> in the <strong>HelloBar widget</strong> click <strong>Go for it.</strong></p>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className='reveal-wrapper' square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <i className="icon-contacts"/>
          <div className='summary-info'>
            <div className="reveal-title">Email my Web Developer</div>
            <p>If someone else manages your website, we can email them the instructions for you.</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="reveal-block">
            <form acceptCharset="UTF-8" method="post" onSubmit={sendEmail}>
              <label>To:</label>
              <FormControl fullWidth>
                <TextField required label="Your developer's email address" type="email" onChange={devEmailChanged} size="small"/>
              </FormControl>
              <br/>
              <br/>
              <label>From:</label><pre>Hello Bar &lt;contact@hellobar.com&gt;</pre>
              <label>Subject:</label>
              <pre className="topper">
                Please install Hello Bar on {site.url}</pre>
              <pre>
                I just created a Hello Bar for {site.url} and I've asked Hello Bar to email you the instructions for installing it.
                All you need to do is add the following bit of JavaScript at the end of each page (right before the closing &lt;/body&gt; tag):
              </pre>
              <pre>
                &lt;script type="text/javascript" src="https://my.hellobar.com/{site.snippet_id}.js" async="async"&gt;&lt;/script&gt;
              </pre>
              <pre>
                If you have any questions don't hesitate to email Hello Bar support at support@hellobar.com,
                or e-mail me at {user.email}.
              </pre>
              <pre>Thank you!</pre>
              <input type="submit" name="commit" value="Send" className="button button-green" />
            </form>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className='reveal-wrapper' square expanded={expanded === 'panel5'} onChange={openFreshchat}>
        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <i className="icon-support"/>
          <div className='summary-info'>
            <div className="reveal-title">I need help!</div>
            <p>Still not sure what to do? Let us help!</p>
          </div>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}

export default withTracking(Summary);
