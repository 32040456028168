import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => {

  return ({
    successColor: {
      backgroundColor: theme.palette.secondary.main,

      '.SnackbarItem-wrappedRoot &.SnackbarItem-variantSuccess': {
        backgroundColor: theme.palette.secondary.main,
      }
    },
    warningColor: {
      backgroundColor: theme.palette.primary.main,

      '.SnackbarItem-wrappedRoot &.SnackbarItem-variantWarning': {
        backgroundColor: theme.palette.primary.main,
      }
    }
  })
});
