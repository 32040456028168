import React, { useContext } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ManageContext } from "../../utils/contexts";

export default function RuleHeader () {
  const { setResetCache } = useContext(ManageContext)

  return (
    <thead>
    <tr>
      <th className="color-flag"/>
      <th>Popup</th>
      <th>Age</th>
      <th>Goal</th>
      <th>GDPR</th>
      <th>Layout</th>
      <th>Views</th>
      <th>Conversions</th>
      <th>%</th>
      <th>
        <div className='refresh' onClick={() => setResetCache(true)} title='Refresh'>
          <RefreshIcon />
        </div>
      </th>
      <th/>
    </tr>
    </thead>
  )
}
