import { FONT_FAMILIES } from '../constants';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl/FormControl';

const StyledFormControl = styled(FormControl)`
  width: 100%;

  & .MuiOutlinedInput-input {
    // padding: 15.5px 14px;
  }
`;

export default function FontPicker({ customFonts, value, onChange }) {
  let fonts = [];
  const newFonts =
    customFonts?.map((font) => ({
      label: font.font_name,
      value: font.font_name,
      family: font.font_name,
    })) || [];

  const existingfonts = Object.keys(FONT_FAMILIES).map((family) => ({
    label: FONT_FAMILIES[family],
    value: family,
    family,
  }));

  fonts = [...newFonts, ...existingfonts];

  useEffect(() => {
    let font;
    if (value && !Object.keys(FONT_FAMILIES).includes(value)) {
      font = existingfonts.find(({ label }) => label === value);
    } else if (value) {
      font = newFonts?.find((item) => item.value === value);
    }
    if (font) {
      onChange(font?.value);
    }
  }, [fonts, onChange, value]);

  const fontStyle = (font) => {
    return { fontFamily: font.family };
  };

  return (
    <StyledFormControl variant="outlined">
      <TextField
        variant="outlined"
        select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        size="small"
      >
        {fonts.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <span style={fontStyle(item)}>{item.label}</span>
          </MenuItem>
        ))}
      </TextField>
    </StyledFormControl>
  );
}
