import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import {InputBase} from '@mui/material';
import { useLocation, Route, Switch, Link } from 'react-router-dom';
import {SearchContactListContext, SearchContext} from '../utils/contexts'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  search: {
    display: 'inline-block',
    position: 'relative',
    border: '1.5px solid #e8562a',
    verticalAlign: 'middle',
    height: '30px'
  },
  searchIcon: {
    color: '#e8562a',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    paddingLeft: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  inputRoot: {
    paddingLeft: '30px',
    width: '300px',
  },
  appBar: {
    paddingLeft: 0,
    paddingBottom: 0,
    flexDirection: 'row !important',
    justifyContent: 'space-between'
  }
}));

export default function MenuTabs(props) {
  const searchData = useContext(SearchContext)
  const searchContactListData = useContext(SearchContactListContext)
  const location = useLocation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={location.pathname}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {props.tabs.map((tab, index) => (
            <Tab key={tab.name} label={tab.name} {...a11yProps(index)} value={tab.path} to={tab.path} component={Link}/>
          ))}
        </Tabs>
        {props.showSearch && <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder={ props.placeholder }
            value={ props.type == 'manage' ? searchData.query : props.type == 'contacts' ? searchContactListData.query : '' }
            classes={{
              root: classes.inputRoot,
            }}
            onChange={e=> props.changeQuery(e.target.value)}
          />
        </div>}
      </AppBar>
      <Switch>
        {props.tabs.map((tabs, index) => (
          <Route key={tabs.name} exact path={tabs.path} render={tabs.render}/>
        ))}
      </Switch>
    </div>
  );
}
