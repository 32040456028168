import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import useAPI from '../../../utils/api';
import Loader from "react-loader-spinner";
import { useSnackbar } from 'notistack';
import { Paper } from "@mui/material";
import { useRoleCheck } from "../../../utils/useRoleCheck";

export default function Edit({ onClose, billingInformation }) {
  const API = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const [address, setAddress] = useState(billingInformation?.address || {});
  const [email, setEmail] = useState(billingInformation?.email || '')
  const [updating, setUpdating] = useState(false);
  const handleRoleCheck = useRoleCheck(API.currentRole);

  const onSave = async (address, email) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    setUpdating(true);
    const [ok, response] = await API.updateBillingInformation({ address, email });
    setUpdating(false);

    if (ok) {
      enqueueSnackbar('Billing information has been updated successfully!', { variant: 'success' });
      onClose({ address, email });
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const changeAddress = (event) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };

  return (
    <div className="BillingInfo--editing">
      <h5 className="page-header" style={{ marginTop: '2em' }}>Billing Information</h5>
      <Paper elevation={3}>
      <FormControl>
        <TextField value={email} onChange={(event) => setEmail(event.target.value)} label="Email"></TextField>
      </FormControl>
      <FormControl>
        <TextField value={address.line1} onChange={changeAddress} name="line1" label="Address Line 1"></TextField>
      </FormControl>
      <FormControl>
        <TextField value={address.line2} onChange={changeAddress} name="line2" label="Address Line 2"></TextField>
      </FormControl>
      <FormControl>
        <TextField value={address.city} onChange={changeAddress} name="city" label="City"></TextField>
      </FormControl>
      <FormControl>
        <TextField value={address.state} onChange={changeAddress} name="state" label="State"></TextField>
      </FormControl>
      <FormControl>
        <TextField value={address.postal_code} onChange={changeAddress} name="postal_code" label="ZIP"></TextField>
      </FormControl>
      <Button color="primary" variant="contained" onClick={() => onSave(address, email)}>
        {updating ? <Loader height={10} visible={updating} type="ThreeDots" color="white"/> : "Save"}
      </Button>
      <Button variant="contained" onClick={onClose}>Cancel</Button>
      </Paper>
    </div>
  );
}
