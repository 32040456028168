import React, { useContext, useState, useEffect } from 'react';
import { SiteContext } from '../../utils/contexts';
import useAPI from '../../utils/api';
import PathIcon from '../../assets/icons/path.svg';
import { Switch } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const prices = {
  monthly: {
    // starter: 8,
    // expand: 19,
    growth: 39,
    premium: 69,
    elite: 129,
    free: 0,
    // starterOld: '$14',
    // expandOld: '$29',
    growthOld: '$59',
    premiumOld: '$99',
    eliteOld: '$199',
  },
  yearly: {
    // starter: 60,
    // expand: 180,
    growth: 348,
    premium: 588,
    elite: 1188,
    free: 0,
    // starterOld: '$149',
    // expandOld: '$299',
    growthOld: '$589',
    premiumOld: '$999',
    eliteOld: '$1999',
  },
};

export default function PlanSelection() {
  const history = useHistory();
  const api = useAPI();
  const site = useContext(SiteContext);
  const { enqueueSnackbar } = useSnackbar();
  const [schedule, setSchedule] = useState('monthly');

  useEffect(() => {
    if (site.installed && site.partner === 'shopify') {
      return (window.location.href =
        'https://admin.shopify.com/charges/hellobar-email-popups-1/pricing_plans');
    }
  }, []);

  const handleChange = () => {
    setSchedule(schedule === 'monthly' ? 'yearly' : 'monthly');
  };

  const handleChangeYearly = () => {
    setSchedule('yearly');
  };

  const handleChangeMonthly = () => {
    setSchedule('monthly');
  };

  const startTrial = (plan) => {
    api
      .createCheckoutSession(`${plan}-${schedule}`, null, true)
      .then(([ok, response]) => {
        if (ok) {
          if (window.self !== window.top) {
            // We're in an iframe
            window.open(response.session_url, '_blank');
          } else {
            // We're not in an iframe
            window.location.href = response.session_url;
          }
        } else {
          enqueueSnackbar(
            'Something went wrong. Please try again or contact us.',
            { variant: 'error' }
          );
        }
      });
  };

  if (
    (!site.plan.free_forever && !site.plan.free) ||
    site.created_at < '2024-08-09T00:00:00.000Z'
  ) {
    history.push(`/sites/${site.id}/summary`);
  }

  return (
    <React.Fragment>
      <div class="plan-selection">
        <div className="site-title">
          Select a plan to unlock the next level of Hello Bar for{' '}
          <b>{site.url}</b>.
        </div>
        <form className="upgrade-account-modal">
          <div className="billing-options">
            <span className="switch-label" onClick={handleChangeMonthly}>
              Monthly
            </span>
            <Switch
              onClick={handleChange}
              checked={schedule === 'yearly'}
              color="primary"
            />
            <span className="switch-label popular" onClick={handleChangeYearly}>
              Yearly
            </span>
            <img src={PathIcon} alt="" />
          </div>
          <div className="plans-holder">
            <div className="select-plans-headers">
              {/* <div>
                <div>Starter Plan</div>
                <div
                  className="pricing"
                  data-old-price={prices[schedule].starterOld}
                >
                  <div className="sign">$</div>
                  <div className="amount">{prices[schedule].starter}</div>
                  <div className="period">
                    / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                  </div>
                </div>
              </div>
              <div>
                <div>Expand Plan</div>
                <div
                  className="pricing"
                  data-old-price={prices[schedule].expandOld}
                >
                  <div className="sign">$</div>
                  <div className="amount">{prices[schedule].expand}</div>
                  <div className="period">
                    / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                  </div>
                </div>
              </div> */}
              <div>
                <div>Growth Plan</div>
                <div
                  className="pricing"
                  data-old-price={prices[schedule].growthOld}
                >
                  <div className="sign">$</div>
                  <div className="amount">{prices[schedule].growth}</div>
                  <div className="period">
                    / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                  </div>
                </div>
              </div>
              <div className="popular">
                <div>Premium Plan</div>
                <div
                  className="pricing"
                  data-old-price={prices[schedule].premiumOld}
                >
                  <div className="sign">$</div>
                  <div className="amount">{prices[schedule].premium}</div>
                  <div className="period">
                    / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                  </div>
                </div>
              </div>
              <div>
                <div>Elite Plan</div>
                <div
                  className="pricing"
                  data-old-price={prices[schedule].eliteOld}
                >
                  <div className="sign">$</div>
                  <div className="amount">{prices[schedule].elite}</div>
                  <div className="period">
                    / {schedule === 'monthly' ? 'MO' : 'YEAR'}
                  </div>
                </div>
              </div>
            </div>
            <div className="select-plans-footer">
              {/* <div
                className="plan-button"
                onClick={() => startTrial('starter_plus')}
              >
                Choose Starter
              </div>
              <div
                className="plan-button"
                onClick={() => startTrial('expand')}
              >
                Choose expand
              </div> */}
              <div
                className="plan-button"
                onClick={() => startTrial('growth_1')}
              >
                Start 14 days Free Trial
              </div>
              <div
                className="plan-button popular"
                onClick={() => startTrial('premium_1')}
              >
                Start 14 days Free Trial
              </div>
              <div
                className="plan-button"
                onClick={() => startTrial('elite_1')}
              >
                Start 14 days Free Trial
              </div>
            </div>
            {/* <p className="disclaimer">
              * Plans are subject to upgrading to next tier and overages when
              exceed volume limits.&nbsp;
              <a
                className="learn-more"
                rel="noreferrer"
                href="https://support.hellobar.com/support/solutions/articles/44001406917-hello-bar-overage-fee-breakdown"
                target="_blank"
              >
                Learn More.
              </a>
            </p> */}
            <p className="disclaimer">* No credit card required.</p>
            <p className="disclaimer">
              * You can cancel the Trial from your dashboard at any time.
            </p>
            <div className="select-plans-content">
              <div className="plans-row empty">
                {/* <div></div>
                <div></div> */}
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="plans-row">
                {/* <div>5,000 views / month</div>
                <div>15,000 views / month</div> */}
                <div>50,000 views / month</div>
                <div className="bold">150,000 views / month</div>
                <div className="bolder">500,000 views / month</div>
              </div>
              <div className="plans-row">
                {/* <div>3 active popups</div>
                <div>10 active popups popups</div> */}
                <div>Unlimited popups</div>
                <div>Unlimited popups</div>
                <div>Unlimited popups</div>
              </div>
              <div className="plans-row">
                {/* <div>Can remove HB logo</div>
                <div>Can remove HB logo</div> */}
                <div>Can remove HB logo</div>
                <div>Can remove HB logo</div>
                <div>Can remove HB logo</div>
              </div>
              <div className="plans-row">
                {/* <div>Basic design features</div>
                <div>Basic design features</div> */}
                <div>Basic design features</div>
                <div className="bold">Premium design features</div>
                <div className="bolder">Advanced design features</div>
              </div>
              <div className="plans-row">
                {/* <div>Basic targeting</div>
                <div>Basic targeting</div> */}
                <div>Basic targeting</div>
                <div className="bold">Premium targeting</div>
                <div className="bolder">Advanced targeting</div>
              </div>
              <div className="plans-row">
                {/* <div className="off">Unlimited A/B testing</div>
                <div>Unlimited A/B testing</div> */}
                <div>Unlimited A/B testing</div>
                <div>Unlimited A/B testing</div>
                <div>Unlimited A/B testing</div>
              </div>
              <div className="plans-row">
                {/* <div>Webhook integration</div>
                <div>Basic integrations</div> */}
                <div>Basic integrations</div>
                <div className="bold">Premium integrations</div>
                <div className="bolder">Advanced integrations</div>
              </div>
              <div className="plans-row">
                {/* <div className="off">Send popup events to GA</div>
                <div className="off">Send popup events to GA</div> */}
                <div>Send popup events to GA</div>
                <div>Send popup events to GA</div>
                <div>Send popup events to GA</div>
              </div>
              <div className="plans-row">
                {/* <div className="off">Premium customer support</div>
                <div className="off">Premium customer support</div> */}
                <div className="off">Premium customer support</div>
                <div>Premium customer support</div>
                <div>Premium customer support</div>
              </div>
              <div className="plans-row empty">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <p className="disclaimer footer">
              * You'll be downgraded to FREE FOREVER plan at the end of the
              trial period.
            </p>
          </div>
        </form>

        {/* <div className="free-plan-block">
          <h5>Free Plan</h5>
          <div className="plan-price">
            <div className="pricing">
              <div className="sign">$</div>
              <div className="amount">{prices[schedule].free}</div>
            </div>
          </div>
          <div className="plan-description">
            <ul>
              <li>5,000 views</li>
              <li>Unlimited popups</li>
              <li>
                <b>Can not remove HB logo</b>
              </li>
            </ul>
          </div>
          <div className="plan-action">
            <div
              className="free-plan-button"
              onClick={() => {
                history.push(`/sites/${site.id}/popups/new/interstitial`);
              }}
            >
              Choose Free
            </div>
          </div>
        </div> */}

        {/* <div className="free-plan-block">
          <h5>Free Plan</h5>
          <div className="plan-price">
            <div className="pricing">
              <div className="sign">$</div>
              <div className="amount">{prices[schedule].free}</div>
              <div className="period">
                / {schedule === 'monthly' ? 'MO' : 'YEAR'}
              </div>
            </div>
          </div>
          <div className="plan-description">
            <ul>
              <li>5,000 views / month</li>
              <li>Only 1 active popup</li>
              <li>Can not remove HB logo</li>
              <li>Limited design features</li>
            </ul>
          </div>
          <div className="plan-action">
            <div
              className="free-plan-button"
              onClick={() => {
                history.push(`/sites/${site.id}/popups/new/interstitial`);
              }}
            >
              Choose Free
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}
