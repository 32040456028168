import FormControl from "@mui/material/FormControl/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";

export default function NumberCondition({ condition, onChange }) {
  const operands =
    [
      { "value": "every", "label": "every" }
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'every' }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <FormControl variant="outlined">
        <TextField size="small" type="number" name="value" placeholder="No. of sessions" onChange={onChange} value={condition.value} variant="outlined"
               endAdornment={<InputAdornment position="end">sessions</InputAdornment>} InputProps={{ inputProps: { min: 0 } }}/>
      </FormControl>
    </div>
  );
}
