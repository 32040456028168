import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";

export default function OperandSelectBox({ operands, value, onChange }) {
  return (
    <FormControl variant="outlined" size="small">
      <Select name="operand" value={value} onChange={onChange}>
        {operands.map(operand => <MenuItem key={operand.label} value={operand.value}>{operand.label}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
