import React from "react";
import SegmentSelectBox from "../SegmentSelectBox";
import OperandSelectBox from "../OperandSelectBox";
import AutocompleteInput from "../AutoCompleteInput";

export default function GeoCondition({ condition, onChange, placeholder }) {
  const operands =
    [
      { "value": "is", "label": "is" },
      { "value": "is_not", "label": "is not" }
    ];

  if (!condition.operand) {
    onChange({ target: { name: 'operand', value: 'is' }});
  }

  if (condition.value && condition.value[0] === '') {
    onChange({ target: { name: 'value', value: [] }});
  }

  const handleChange = (value) => {
    onChange({ target: { name: 'value', value: value }});
  }

  return (
    <div className="condition-ruleset">
      <SegmentSelectBox value={condition.segment} onChange={onChange}/>
      <OperandSelectBox operands={operands} value={condition.operand} onChange={onChange}/>
      <AutocompleteInput condition={condition} onChange={handleChange} placeholder={placeholder}/>
    </div>
  );
}
