import styled from 'styled-components'
import React, {useContext, useState, useEffect} from 'react'
import {LabelSelector, ListWrapper, Title, TitleHint} from './components'
import SelectInput from "../components/SelectInput";
import {EditorContext, SiteContext} from "../../../utils/contexts";
import CreateRuleDialog from "../../Modals/CreateRuleDialog";
import { withEditorPageTracking } from "../../../shared/Tracking";

const CreateNewRuleButton = styled.div`
  color: #27ab9e;
  margin: 1em 0 2em 0;
  cursor: pointer;
`

function TargetingStep({className}) {
  const {settings, updateSettings, systemUpdate} = useContext(EditorContext)
  const site = useContext(SiteContext)
  const [rules, setRules] = useState(settings.site.rules.map(rule => ({value: rule.id, label: rule.name})))

  const updateRule = (value) => {
    updateSettings({rule_id: value})
  }

  useEffect(()=> {
    if (settings.show_thankyou === true) {
      systemUpdate({show_thankyou: false})
    }
  }, [settings.show_thankyou, systemUpdate])

  const setRule = (rule) => {
    setRules([...rules, {value: rule.id, label: rule.name}])
    updateSettings({rule_id: rule.id})
  }

  const setCustomRule = () => {
    systemUpdate({ custom_rule: true })
  }
  
  useEffect(() => {
    window.hellobar?.displayPage && window.hellobar?.displayPage('default')
  }, [])
  
  return (
    <div className={className}>
      <div>
        <Title>Targeting</Title>
        <TitleHint>Change who sees your Hello Bar.</TitleHint>
      </div>
      <ListWrapper>
        <LabelSelector>
          <span>Who should see this?</span>
          <SelectInput items={rules} value={settings.rule_id} onChange={updateRule}/>
        </LabelSelector>
        {site.plan.free && <CreateNewRuleButton onClick={setCustomRule}>Create new custom targeting rule</CreateNewRuleButton>}
        {!site.plan.free && <CreateRuleDialog onUpdate={setRule}
                          handler={<CreateNewRuleButton>Create new custom targeting rule</CreateNewRuleButton>}/>}
      </ListWrapper>
    </div>
  )
}

export default styled(withEditorPageTracking(TargetingStep, 'Targeting'))`
`
